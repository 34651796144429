import { createContext } from 'react';

export const FilterContext = createContext({
    filterParams: { },
    parseFilterParams: { },
    productOptionsData: { },
    setAsyncFilterParams: { },
    isOpen: false,
    filterOpen: () => { },
    filterClose: () => { },
    filterToggle: () => { },
    changeFilter: () => { },
    clearFilter: () => { },
    applyFilter: () => { }
});
