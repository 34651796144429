import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    CREATE_ASSETS, DELETE_PRODUCT_SUBSCRIPTION, GET_PRODUCT, GET_PRODUCT_CUSTOMER, UPDATE_CONVERSATION, UPDATE_PRODUCT,
    UPDATE_PRODUCT_SUBSCRIPTION
} from '_graphql';
import ProductDetails from './productDetails';
import { ProductCustomersTable } from './product-customers';
import { GeneralContext } from 'contexts';
import { ChatMessages, ImageSlider } from 'components';
import { useAuthToken, useGraphqlCache } from '_hooks';
import { IconPallets } from 'assets/images/svg';
import NewTaraModal from '../../../components/modals/new-tara-modal';

const PalletDetailsPage = () => {
    const { setLoading, toggleNewTaraModal } = useContext(GeneralContext);
    const { updateProductInCache } = useGraphqlCache();
    const { id } = useParams();
    const { userId } = useAuthToken();
    const location = useLocation();

    const { data, loading, error } = useQuery(GET_PRODUCT, { variables: { _id: id }, skip: !id });

    const { data: subsProductUpdate } = useSubscription(UPDATE_PRODUCT_SUBSCRIPTION, {
        variables: { _id: data?.product?._id || ' ' },
        shouldResubscribe: !!data?.product?._id
    });
    const { data: subsProductDelete } = useSubscription(DELETE_PRODUCT_SUBSCRIPTION, {
        variables: { _id: data?.product?._id || ' ' },
        shouldResubscribe: !!data?.product?._id
    });

    const [updateProduct, {
        data: updatedProduct,
        loading: updatingStatus,
        error: updateError
    }] = useMutation(UPDATE_PRODUCT);
    const [createAssets, { loading: assetsLoading, error: assetsError }] = useMutation(CREATE_ASSETS);

    const [chatConversation, setChatConversation] = useState(null);

    const [deletedImages, setDeletedImages] = useState([]);
    const [productData, setProductData] = useState(null);

    const {
        data: customerData,
        loading: customerLoading,
        error: customerError
    } = useQuery(GET_PRODUCT_CUSTOMER, {
        variables: {
            productId: id,
            customerId: userId,
            ownerId: productData?.userId?._id
        },
        skip: !id || !userId || productData?.ownerId?._id === userId,
        fetchPolicy: 'network-only'
    });

    const [updateConversation, {
        loading: closeLoading,
        error: closeError
    }] = useMutation(UPDATE_CONVERSATION, { fetchPolicy: 'network-only' });

    const closeChat = async (chatId = chatConversation._id) => {
        if (!chatId) {
            return;
        }
        await updateConversation({
            variables: { _id: chatId, data: { chatClosesUser: userId } }
        });
    };

    useEffect(() => {
        setProductData(data?.product || null);
    }, [data, updatedProduct]);

    useEffect(() => {
        const customer = customerData?.productCustomer;
        if (customer && customer.conversationId._id && customer.ownerId?._id !== userId) {
            setChatConversation(customer.conversationId);
        } else {
            setChatConversation(null);
        }
    }, [location, customerData]);

    useEffect(() => {
        const product = subsProductDelete?.productDeleted;
        if (product && data?.product && product._id === data?.product?._id) {
            updateProductInCache({ product: { isDeleted: true }, variables: { _id: id } });
        }
    }, [subsProductDelete]);

    useEffect(() => {
        const product = subsProductUpdate?.productUpdated;
        if (product && data?.product && product._id === data?.product?._id) {
            updateProductInCache({ product, variables: { _id: id } });
        }
    }, [subsProductUpdate]);

    useEffect(() => {
        setLoading((loading && !error) && (updatingStatus && !updateError) && (assetsLoading && !assetsError) && (customerLoading && !customerError) && (closeLoading && !closeError));
    }, [loading, error, updatingStatus, updateError, assetsLoading, assetsError, customerLoading, customerError, closeLoading, closeError]);

    const productUpdate = async (formData, updateImages) => {
        const variables = { _id: id, data: formData };
        let product = { ...productData };

        if (deletedImages.length > 0) {
            variables.removeAssetList = deletedImages;
        }

        await updateProduct({
            variables, update: (cache, { data }) => {
                if (data.updateProduct) {
                    product = { ...product, ...data.updateProduct };
                }
            }
        });

        if (updateImages?.length) {
            await createAssets({
                variables: { data: { model: 'product', itemId: id }, files: updateImages },
                update: (assetCache, { data: assetsData }) => {
                    if (assetsData?.createAssets) {
                        product = { ...product, assets: [...product.assets, ...assetsData?.createAssets] };
                    }
                }
            });
        }

        updateProductInCache({
            product,
            variables: { _id: id },
            success: () => {
                toggleNewTaraModal();
                setDeletedImages([]);
            }
        });
    };

    return (
        <>
            {!productData || productData?.isDeleted || (!productData?.isActivated && userId !== productData?.userId?._id) ? (
                <div className='flex flex-row h-full scrollable gap-x-5'>
                    <div className='w-full h-full flex justify-center items-center'>
                        <p className='text-lg uppercase text-gray-300 text-center'>
                            {!productData && 'PRODUCT NOT FOUND'}
                            {productData?.isDeleted && ('product is deleted')}
                            {productData && !productData?.isDeleted && !productData?.isActivated && ('product is deactivated')}
                        </p>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col h-full gap-4'>
                    <div className='flex-grow-0 h-104 w-full flex flex-row gap-6 font-sans'>
                        <div
                            className={`${!productData?.assets?.length ? 'bg-theme-gray-main rounded-2xl' : ''} overflow-hidden min-w-90 max-w-90`}>
                            <ImageSlider
                                images={productData?.assets?.map(({ _id, assetUrl }) => ({
                                    image: assetUrl,
                                    _id
                                })) || []}
                                noImageIcon={<IconPallets/>}
                                onlyView={true}
                            />
                        </div>
                        <div className='bg-theme-gray-main rounded-xl shadow-main overflow-hidden w-full'>
                            <ProductDetails
                                productData={productData}
                                toggleEditProductModal={toggleNewTaraModal}
                            />
                        </div>
                    </div>
                    <div
                        className='flex-grow-0 h-full max-h-1/2 clear-both max-h-full w-full flex flex-row gap-6 font-sans'>
                        <ProductCustomersTable
                            ownerId={productData?.userId?._id}
                            setChatConversation={setChatConversation}
                            chatConversation={chatConversation}
                        />
                        <div className={'h-full w-1/2 flex-grow row-span-2 rounded-xl bg-theme-gray-main shadow-main'}>
                            <ChatMessages closeChat={closeChat} selectedConversation={chatConversation}/>
                        </div>
                    </div>
                </div>
            )}
            <NewTaraModal editData={productData} setDeletedImages={setDeletedImages} productUpdate={productUpdate}/>
        </>
    );
};
export default PalletDetailsPage;
