import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSegment } from 'react-segment-hooks';
import { AUTH_API, AUTH_SOCKET_API, navigation } from '_constants';
import { useAuthToken } from '_hooks';
import io from 'socket.io-client';
import Swal from 'sweetalert2';
import useWindowDimensions from '_hooks/useWindowDimensions';

const useLogin = ({ service = 'google' }) => {
    const { setUserData, setAuthToken } = useAuthToken();
    const analytics = useSegment();
    const history = useHistory();

    const { width, height } = useWindowDimensions();

    const [loginWindow, setLoginWindow] = useState();
    const [userInfo, setUserInfo] = useState();
    const [disabled, setDisabled] = useState(false);
    const [socketId, setSocketId] = useState(null);
    const [intervalId, setIntervalId] = useState(null);

    useEffect(() => {
        const socket = io(AUTH_SOCKET_API, {
            transports: ['websocket'],
            rejectUnauthorized: false,
            jsonp: false
        });

        socket.on('connect', () => {
            console.log('socket_connected');
            setSocketId(socket.id);
        });
        socket.on('disconnect', () => {
            setSocketId(null);
        });
        socket.on('connect_error', (err) => {
            console.log(`socket_connect_error due to ${err.message}`);
        });
        socket.on(service, (response) => {
            const user = {
                ...response.currentUser,
                accessToken: response.accessToken,
                inBeta: response.inBeta
            };
            setUserInfo(user);
        });

        return () => {
            socket.disconnect();
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    // After user signed need to close window, save user data to cache and navigate to dashboard
    useEffect(() => {
        closeLoginWindow();

        if (userInfo) {
            const { accessToken, ...rest } = userInfo;

            if (accessToken) {
                analytics.identify({
                    userId: userInfo._id,
                    traits: {
                        viewport: `${width}x${height}`,
                        ...rest
                    }
                });

                setAuthToken(accessToken);

                // if (userInfo.username && userInfo.modifiedAt) {
                setUserData(rest);
                //     history.push(navigation.pages.app);
                // } else {
                history.push(navigation.nonAuth.login);
                // }
            } else {
                Swal.fire({
                    title: 'ERROR!',
                    text: 'INVALID TOKEN!',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        }
    }, [userInfo]);

    const loginService = () => {
        analytics.track({ event: `Clicked on “Sign in via ${service}” button` });

        if (!disabled) {
            const instance = openLoginWindow();
            setLoginWindow(instance);
            checkLoginWindow();
            setDisabled(true);
        }
    };

    const closeLoginWindow = () => {
        const id = setInterval(() => {
            if (loginWindow) {
                loginWindow.close();
            }
        }, 200);
        setIntervalId(id);
    };

    const checkLoginWindow = () => {
        setDisabled(false);
        const check = setInterval(() => {
            if (
                !loginWindow ||
                loginWindow.closed ||
                loginWindow.closed === undefined
            ) {
                clearInterval(check);
                setDisabled(false);
            }
        }, 500);
    };

    const openLoginWindow = () => {
        if (socketId) {
            const url = `${AUTH_API}/auth/${service}?socketId=${socketId}`;
            return window.open(url, '');
        }
        return null;
    };

    return loginService;
};

export default useLogin;
