import React, { useContext, useEffect } from 'react';
import BaseModal from '../index';
import { useLogout } from '_hooks';
import { GeneralContext } from 'contexts';
import { useSegment } from 'react-segment-hooks';

const LogoutModal = () => {
    const { logoutModal, closeLogoutModal, toggleSegmentButton } = useContext(GeneralContext);
    const analytics = useSegment();

    const logOut = useLogout();

    useEffect(() => {
        if (logoutModal) {
            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'Logout'
                }
            });
        }
    }, [logoutModal]);

    return (
        <BaseModal onClose={closeLogoutModal} isOpen={logoutModal}>
            <div className='sm:max-w-xs py-9 text-gray-900 mx-auto'>
                <p className='text-center font-bold w-full text-xl'>Log out</p>
                <p className='px-2 text-center'>Are you sure you want to log out?</p>
                <div className='flex justify-center mt-4'>
                    <button
                        className='w-32 py-2 self-stretch rounded mr-5 bg-transparent border  hover:text-gray-600 hover:bg-gray-200'
                        onClick={() => {
                            closeLogoutModal();
                            toggleSegmentButton('Cancel', 'Logout');
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className='w-32 py-2 self-stretch text-gray-900 rounded cursor-pointer hover: text-gray-600'
                        onClick={() => {
                            logOut();
                            toggleSegmentButton('Log out', 'Logout');
                        }}
                    >
                        Log out
                    </button>
                </div>
            </div>
        </BaseModal>
    );
};
export default LogoutModal;
