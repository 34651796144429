import React, {useContext} from 'react';
import {
    IconArrow6, IconNotifications, IconOffer, IconPersonName, IconTara, IconTruck, IconLogo, IconChat
} from 'assets/images/svg';
import {withTranslation} from 'react-i18next';
import {GeneralContext, MessagesContext, SubMenuContext} from 'contexts';
import {navigation} from '_constants';
import {getBasicLocationUtils} from 'utils';
import {NavLink} from 'react-router-dom';
import {useAuthToken} from '_hooks';

const SideBar = ({ t }) => {
    const { userData } = useAuthToken()
    const { unReadMessagesCount } = useContext(MessagesContext)
    const { setActiveSubMenu, activeSubMenu, setButtonsRefs } = useContext(SubMenuContext);
    const { toggleLogoutModal } = useContext(GeneralContext)

    const navClassName = ' py-1 cursor-pointer w-full flex flex-col justify-center items-center gap-y-3 border-r-2 border-transparent hover:bg-theme-blue-main hover:text-theme-blue hover:border-theme-blue duration-100';
    const navActiveClassName = ' text-theme-blue bg-theme-blue-main border-r-2 border-theme-blue';

    const sideBarButtonProps = (activeMenu) => {
        const active = getBasicLocationUtils(activeSubMenu) === activeMenu

        return ({
            onClick: () => setActiveSubMenu(activeMenu),
            className: active ? `${navClassName + navActiveClassName}` : navClassName,
            ref: (element) => setButtonsRefs(element, activeMenu)
        })
    }

    const sideBarNavProps = (activeMenu) => ({
        to: activeMenu,
        className: navClassName,
        activeClassName: navActiveClassName,
        ref: (element) => setButtonsRefs(element, activeMenu)
    })

    return (
        <>
            <div className='absolute inset-0 z-10 overflow-hidden overflow-y-auto rounded-xl shadow-main bg-theme-gray-main flex flex-col justify-between text-menu font-normal pb-3 pt-8'>
                <div className='flex-grow flex flex-col justify-start gap-y-7'>
                    <NavLink {...sideBarNavProps(navigation.pages.app)} exact>
                        <IconLogo/>
                    </NavLink>
                    <button {...sideBarButtonProps(navigation.pages.account)}>
                        <IconPersonName className="w-5 h-5 stroke-current"/>
                        <p className='truncate text-left'>{userData?.username}</p>
                    </button>
                    <button {...sideBarButtonProps(navigation.pages.pallets)} >
                        <IconTara className="stroke-current"/>
                        <p>{t('sidebar.menu.tara')}</p>
                    </button>
                    <button {...sideBarButtonProps(navigation.pages.delivery)}>
                        <IconTruck className="stroke-current"/>
                        <p>{t('sidebar.menu.delivery')}</p>
                    </button>
                    <button {...sideBarButtonProps(navigation.pages.proposals)}>
                        <IconOffer className="stroke-current"/>
                        <p>{t('sidebar.menu.proposals')}</p>
                    </button>
                    <NavLink {...sideBarNavProps(navigation.pages.messenger)}>
                        <div className='relative flex items-center justify-center pt-1 px-3'>
                            <IconChat className="stroke-current"/>
                            <div className={`absolute ${unReadMessagesCount > 0 ? 'opacity-100' : 'opacity-0'} rounded-full ${unReadMessagesCount > 99 ? 'h-5 w-5' : 'h-4 w-4'} bg-theme-red top-0 right-0 flex items-center justify-center text-white duration-100`}>
                                {unReadMessagesCount > 99 ? '99+' : (unReadMessagesCount || 0)}
                            </div>
                        </div>
                        <p>{t('subMenu.message')}</p>
                    </NavLink>
                    <button className={navClassName + ' hidden'} onClick={() => {}}>
                        <div className='relative flex items-center justify-center pt-1 px-3'>
                            <IconNotifications className="stroke-current"/>
                            <div className='absolute rounded-full h-4 w-4 bg-theme-red top-0 right-0 flex items-center justify-center text-white'>8</div>
                        </div>
                        <p>{t('sidebar.profile.notification')}</p>
                    </button>
                </div>
                <button className='py-1 flex-none cursor-pointer w-full text-theme-blue flex flex-col justify-center items-center gap-y-5 hover:bg-theme-blue-main border-r-2 border-transparent hover:border-theme-blue duration-100' onClick={toggleLogoutModal}>
                    <IconArrow6 className="stroke-current"/>
                    <p>{t('sidebar.menu.logout')}</p>
                </button>
            </div>
        </>
    )
}

export default withTranslation()(SideBar)
