import { gql } from '@apollo/client';
import {TYPE_CURRENT_USER} from '_graphql/types';

export const CREATE_USER_MUTATION = gql`
    mutation createUser(
            $email: String!
            $username: String!
            $password: String!
    ){
        createUser(
            email: $email
            username: $username
            password: $password
        ){
            accessToken
            currentUser {
                ${TYPE_CURRENT_USER}
            }
        }
    }
`;

