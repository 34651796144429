import React from 'react';
import {columnName} from 'components/table/configuration';

export default ({ t }) => ({
    name: columnName.phones,
    title: t('table.header.phoneNum'),
    excelValue: (item) => item?.phones?.at(0) || '',
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1 align-top text-left'}>
                <p className='whitespace-nowrap font-medium text-xs text-black'>
                    {this.title}
                </p>
            </th>);
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'px-1 max-w-20 lg+:max-w-30 w-max-60'}>
                {item?.phones?.length ? item.phones.slice(0, 2).map((item) => (<p key={item}>{item}</p>)) : (
                    <p>{'-'}</p>)}
            </td>
        );
    },
    tabletTitle: function ({ _id }) {
        return (
            <p key={`title_${this.name}${_id}`}
               className='whitespace-nowrap font-medium'>{this.title}</p>
        );
    },
    tabletContent: function ({ _id, phones }) {
        return (
            <p key={`content_${this.name}${_id}`}>{phones?.length ? phones[0] : '-'}</p>);
    }
})
