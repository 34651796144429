import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_OPTIONS_LIST } from '_graphql';
import { useLocation } from 'react-router-dom';
import { storageNames } from '_constants';
import { checkStringUtils, hasOwnPropertyUtils } from 'utils';
import {columnName} from 'components';

const initialState = {
    username: null,
    userIds: [],
    customerIds: [],
    ownerIds: [],
    orderStatus: {
        rejected: false,
        approved: false
    },
    createdAt: {
        from: null,
        to: null
    },
    updatedAt: {
        from: null,
        to: null
    },
    minCount: 0,
    maxCount: 0,
    isTax: {
        with: false,
        withOut: false
    },
    isOwnerTransport: {
        with: false,
        withOut: false
    },
    isBuy: {
        buy: false,
        sell: false
    },
    isCertificate: {
        with: false,
        withOut: false
    },
    city: null,
    price: {
        from: 0,
        to: 0
    },
    productTypes: [],
    productLoads: [],
    productSorts: [],
    productSizes: [],
    productPalletTypes: [],
    productBrands: [],
    paymentType: null,
    customSize: false,
    customLoad: false,
    customSort: false,
    customPalletType: false
};

const useFilter = () => {
    const location = useLocation();

    const filterParams = JSON.parse(localStorage.getItem(storageNames.filterOptions));

    const [filterPanel, setFilterPanel] = useState(false);

    const { data: optionsListData } = useQuery(GET_PRODUCT_OPTIONS_LIST, {
        variables: { page: 1 },
        errorPolicy: 'all',
        fetchPolicy: 'cache-first'
    });

    const [filter, setFilter] = useState(filterParams);
    const [parseFilter, setParseFilter] = useState(null);
    const [asyncFilter, setAsyncFilter] = useState(null);

    const currentFilter = useMemo(() => {
        if (filter && hasOwnPropertyUtils(filter, location.pathname)) {
            return filter[location.pathname];
        }
        return initialState;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, filter]);

    useEffect(() => {
        localStorage.setItem(storageNames.filterOptions, JSON.stringify(filter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parseFilter]);

    useEffect(() => {
        applyFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        if (asyncFilter && Object.keys(asyncFilter).length > 0) {
            Object.keys(asyncFilter).forEach((item) => {
                setCurrentFilter(asyncFilter[item], item);
            });

            setAsyncFilter(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asyncFilter]);

    useEffect(() => {
        setAsyncFilter(null);
    }, [location]);

    const productUniqOptions = useMemo(() => {
        const productOptions = optionsListData?.productOptions;
        const productTypes = productOptions?.productTypes?.rows || [];
        const productPalletTypes = productOptions?.productPalletTypes?.rows || [];
        const productBrands = productOptions?.productBrands?.rows || [];
        const paymentTypes = productOptions?.paymentTypes?.rows || [];

        let result = {
            productPalletTypes: [
                ...productPalletTypes?.map((item) => ({
                    ...item,
                    checked: currentFilter.productPalletTypes?.includes(item._id)
                })),
                { _id: 'other', checked: currentFilter.customPalletType, inputName: 'customPalletType' }
            ],
            productBrands: productBrands?.map((item) => ({
                ...item,
                checked: currentFilter.productBrands?.includes(item._id)
            })),
            paymentTypes: paymentTypes?.map((item) => ({ ...item, checked: currentFilter.paymentType === item._id }))
        };

        if (productTypes) {
            const types = productTypes.map((item) => ({
                ...item,
                checked: currentFilter.productTypes?.includes(item._id)
            }));
            const productSizes = [...new Set(productTypes.flatMap(({ productSize }) => productSize))].map((item) => ({
                ...item,
                checked: currentFilter.productSizes?.includes(item._id)
            }));
            const productSorts = [...new Set(productTypes.flatMap(({ productSort }) => productSort))].map((item) => ({
                ...item,
                checked: currentFilter.productSorts?.includes(item._id)
            }));
            const productLoads = [...new Set(productTypes.flatMap(({ productLoad }) => productLoad))].map((item) => ({
                ...item,
                checked: currentFilter.productLoads?.includes(item._id)
            }));

            if (productTypes.some((item) => item.isCustomSize)) {
                productSizes.push({ _id: 'other', checked: currentFilter.customSize, inputName: 'customSize' });
            }
            if (productTypes.some((item) => item.isCustomSort)) {
                productSorts.push({ _id: 'other', checked: currentFilter.customSort, inputName: 'customSort' });
            }
            if (productTypes.some((item) => item.isCustomLoad)) {
                productLoads.push({
                    _id: 'other',
                    checked: currentFilter.customLoad,
                    inputName: 'customLoad',
                    index: 999
                });
            }
            result = {
                ...result,
                productSizes,
                productSorts,
                productLoads: productLoads.sort((a, b) => a.index - b.index),
                productTypes: types
            };
        }

        return result;
    }, [optionsListData, currentFilter]);

    const setCurrentFilter = (params, key = location.pathname) => {
        setFilter((prev) => {
            const prevState = prev && prev[key] ? prev[key] : initialState;
            return ({ ...prev, [key]: { ...prevState, ...params } });
        });
    };

    const applyFilter = (params = currentFilter) => {
        const newFilterParams = {};

        Object.keys(params).forEach((key) => {
            const value = params[key]

            if (!!value && Array.isArray(value) && value.length > 0) {
                newFilterParams[key] = value.filter(Boolean)
            }
            if (typeof value === 'number' && value > 0) {
                newFilterParams[key] = Number(value)
            }
            if (key === 'city' && !!value && value.placeId) {
                newFilterParams[key] = value.placeId
            }
            if (
                (typeof value === 'boolean' && !!value) ||
                (key === 'username' && checkStringUtils(value)) ||
                ((key === 'createdAt' || key === 'updatedAt') && value?.from && value?.to)
            ) {
                newFilterParams[key] = value
            }
            if (
                (key === 'orderStatus' && (value?.rejected || value?.approved) && value?.rejected !== value?.approved) ||
                ((key === 'isTax' || key === 'isCertificate') && (value?.with || value?.withOut) && value?.with !== value?.withOut) ||
                (key === 'isBuy' && (value?.buy || value?.sell) && value?.buy !== value?.sell)
            ) {
                if (value.approved || value.with || value.buy) {
                    newFilterParams[key] = true;
                }
                if (value.rejected || value.withOut || value.sell) {
                    newFilterParams[key] = false;
                }
            }
            if (key === 'price' && value && (value?.from > 0 || params['city']?.to > 0)) {
                if (value.from > 0) {
                    newFilterParams[key].from = Number(value.from);
                }
                if (value.to > 0) {
                    newFilterParams[key].to = Number(value.to);
                }
            }
        })

        setParseFilter(newFilterParams);
    };

    const saveToFilter = (field, data) => setCurrentFilter({ ...currentFilter, [field]: data });

    const onChangeDate = (array, field = columnName.createdAt) => {
        saveToFilter(field, { from: array[0], to: array[1] });
    };

    const clearFilter = async (field = false) => {
        let state = initialState;

        if (field) {
            const fields = Array.isArray(field) ? field : [field];
            state = fields.reduce((acc, item) => ({ ...acc, [item]: initialState[item] }), currentFilter);
        }

        await setCurrentFilter(state);
        await applyFilter(state);
    };

    const filterContextValue = {
        filterParams: currentFilter,
        parseFilterParams: parseFilter,
        setAsyncFilterParams: setAsyncFilter,
        isOpen: filterPanel,
        filterOpen: () => setFilterPanel(true),
        filterClose: () => setFilterPanel(false),
        filterToggle: () => setFilterPanel((prev) => !prev),
        changeFilter: setCurrentFilter,
        clearFilter,
        applyFilter,
        onChangeDate,
        saveToFilter,
        initialStateFilter: initialState,
        optionsListData,
        productUniqOptions
    };

    return {
        filterContextValue,
        filterPanel
    };
};

export default useFilter;
