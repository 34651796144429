import {gql} from '@apollo/client';
import {TYPE_AVATAR, TYPE_LOCATION} from '../../types';

export const UPDATE_USER = gql`
    mutation UpdateUser(
        $_id:ID!, 
        $data: UpdateUserInput,
        $file: Upload
    ){
        updateUser(
            _id: $_id,
            data: $data,
            file: $file
        ){
            _id
            username
            email
            phones
            birthdayDate
            gender
            location {
                ${TYPE_LOCATION}
            }
            streetName
            houseNumber
            ranking
            avatar {
                ${TYPE_AVATAR}
            }   
            description {
                userDesc
                companyDesc
            }
            createdAt
            modifiedAt
        }
    }
`;
