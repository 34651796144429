import React, { useContext, useEffect } from 'react';
import BaseModal from '../index';
import { useMutation } from '@apollo/client';
import { useGraphqlCache } from '_hooks';
import { DELETE_ASSET, DELETE_MESSAGE } from '_graphql';
import { GeneralContext, MessagesContext } from 'contexts';
import { useSegment } from 'react-segment-hooks';

const RemoveMessageModal = ({ onClose, isOpen, message, pagination, conversationId }) => {
    const analytics = useSegment();
    const { setLoading, toggleSegmentButton } = useContext(GeneralContext);
    const { selectedConversation } = useContext(MessagesContext);
    const { updateMessageInCache, updateConversationListInCache } = useGraphqlCache();

    const [removeMessage, { loading, error }] = useMutation(DELETE_MESSAGE);
    const [deleteAssets, { loading: deleteAssetLoading, error: deleteAssetError }] = useMutation(DELETE_ASSET);

    useEffect(() => {
        if (isOpen) {
            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'Remove Message'
                }
            });
        }
    }, [isOpen]);

    useEffect(() => {
        setLoading((loading && !error) || (deleteAssetLoading && !deleteAssetError));
    }, [loading, error, deleteAssetLoading, deleteAssetError]);

    const applyRemoveMessage = () => {
        toggleSegmentButton('Remove', 'Remove Message');
        removeMessage({
            variables: { _id: message._id },
            update: async (cache, { data }) => {
                const removeMessage = data?.removeMessage ? { ...data?.removeMessage } : null;

                if (removeMessage) {
                    if (removeMessage.assets?.length > 0) {
                        await deleteAssets({ variables: { _id: message.assets[0]._id } });
                        removeMessage.assets = null;
                    }

                    updateMessageInCache({
                        message: removeMessage,
                        variables: pagination,
                        options: { remove: true }
                    });

                    if (selectedConversation?.lastMessageId?._id === removeMessage?._id) {
                        updateConversationListInCache({
                            conversation: {
                                ...conversationId,
                                lastMessageId: { ...conversationId.lastMessageId, isDeleted: true }
                            },
                            options: { change: true }
                        });
                    }
                }
            }
        }).finally(() => {
            onClose();
        });
    };

    return (
        <BaseModal onClose={onClose} isOpen={isOpen}>
            <div className='sm:max-w-xs py-9 text-gray-900 mx-auto text-center'>
                <p className='font-bold w-full text-xl uppercase'>Remove message</p>
                <p className='px-2'>Are you sure you want to remove this message
                    = &ldquo;{message?.message}&ldquo; ?</p>
                <div className='flex justify-center mt-4'>
                    <button
                        className='w-32 py-2 self-stretch rounded mr-5 bg-transparent border  hover:text-gray-600 hover:bg-gray-200'
                        onClick={() => {
                            onClose();
                            toggleSegmentButton('Cancel', 'Remove Message');
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className='w-32 py-2 self-stretch text-gray-900 rounded cursor-pointer hover: text-gray-600'
                        onClick={applyRemoveMessage}
                    >
                        Remove
                    </button>
                </div>
            </div>
        </BaseModal>
    );
};
export default RemoveMessageModal;
