import React, { useContext } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { navigation } from '_constants';
import {
    IconAllPallet, IconArrow6, IconChat, IconDelivery, IconLeaf, IconLeaf2, IconLeaf3, IconLogo, IconMyPallet, IconNote,
    IconPeople, IconPersonName
} from 'assets/images/svg';
import { GeneralContext, LocaleContext, MessagesContext } from 'contexts';
import { useSegment } from 'react-segment-hooks';

const TopMenu = ({ t }) => {
    const analytics = useSegment();
    const location = useLocation();
    const history = useHistory();
    const { toggleLogoutModal, toggleNewTaraModal, userInfo } = useContext(GeneralContext);
    const { unReadMessagesCount } = useContext(MessagesContext);
    const { locale, changeLocale, localeItems } = useContext(LocaleContext);

    const linkClassName = 'h-full flex flex-col justify-center gap-y-1.5 items-center hover:text-theme-blue text-black duration-100';
    const linkActiveClassName = 'text-theme-blue';

    const clickedToSubMenu = (event, properties = null) => {
        analytics.track({ event: `Select "${event}" from Top menu`, properties });
    };

    const newTaraModal = () => {
        clickedToSubMenu('Select “Add Suggestions” from Top menu');
        toggleNewTaraModal();
        if (location.pathname !== navigation.pages.activatePallets) {
            history.push(navigation.pages.activatePallets);
        }
    };

    const clickChangeLocale = (newLocale) => {
        clickedToSubMenu('Select “Change locale” from Top menu', { locale: newLocale });
        changeLocale(newLocale);
    };

    const clickLogout = () => {
        clickedToSubMenu('Select “Logout” from Top menu');
        toggleLogoutModal();
    };

    return (
        <div className='flex flex-row px-6 items-center justify-between text-xss h-full gap-x-6'>
            <div className='h-full flex flex-row items-center gap-x-6 md+:gap-x-2 lg+:gap-x-6'>
                <NavLink to={navigation.pages.app} exact className={linkClassName + ' mr-2 md+:mr-6'}>
                    <IconLogo/>
                </NavLink>
                <NavLink exact to={navigation.pages.pallets} activeClassName={linkActiveClassName}
                         className={linkClassName} onClick={() => clickedToSubMenu('Select "All Tara" from menu')}>
                    <IconAllPallet className='stroke-current fill-current'/>
                    <p className='whitespace-nowrap truncate md+:block xs:hidden'>{t('topMenu.allPallets')}</p>
                </NavLink>
                <NavLink exact to={navigation.pages.activatePallets} activeClassName={linkActiveClassName}
                         className={linkClassName}
                         onClick={() => clickedToSubMenu('Select “Activate Suggestions” from menu')}>
                    <IconMyPallet className='stroke-current fill-current'/>
                    <p className='whitespace-nowrap truncate md+:block xs:hidden'>{t('topMenu.activatePallets')}</p>
                </NavLink>
                <NavLink exact to={navigation.pages.myOrders} activeClassName={linkActiveClassName}
                         className={linkClassName} onClick={() => clickedToSubMenu('Select “My orders” from menu')}>
                    <IconLeaf className='stroke-current fill-current h-6'/>
                    <p className='whitespace-nowrap truncate md+:block xs:hidden'>{t('topMenu.myOrders')}</p>
                </NavLink>
                <NavLink exact to={navigation.pages.deactivatePallets} activeClassName={linkActiveClassName}
                         className={linkClassName}
                         onClick={() => clickedToSubMenu('Select “Deactivate Suggestions” from menu')}>
                    <IconLeaf2 className='stroke-current fill-current h-6'/>
                    <p className='whitespace-nowrap truncate md+:block xs:hidden'>{t('topMenu.deactivatePallets')}</p>
                </NavLink>
                <NavLink exact to={navigation.pages.archiveOrders} activeClassName={linkActiveClassName}
                         className={linkClassName}
                         onClick={() => clickedToSubMenu('Select “Archive orders” from menu')}>
                    <IconLeaf3 className='stroke-current fill-current h-6'/>
                    <p className='whitespace-nowrap truncate md+:block xs:hidden'>{t('topMenu.archiveOrders')}</p>
                </NavLink>
                <NavLink exact to={navigation.pages.messenger} activeClassName={linkActiveClassName}
                         className={linkClassName} onClick={() => clickedToSubMenu('Select “Chat” from menu')}>
                    <div className='relative flex items-center justify-center px-3'>
                        <IconChat className='stroke-current h-6'/>
                        <div
                            className={`absolute ${unReadMessagesCount > 0 ? 'opacity-100' : 'opacity-0'} rounded-full ${unReadMessagesCount > 99 ? 'h-5 w-5' : 'h-4 w-4'} bg-theme-red top-0 right-0 flex items-center justify-center text-white duration-100`}>
                            {unReadMessagesCount > 99 ? '99+' : (unReadMessagesCount || 0)}
                        </div>
                    </div>
                    <p className='whitespace-nowrap truncate md+:block xs:hidden'>{t('topMenu.chat')}</p>
                </NavLink>
                <NavLink exact to={navigation.pages.delivery} activeClassName={linkActiveClassName}
                         className={linkClassName} onClick={() => clickedToSubMenu('Select “Delivery” from menu')}>
                    <IconDelivery className='stroke-current h-6'/>
                    <p className='whitespace-nowrap truncate md+:block xs:hidden'>{t('topMenu.delivery')}</p>
                </NavLink>
            </div>
            <div className='h-full flex items-center '>
                <button className={linkClassName} onClick={newTaraModal}>
                    <IconNote className='stroke-current fill-current'/>
                    <p className='whitespace-nowrap truncate md+:block xs:hidden'>{t('topMenu.createProduct')}</p>
                </button>
            </div>

            <div className='h-full flex flex-row items-center gap-x-6 md+:gap-x-2 lg+:gap-x-6'>
                <NavLink exact to={navigation.pages.members} activeClassName={linkActiveClassName}
                         className={linkClassName} onClick={() => clickedToSubMenu('Select “Members” from menu')}>
                    <IconPeople className='stroke-current h-6'/>
                    <p className='md+:block xs:hidden whitespace-nowrap truncate text-left'>{t('topMenu.members')}</p>
                </NavLink>
                <NavLink exact to={navigation.pages.account} activeClassName={linkActiveClassName}
                         className={linkClassName} onClick={() => clickedToSubMenu('Select “Account” from menu')}>
                    <IconPersonName className='w-5 h-6 stroke-current'/>
                    <p className='md+:block xs:hidden whitespace-nowrap truncate text-left max-w-15'>{userInfo?.username}</p>
                </NavLink>
                <div className='flex flex-col justify-center gap-y-1'>
                    {localeItems.map((item) => (
                        <button
                            key={item.value}
                            onClick={() => clickChangeLocale(item.value)}
                            className={`uppercase ${locale === item.value ? 'text-theme-blue underline' : 'text-theme-blue-main'} hover:text-theme-blue-light duration-100`}
                        >
                            {item.value.slice(3)}
                        </button>
                    ))}
                </div>
                <button
                    className='flex-none cursor-pointer h-full text-theme-blue hover:text-theme-blue-light flex flex-col justify-center items-center gap-y-1.5 duration-100'
                    onClick={clickLogout}
                >
                    <IconArrow6 className='stroke-current h-6'/>
                    <p className='md+:block xs:hidden whitespace-nowrap truncate text-left'>{t('topMenu.logout')}</p>
                </button>
            </div>
        </div>
    );
};

export default withTranslation()(TopMenu);
