import React, { useContext, useEffect } from 'react';
import { IconSearch1 } from 'assets/images/svg';
import { withTranslation } from 'react-i18next';
import { FilterContext } from 'contexts';

const FilterClientInput = ({ t, setSpinnerState }) => {
    const { filterParams, saveToFilter } = useContext(FilterContext);
    useEffect(() => setSpinnerState(false), []);
    const saveToFiltersWithName = (e) => {
        const { value, name } = e.target;
        saveToFilter(name, value ? value : null);
    };
    return (
        <>
            <p className='mb-2'>{t('filterPanel.username')}</p>
            <div className='w-full relative'>
                <IconSearch1 className='w-4 h-4 absolute top-1/2 left-3 transform -translate-y-1/2'/>
                <input type='text' name='username'
                       className='w-full py-1.5 pl-9 pr-4 border border-theme-blue-main rounded-3xl bg-transparent'
                       value={filterParams.username || ''} onChange={saveToFiltersWithName}/>
            </div>
        </>
    );
};
export default withTranslation()(FilterClientInput);
