import React, { useContext, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { FilterContext, GeneralContext } from 'contexts';
import { useAuthToken, useGraphqlCache } from '_hooks';
import { useLazyQuery, useSubscription } from '@apollo/client';
import { GET_PRODUCT_CUSTOMERS } from '_graphql';
import { Table, columnName } from 'components';
import { OrderDocumentsModal } from 'components/modals';
import { useSegment } from 'react-segment-hooks';
import { navigation } from '_constants';
import { useHistory } from 'react-router-dom';
import { UPDATE_PRODUCT_CUSTOMER_SUBSCRIPTION } from '_graphql/subscriptions/products/productCustomerUpdated';

const ArchiveOrdersPage = ({ t }) => {
    const analytics = useSegment();
    const { tablePageSize, toggleOrderDocumentsModal } = useContext(GeneralContext);
    const { parseFilterParams } = useContext(FilterContext);
    const { userId } = useAuthToken();
    const history = useHistory();
    const { updateProductCustomersListInCache } = useGraphqlCache();

    const [orderAssets, setOrderAssets] = useState([]);

    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: tablePageSize,
        ownerAndCustomer: userId,
        isFinished: true,
        isApproved: true
    });

    const [getCustomersList, {
        data,
        loading,
        error
    }] = useLazyQuery(GET_PRODUCT_CUSTOMERS, { fetchPolicy: 'network-only' });

    const { data: updatedProductCustomer } = useSubscription(UPDATE_PRODUCT_CUSTOMER_SUBSCRIPTION);

    const products = useMemo(() => data?.productCustomers ? data?.productCustomers : {
        rows: [],
        totalPages: 0
    }, [data]);

    useEffect(() => {
        analytics.page({ name: 'Archive Orders Page' });
    }, []);

    useEffect(() => {
        let variables = pagination;
        if (parseFilterParams && Object.keys(parseFilterParams).length > 0) {
            variables = {
                ...variables,
                ...parseFilterParams
            };
            analytics.track({ event: 'Set Filter params in my order pallets page', properties: { data: variables } });
        }

        getCustomersList({ variables });
    }, [pagination, parseFilterParams]);

    useEffect(() => {
        const customer = updatedProductCustomer?.productCustomerUpdated;
        if (customer && (customer.customerId?._id === userId || customer.ownerId?._id === userId)) {
            updateProductCustomersListInCache({
                customer,
                variables: { ...pagination, ...parseFilterParams },
                options: { add: true }
            });
        }
    }, [updatedProductCustomer]);

    const handleClickRow = (item) => {
        if (!item?.productId?.isDeleted) {
            history.push(`${navigation.pages.pallets}/${item.productId._id}`);
        }
    };

    const handleOrderAssetsClick = (assets) => {
        if (assets?.length) {
            toggleOrderDocumentsModal();
            setOrderAssets(assets || []);
        }
    };

    return (
        <div className='flex-grow flex flex-row gap-x-5 h-full '>
            <Table
                tableName={'archiveOrders'}
                products={products}
                setPagination={setPagination}
                loader={loading && !error}
                show={{
                    columns: [
                        columnName.sequenceNumber,
                        columnName.createdAt,
                        { name: columnName.owner, title: t('table.header.ownerRequest') },
                        columnName.city,
                        columnName.isBuy,
                        { name: columnName.customer, title: t('table.header.client') },
                        columnName.productType,
                        columnName.productPalletType,
                        columnName.productSort,
                        columnName.productSize,
                        columnName.productLoad,
                        columnName.count,
                        columnName.price,
                        columnName.isTax,
                        columnName.paymentType,
                        { name: columnName.productBrands, defaultShow: false },
                        { name: columnName.isCertificate, defaultShow: false },
                        columnName.isOwnerTransport,
                        columnName.dateLoad,
                        { name: columnName.documents },
                        { name: columnName.updatedAt, title: t('table.header.orderDate'), archiveOrders: true }
                    ],
                    tabletSort: [
                        columnName.sequenceNumber,
                        columnName.createdAt,
                        { name: columnName.owner, title: t('table.header.ownerRequest') },
                        columnName.city,
                        { name: columnName.customer, title: t('table.header.client') },
                        columnName.productType,
                        columnName.productPalletType,
                        columnName.productSort,
                        columnName.productSize,
                        columnName.productLoad,
                        columnName.count,
                        columnName.price,
                        columnName.isTax,
                        columnName.paymentType,
                        { name: columnName.productBrands, defaultShow: false },
                        { name: columnName.isCertificate, defaultShow: false },
                        columnName.dateLoad,
                        { name: columnName.updatedAt, title: 'Час угоди' },
                        columnName.isBuy,
                        { name: columnName.documents }
                    ],
                    titleFilters: [columnName.owner, columnName.customer, columnName.price, columnName.dateLoad, columnName.updatedAt],
                    activeRows: [
                        {
                            value: (item) => !item?.productId?.isActivated,
                            className: 'bg-transparent',
                            hideClick: true
                        }
                    ],
                    downloadExcelFile: true
                }}
                change={{
                    handleClickRow,
                    handleOrderAssetsClick,
                    isActive: true,
                    filterVariables: { ...pagination, pageSize: 10000 }
                }}
            />
            <OrderDocumentsModal assets={orderAssets}/>
        </div>
    );
};

export default withTranslation()(ArchiveOrdersPage);
