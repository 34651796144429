import React, { useContext, useEffect, useMemo, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { GOOGLE_API_KEY } from '_constants';
import { withTranslation } from 'react-i18next';
import { toBase64Utils } from 'utils';
import { GeneralContext, LocaleContext } from 'contexts';
import { IconPallets } from 'assets/images/svg';
import { ImageSlider, UploadFileButton } from 'components';

const NewTaraModalRightBlock = ({
    t,
    onClose,
    hookForm,
    setFiles,
    isEdit,
    userWarehouses,
    setDeletedImages,
    formValues
}) => {
    const { locale } = useContext(LocaleContext);
    const { toggleSegmentButton } = useContext(GeneralContext);

    const assets = hookForm.getValues('assets')?.map(({ assetUrl, _id }) => ({ image: assetUrl, _id })) || [];

    const [uploadImages, setUploadImages] = useState(assets);

    const [isAddressInput, setIsAddressInput] = useState(!userWarehouses.length);

    useEffect(() => {
        setFiles(uploadImages.map(({ file }) => file).filter(Boolean));
    }, [uploadImages]);

    const onPlaceSelected = (place) => {
        if (place.formatted_address && place.place_id) {
            hookForm.setValue('city', { name: place.formatted_address, placeId: place.place_id });
        }
    };

    const { ref: autoCompleteRef } = usePlacesWidget({
        apiKey: GOOGLE_API_KEY,
        onPlaceSelected: onPlaceSelected,
        language: locale,
        options: {
            types: ['(cities)']
        }
    });

    const handleAddressSelect = (e) => {
        const selectedAddress = JSON.parse(e.target.value);
        setIsAddressInput(!selectedAddress?.streetName);
        hookForm.setValue('city', (selectedAddress?.name && selectedAddress?.placeId) ? {
            name: selectedAddress?.name,
            placeId: selectedAddress?.placeId
        } : '');
        autoCompleteRef.current.value = selectedAddress?.streetName ? selectedAddress?.name : '';
        hookForm.setValue('streetName', selectedAddress?.streetName || '');
        hookForm.setValue('houseNumber', selectedAddress?.houseNumber || '');
    };

    const addressList = useMemo(() => {
        const initArray = !userWarehouses?.length ? [] :
            [...userWarehouses, {
                name: t('modals.newTaraModal.addressInput'),
                default: true
            }];

        return initArray?.map((item) => ({
            default: item.name === hookForm.getValues('city')?.name,
            value: JSON.stringify({
                name: item.name,
                placeId: item.placeId,
                streetName: item.streetName,
                houseNumber: item.houseNumber
            }),
            ...item
        }));
    }, [userWarehouses]);

    const cancelNewTara = () => {
        toggleSegmentButton('Cancel', 'New Tara');
        onClose();
    };

    const saveNewTara = () => {
        toggleSegmentButton('Save', 'New Tara');
    };

    const uploadImagesFile = async (e) => {
        const imageCount = uploadImages?.length || 0;
        const { files } = e.target;
        if (imageCount <= 10 && files.length > 0) {
            const filesWithBase64 = [];

            for (let i = 0; files.length > i; i++) {
                if (imageCount + 1 <= 10) {
                    const file = files[i]
                    filesWithBase64.push({ file, image: await toBase64Utils(file), _id: i });
                } else {
                    break;
                }
            }
            setUploadImages((prev) => prev.length > 0 ? [...prev, ...filesWithBase64] : filesWithBase64);
        }
    };

    return (<div
        className='flex-none w-full xs:max-w-70 md++:max-w-newTaraRightBlock p-8 pb-8 text-theme-blue flex flex-col gap-y-4'>
        <p className='flex-grow-0 text-left text-lg font-medium'>{t('modals.newTaraModal.title')}</p>
        <div className='flex flex-col gap-y-4 mb-4'>
            {addressList?.length > 0 && (
                <select
                    className={'flex-grow-0 truncate w-full py-2 pl-3 pr-10 border border-theme-blue rounded-3xl bg-transparent bg-select-arrow'}
                    name={'warehouseSelect'}
                    defaultValue={isEdit ? addressList.find((address) => address.default)?.value : ''}
                    onChange={handleAddressSelect}>
                    {addressList?.map((item) => (
                        <option key={item.value} value={item.value}>
                            {!item?.streetName ? item?.name : `${item?.name} / ${item?.streetName} / ${item?.houseNumber}`}
                        </option>
                    ))}
                    <option hidden disabled key={'empty'} value={''}>
                        {t('modals.newTaraModal.addressSelect')}
                    </option>
                </select>
            )}
            <input{...hookForm.register('city')} disabled={!isAddressInput} type='text' name='city'
                  ref={autoCompleteRef}
                  defaultValue={hookForm.getValues('city')?.name || ''}
                  placeholder={formValues.isBuy ? t('modals.newTaraModal.cityDelivery') : t('modals.newTaraModal.cityUnloading')}
                  className={`flex-grow-0 w-full py-2 px-4 border ${hookForm?.errors?.city ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}
                  onChange={() => hookForm.setValue('city', {})}
            />
            <input
                {...hookForm.register('streetName')} disabled={!isAddressInput}
                type='text'
                name='streetName'
                placeholder={t('modals.newTaraModal.street')}
                className={`flex-grow-0 w-full py-2 px-4 border ${hookForm?.errors?.streetName ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}/>
            <input
                {...hookForm.register('houseNumber')} disabled={!isAddressInput}
                type='text'
                name='houseNumber'
                placeholder={t('modals.newTaraModal.house')}
                className={`flex-grow-0 w-full py-2 px-4 border ${hookForm?.errors?.houseNumber ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}
            />
        </div>
        <>
            <UploadFileButton
                onClick={() => toggleSegmentButton('Upload File', 'New Tara')}
                disabled={uploadImages?.length >= 10}
                onChange={uploadImagesFile}
            />
            <ImageSlider
                className={'h-20'}
                images={uploadImages}
                setDeletedImages={setDeletedImages}
                setImages={setUploadImages}
                noImageIcon={<IconPallets/>}
            />
        </>
        <div className='mt-auto flex justify-center mt-auto w-full'>
            <button type='button'
                    className='flex flex-row items-center justify-center w-1/2 bg-theme-blue-main rounded-3xl text-theme-blue h-10 px-7 text-sm mr-6 hover:bg-theme-gray-main duration-100'
                    onClick={cancelNewTara}>{t('modals.newTaraModal.cancel')}</button>
            <button type='submit'
                    className='flex flex-row items-center justify-center w-1/2 bg-theme-blue rounded-3xl text-white h-10 px-7 text-sm hover:bg-theme-blue-light duration-100'
                    onClick={saveNewTara}>{t('modals.newTaraModal.save')}</button>
        </div>
    </div>);
};

export default withTranslation()(NewTaraModalRightBlock);
