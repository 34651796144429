import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconArrow5, IconClose, IconFilterTitle} from 'assets/images/svg';
import {CustomCheckbox} from 'components/common';

export default ({
                    t,
                    onChangeSort,
                    checkSortField,
                    filterParams,
                    onOpenFilterPanel,
                    setRefTitle,
                    activeFilterPanel,
                    clearFilterParams,
                    applyFilterParams,
                    onChangeTransport
                }) => ({
    name: columnName.isOwnerTransport,
    title: t('modals.productDetails.transport.title'),
    titleFilter: true,
    excelValue: (item) => t(item?.isOwnerTransport ? 'modals.productDetails.transport.owner' : 'modals.productDetails.transport.client'),
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1 align-top'}>
                <div className='relative flex flex-row items-center justify-center'>
                    <div className='activeBtn flex flex-col items-center cursor-pointer justify-center'
                         onClick={(e) => onChangeSort(e, this.name)}>
                        <p className='whitespace-nowrap font-medium text-xs text-black'>{this.title}</p>
                        <div className='flex flex-row justify-center items-center'>
                            <IconArrow5
                                className={`h-2.5 w-2.5 fill-current text-theme-blue ${checkSortField(this.name)}`}/>
                            {this.titleFilter && (
                                <button
                                    className={`activeBtn text-theme-blue-light hover:text-theme-blue ml-1 ${filterParams?.isOwnerTransport?.with || filterParams?.isOwnerTransport?.withOut ? 'active' : ''}`}
                                    onClick={(e) => onOpenFilterPanel(e, this.name)}
                                >
                                    <IconFilterTitle className='stroke-current w-4 h-4'/>
                                </button>
                            )}
                        </div>
                    </div>
                    {this.titleFilter && (
                        <div
                            ref={(element) => setRefTitle(element, this.name)}
                            className={`absolute z-10 ${activeFilterPanel === this.name ? 'block' : 'hidden'} mt-3 text-theme-blue top-full -left-full text-xs font-normal bg-theme-gray-main shadow-main rounded-2xl py-4 gap-x-4 flex flex-col overflow-hidden w-60 px-3 text-left`}
                        >

                            <p className='w-15 mb-2'>{t('filterPanel.productType')}</p>
                            <div className='flex flex-col px-4 gap-y-4'>
                                <CustomCheckbox
                                    checked={filterParams.isOwnerTransport?.with}
                                    onChange={onChangeTransport}
                                    name='with'
                                    text={t('modals.productDetails.transport.owner')}
                                />
                                <CustomCheckbox
                                    checked={filterParams.isOwnerTransport?.withOut}
                                    onChange={onChangeTransport}
                                    name='withOut'
                                    text={t('modals.productDetails.transport.client')}
                                />
                            </div>
                            <div
                                className='w-full flex flex-row justify-around gap-x-4 mt-auto pt-4 text-theme-blue'>
                                <button
                                    className='flex flex-row items-center justify-center border border-theme-blue rounded-2xl text-theme-blue bg-transparent hover:bg-theme-blue-light hover:border-theme-blue-light hover:text-white h-8 w-full duration-100'
                                    onClick={() => clearFilterParams(this.name)}
                                >
                                    <IconClose className={'fill-current'}/>
                                    <p>{t('filterPanel.clear')}</p>
                                </button>
                                <button
                                    className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light h-8 w-full duration-100'
                                    onClick={applyFilterParams}>{t('filterPanel.apply')}</button>
                            </div>
                        </div>
                    )}
                </div>
            </th>
        );
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'whitespace-nowrap px-1'}>
                <div
                    className={'text-center text-menu font-normal rounded-3xl py-2.5 text-black'}
                >
                    {t(item?.isOwnerTransport ? 'modals.productDetails.transport.owner' : 'modals.productDetails.transport.client')}
                </div>
            </td>
        );
    },
    tabletTitle: () => null,
    tabletContent: function ({ _id, isOwnerTransport }) {
        return (
            <div
                key={`title_${this.name}${_id}`}
                className={`w-20 text-center text-menu font-normal rounded-3xl py-1.5 my-1.5 ${isOwnerTransport ? 'text-theme-blue bg-theme-blue-main' : 'text-white bg-theme-blue'}`}
            >
                {t(isOwnerTransport ? 'modals.productDetails.transport.owner' : 'modals.productDetails.transport.client')}
            </div>
        );
    }
})
