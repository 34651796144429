import React, { cloneElement, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { IconClose } from 'assets/images/svg';
import { LoadingSpinner } from 'components';

const AsyncFilter = ({ t, name, applyFilterParams, clearFilterParams, filterName, setRefTitle, children }) => {
    const [spinnerState, setSpinnerState] = useState(true);

    return (
        <>
            <div
                ref={(element) => setRefTitle(element, name)}
                className={`absolute z-10 mt-3 ${spinnerState ? 'invisible' : 'visible'} text-theme-blue top-full left-5 text-xs font-normal bg-theme-gray-main shadow-main rounded-2xl py-4 gap-x-4 flex flex-col w-60 px-3 text-left`}
            >
                {cloneElement(children, { setSpinnerState })}
                <div
                    className='w-full flex flex-row justify-around gap-x-4 mt-auto pt-4 text-theme-blue'>
                    <button
                        className='flex flex-row items-center justify-center border border-theme-blue rounded-2xl text-theme-blue bg-transparent hover:bg-theme-blue-light hover:border-theme-blue-light hover:text-white h-8 w-full duration-100'
                        onClick={() => clearFilterParams(filterName)}
                    >
                        <IconClose className={' fill-current'}/>
                        <p>{t('filterPanel.clear')}</p>
                    </button>
                    <button
                        className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light h-8 w-full duration-100'
                        onClick={applyFilterParams}>{t('filterPanel.apply')}</button>
                </div>
            </div>
            <LoadingSpinner loading={spinnerState}/>
        </>
    );
};

export default withTranslation()(AsyncFilter);
