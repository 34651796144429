import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconArrow5, IconClose, IconFilterTitle} from 'assets/images/svg';
import {CustomCheckbox} from 'components/common';

export default ({
                    t,
                    onChangeSort,
                    checkSortField,
                    filterParams,
                    onOpenFilterPanel,
                    setRefTitle,
                    activeFilterPanel,
                    clearFilterParams,
                    applyFilterParams,
                    onChangeOrderStatus
                }) => ({
    name: columnName.orderStatus,
    title: t('table.header.orderStatus'),
    excelValue: null,
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle flex justify-center py-3 px-1 align-top'}>
                <div className='relative flex flex-row items-center'>
                    <div className='activeBtn flex flex-col items-center cursor-pointer justify-center'
                         onClick={(e) => onChangeSort(e, this.name)}>
                        <p className='whitespace-nowrap font-medium text-xs text-black'>{this.title}</p>
                        <div className='flex flex-row justify-center items-center'>
                            <IconArrow5
                                className={`h-2.5 w-2.5 fill-current text-theme-blue ${checkSortField(this.name)}`}/>
                            {this.titleFilter && (
                                <button
                                    className={`activeBtn text-theme-blue-light hover:text-theme-blue ml-1 ${filterParams?.isBuy?.buy || filterParams?.isBuy?.sell ? 'active' : ''}`}
                                    onClick={(e) => onOpenFilterPanel(e, this.name)}
                                >
                                    <IconFilterTitle className='stroke-current w-4 h-4'/>
                                </button>
                            )}
                        </div>
                    </div>
                    {this.titleFilter && (
                        <div
                            ref={(element) => setRefTitle(element, this.name)}
                            className={`absolute z-10 top-full left-1/2 rounded-2xl flex flex-col w-60 px-3 mt-3 py-4 gap-x-4 text-left text-xs font-normal text-theme-blue bg-theme-gray-main shadow-main overflow-hidden ${activeFilterPanel === this.name ? 'block' : 'hidden'}`}
                        >
                            <p className='w-25 mb-2'>{t('filterPanel.orderStatusFilter.title')}</p>
                            <div className='flex flex-col pl-4 gap-y-4'>
                                <CustomCheckbox
                                    checked={filterParams?.orderStatus?.rejected}
                                    onChange={onChangeOrderStatus}
                                    name='rejected'
                                    text={
                                        <p className={'flex flex-grow w-full items-center gap-x-1'}>
                                                    <span
                                                        className={'h-4 w-4 inline-block rounded-full border border-theme-red bg-theme-red'}/>
                                            {'-'}
                                            <span>{t('filterPanel.orderStatusFilter.rejected')}</span>
                                        </p>
                                    }
                                />
                                <CustomCheckbox
                                    checked={filterParams?.orderStatus?.approved}
                                    onChange={onChangeOrderStatus}
                                    name='approved'
                                    text={
                                        <p className={'flex flex-grow w-full items-center gap-x-1'}>
                                                    <span
                                                        className={'h-4 w-4 inline-block rounded-full border border-green-600 bg-green-600'}/>
                                            {'-'}
                                            <span>{t('filterPanel.orderStatusFilter.inProgress')}</span>
                                        </p>
                                    }
                                />
                            </div>
                            <div
                                className='w-full flex flex-row justify-around gap-x-4 mt-auto pt-4 text-theme-blue'>
                                <button
                                    className='flex flex-row items-center justify-center border border-theme-blue rounded-2xl text-theme-blue bg-transparent hover:bg-theme-blue-light hover:border-theme-blue-light hover:text-white h-8 w-full duration-100'
                                    onClick={() => clearFilterParams(this.name)}
                                >
                                    <IconClose className={'fill-current'}/>
                                    <p>{t('filterPanel.clear')}</p>
                                </button>
                                <button
                                    className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light h-8 w-full duration-100'
                                    onClick={applyFilterParams}>{t('filterPanel.apply')}</button>
                            </div>
                        </div>
                    )}
                </div>
            </th>
        );
    },
    tbody: function (item) {
        const actualStatus = item?.isFinished && !item?.isApproved ? 'theme-red' : 'green-600';

        return (
            <td key={this.name + item._id} className={'w-1 whitespace-nowrap px-1 text-center'}>
                <div
                    className={`h-4 w-4 mx-auto rounded-full border border-${actualStatus} bg-${actualStatus}`}>{''}</div>
            </td>
        )
            ;
    },
    tabletTitle: () => null,
    tabletContent: () => null
})
