import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LocaleContext } from 'contexts';
import { checkCityUtils, checkEmailUtils, checkStringUtils } from 'utils';
import PhoneInput from 'react-phone-number-input';
import { withTranslation } from 'react-i18next';
import { GOOGLE_API_KEY } from '_constants';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useParams } from 'react-router-dom';
import { IconClose, IconPlus, IconTick2 } from 'assets/images/svg';
import WarehouseFrom from '../warehouse-from';
import { useSegment } from 'react-segment-hooks';

const UserInfo = ({ t, user, saveUserData, saveStatus }) => {
    const { id } = useParams();
    const { locale } = useContext(LocaleContext);
    const analytics = useSegment();

    const [isEditing, setIsEditing] = useState(false);
    const [userData, setUserData] = useState(null);
    const [errorFields, setErrorFields] = useState(null);
    const [newWarehouseForm, setNewWareHouseForm] = useState(false);
    const [userPhones, setUserPhones] = useState([]);

    const [newWarehouses, setNewWarehouses] = useState([]);
    const [deletedWarehouses, setDeletedWarehouses] = useState([]);

    useEffect(() => {
        setUserData(user);
        setUserPhones(user?.phones?.map((item, index) => ({ id: index, phone: item })) || []);
    }, [user]);

    useEffect(() => {
        if (!saveStatus) {
            setIsEditing(false);
        }
    }, [saveStatus]);

    const changeValue = (field, value) => {
        setUserData((prev) => ({ ...prev, [field]: value }));
    };

    const changePhone = (phone, id) => {
        setUserPhones((prev) => prev.map((value) => value.id === id ? ({ id, phone }) : value));
    };

    const validationFields = () => {
        const errors = [];

        if (!checkEmailUtils(userData?.email)) {
            errors.push('email');
        }

        if (userData?.location) {
            if (!checkCityUtils(userData?.location)) {
                errors.push('location');
            }
            if (!checkStringUtils(userData?.streetName)) {
                errors.push('streetName');
            }
            if (!checkStringUtils(userData?.houseNumber)) {
                errors.push('houseNumber');
            }
        }

        setErrorFields(errors?.length > 0 ? errors : null);
        return errors?.length > 0;
    };

    const saveValues = () => {
        if (validationFields()) {
            return;
        }

        const location = {
            name: userData?.location?.name,
            placeId: userData?.location?.placeId
        };

        const newData = {
            phones: userPhones.map(({ phone }) => phone),
            location,
            houseNumber: userData?.houseNumber,
            streetName: userData?.streetName,
            email: userData?.email
        };
        if (!location?.name) {
            newData.location = null;
            newData.houseNumber = null;
            newData.streetName = null;
        }

        const dataType = 'info';
        saveUserData(newData, { newWarehouses, deletedWarehouses }, dataType);
        setNewWarehouses([]);
        setDeletedWarehouses([]);
    };

    const cancelEditing = () => {
        setIsEditing(false);
        setUserData(user);
    };

    const saveNewWarehouse = (data) => {
        analytics.track({ event: 'Clicked on "save new warehouse"', properties: { data } });
        setNewWarehouses((prev) => [...prev, data]);
        setNewWareHouseForm(false);
    };

    const userWarehouses = useMemo(() => {
        const filtered = userData?.warehouses?.filter((item) => !deletedWarehouses.includes(item._id)) || [];
        return [...newWarehouses?.map((item, index) => ({
            ...item,
            _id: index,
            new: true
        })), ...filtered?.map((item) => ({ ...item, new: false }))];
    }, [userData?.warehouses, newWarehouses, deletedWarehouses, user]);

    const removeWareHouse = (item) => {
        analytics.track({ event: 'Clicked on "delete warehouse"' });
        if (item.new) {
            setNewWarehouses((prev) => prev.filter((value) => value._id === item._id));
        } else {
            setDeletedWarehouses((prev) => [...prev, item._id]);
        }
    };

    const toggleNewWarehouse = () => {
        analytics.track({ event: 'Clicked on "add new warehouse"' });
        setNewWareHouseForm(true);
    };

    const handleEdit = () => {
        setIsEditing(true);
        analytics.track({ event: 'Clicked on "edit user info"' });
    };

    const addPhones = () => {
        analytics.track({ event: 'Clicked on "add new phone"' });
        setUserPhones((prev) => [...prev, { id: prev.length, phone: '' }]);
    };

    const removePhones = (id) => {
        analytics.track({
            event: 'Clicked on "delete phone"',
            properties: { phone: userPhones?.find((phone) => phone.id === id).phone }
        });
        setUserPhones((prev) => prev.filter((value) => value.id !== id));
    };

    return (

        <div
            className='flex-grow rounded-xl shadow-main min-w-80 px-7 pt-10 pb-4 flex flex-col overflow-y-auto scrollable w-profileUserDetail'>
            <ul className='list-outside list-disc text-theme-blue flex flex-col gap-5'>
                <li>
                    <p className='flex flex-row items-center'>
                        {t('profile.phone')}
                        {isEditing && (<button className='text-theme-blue ml-2' onClick={addPhones}><IconPlus
                            className='fill-current'/></button>)}
                    </p>
                    <div className='flex flex-col gap-y-2 mt-3'>
                        {userPhones.length > 0 ? userPhones.map(({ id, phone }) => isEditing ? (
                            <div key={'phone' + id} className='flex flex-row justify-between items-center'>
                                <PhoneInput
                                    international
                                    defaultCountry={locale.split('-')[1]}
                                    placeholder={t('modals.newTaraModal.phone')}
                                    className={'bg-transparent text-black w-60 px-3 border h-6 text-xs border-theme-blue rounded-3xl overflow-hidden'}
                                    value={phone}
                                    onChange={(newPhone) => changePhone(newPhone, id)}
                                />
                                <button type='button' className='ml-2' onClick={() => removePhones(id)}>
                                    <IconClose/>
                                </button>
                            </div>
                        ) : (
                            <p key={'phone' + id} className='text-black'>{phone}</p>
                        )) : (
                            <p className={'text-black'}>{t('profile.notSpecified')}</p>
                        )}
                    </div>
                </li>
                <li>
                    <p className=''>{t('profile.address')}</p>
                    {isEditing ? (
                        <div className='flex flex-col'>
                            <CityInput changeValue={(field, value) => changeValue(field, value)} t={t} locale={locale}
                                       errorFields={errorFields} userData={userData}/>
                        </div>
                    ) : (
                        <p className='text-black mt-3'>{userData?.location?.name ? `${userData?.location?.name}, ${userData?.streetName}, ${userData?.houseNumber}` : t('profile.notSpecified')}</p>
                    )}
                </li>
                <li>
                    <p className=''>{t('profile.email')}</p>
                    <p className='text-black mt-3 flex flex-row items-center'>{userData?.email} {userData?.emailVerified && (
                        <IconTick2 className={'h-4 w-4 fill-current text-theme-blue ml-1'}/>)} </p>


                </li>
                <li>
                    <p className='flex flex-row items-center'>
                        {t('profile.warehouses')}
                        {isEditing && (
                            <button className='text-theme-blue ml-2' onClick={toggleNewWarehouse}><IconPlus
                                className='fill-current'/></button>)}
                    </p>
                    {newWarehouseForm && (
                        <WarehouseFrom
                            open={newWarehouseForm}
                            close={() => setNewWareHouseForm(false)}
                            save={saveNewWarehouse}
                        />
                    )}
                    <div className='text-black mt-3'>
                        {userWarehouses.length > 0 ? userWarehouses?.map((warehouse, index, all) => (
                            <div key={warehouse._id}
                                 className={`flex flex-row justify-between items-center py-1 ${(index + 1) < all.length ? 'border-b border-theme-blue-light' : ''}`}>
                                <p>{warehouse.name}, {warehouse.streetName}, {warehouse.houseNumber}</p>
                                {isEditing && (
                                    <button type='button' className='ml-2' onClick={() => removeWareHouse(warehouse)}>
                                        <IconClose/>
                                    </button>
                                )}
                            </div>
                        )) : (
                            <p>{t('table.rows.notSpecified')}</p>
                        )}
                    </div>
                </li>
            </ul>
            {!id &&
                (<div className='flex w-full justify-end gap-5 mt-auto'>
                    {isEditing ? (
                        <>
                            <button className='w-24 h-7 rounded-3xl bg-theme-blue text-white text-xs'
                                    onClick={cancelEditing}>{t('profile.cancel')}</button>
                            <button className='w-24 h-7 rounded-3xl bg-theme-blue text-white text-xs'
                                    onClick={saveValues}>{t('profile.save')}</button>
                        </>

                    ) : (
                        <button className='w-24 h-7 rounded-3xl bg-theme-blue text-white text-xs'
                                onClick={handleEdit}>{t('profile.edit')}</button>
                    )}

                </div>)
            }
        </div>
    );
};
export default withTranslation()(UserInfo);

const CityInput = ({ t, changeValue, locale, errorFields, userData }) => {
    const onPlaceSelected = (place) => {
        if (place.formatted_address && place.place_id) {
            changeValue('location', { name: place.formatted_address, placeId: place.place_id });
        }
    };

    const { ref: autoCompleteRef } = usePlacesWidget({
        apiKey: GOOGLE_API_KEY,
        onChange: () => changeValue('location', {}),
        onPlaceSelected: onPlaceSelected,
        language: locale,
        fields: {
            streetAddress: 'route',
            streetAddress2: 'administrative_level_4',
            locality: 'locality',
            cityOrState: 'administrative_area_level_1',
            postalcode: 'postal_code',
            country: 'country'
        },
        options: {
            types: ['(cities)']
        }
    });

    return (
        <div className='flex flex-col gap-3'>
            <input
                type='text'
                name='location'
                ref={autoCompleteRef}
                placeholder={t('profile.city')}
                defaultValue={userData?.location?.name || ''}
                onChange={(e) => changeValue('location', e?.target?.value)}
                className={`h-6 text-xs flex-grow-0 w-60 mt-3  px-3 border ${errorFields?.find((item) => item === 'location') ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}
            />
            {userData?.location?.name &&
                <>
                    <input
                        type='text'
                        className={`h-6 text-xs text-black mt-3 bg-transparent w-60 rounded-3xl border border-1 ${errorFields?.find((item) => item === 'streetName') ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} px-2`}
                        placeholder={t('profile.street')}
                        defaultValue={userData?.streetName || ''}
                        onChange={(e) => changeValue('streetName', e.target.value)}
                    />
                    <input
                        type='text'
                        className={`h-6 text-xs text-black mt-3 bg-transparent w-60 rounded-3xl border border-1 ${errorFields?.find((item) => item === 'houseNumber') ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} px-2`}
                        placeholder={t('profile.building')}
                        defaultValue={userData?.houseNumber || ''}
                        onChange={(e) => changeValue('houseNumber', e.target.value)}
                    />
                </>
            }
        </div>
    );
};

