import {
    cache, GET_CONVERSATIONS, GET_MESSAGES, GET_PRODUCT, GET_PRODUCT_CUSTOMERS, GET_PRODUCT_LIST, GET_USER
} from '_graphql';
import { useAuthToken } from '_hooks/useAuth';
import { messenger } from '_constants';

const defaultListKeys = {
    page: 1,
    pageSize: 1,
    rows: [],
    total: 1,
    totalPages: 1
}

const useGraphqlCache = () => {
    const { userId } = useAuthToken();

    const checkProps = (...rest) => !cache || rest.length <= 0 || rest.find((arg) => !arg)

    const checkDefaultListKeys = (items) => ({ ...defaultListKeys, ...items })

    const removeItemFromListById = (items, item) => ({
        ...items,
        rows: [...items.rows?.filter((row) => row._id !== item._id)],
        total: items.total - 1
    })

    const addItemToList = (items, item) => {
        const total = items.total + 1
        const validItemsInList = items.pageSize < total
        const rows = [item, ...items?.rows]

        return {
            ...items,
            rows: validItemsInList ? rows : rows.slice(items.pageSize - 1),
            total: validItemsInList ? total : items.total
        }
    }

    const updateItemInList = (items, item) => ({
        ...items,
        rows: items.rows.map((row) => row._id === item._id ? { ...row, ...item } : row)
    })

    const updateProductListInCache = ({
        product = null,
        variables = null,
        options: { remove = false, add = false, change = false },
        success = () => {},
        error = () => {}
    }) => {
        if (checkProps(product, variables, (remove || add || change))) {
            return
        }

        try {
            let { products = defaultListKeys } = cache.readQuery({ query: GET_PRODUCT_LIST, variables });
            const action = add ? addItemToList : remove ? removeItemFromListById : updateItemInList
            products = action(checkDefaultListKeys(products), product)
            cache.writeQuery({ query: GET_PRODUCT_LIST, variables, data: { products } });
            success();
        } catch (e) {
            console.error('Update product list in cache error: ', e);
            error();
        }
    };

    const updateProductInCache = ({
        product: newProduct = null,
        variables = null,
        success = () => {},
        error = () => {}
    }) => {
        if (checkProps(newProduct, variables)) {
            return;
        }

        try {
            const { product = {} } = cache.readQuery({ query: GET_PRODUCT, variables });
            cache.writeQuery({ query: GET_PRODUCT, variables, data: { product: { ...product, ...newProduct } } });
            success(newProduct);
        } catch (e) {
            console.error('Update product in cache error: ', e);
            error()
        }
    };

    const updateCustomersListInCache = ({
        customer = null,
        variables = null,
        success = () => {},
        error = () => {}
    }) => {
        if (checkProps(customer, variables)) {
            return;
        }

        try {
            let { productCustomers = defaultListKeys } = cache.readQuery({ query: GET_PRODUCT_CUSTOMERS, variables });
            productCustomers = updateItemInList(checkDefaultListKeys(productCustomers), customer)
            cache.writeQuery({ query: GET_PRODUCT_CUSTOMERS, variables, data: { productCustomers } });
            success();
        } catch (e) {
            console.error('Update customer list in cache error: ', e);
            error()
        }
    };

    const updateUserInfoInCache = ({
       userInfo = null,
       variables = { _id: userId },
       success = () => {},
       error = () => {}
    }) => {
        if (checkProps(userInfo)) {
            return;
        }

        try {
            let { user = {} } = cache.readQuery({ query: GET_USER, variables });
            user = { ...user, ...userInfo };
            cache.writeQuery({ query: GET_USER, variables, data: { user } });
            success();
        } catch (e) {
            console.error('Update User Info In Cache error: ', e);
            error()
        }
    };

    const updateConversationListInCache = ({
        conversation = null,
        options: { remove = false, add = false, change = false },
        success = () => {},
        error = () => {}
    }) => {
        if (checkProps(conversation, (remove || add || change))) {
            return;
        }

        try {
            let { conversations = defaultListKeys } = cache.readQuery({
                query: GET_CONVERSATIONS,
                variables: { senderId: userId, page: 1, pageSize: messenger.conversations.pageSize }
            });

            if (remove) {
                conversations = removeItemFromListById(conversations, conversation)
            } else {
                conversations = conversations.rows.some((item) => item._id === conversation._id) ?
                    updateItemInList(conversations, conversation) : addItemToList(conversations, conversation)
            }

            cache.writeQuery({
                query: GET_CONVERSATIONS,
                variables: { senderId: userId, page: 1, pageSize: messenger.conversations.pageSize },
                data: { conversations }
            });

            success();
        } catch (e) {
            console.error('Update Conversation List In Cache error: ', e);
            error()
        }
    };

    const updateMessageInCache = ({
        message = null,
        options: { remove = false, add = false, change = false },
        variables = null,
        success = () => {},
        error = () => {}
    }) => {
        if (checkProps(message, (remove || add || change))) {
            return;
        }

        try {
            let { messages = defaultListKeys } = cache.readQuery({ query: GET_MESSAGES, variables });
            const action = add ? addItemToList : remove ? removeItemFromListById : updateItemInList
            messages = action(checkDefaultListKeys(messages), message)
            cache.writeQuery({ query: GET_MESSAGES, variables, data: { messages } });
            success();
        } catch (e) {
            console.error('Update message in cache error: ', e);
            error();
        }
    };

    const updateMessagesSeenInCache = (readMessagesId, variables) => {
        if (checkProps(variables, readMessagesId?.length > 0)) {
            return;
        }

        try {
            let { messages = defaultListKeys } = cache.readQuery({ query: GET_MESSAGES, variables });
            if (messages?.rows?.some((item) => !item.seen)) {
                messages = {
                    ...messages,
                    rows: messages?.rows?.map((item) => !item.seen && readMessagesId.includes(item._id) ? {
                        ...item,
                        seen: true
                    } : item)
                };
                cache.writeQuery({ query: GET_MESSAGES, variables, data: { messages } });
            }
        } catch (e) {
            console.error('Update message seen in cache error: ', e);
        }
    };

    const updateProductCustomersListInCache = ({
        customer = null,
        variables = null,
        options: { remove = false, add = false, change = false },
        success = () => {},
        error = () => {}
    }) => {
        if (checkProps(customer, variables, (remove || add || change))) {
            return;
        }

        try {
            let { productCustomers = defaultListKeys } = cache.readQuery({ query: GET_PRODUCT_CUSTOMERS, variables });
            const action = add ? addItemToList : remove ? removeItemFromListById : updateItemInList
            productCustomers = action(checkDefaultListKeys(productCustomers), customer)
            cache.writeQuery({ query: GET_PRODUCT_CUSTOMERS, variables, data: { productCustomers }});
            success();
        } catch (e) {
            console.error('Update product list in cache error: ', e);
            error()
        }
    };

    return {
        updateProductListInCache,
        updateProductInCache,
        updateCustomersListInCache,
        updateUserInfoInCache,
        updateConversationListInCache,
        updateMessageInCache,
        updateMessagesSeenInCache,
        updateProductCustomersListInCache
    };
};

export default useGraphqlCache;
