import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconArrow5, IconClose, IconFilterTitle} from 'assets/images/svg';
import {CustomCheckbox} from 'components/index';

export default ({
                    t,
                    onChangeSort,
                    checkSortField,
                    filterParams,
                    onOpenFilterPanel,
                    setRefTitle,
                    activeFilterPanel,
                    clearFilterParams,
                    applyFilterParams,
                    productUniqOptions,
                    onChangeCheckBoxesList
                }) => ({
    name: columnName.productSize,
    title: t('table.header.size'),
    titleFilter: true,
    excelValue: (item) => item.productId ? (item.productId?.productSize?.name || item.productId?.customSize) : (item?.productSize?.name || item?.customSize) || '-',
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1 align-top'}>
                <div className='relative flex flex-row items-center'>
                    <div className='activeBtn flex flex-col items-center cursor-pointer justify-center'
                         onClick={(e) => onChangeSort(e, this.name)}>
                        <p className='whitespace-nowrap font-medium text-xs text-black'>{this.title}</p>
                        <div className='flex flex-row justify-center items-center'>
                            <IconArrow5
                                className={`h-2.5 w-2.5 fill-current text-theme-blue ${checkSortField(this.name)}`}/>
                            {this.titleFilter && productUniqOptions?.productSizes?.length > 0 && (
                                <button
                                    className={`activeBtn text-theme-blue-light hover:text-theme-blue ml-1 ${filterParams?.productSizes?.length > 0 || filterParams.customSize ? 'active' : ''}`}
                                    onClick={(e) => onOpenFilterPanel(e, 'productSizes')}
                                >
                                    <IconFilterTitle className='stroke-current w-4 h-4'/>
                                </button>
                            )}
                        </div>
                    </div>
                    {this.titleFilter && productUniqOptions?.productSizes?.length > 0 && (
                        <div
                            ref={(element) => setRefTitle(element, this.name)}
                            className={`absolute z-10 ${activeFilterPanel === 'productSizes' ? 'block' : 'hidden'} mt-3 text-theme-blue top-full left-5 text-xs font-normal bg-theme-gray-main shadow-main rounded-2xl py-4 gap-x-4 flex flex-col overflow-hidden min-w-100 px-3 text-left`}
                        >

                            <p className='mb-2'>{t('filterPanel.productSize')}</p>
                            <div className='w-full flex flex-row flex-wrap gap-y-1 overflow-y-auto'>
                                {productUniqOptions?.productSizes?.map(({ _id, name, checked, inputName }) => (
                                    <div key={_id} className='w-1/3'>
                                        <CustomCheckbox
                                            key={_id}
                                            checked={checked}
                                            name={inputName || 'productSizes'}
                                            text={_id === 'other' ? t('filterPanel.otherSize') : name}
                                            onChange={(e) => onChangeCheckBoxesList(e.target.name, _id)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div
                                className='w-full flex flex-row justify-around gap-x-4 mt-auto pt-4 text-theme-blue'>
                                <button
                                    className='flex flex-row items-center justify-center border border-theme-blue rounded-2xl text-theme-blue bg-transparent hover:bg-theme-blue-light hover:border-theme-blue-light hover:text-white h-8 w-full duration-100'
                                    onClick={() => clearFilterParams([this.name + 's', 'customSize'])}
                                >
                                    <IconClose className={'fill-current'}/>
                                    <p>{t('filterPanel.clear')}</p>
                                </button>
                                <button
                                    className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light h-8 w-full duration-100'
                                    onClick={applyFilterParams}>{t('filterPanel.apply')}</button>
                            </div>
                        </div>
                    )}
                </div>
            </th>
        );
    },
    tbody: function (item) {
        const name = item.productId ? (item.productId?.productSize?.name || item.productId?.customSize) : (item?.productSize?.name || item?.customSize) || '-';
        return (
            <td key={this.name + item._id} className={'px-1'}>
                <p className='truncate'>
                    {name}
                </p>
            </td>
        );
    },
    tabletTitle: function ({ _id }) {
        return (
            <p key={`title_${this.name}${_id}`}
               className='font-medium'>{this.title}</p>
        );
    },
    tabletContent: function (item) {
        const name = item.productId ? (item.productId?.productSize?.name || item.productId?.customSize) : (item?.productSize?.name || item?.customSize) || '-';
        return (
            <p key={`content_${this.name}${item._id}`} className='truncate'>{name}</p>
        );
    }
})
