import {columnName} from 'components/table/configuration';
import React from 'react';
import {IconProfile} from 'assets/images/svg';

export default ({ t }) => ({
    name: columnName.userImage,
    title: t('table.header.avatar'),
    excelValue: null,
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1'}/>
        );
    },
    tbody: function (item) {
        const src = item?.avatar ? item?.avatar?.assetUrl : item?.userId?.avatar?.assetUrl;
        return (
            <td key={this.name + item._id} className={'px-1'}>
                <p className='truncate max-w-20 text-theme-blue hover:text-blue-400'>
                    {src ? (
                        <img className='h-6 w-6 rounded-full ' src={src} alt={'user avatar'}/>
                    ) : (
                        <IconProfile className={'h-6 w-6'}/>
                    )}
                </p>
            </td>
        );
    },
    tabletTitle: null,
    tabletContent: null
})
