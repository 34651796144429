import {gql} from '@apollo/client';
import {TYPE_USERID} from '_graphql/types';

export const GET_NOTIFICATION_LIST = gql`
    query notifications(
        $userId: ID!
        $page: Int!
        $pageSize: Int
        $seen: Boolean
    ){
        notifications(
            userId: $userId
            page: $page
            pageSize: $pageSize
            seen: $seen
        ){
            rows {
                _id
                userId {
                    ${TYPE_USERID}
                }
                title
                message
                seen
                createdAt
                updatedAt
            }
            total
            page
            pageSize
            totalPages
        }
    }
`;
