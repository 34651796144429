import React, { useContext, useEffect, useState } from 'react';
import { IconPlus, IconProfile } from 'assets/images/svg';
import { toBase64Utils, urlToFileUtils } from 'utils';
import Avatar from 'react-avatar-edit';
import { useHistory, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { messenger, navigation } from '../../../_constants';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_CONVERSATION, GET_PRIVATE_CHAT } from '../../../_graphql';
import { MessagesContext } from '../../../contexts';
import { useAuthToken, useGraphqlCache } from '../../../_hooks';
import { useSegment } from 'react-segment-hooks';

const ProfileImage = ({ t, userData, onSave, status }) => {
    const { id } = useParams();
    const { userId, userName } = useAuthToken();
    const history = useHistory();
    const analytics = useSegment();

    const [uploadedImage, setUploadedImage] = useState(null);
    const [cropImage, setCropImage] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [defaultValue, setDefaultValue] = useState({});
    const [errors, setErrors] = useState(false);

    const { changeActiveConversation } = useContext(MessagesContext);

    const { fetchMore: fetchMoreConversation } = useQuery(GET_PRIVATE_CHAT);
    const { updateConversationListInCache } = useGraphqlCache();
    const [createConversation] = useMutation(CREATE_CONVERSATION);

    useEffect(() => {
        setDefaultValue(userData);
    }, [userData]);

    useEffect(() => {
        if (!status) {
            setIsEditing(false);
        }
    }, [status]);

    const uploadImageFile = async (e) => {
        const { files } = e.target;
        analytics.track({ event: 'Clicked on "upload new avatar"' });
        setUploadedImage(await toBase64Utils(files[0]));
    };

    const checkPrivateChat = async () => {
        try {
            const result = await fetchMoreConversation({
                variables: { recipientUserId: id }
            });

            return result?.data?.privateChat;
        } catch (error) {
            console.log('Error getting agreement info: ', error);
        }
    };

    const createPrivateChat = () => {
        const data = {
            members: [id, userId],
            type: messenger.types.private
        };

        createConversation({
            variables: { data },
            update: async (cache, result) => {
                const newConversation = result?.data?.createConversation;
                if (newConversation) {
                    await updateConversationListInCache({
                        conversation: newConversation,
                        options: { add: true }
                    });
                    changeActiveConversation(newConversation);
                    history.push(navigation.pages.messenger);
                }
            }
        });
    };

    const sendMsgToUser = async () => {
        if (!id) {
            return;
        }
        analytics.track({
            event: 'Clicked on "start chat"', properties: {
                initiator: {
                    id: userId,
                    username: userName
                },
                recipient: {
                    id,
                    username: defaultValue.username
                }
            }
        });
        const result = await checkPrivateChat();

        if (result) {
            changeActiveConversation(result);
            history.push(navigation.pages.messenger);
        } else {
            createPrivateChat(id);
        }
    };

    const changeValue = (fill, value) => {
        setErrors(false);
        setDefaultValue((prev) => ({ ...prev, [fill]: value }));
    };

    const cancelEditing = () => {
        setIsEditing(false);
        setUploadedImage(null);
        setCropImage(null);
        setDefaultValue(userData);
    };

    const cancelImgCrop = () => {
        setUploadedImage(null);
        setCropImage(null);
    };

    const saveImage = () => {
        setUploadedImage(null);
    };

    const saveData = async () => {
        if (!defaultValue.username) {
            setErrors(true);
            return;
        }
        let file = null;
        const user = {
            username: defaultValue.username
        };

        if (cropImage) {
            file = await urlToFileUtils(cropImage);
        }

        const dataType = 'avatar';
        onSave(user, { file }, dataType);
    };

    const handleEdit = () => {
        setIsEditing(true);
        analytics.track({ event: 'Clicked on "edit user avatar"' });
    };

    return (
        <div
            className='flex-grow-0 rounded-xl shadow-main min-w-80 p-5 flex flex-col items-end justify-between gap-8 overflow-y-auto scrollable'>
            {uploadedImage ? (
                <div className='flex flex-col items-center justify-center gap-5 w-full'>
                    <Avatar
                        width={170}
                        height={170}
                        imageHeight={170}
                        onCrop={setCropImage}
                        src={uploadedImage}
                        cropColor={'#2563EB'}
                        closeIconColor={'transparent'}
                        shadingColor={'#BFDBFE'}
                        lineWidth={5}
                        onClose={cancelImgCrop}
                    />
                    <div className='w-full flex justify-between items-center'>
                        <button className='w-24 h-7 rounded-3xl bg-theme-blue text-white' onClick={cancelImgCrop}>
                            {t('profile.cancel')}
                        </button>
                        <button className='w-24 h-7 rounded-3xl bg-theme-blue text-white' onClick={saveImage}>
                            {t('profile.choose')}
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <div className='w-full flex flex-col items-center justify-center gap-5'>
                        <div className='relative'>
                            {(userData?.avatar || cropImage) ? (
                                <img
                                    className='flex justify-center items-center rounded-full w-16 h-16 bg-theme-gray-light-1 border-solid border-4 border-theme-blue'
                                    src={cropImage ? (cropImage) : (defaultValue?.avatar?.assetUrl)} alt='user image'
                                />
                            ) : (
                                <div
                                    className='flex justify-center items-center rounded-full w-16 h-16 bg-theme-gray-light-1 border-solid border-4 border-theme-blue'>
                                    <IconProfile/>
                                </div>
                            )}
                            {isEditing &&
                                (<label htmlFor='fileInput'
                                        className='w-16 h-16 bg-theme-gray-light-1 opacity-50 hover:opacity-90 cursor-pointer border-solid border-4 border-theme-blue rounded-full absolute top-0 flex items-center justify-center'>
                                    <IconPlus className='stroke-theme-blue'/>
                                </label>)
                            }
                        </div>
                        <div className='w-max text-center flex items-center gap-2 relative'>
                            <input
                                className={`text-center text-base tracking-wide w-max bg-transparent border border-1  ${isEditing ? 'rounded-3xl border-theme-blue' : 'w-32 border-transparent'}  ${errors ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                                disabled={!isEditing} value={defaultValue?.username || ''}
                                onChange={(e) => changeValue('username', e.target.value)}
                            />
                        </div>
                    </div>
                    {isEditing && (
                        <>
                            <input
                                accept='image/*'
                                type='file'
                                name='file'
                                className='hidden'
                                onChange={uploadImageFile}
                                id='fileInput'
                            />
                            <div className='w-full flex items-center justify-between text-xs'>
                                <button
                                    className='w-24 h-7 rounded-3xl border border-1 border-theme-blue bg-transparent'
                                    onClick={cancelEditing}
                                >
                                    {t('profile.cancel')}
                                </button>
                                <button className='w-24 h-7 rounded-3xl bg-theme-blue text-white' onClick={saveData}>
                                    {t('profile.save')}
                                </button>
                            </div>
                        </>
                    )}

                    {id && (
                        <button
                            className='w-24 h-7 rounded-3xl bg-theme-blue text-white text-xs'
                            onClick={sendMsgToUser}
                        >
                            {t('profile.openChat')}
                        </button>
                    )}

                    {!isEditing && !id && (
                        <button
                            className='w-24 h-7 rounded-3xl bg-theme-blue text-white text-xs'
                            onClick={handleEdit}
                        >
                            {t('profile.edit')}
                        </button>
                    )}
                </>
            )}
        </div>
    );
};
export default withTranslation()(ProfileImage);
