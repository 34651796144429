import {gql} from '@apollo/client';
import {TYPE_DELIVERY} from '_graphql/types';

export const CREATE_DELIVERY = gql`
    mutation createDelivery(
        $data: DeliveryInput!
    ){
        createDelivery(
            data: $data
        ){
            ${TYPE_DELIVERY}
        }
    }
`;
