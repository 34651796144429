/* global google */
import React from 'react';
import {
    withGoogleMap,
    GoogleMap,
    withScriptjs,
    Marker,
    DirectionsRenderer
} from 'react-google-maps';
import {GOOGLE_API_KEY} from '_constants';

const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=geometry,drawing,places`

class MapDirectionsRenderer extends React.Component {
    state = {
        directions: null
    };

    componentDidMount () {
        const { places, travelMode } = this.props;

        const waypoints = places.map((p) => ({
            location: { lat: p.latitude, lng: p.longitude },
            stopover: true
        }))
        const origin = waypoints.shift().location;
        const destination = waypoints.pop().location;

        const directionsService = new google.maps.DirectionsService();
        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: travelMode,
                waypoints: waypoints
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result
                    });
                } else {
                    console.error('MAP ERROR!!', result)
                }
            }
        );
    }

    render () {
        return (this.state.directions && <DirectionsRenderer directions={this.state.directions} />)
    }
}

const Map = ({ markers, loadingElement, containerElement, mapElement, defaultCenter, defaultZoom, ...rest }) => {
    const LoadGoogleMap = withScriptjs(
        withGoogleMap(
            (props) => {
                const notOnePoint = props.markers?.length > 1
                let zoom = props.defaultZoom

                if (!notOnePoint && props.markers) {
                    zoom = 10
                }

                return (
                    <GoogleMap
                        defaultCenter={props.defaultCenter}
                        defaultZoom={zoom}
                    >
                        {props.markers?.map((marker, index) => {
                            const position = {lat: marker.latitude, lng: marker.longitude};
                            return <Marker key={index} position={position}/>;
                        })}
                        {notOnePoint && (
                            <MapDirectionsRenderer
                                places={props.markers}
                                travelMode={google.maps.TravelMode.DRIVING}
                            />
                        )}
                    </GoogleMap>
                )
            }
        )
    )

    return (
        <LoadGoogleMap
            {...rest}
            googleMapURL={googleMapURL}
            markers={markers}
            loadingElement={loadingElement}
            containerElement={containerElement}
            mapElement={mapElement}
            defaultCenter={defaultCenter}
            defaultZoom={defaultZoom}
        />
    )
}

Map.defaultProps = {
    loadingElement: (<div style={{height: '100%'}}/>),
    containerElement: (<div style={{height: '100%' }}/>),
    mapElement: (<div style={{height: '100%'}}/>),
    defaultCenter: { lat: 48.383022, lng: 31.1828699 },
    defaultZoom: 5
}

export default Map;
