import {useTranslation} from 'react-i18next';
import sequenceNumberColumn from './sequenceNumber'
import description from './description'
import updatedAt from './updatedAt'
import createdAt from './createdAt'
import username from './username'
import userImage from './userImage'
import documents from './documents'
import customerImage from './customerImage'
import city from './city'
import location from './location'
import phones from './phones'
import productSort from './productSort'
import productLoad from './productLoad'
import productSize from './productSize'
import minCount from './minCount'
import maxCount from './maxCount'
import price from './price'
import isTax from './isTax'
import isBuy from './isBuy'
import order from './order'
import sendMessage from './sendMessage'
import remove from './remove'
import quantity from './quantity'
import owner from './owner'
import customer from './customer'
import isActivated from './isActivated'
import activatedProducts from './activatedProducts'
import finishOrder from './finishOrder'
import orderStatus from './orderStatus'
import unreadMessagesCount from './unreadMessagesCount'
import count from './count'
import productPalletType from './productPalletType'
import productBrands from './productBrands'
import isCertificate from './isCertificate'
import paymentType from './paymentType'
import lastLoginAt from './lastLoginAt'
import sendMessageToUser from './sendMessageToUser'
import lastMessage from './lastMessage'
import isOwnerTransport from './isOwnerTransport'
import dateLoad from './dateLoad'
import productType from './productType'

const TableColumns = (props) => {
    const { t } = useTranslation();

    const { hide, show } = props

    const tableColumns = [
        sequenceNumberColumn,
        description,
        updatedAt,
        createdAt,
        username,
        userImage,
        customerImage,
        city,
        location,
        phones,
        productSort,
        productLoad,
        productSize,
        minCount,
        maxCount,
        price,
        isTax,
        isBuy,
        order,
        sendMessage,
        remove,
        quantity,
        owner,
        customer,
        isActivated,
        activatedProducts,
        finishOrder,
        orderStatus,
        unreadMessagesCount,
        count,
        productPalletType,
        productBrands,
        isCertificate,
        paymentType,
        sendMessageToUser,
        lastLoginAt,
        lastMessage,
        isOwnerTransport,
        dateLoad,
        productType,
        documents
    ].map((column) => column({ t, ...props }))

    const activeTableColumns = () => {
        const showHideTitleFilter = (array) => {
            if (hide.titleFilters) {
                if (Array.isArray(hide.titleFilters)) {
                    return array.map((item) => ({ ...item, titleFilter: !hide.titleFilters.includes(item.name) }));
                }
                return array.map((item) => ({ ...item, titleFilter: false }));
            } else if (Array.isArray(show.titleFilters)) {
                return array.map((item) => ({ ...item, titleFilter: show.titleFilters.includes(item.name) }));
            }
            return array;
        };

        const sort = (columns = []) => {
            if (columns?.length > 0) {
                const newArrColumns = columns.reduce((acc, item) => {
                    const findCol = tableColumns.find((col) => col.name === item.name || col.name === item) || null;
                    if (findCol) {
                        acc.push(typeof item === 'object' ? { ...findCol, ...item } : findCol);
                    }
                    return acc;
                }, []).filter((item) => item);
                return showHideTitleFilter(newArrColumns);
            } else {
                return [];
            }
        };

        const defaultSort = sort(show.columns);

        return {
            rows: defaultSort,
            tablet: show.tabletSort ? sort(show.tabletSort) : defaultSort
        };
    };

    return {
        activeTableColumns: activeTableColumns()
    }
}

export default TableColumns

