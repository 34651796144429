import * as yup from 'yup';

export const newProductSchema = () => yup.object({
    city: yup.object().shape({
        name: yup.string(),
        placeId: yup.string().required()
    }),
    houseNumber: yup.string().required(),
    streetName: yup.string().required(),
    minCount: yup.number().min(1),
    maxCount: yup.number().min(1),
    max: yup.number().when(['minCount', 'maxCount'], {
        is: (min, max) => min > max,
        then: yup.number().required(),
        otherwise: yup.number()
    })
}).required();

export const newWarehouseSchema = () => yup.object({
    name: yup.string().required(),
    placeId: yup.string().required(),
    houseNumber: yup.string().required(),
    streetName: yup.string().required()
}).required();

export const newMessageFormSchema = () => yup.object({
    message: yup.string().when('files', {
        is: (files) => !files,
        then: yup.string().required(),
        otherwise: yup.string()
    }),
    files: yup.mixed()
}).required();

export const newDeliverySchema = () => yup.object().shape({
    name: yup.string().required().min(3).max(20),
    email: yup.string().email().required(),
    phone: yup.string().min(16).required(),
    startAddress: yup.object({
        placeName: yup.string(),
        placeId: yup.string().required(),
        houseNumber: yup.string().required(),
        streetName: yup.string().required()
    }),
    endAddress: yup.object().shape({
        placeName: yup.string(),
        placeId: yup.string().when(
            'placeName',
            {
                is: (val) => !!val?.length,
                then: yup.string().required(),
                otherwise: yup.string()
            }
        ),
        houseNumber: yup.string().when(
            'placeName',
            {
                is: (val) => !!val?.length,
                then: yup.string().required(),
                otherwise: yup.string()
            }
        ),
        streetName: yup.string().when(
            'placeName',
            {
                is: (val) => !!val?.length,
                then: yup.string().required(),
                otherwise: yup.string()
            }
        )
    })
}).required();

export const welcomeFormSchema = () => yup.object({
    username: yup.string().required(),
    email: yup.string().email().required(),
    placeName: yup.string(),
    placeId: yup.string().when(
        'placeName',
        {
            is: (val) => !!val?.length,
            then: yup.string().required(),
            otherwise: yup.string()
        }
    ),
    houseNumber: yup.string().when(
        'placeId',
        {
            is: (val) => !!val?.length,
            then: yup.string().required(),
            otherwise: yup.string()
        }
    ),
    streetName: yup.string().when(
        'placeId',
        {
            is: (val) => !!val?.length,
            then: yup.string().required(),
            otherwise: yup.string()
        }
    )
});

export const finishOrderSchema = () => yup.object({
    count: yup.number().min(1).required(),
    price: yup.number().min(1).required()
}).required();

export const changePasswordSchema = (t) => yup.object({
    password: yup.string().required(t('changePassword.errorPassword')),
    passwordConfirmation: yup.string().required(t('changePassword.errorConfirm')).oneOf([yup.ref('password'), null], (t('signup.errorComparePasswords')))
});
