import {gql} from '@apollo/client';
import {TYPE_PRODUCT_CUSTOMER} from '_graphql/types';

export const UPDATE_PRODUCT_CUSTOMER_SUBSCRIPTION = gql`
    subscription productCustomerUpdated(
        $_id: ID
    ){
        productCustomerUpdated(
            _id: $_id
        ){
            ${TYPE_PRODUCT_CUSTOMER}
        }
    }
`;
