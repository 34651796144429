import React, { useEffect, useMemo, useState } from 'react';
import { IconDelete } from 'assets/images/svg';

const ImageSlider = ({ images = [], setImages = () => {}, setDeletedImages = null, noImageIcon = null, onlyView = false }) => {
    const [uploadImages, setUploadImages] = useState(images);

    useEffect(() => setUploadImages(images), [images]);

    const assets = useMemo(() => {
        const assets = [];

        assets.push(uploadImages.filter((value, valueIndex) => valueIndex % 2 === 0));

        if (uploadImages.length > 1) {
            assets.push(uploadImages.filter((value, valueIndex) => valueIndex % 2 !== 0));
        }

        return assets;
    }, [uploadImages]);

    const removeImage = (item) => {
        const filteredImages = uploadImages.filter((value) => value._id !== item._id);

        // eslint-disable-next-line no-unused-vars
        setImages(filteredImages);
        if (setDeletedImages) {
            setDeletedImages((prev) => [...prev, ...[item?._id]])
        }
    };

    return uploadImages.length > 0 ? (
        <div
            className={'flex-grow gap-2 flex flex-col justify-center overflow-hidden overflow-x-auto scrollable max-h-90'}>
            {assets.map((row, rowIndex) => (
                <div
                    key={`row_${rowIndex}`}
                    className={`${assets.length > 1 ? 'h-43 w-max' : 'h-full w-full'} flex flex-row flex-nowrap gap-x-2 overflow-hidden`}
                >
                    {row.map((item) => (
                        <div key={`img_${item._id}`}
                             className={`block w-auto ${assets.length > 1 ? 'h-43' : 'h-full'} relative flex items-center`}>
                            <img
                                alt='gallery'
                                className='block w-auto max-h-full h-auto rounded-lg'
                                src={item.image}
                            />
                            {!onlyView && (
                                <div
                                    className='absolute inset-0 rounded-lg bg-gray-400 cursor-pointer opacity-0 hover:opacity-80 flex justify-center items-center duration-100'
                                    onClick={() => removeImage(item)}>
                                    <IconDelete className='stroke-current text-black'/>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    ) : (
        <div className='flex justify-center items-center h-70 pb-2'>
            {noImageIcon}
        </div>
    );
};

export default ImageSlider;
