import React, { useEffect, useState } from 'react';
import { IconCellPhone, IconEmail, IconProfile } from 'assets/images/svg';
import { withTranslation } from 'react-i18next';
import { useAuthToken } from '_hooks';
import { ToolTip } from 'components';

const ProductDetails = ({ t, productData, toggleEditProductModal, editStatus }) => {
    const { userId } = useAuthToken();

    const [defaultValues, setDefaultValues] = useState({});

    useEffect(() => {
        setDefaultValues(productData);
    }, [productData, editStatus]);

    return (
        <>
            <div className='flex p-3 h-full w-full text-xs md++:text-sm justify-start'>
                <div className='font-sans text-theme-blue flex flex-col grow-0 justify-start pr-5 w-2/3'>

                    <div className='flex flex-row justify-between 2xl:justify-start 2xl:gap-25'>
                        <div className='w-max flex flex-row items-center justify-start mb-5 gap-x-4 2xl:w-1/3'>
                            <div className='tracking-wide flex flex-row gap-3 min-h-full items-center'>
                                <div
                                    className='w-10 h-10 rounded-full bg-theme-blue-main flex items-center justify-center'>
                                    {defaultValues?.userId?.avatar?.assetUrl ? (
                                        <div
                                            className='w-10 h-10 rounded-full bg-theme-blue-main flex items-center justify-center'>
                                            <img className='rounded-full w-10 h-10'
                                                 src={defaultValues?.userId?.avatar?.assetUrl}
                                                 alt='userImage'/>
                                        </div>
                                    ) : (
                                        <div
                                            className='w-10 h-10 rounded-full bg-theme-blue-main flex items-center justify-center'>
                                            <IconProfile/>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='tracking-wide flex flex-col justify-center items-start max-w-50'>
                                <p className='truncate text-center  tracking-wide max-w-50'>{defaultValues?.userId?.username}</p>
                                <a data-tip data-for='location'
                                   className='truncate text-left  tracking-wide max-w-50'>{defaultValues?.userId?.location?.name || '-'}</a>
                                <ToolTip id={'location'} title={defaultValues?.userId?.location?.name || '-'}/>
                            </div>
                        </div>
                        <div className='min-w-60 max-w-60 tracking-wide flex flex-col justify-start'>
                            <div className='flex flex-row items-center gap-x-2 '>
                                <IconEmail className='stroke-current'/>
                                <p data-tip data-for='email'
                                   className='truncate  tracking-wide max-w-45'>{defaultValues?.userId?.email || '-'}</p>
                                <ToolTip id={'email'} title={defaultValues?.userId?.email || '-'}/>
                            </div>
                            <div className='flex flex-row items-center gap-x-2'>
                                <IconCellPhone className='stroke-current mx-0.5'/>
                                <p className=' tracking-wide max-w-45'>{defaultValues?.userId?.phone || '-'}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row w-full items-center mb-1'>
                        <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.address')}</p>
                        <div className='flex-grow truncate text-theme-blue-light '>
                            <span data-tip
                                  data-for='address'>{productData?.city?.name} / {productData?.streetName} / {productData?.houseNumber}</span>
                            <ToolTip id={'address'}
                                     title={`${productData?.city?.name} / ${productData?.streetName} / ${productData?.houseNumber}`}/>
                        </div>
                    </div>
                    <div className='flex flex-col lg+:flex-row justify-between 2xl:justify-start 2xl:gap-25'>
                        <div className='flex flex-col gap-1/3 lg+:gap-1 2xl:w-1/3'>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.requestType')}</p>
                                <p className='text-theme-blue-light '>{productData?.isBuy ? t('modals.productDetails.buy') : t('modals.productDetails.sell')}</p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.taraType')}</p>
                                <p className='text-theme-blue-light '>{t(`modals.productDetails.taraTypeOptions.${productData?.productType?.value}`)}</p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.palletType')}</p>
                                <p className='text-theme-blue-light '>{productData?.productPalletType?.name || productData?.customPalletType || '-'}</p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.taraSort')}</p>
                                <p className='text-theme-blue-light '>
                                    {productData?.productSort?.name?.toLowerCase() === 'higher' ?
                                        t('global.productOptions.sortHigher') :
                                        productData?.productSort?.name || '-'}
                                </p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.price')}</p>
                                <p className='text-theme-blue-light '>
                                    <span className='mr-2'>
                                        {`${productData?.price || 0} ${t('modals.productDetails.priceCurrency')}`}
                                    </span>
                                </p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.paymentType')}</p>
                                <p className='text-theme-blue-light '>{productData?.paymentType?.name ? t(`modals.newTaraModal.${productData?.paymentType?.name.toLowerCase()}`) : '-'}</p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.tax.label')}</p>
                                <p className='text-theme-blue-light '>{t(`modals.productDetails.tax.${productData?.isTax ? 'withTax' : 'noTax'}`)}</p>
                            </div>
                        </div>
                        <div className='min-w-60 max-w-60 flex flex-col gap-1/3 lg+:gap-1'>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.taraSize')}</p>
                                <p className='text-theme-blue-light  flex-grow flex-wrap'>{productData?.customSize ? productData.customSize : productData?.productSize?.name || '-'}</p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.taraLoad')}</p>
                                <p className='text-theme-blue-light '>{productData?.productLoad?.name || '-'}</p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.min')}</p>
                                <p className='text-theme-blue-light '>{productData?.minCount}</p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.max')}</p>
                                <p className='text-theme-blue-light '>{productData?.maxCount}</p>
                            </div>
                            {/*<div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.number')}</p>
                                <p className='text-theme-blue-light '>{productData?.count || 0}</p>
                            </div>*/}
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 flex-none truncate text-theme-blue'>{t('modals.productDetails.brand')}</p>
                                <p className='text-theme-blue-light flex-grow flex-wrap'>{!productData?.productType?.isBrand ? '-' : productData?.productBrands?.length ? productData?.productBrands?.map((item) => item.name).join(', ') : t('modals.productDetails.noCertificate')}</p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='min-w-30 text-theme-blue'>{t('modals.productDetails.certificate')}</p>
                                <p className='text-theme-blue-light '>{!productData?.productType?.isCertificate ? '-' : t(`modals.productDetails.${productData?.isCertificate ? 'withCertificate' : 'noCertificate'}`)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col grow w-1/3'>
                    <>
                        <p className='font-normal leading-5 tracking-wide text-theme-blue'>{t('detailsPage.note')}</p>
                        <div
                            className='border-b-2 bg-transparent border-theme-blue bg-theme-blue-light-2 rounded-t-xl px-2 py-1 h-full'>
                            {defaultValues?.description?.split('\n').map((str, i) => (
                                <p key={i + 'p'} className='min-h-6'>{str}</p>))}
                        </div>
                    </>
                    {defaultValues?.createdBy === userId && (
                        <div className='w-full flex items-center justify-end gap-8 text-xss tracking-wide p-3 pb-0'>
                            <button type='submit' className='w-24 h-7 rounded-3xl bg-theme-blue text-white'
                                    onClick={() => toggleEditProductModal()}>{t('detailsPage.edit')}</button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default withTranslation()(ProductDetails);
