import { gql } from '@apollo/client';
import { TYPE_PRODUCT_CUSTOMER } from '_graphql/types';

export const GET_PRODUCT_CUSTOMERS = gql`
    query productCustomers(
        $ownerId: ID
        $customerId: ID
        $customerIds: [ID]
        $ownerIds: [ID]
        $orderStatus: Boolean
        $productId: ID
        $page: Int!
        $pageSize: Int
        $sort: SortInput
        $count: Int
        $city: String
        $createdAt: DateInput
        $price: Float
        $isFinished: Boolean
        $isApproved: Boolean
        $ownerAndCustomer: ID
    ){
        productCustomers(
            ownerId: $ownerId
            customerId: $customerId
            customerIds: $customerIds
            ownerIds: $ownerIds
            orderStatus: $orderStatus
            productId: $productId
            page: $page
            pageSize: $pageSize
            sort: $sort
            count: $count
            city: $city
            createdAt: $createdAt
            price: $price
            isFinished: $isFinished
            isApproved: $isApproved
            ownerAndCustomer: $ownerAndCustomer
        ){
            rows {
                ${TYPE_PRODUCT_CUSTOMER}
            }
            total
            page
            pageSize
            totalPages
        }
    }
`;
