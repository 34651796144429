export const TYPE_AVATAR = `
    _id
    assetUrl
`;

export const TYPE_DESCRIPTION_USER = `
    userDesc
    companyDesc
`;

export const TYPE_LOCATION = `
    name
    placeId
`

export const TYPE_WAREHOUSES = `
        _id
        name
        placeId
        houseNumber
        streetName
`;

export const TYPE_USERID = `
    _id
    username
    email
    location {
        ${TYPE_LOCATION}
    }
    avatar {
        ${TYPE_AVATAR}
    }
    ranking
    phones
`;

export const TYPE_MEMBERS = `
    _id
    username
    location {
        ${TYPE_LOCATION}
    }
    avatar {
        ${TYPE_AVATAR}
    }
    role
    createdAt
`;

export const TYPE_CURRENT_USER = `
    _id
    username
    phones
    email
    location {
        ${TYPE_LOCATION}
    }
    ranking
    googleId
    googleAccessToken
    avatar {
        ${TYPE_AVATAR}
    }
    description {
        ${TYPE_DESCRIPTION_USER}
    }
    role
    createdAt
    modifiedAt
`

export const TYPE_USER = `
    _id
    username
    email
    phones
    birthdayDate
    gender
    location {
        ${TYPE_LOCATION}
    }
    counts {
        activatedProducts
        deactivatedProducts
    }
    houseNumber
    streetName
    ranking
    googleId
    googleAccessToken
    avatar {
        ${TYPE_AVATAR}
    }
    description {
        ${TYPE_DESCRIPTION_USER}
    }
    warehouses {
        ${TYPE_WAREHOUSES}
    }
    role
    createdAt
    modifiedAt
    lastLoginAt
    emailVerified
`

