import React, { useContext, useEffect, useState } from 'react';
import BaseModal from '../index';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { GeneralContext, MessagesContext } from 'contexts';
import { CREATE_CONVERSATION } from '_graphql';
import { messenger, navigation } from '_constants';
import { useHistory } from 'react-router-dom';
import { useAuthToken, useGraphqlCache } from '_hooks';
import { useSegment } from 'react-segment-hooks';

const CreatePrivateChatModal = ({ t, modals }) => {
    const { userId } = useAuthToken();
    const analytics = useSegment();
    const { setLoading, toggleSegmentButton } = useContext(GeneralContext);
    const { changeActiveConversation } = useContext(MessagesContext);
    const { updateConversationListInCache } = useGraphqlCache();

    const history = useHistory();

    const {
        createMessageModal: { display: isOpen, id: recipientUserId },
        closeCreateMessageModal
    } = modals;

    const [createConversation, { loading, error }] = useMutation(CREATE_CONVERSATION);

    const [message, setMessage] = useState('');

    useEffect(() => {
        setLoading(loading && !error);
    }, [loading, error]);

    useEffect(() => {
        setMessage('');
        if (isOpen) {
            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'Create Private Chat'
                }
            });
        }
    }, [isOpen]);

    const onSubmitForm = (e) => {
        e.preventDefault();
        toggleSegmentButton('Submit', 'Create Private Chat');

        const data = {
            members: [recipientUserId, userId],
            type: messenger.types.private,
            message
        };

        createConversation({
            variables: { data },
            update: async (cache, result) => {
                const newConversation = result?.data?.createConversation;
                if (newConversation) {
                    await updateConversationListInCache({
                        conversation: newConversation,
                        options: { add: true }
                    });
                    changeActiveConversation(newConversation);
                    history.push(navigation.pages.messenger);
                }
            }
        });
    };

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={closeCreateMessageModal}
            width='w-97'
            showClose={false}
        >
            <div className='p-7 text-sm text-theme-blue'>
                <form className='flex flex-col gap-y-4' onSubmit={onSubmitForm}>
                    <textarea
                        className={'w-full min-h-16 border-b-2 bg-transparent border-theme-blue bg-theme-blue-light-2 rounded-t-xl px-2 py-1 placeholder-theme-blue-light text-theme-blue border-theme-blue'}
                        placeholder={t('modals.buySellProduct.message')}
                        name='message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <div className='flex justify-end'>
                        <button type='button'
                                className='flex flex-row items-center justify-center bg-theme-blue-main rounded-3xl text-theme-blue h-7 px-7 text-10 mr-6 hover:bg-theme-gray-main duration-100'
                                onClick={() => {
                                    closeCreateMessageModal();
                                    toggleSegmentButton('Cancel', 'Create Private Chat');
                                }}>{t('modals.buySellProduct.cancel')}</button>
                        <button type='submit'
                                className='flex flex-row items-center justify-center bg-theme-blue rounded-3xl text-white h-7 px-7 text-10 hover:bg-theme-blue-light duration-100'>Відправити
                        </button>
                    </div>
                </form>
            </div>
        </BaseModal>
    );
};

CreatePrivateChatModal.defaultProps = {
    isOpen: false
};

export default withTranslation()(CreatePrivateChatModal);
