import {gql} from '@apollo/client';
import {TYPE_PRODUCT} from '_graphql/types';

export const GET_PRODUCT_LIST = gql`
    query products(
        $userId: ID
        $userIds: [ID]
        $city: String
        $username: String
        $minCount: Int
        $maxCount: Int
        $createdAt: DateInput
        $updatedAt: DateInput
        $isTax: Boolean
        $isBuy: Boolean
        $price: PriceInput
        $sort: SortInput
        $page: Int!
        $pageSize: Int
        $productTypes: [ID]
        $productLoads: [ID]
        $productSorts: [ID]
        $productSizes: [ID]
        $productPalletTypes: [ID]
        $productBrands: [ID]
        $customSize: Boolean
        $customSort: Boolean
        $customLoad: Boolean
        $isActivate: Boolean
        $customPalletType: Boolean
        $isCertificate: Boolean
    ){
        products(
            userId: $userId
            userIds: $userIds
            city: $city
            username: $username
            minCount: $minCount
            maxCount: $maxCount
            createdAt: $createdAt
            updatedAt: $updatedAt
            isTax: $isTax
            isBuy: $isBuy
            price: $price
            sort: $sort
            page: $page
            pageSize: $pageSize
            productTypes: $productTypes
            productLoads: $productLoads
            productSorts: $productSorts
            productSizes: $productSizes
            productPalletTypes: $productPalletTypes
            productBrands: $productBrands
            customSize: $customSize
            customSort: $customSort
            customLoad: $customLoad
            isActivate: $isActivate
            customPalletType: $customPalletType
            isCertificate: $isCertificate
        ){
            rows {
                ${TYPE_PRODUCT}
            }
            total
            page
            pageSize
            totalPages
        }
    }
`;
