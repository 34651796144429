import React, { useContext, useEffect, useState } from 'react';
import BaseModal from '../index';
import { withTranslation } from 'react-i18next';
import { useAuthToken } from '_hooks';
import { useMutation } from '@apollo/client';
import { GeneralContext } from 'contexts';
import { checkStringUtils } from 'utils/validations.utils';
import { CREATE_PRODUCT_CUSTOMER } from '_graphql';
import { useHistory } from 'react-router-dom';
import { navigation } from '_constants';
import { useSegment } from 'react-segment-hooks';

const initialState = {
    message: ''
};

const BuySellProductModal = ({ t, modals }) => {
    const { setLoading } = useContext(GeneralContext);
    const { userId } = useAuthToken();
    const analytics = useSegment();
    const history = useHistory();

    const {
        toggleSegmentButton,
        buySellProductModal: { display: isOpen, params },
        closeBuySellProductModal: onClose
    } = modals;

    const [createProductCustomer, { loading, error }] = useMutation(CREATE_PRODUCT_CUSTOMER);

    const [customerInfo, setCustomerInfo] = useState(initialState);
    const [errorFields, setErrorFields] = useState(null);

    useEffect(() => {
        setLoading(loading && !error && isOpen);
    }, [loading, error, isOpen]);

    useEffect(() => {
        setCustomerInfo(initialState);
        setErrorFields(null);
        if (isOpen) {
            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'Buy/Sell product'
                }
            });
        }
    }, [isOpen]);

    useEffect(() => {
        if (errorFields?.length) {
            setErrorFields((prev) => prev.filter((field) => !customerInfo[field]));
        }
    }, [customerInfo]);

    const editCustomerInfo = (field, value) => {
        setCustomerInfo((prev) => ({ ...prev, [field]: value }));
    };

    const editCustomerInfoByTarget = ({ target }) => {
        editCustomerInfo(target.name, target.value);
    };

    const validationFields = () => {
        const errors = [];

        if (!checkStringUtils(customerInfo.message)) {
            errors.push('message');
        }

        setErrorFields(errors.length > 0 ? errors : null);

        return errors.length > 0;
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        toggleSegmentButton('Submit', 'Buy/Sell product');

        if (validationFields()) {
            return;
        }

        const data = {
            ...customerInfo,
            ownerId: params?.ownerId,
            productId: params?._id,
            customerId: userId
        };

        createProductCustomer({
            variables: { data },
            update: (cache, { data }) => {
                onClose();
                const _id = data?.createProductCustomer?.productId?._id;
                if (_id) {
                    history.push(`${navigation.pages.pallets}/${_id}`);
                }
            }
        });
    };

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width='w-97'
            showClose={false}
        >
            <div className='p-7 text-sm text-theme-blue'>
                <form className='flex flex-col gap-y-4' onSubmit={onSubmitForm}>
                    <textarea
                        className={`w-full min-h-16 border-b-2 bg-transparent border-theme-blue bg-theme-blue-light-2 rounded-t-xl px-2 py-1 placeholder-theme-blue-light text-theme-blue ${errorFields?.find((item) => item === 'message') ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'}`}
                        placeholder={t('modals.buySellProduct.message')}
                        name='message'
                        value={customerInfo.message}
                        onChange={editCustomerInfoByTarget}
                    />
                    <div className='flex justify-end'>
                        <button type='button'
                                className='flex flex-row items-center justify-center bg-theme-blue-main rounded-3xl text-theme-blue h-7 px-7 text-10 mr-6 hover:bg-theme-gray-main duration-100'
                                onClick={() => {
                                    onClose();
                                    toggleSegmentButton('Cancel', 'Buy/Sell product');
                                }}>{t('modals.buySellProduct.cancel')}</button>
                        <button type='submit'
                                className='flex flex-row items-center justify-center bg-theme-blue rounded-3xl text-white h-7 px-7 text-10 hover:bg-theme-blue-light duration-100'>{t('modals.buySellProduct.save')}</button>
                    </div>
                </form>
            </div>
        </BaseModal>
    );
};

BuySellProductModal.defaultProps = {
    isOpen: false
};

export default withTranslation()(BuySellProductModal);
