import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IconArrow2 } from 'assets/images/svg';
import { withTranslation } from 'react-i18next';
import { CustomCheckboxSlider, CustomRadioSlider, MultiSelect } from 'components';
import _ from 'lodash';
import { FilterContext } from 'contexts';
import { hasOwnPropertyUtils } from 'utils';

const NewTaraModalLeftBlock = ({ t, hookForm, formValues, editData, setFormValues }) => {
    const { optionsListData } = useContext(FilterContext);

    const optionsList = useMemo(() => optionsListData?.productOptions || null, [optionsListData]);

    const [isCustomSize, setIsCustomSize] = useState(false);
    const [activeProductType, setActiveProductType] = useState({});

    const paymentTypesOptions = useMemo(() => optionsList?.paymentTypes?.rows?.map((item) => ({
        ...item, checked: formValues.paymentType === item._id
    })) || [], [formValues, optionsList?.paymentTypes]);
    const productPalletTypesOptions = useMemo(() => optionsList?.productPalletTypes?.rows?.map((item) => ({
        ...item, checked: formValues.productPalletType === item._id
    })) || [], [formValues, optionsList?.productPalletTypes]);
    const productBrandsOptions = useMemo(() => optionsList?.productBrands?.rows?.map((item) => ({
        ...item, checked: formValues.productBrands === item._id
    })) || [], [formValues, optionsList?.productBrands]);

    useEffect(() => {
        const subscription = hookForm.watch((value) => {
            setFormValues(value);
        });
        return () => subscription.unsubscribe();
    }, [hookForm.watch]);

    useEffect(() => {
        if (optionsList?.paymentTypes) {
            hookForm.setValue('paymentType', editData?.paymentType?._id || optionsList?.paymentTypes.rows[0]?._id);
        }
        if (optionsList?.productPalletTypes) {
            const existedPalletType = editData?.customPalletType ? 'other' : false;
            hookForm.setValue('productPalletType', existedPalletType || editData?.productPalletType?._id || editData?.productPalletType || optionsList?.productPalletTypes.rows[0]?._id);
        }
        if (optionsList?.productBrands) {
            const existedBrands = editData?.productBrands?.map((item) => item._id);
            hookForm.setValue('productBrands', existedBrands);
        }

        if (optionsList?.productTypes) {
            const existedType = editData?.productType?._id || optionsList?.productTypes.rows[0]?._id;
            hookForm.setValue('productType', existedType);
            setActiveProductType(optionsList?.productTypes.rows[0]);
            checkIsCustomSize(optionsList?.productTypes.rows[0]);
        }
    }, [optionsList]);

    useEffect(() => {
        if (isCustomSize) {
            let customSize = '';
            if (hasOwnPropertyUtils(isCustomSize, 'length')) {
                customSize += isCustomSize.length;
            }
            if (hasOwnPropertyUtils(isCustomSize, 'width')) {
                customSize += ' x ' + isCustomSize.width;
            }
            if (hasOwnPropertyUtils(isCustomSize, 'height')) {
                customSize += ' x ' + isCustomSize.height;
            }
            hookForm.setValue('customSize', customSize);
        }
    }, [isCustomSize, formValues.productSize]);

    const getAllowedFields = () => {
        const existedSize = activeProductType?.productSize?.find(({ _id }) => editData?.productSize?._id === _id);
        const actualSize = editData?.customSize ? 'other' : existedSize?._id || activeProductType?.productSize?.[0]?._id;

        const existedLoad = activeProductType?.productLoad?.find(({ _id }) => editData?.productLoad?._id === _id)?._id ||
            activeProductType?.productLoad?.[0]?._id;
        const actualLoad = activeProductType?.value !== '1' ? null : existedLoad;

        const actualSort = activeProductType?.productSort?.find(({ _id }) => editData?.productSort?._id === _id)?._id ||
            activeProductType?.productSort?.[0]?._id;

        const existedBrands = editData?.productBrands?.map((item) => item._id);
        const actualBrands = !activeProductType?.isBrand ? null : existedBrands;

        const existedPalletType = editData?.customPalletType ? 'other' : editData?.productPalletType?._id ||
            optionsList?.productPalletTypes.rows[0]?._id;
        const actualPalletType = activeProductType?.value !== '1' ? null : existedPalletType;

        return {
            productSize: actualSize,
            productLoad: actualLoad,
            productSort: actualSort,
            productBrands: actualBrands,
            productPalletType: actualPalletType
        };
    };

    useEffect(() => {
        checkIsCustomSize(activeProductType);
        const actualValues = getAllowedFields();

        hookForm.setValue('productSize', actualValues.productSize);
        hookForm.setValue('productLoad', actualValues.productLoad);
        hookForm.setValue('productSort', actualValues.productSort);
        hookForm.setValue('productBrands', actualValues.productBrands);
        hookForm.setValue('productPalletType', actualValues.productPalletType);
        if (!activeProductType?.isCertificate) {
            hookForm.setValue('isCertificate', false);
        }
    }, [activeProductType]);

    useEffect(() => {
        const findProductType = optionsList?.productTypes?.rows?.find((item) => item._id === formValues.productType);
        setActiveProductType(findProductType);
        checkIsCustomSize(findProductType);
        if (formValues.productSize !== 'other') {
            hookForm.setValue('customSize', null);
        }
    }, [formValues.productType]);

    const checkIsCustomSize = (productType) => {
        if (productType?.isCustomSize) {
            const initSize = {
                height: editData?.productType?.isHeight,
                length: editData?.productType?.isLength
            };
            const customSize = editData?.customSize?.split(' x ');
            const { isHeight, isLength } = productType;
            const size = {
                width: customSize?.length ? customSize[0] : 0
            };

            if (isLength) {
                size.length = customSize?.length ? customSize[1] : 0;
                size.height = customSize?.length ? customSize[2] : 0;
            } else {
                size.height = customSize?.length ? customSize[1] : 0;
            }
            if (!initSize.length) {
                size.length = 0;
            }
            if (!initSize.height) {
                size.height = 0;
            }
            if (!isHeight) {
                size.height = undefined;
            }
            setIsCustomSize(JSON.parse(JSON.stringify(size)));
        } else {
            setIsCustomSize(false);
        }
    };

    const editCustomInfo = (field, value) => {
        setIsCustomSize((prev) => ({ ...prev, [field]: value }));
    };

    const changeMinMaxTara = (e, field) => {
        e.persist();
        hookForm.setValue(field, Number(e.target.value));
    };

    const incrementMinMax = (field) => {
        hookForm.setValue(field, Number(formValues[field]) + 1);
    };

    const decrementMinMax = (field) => {
        hookForm.setValue(field, Math.max(Number(formValues[field]) - 1, 1));
    };

    const changePaymentType = (_id) => {
        hookForm.setValue('paymentType', _id);
    };

    return (
        <div
            className='flex-grow min-h-full flex flex-col w-full text-sm bg-theme-gray-main p-8 text-theme-blue overflow-y-auto scrollable'>
            <p className='text-left text-lg uppercase w-max inline-block mx-auto mb-4 font-medium'>{t('modals.newTaraModal.info')}</p>
            <div className='flex flex-col gap-y-3 mb-13'>
                <div className='flex flex-row items-center gap-2'>
                    <p className='flex-grow-0 w-31'>{t('modals.newTaraModal.min')}</p>
                    <div className='flex-grow flex flex-row'>
                        <label className='w-1/2 min-w-max flex flex-row gap-1 h-6 items-center'>
                            <div className='relative w-full max-w-24 h-6'>
                                <input type='number'
                                       {...hookForm.register('minCount')}
                                       min={1}
                                       step={1}
                                       className='w-full h-full pl-2 pr-5 border border-theme-blue rounded-3xl bg-transparent text-xs'
                                       onChange={(e) => changeMinMaxTara(e, 'minCount')}
                                />
                                <div className='absolute top-0 right-0 flex flex-col h-6'>
                                    <button type='button'
                                            className='block h-3 pr-2 hover:text-theme-blue-light duration-150'
                                            onClick={() => incrementMinMax('minCount')}>
                                        <IconArrow2 className='stroke-current'/>
                                    </button>
                                    <button type='button'
                                            className='block h-3 pr-2 hover:text-theme-blue-light duration-150'
                                            onClick={() => decrementMinMax('minCount')}>
                                        <IconArrow2 className='transform rotate-180  stroke-current'/>
                                    </button>
                                </div>
                            </div>
                        </label>
                        <label className='w-1/2 min-w-max flex flex-row h-6 items-center'>
                            <p className='mr-8'>{t('modals.newTaraModal.max')}</p>
                            <div className='relative w-full max-w-24 h-6'>
                                <input type='number'
                                       {...hookForm.register('maxCount')}
                                       min={1}
                                       step={1}
                                       className={`w-full h-full pl-2 pr-5 border border-theme-blue rounded-3xl bg-transparent text-xs ${hookForm?.errors?.max ? 'bg-theme-red-light border-theme-red-main ' : ''}`}
                                       onChange={(e) => changeMinMaxTara(e, 'maxCount')}
                                />
                                <div className='absolute top-0 right-0 flex flex-col h-6'>
                                    <button type='button'
                                            className='block h-3 pr-2 hover:text-theme-blue-light duration-150'
                                            onClick={() => incrementMinMax('maxCount')}>
                                        <IconArrow2 className='stroke-current'/>
                                    </button>
                                    <button type='button'
                                            className='block h-3 pr-2 hover:text-theme-blue-light duration-150'
                                            onClick={() => decrementMinMax('maxCount')}>
                                        <IconArrow2 className='transform rotate-180  stroke-current'/>
                                    </button>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
                {/*{!!editData && (*/}
                {/*    <div className='flex flex-row w-full items-center gap-2'>*/}
                {/*        <p className='flex-grow-0 w-31'>{t('modals.newTaraModal.number')}</p>*/}
                {/*        <input*/}
                {/*            {...hookForm.register('count')}*/}
                {/*            type='text'*/}
                {/*            className='w-full max-w-24 h-6 px-2 border border-theme-blue rounded-3xl bg-transparent text-xs'*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*)}*/}
                <div className='flex flex-row items-center gap-2'>
                    <p className='flex-grow-0 w-31'>{t('modals.newTaraModal.price')}</p>
                    <div className='flex-grow flex flex-row items-center'>
                        <div className='w-1/2 flex flex-row gap-4 items-center'>
                            <input
                                {...hookForm.register('price')}
                                type='number'
                                min={0}
                                step={1}
                                className='w-full  max-w-24 h-6 px-2 border border-theme-blue rounded-3xl bg-transparent text-xs'
                            />
                            <p>{t('modals.newTaraModal.currency')}</p>
                        </div>
                        <div className='w-1/2'>
                            <CustomCheckboxSlider
                                register={hookForm.register}
                                name='isTax'
                                text={t('modals.newTaraModal.tax')}
                                checked={formValues.isTax}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex flex-row items-center gap-2'>
                    <p className='flex-grow-0 w-31'>{t('modals.newTaraModal.type')}</p>
                    <div className='flex-grow flex flex-row items-center'>
                        <div className='w-1/2'>
                            <CustomRadioSlider
                                register={hookForm.register}
                                name={'isBuy'}
                                value={formValues.isBuy}
                                text={t('modals.newTaraModal.typeBuy')}
                                onChange={() => hookForm.setValue('isBuy', true)}
                            />
                        </div>
                        <div className='w-1/2'>
                            <CustomRadioSlider
                                register={hookForm.register}
                                name={'isBuy'}
                                value={!formValues.isBuy}
                                text={t('modals.newTaraModal.typeSell')}
                                onChange={() => hookForm.setValue('isBuy', false)}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex flex-row items-center gap-2'>
                    <p className='flex-grow-0 w-31'>{t('modals.newTaraModal.paymentType')}</p>
                    <div className='flex-grow flex flex-row items-center'>
                        {paymentTypesOptions.map(({ _id, checked, name }) => (
                            <div className='w-1/2' key={_id}>
                                <CustomRadioSlider
                                    value={checked}
                                    text={t(`modals.newTaraModal.${name.toLowerCase()}`)}
                                    onClick={() => changePaymentType(_id)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <p className='text-left text-lg uppercase w-max inline-block mx-auto mb-4 font-medium'>{t('modals.newTaraModal.typeInfo')}</p>
            <div className='flex flex-row w-full items-start justify-between mb-3'>
                <div className='w-max flex flex-col gap-3'>
                    <div className='flex flex-row w-full items-center'>
                        <p className='w-35 min-w-max'>{t('modals.newTaraModal.typeTara')}</p>
                        <select
                            className='block w-full h-6 text-xs px-2 pr-7 max-w-24 border border-theme-blue hover:border-theme-blue-light hover:text-theme-blue-light rounded-3xl bg-transparent duration-100'
                            name='productType' {...hookForm.register('productType')}>
                            {optionsList?.productTypes?.rows?.map((item) => (
                                <option key={item._id}
                                        value={item._id}>{t(`modals.newTaraModal.taraTypeOptions.${item.value}`)}</option>))}
                        </select>
                    </div>

                    <div className='flex flex-row w-full items-center'>
                        <p className='w-35 min-w-max'>{t('modals.newTaraModal.size')}</p>
                        <select
                            className='block w-full h-6 text-xs px-2 pr-7 max-w-24 border border-theme-blue hover:border-theme-blue-light hover:text-theme-blue-light rounded-3xl bg-transparent duration-100'
                            name='productSize' {...hookForm.register('productSize')}>
                            {activeProductType?.productSize?.map((item) => (
                                <option key={item._id} value={item._id}>{item.name}</option>))}
                            {isCustomSize && (<option value='other'>{t('modals.newTaraModal.otherOption')}</option>)}
                        </select>
                    </div>
                    {isCustomSize && formValues.productSize === 'other' && (<>
                        {hasOwnPropertyUtils(isCustomSize, 'length') && (
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 min-w-max'>{t('modals.newTaraModal.length')}</p>
                                <input
                                    type='number'
                                    step={1}
                                    value={isCustomSize.length}
                                    className='w-full max-w-24 h-6 px-2 border border-theme-blue rounded-3xl bg-transparent text-xs'
                                    onChange={(e) => editCustomInfo('length', e.target.value)}
                                />
                            </div>
                        )}
                        {hasOwnPropertyUtils(isCustomSize, 'width') && (
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 min-w-max'>{t('modals.newTaraModal.width')}</p>
                                <input
                                    type='number'
                                    step={1}
                                    value={isCustomSize.width}
                                    className='w-full max-w-24 h-6 px-2 border border-theme-blue rounded-3xl bg-transparent text-xs'
                                    onChange={(e) => editCustomInfo('width', e.target.value)}
                                />
                            </div>
                        )}
                        {hasOwnPropertyUtils(isCustomSize, 'height') && (
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 min-w-max'>{t('modals.newTaraModal.height')}</p>
                                <input
                                    type='number'
                                    step={1}
                                    value={isCustomSize.height}
                                    className='w-full max-w-24 h-6 px-2 border border-theme-blue rounded-3xl bg-transparent text-xs'
                                    onChange={(e) => editCustomInfo('height', e.target.value)}
                                />
                            </div>
                        )}
                    </>)}
                    <div className='flex flex-row w-full items-center'>
                        <p className={`w-35 min-w-max ${!activeProductType?.productSort?.length && ('text-theme-gray')}`}>{t('modals.newTaraModal.sort')}</p>
                        <select
                            {...hookForm.register('productSort')}
                            name='productSort'
                            className={'block w-full h-6 text-xs px-2 max-w-24 border border-theme-blue hover:border-theme-blue-light hover:text-theme-blue-light rounded-3xl bg-transparent duration-100'}
                        >
                            {_.chain(activeProductType?.productSort).sortBy(['index']).value().map((item) => (
                                    <option key={item._id} value={item._id}
                                            className={item.highlight ? 'font-bold' : ''}>
                                        {item.name.toLowerCase() === 'higher' ? t('global.productOptions.sortHigher') : item.name}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    <div className='flex flex-row items-center flex-nowrap mb-4'>
                        <p className={`w-35 min-w-max ${!activeProductType?.isCertificate && ('text-theme-gray')}`}>{t('modals.newTaraModal.certificate')}</p>
                        <div className='flex flex-grow items-center'>
                            <CustomCheckboxSlider
                                isDisabled={!activeProductType?.isCertificate}
                                register={hookForm.register}
                                name='isCertificate'
                                checked={formValues.isCertificate}
                            />
                        </div>
                    </div>
                </div>
                <div className='w-max flex flex-col gap-3'>
                    <div className='flex flex-row w-full items-start theme-gray'>
                        <p className={`w-35 min-w-max ${!activeProductType?.isPalletType && ('text-theme-gray')}`}>{t('modals.newTaraModal.palletType')}</p>
                        <select
                            {...hookForm.register('productPalletType')}
                            name='productPalletType'
                            className={`block w-full max-w-30 h-6 text-xs px-2 border ${activeProductType?.isPalletType ?
                                'border-theme-blue hover:border-theme-blue-light hover:text-theme-blue-light' :
                                'disabled border-theme-gray text-theme-gray cursor-not-allowed'} rounded-3xl bg-transparent duration-100`}
                            disabled={!activeProductType?.isPalletType}
                            value={hookForm.getValues('productPalletType') || '-'}
                        >
                            {!activeProductType?.isPalletType ? (
                                <option value='-' className='font-bold'>{'-'}</option>
                            ) : (
                                <>
                                    {productPalletTypesOptions?.map((item) => (
                                        <option key={item._id} checked={item.checked}
                                                value={item._id}>{item.name}</option>))}
                                    <option value='other'>{t('modals.newTaraModal.otherOption')}</option>
                                </>
                            )}
                        </select>
                    </div>
                    {activeProductType?.isPalletType && formValues.productPalletType === 'other' && (
                        <div className='flex flex-row w-full items-start'>
                            <p className='w-35 min-w-max'>{t('modals.newTaraModal.otherPalletType')}</p>
                            <input
                                {...hookForm.register('customPalletType')}
                                type='text'
                                className='w-full max-w-30 h-6 px-2 border border-theme-blue rounded-3xl bg-transparent text-xs'
                            />
                        </div>
                    )}
                    <div className='flex flex-row w-full items-center'>
                        <p className={`w-35 min-w-max ${activeProductType?.value !== '1' && ('text-theme-gray')}`}>{t('modals.newTaraModal.load')}</p>
                        <select
                            {...hookForm.register('productLoad')}
                            name='productLoad'
                            value={activeProductType?.value !== '1' ? '-' : hookForm.getValues('productLoad') || ''}
                            disabled={activeProductType?.value !== '1'}
                            className={`block w-full h-6 text-xs px-2 max-w-30 border ${activeProductType?.value !== '1' ?
                                'disabled border-theme-gray text-theme-gray cursor-not-allowed' :
                                'border-theme-blue hover:border-theme-blue-light hover:text-theme-blue-light'} rounded-3xl bg-transparent duration-100`}
                        >
                            {activeProductType?.value !== '1' ? (
                                <option value='-' className='font-bold'>{'-'}</option>
                            ) : (
                                _.chain(activeProductType?.productLoad).sortBy(['index']).value().map((item) => (
                                    <option key={item._id} value={item._id}
                                            className={item.highlight ? 'font-bold' : ''}>
                                        {item.name}
                                    </option>
                                ))
                            )}
                        </select>
                    </div>
                    <div className='flex flex-row w-full items-start'>
                        <p className={`flex-none w-35 min-w-max ${!activeProductType?.isBrand && ('text-theme-gray')}`}>{t('modals.newTaraModal.brand')}</p>
                        <MultiSelect
                            isDisabled={!activeProductType?.isBrand}
                            className={'flex-grow w-30 flex flex-col items-center relative text-xs'}
                            defaultSelected={hookForm.getValues('productBrands')?.filter(Boolean) || []}
                            data={productBrandsOptions}
                            setValue={(data) => hookForm.setValue('productBrands', data)}
                        />
                    </div>

                    {formValues.productSize === 'other' && (
                        <div
                            className={`flex flex-row w-full text-theme-blue-light items-start mt-${hasOwnPropertyUtils(isCustomSize, 'height') ? '18' : '9'}`}
                        >
                            <p className={'flex-none w-35 min-w-max'}>{t('modals.NewTaraModal.sizeType.title')}</p>
                            <p className={'flex-grow'}>{hasOwnPropertyUtils(isCustomSize, 'height') ? t('modals.NewTaraModal.sizeType.full') : t('modals.NewTaraModal.sizeType.part')}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='h-full w-full pb-8'>
                <p className='mb-2'>{t('modals.newTaraModal.description')}</p>
                <textarea
                    {...hookForm.register('description')}
                    className='w-full h-full min-h-35 border-b-2 bg-transparent border-theme-blue bg-theme-blue-light-2 rounded-t-xl px-2 py-1'
                    name='description'
                />
            </div>
        </div>
    );
};

export default withTranslation()(NewTaraModalLeftBlock);
