import React from 'react';
import {columnName} from 'components/table/configuration';

export default ({ t }) => ({
    name: columnName.unreadMessagesCount,
    title: t('table.header.unreadMessagesCount'),
    excelValue: null,
    thead: function () {
        return (<th key={this.name} className={'px-1'}/>);
    },
    tbody: function (item) {
        const unreadMessagesCount = item?.conversationId?.unreadMessagesCount || 0;
        return (
            <td key={this.name + item._id} className={'w-1 whitespace-nowrap pl-1'} style={{ paddingRight: 0 }}>
                <div className='flex items-center'>
                    {unreadMessagesCount > 0 ? (
                        <p className='h-10 w-5 flex items-center justify-center bg-theme-red rounded-l-full inline-block text-white text-xss'>
                            {unreadMessagesCount > 99 ? '99+' : (unreadMessagesCount)}
                        </p>
                    ) : (
                        <p className='h-10 w-5'/>
                    )}
                </div>
            </td>
        );
    },
    tabletTitle: () => null,
    tabletContent: () => null
})
