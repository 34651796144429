import React from 'react';
import GoogleLoginButton from 'react-google-login-button';

export const GoogleSignButton = ({width, height, longTitle, theme}) => (
    <GoogleLoginButton
        googleClientId='YOUR_GOOGLE_CLIENT_ID_HERE'
        width={width}
        height={height}
        longTitle={longTitle}
        theme={theme}
    />
);

GoogleSignButton.defaultProps = {
    width: 185,
    height: 40,
    longTitle: true,
    theme: 'dark',
    onClick: undefined
};

export default GoogleSignButton;
