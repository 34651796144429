import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSegment } from 'react-segment-hooks';
import { messenger, navigation, storageNames, tableConstants } from '_constants';
import { useAuthToken, useGraphqlCache } from '_hooks/index';
import { FilterContext, GeneralContext, MessagesContext } from 'contexts';
import { hasOwnPropertyUtils } from 'utils';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_CONVERSATION, GET_PRIVATE_CHAT, GET_PRODUCT_CUSTOMER } from '_graphql';
import TableColumns from 'components/table/columns';

const useTableColumnsHook = ({ hide, change, show, setPagination, tableName }) => {
    const { pathname } = useLocation();
    const analytics = useSegment();
    const history = useHistory();
    const { userId, userName } = useAuthToken();
    const { changeActiveConversation } = useContext(MessagesContext);
    const { setTablePageSize, tablePageSize } = useContext(GeneralContext);
    const { updateConversationListInCache } = useGraphqlCache();

    const {
        applyFilter,
        clearFilter,
        filterParams,
        saveToFilter,
        productUniqOptions,
        initialStateFilter
    } = useContext(FilterContext);

    const {
        onToggleProductActivate: changeOnToggleProductActivate,
        onRemoveProduct: changeOnRemoveProduct,
        handleClickRow: changeHandleClickRow,
        handleOrderAssetsClick,
        defaultSort = null
    } = change;

    const { fetchMore: fetchMoreProductCustomer } = useQuery(GET_PRODUCT_CUSTOMER);
    const { fetchMore: fetchMoreConversation } = useQuery(GET_PRIVATE_CHAT);

    const [createConversation] = useMutation(CREATE_CONVERSATION);

    const [sorting, setSorting] = useState(defaultSort);
    const [activeFilterPanel, setActiveFilterPanel] = useState(null);
    const [titleFilterPanels, setTitleFilterPanels] = useState({});
    const [refs, setRefs] = useState({});
    const [tabletType, setTabletType] = useState(localStorage.getItem(storageNames.tabletType) ? JSON.parse(localStorage.getItem(storageNames.tabletType)) : false);

    const [buySellProductModal, setBuySellProductModal] = useState({ display: false, params: null });
    const [productDetailsModal, setProductDetailsModal] = useState({ display: false, _id: null });
    const [orderFinishModal, setOrderFinishModal] = useState({ display: false, params: null });
    const [createMessageModal, setCreateMessageModal] = useState({ display: false, id: null });

    const tableType = useMemo(() => {
        if (!!tabletType && hasOwnPropertyUtils(tabletType, tableName)) {
            return tabletType[tableName];
        }
        return false;
    }, [pathname, tabletType]);

    useEffect(() => {
        localStorage.setItem(storageNames.tabletType, JSON.stringify(tabletType));
    }, [tabletType]);

    useEffect(() => {
        const tableHeight = refs?.table?.clientHeight || 0;
        const titleHeight = refs?.title?.clientHeight || 0;

        if (tableHeight > 0 && titleHeight > 0) {
            const pageSize = Math.floor((tableHeight - titleHeight) / tableConstants.default.rowHeight);
            if (pageSize && pageSize !== tablePageSize) {
                setTablePageSize(pageSize);
            }
        }
    }, [refs]);

    useEffect(() => {
        setPagination((prev) => {
            // eslint-disable-next-line no-unused-vars
            const { sort, ...rest } = prev;
            if (sorting) {
                rest.sort = sorting;
            }
            return rest;
        });
    }, [sorting]);

    useEffect(() => {
        setPagination((prev) => ({ ...prev, pageSize: tablePageSize }));
    }, [tablePageSize]);

    const onChangeSort = (e, field) => {
        e.stopPropagation();
        let sortingValue = sorting;
        if (sortingValue?.field !== field) {
            sortingValue = { field, value: '1' };
        } else {
            sortingValue = sortingValue?.value === '1' ? { ...sortingValue, value: '-1' } : null;
        }

        analytics.track({
            event: 'Clicked on “sort” button',
            properties: { fieldName: field, value: sortingValue?.value || null, tableName }
        });

        setSorting(sortingValue);
    };

    const checkSortField = (name) => {
        if (sorting?.field === name) {
            return `${sorting.value ? ' active' : ''} ${sorting.value === '-1' ? 'transform rotate-180' : ''}`;
        }
        return '';
    };

    const onOpenFilterPanel = (e, fieldName) => {
        e.stopPropagation();
        // analytics.track({ event: 'Clicked on “filter” button', properties: { fieldName, tableName } });
        setActiveFilterPanel((prev) => prev === fieldName ? null : fieldName);
    };

    const applyFilterParams = () => {
        analytics.track({
            event: 'Clicked on “filter” button',
            properties: {
                fieldName: activeFilterPanel,
                params: filterParams?.[activeFilterPanel],
                tableName
            }
        });
        applyFilter();
        setActiveFilterPanel(null);
    };

    const clearFilterParams = (field) => {
        analytics.track({
            event: 'Clicked on “filter” button',
            properties: { fieldName: activeFilterPanel, params: initialStateFilter?.[activeFilterPanel], tableName }
        });
        clearFilter(field);
        setActiveFilterPanel(null);
    };

    const onChangeTax = (e) => {
        const { name } = e.target;
        const { isTax } = filterParams;
        saveToFilter('isTax', { ...isTax, [name]: !isTax[name] });
    };

    const onChangeBuy = (e) => {
        const { name } = e.target;
        const { isBuy } = filterParams;
        if (isBuy && hasOwnPropertyUtils(isBuy, 'buy') && hasOwnPropertyUtils(isBuy, 'sell')) {
            saveToFilter('isBuy', { ...isBuy, [name]: !isBuy[name] });
        } else {
            saveToFilter('isBuy', { ...initialStateFilter.isBuy, [name]: true });
        }
    };

    const onChangeOrderStatus = (e) => {
        const { name } = e.target;
        const { orderStatus } = filterParams;
        saveToFilter('orderStatus', { ...orderStatus, [name]: !orderStatus[name] });
    };

    const onChangeCertificate = (e) => {
        const { name } = e.target;
        const { isCertificate } = filterParams;
        if (isCertificate && hasOwnPropertyUtils(isCertificate, 'with') && hasOwnPropertyUtils(isCertificate, 'withOut')) {
            saveToFilter('isCertificate', { ...isCertificate, [name]: !isCertificate[name] });
        } else {
            saveToFilter('isCertificate', { ...initialStateFilter.isCertificate, [name]: true });
        }
    };

    const onChangeTransport = (e) => {
        const { name } = e.target;
        const { isOwnerTransport } = filterParams;
        if (isOwnerTransport && hasOwnPropertyUtils(isOwnerTransport, 'with') && hasOwnPropertyUtils(isOwnerTransport, 'withOut')) {
            saveToFilter('isOwnerTransport', { ...isOwnerTransport, [name]: !isOwnerTransport[name] });
        } else {
            saveToFilter('isOwnerTransport', { ...initialStateFilter.isOwnerTransport, [name]: true });
        }
    };

    const onChangePaymentType = (id) => {
        const { paymentType } = filterParams;
        saveToFilter('paymentType', paymentType === id ? '' : id);
    };

    const setRefTitle = (element, name) => {
        if (element && !titleFilterPanels[name]) {
            setTitleFilterPanels((prev) => ({ ...prev, [name]: { current: element } }));
        }
    };

    const onChangeCheckBoxesList = (field, id) => {
        const fieldProps = filterParams[field] ? filterParams[field] : initialStateFilter[field];
        let data = null;

        if (id === 'other') {
            data = !fieldProps;
        } else if (fieldProps) {
            const findItem = fieldProps?.find((prevId) => prevId === id);
            data = findItem ? fieldProps?.filter((prevId) => prevId !== id) : [...fieldProps, id];
        } else {
            data = [id];
        }

        saveToFilter(field, data);
    };

    const navToProfile = (e, id) => {
        e.stopPropagation();
        if (!hide.handleClickUsername) {
            history.push(userId === id ? navigation.pages.account : `${navigation.pages.account}/${id}`);
        }
    };

    const redirectToDetailsPage = (item) => {
        if (item?._id) {
            history.push(`${navigation.pages.pallets}/${item._id}`);
        }
    };

    const openOrderModal = (item) => {
        if (item) {
            setBuySellProductModal({
                display: true,
                params: {
                    _id: item._id,
                    ownerId: item.userId?._id,
                    minCount: item.minCount,
                    maxCount: item.maxCount
                }
            });
        }
    };

    const sendMessage = async (e, item) => {
        e.stopPropagation();

        const result = await checkPrivateChat(item?.userId?._id);

        if (result) {
            await changeActiveConversation(result);
            history.push(navigation.pages.messenger);
        } else {
            setCreateMessageModal({
                display: true,
                id: item?.userId?._id
            });
        }
    };

    const createOrder = async (e, item) => {
        e.stopPropagation();
        analytics.track({
            event: 'Clicked on “buy/sell” button from list',
            properties: { productId: item._id, productType: item.productType.name }
        });

        const isAgreement = await checkAgreement(item);

        if (!isAgreement) {
            openOrderModal(item);
        } else {
            redirectToDetailsPage(item);
        }
    };

    const onRemoveProduct = (e, item) => {
        e.stopPropagation();

        analytics.track({
            event: 'Clicked on "delete request" button',
            properties: { buttonName: 'removeProduct', tableName }
        });

        if (changeOnRemoveProduct) {
            changeOnRemoveProduct(item._id);
        }
    };

    const onToggleProductActivate = (e, item, requestType) => {
        e.stopPropagation();

        analytics.track({
            event: `Clicked on "${requestType} request" button`,
            properties: { productParams: item }
        });

        if (typeof changeOnToggleProductActivate === 'function') {
            changeOnToggleProductActivate(item);
        }
    };

    const setRef = (element, field) => {
        if (element !== null && !refs?.[field]) {
            setRefs((prev) => ({ ...prev, [field]: element }));
        }
    };

    const tabletTypeToggle = () => {
        analytics.track({
            event: 'Clicked on button in Table',
            properties: { buttonName: 'Toggle table type', tableName }
        });
        setTabletType((prev) => ({ ...prev, [tableName]: !prev[tableName] }));
    };

    const checkAgreement = async (product) => {
        try {
            const result = await fetchMoreProductCustomer({
                variables: {
                    productId: product?.__typename === 'ProductCustomer' ? product?.productId?._id : product?._id,
                    customerId: userId,
                    ownerId: product?.userId?._id || product?.ownerId?._id
                }
            });

            return !!result?.data?.productCustomer || product?.userId?._id === userId;
        } catch (error) {
            console.log('Error getting agreement info: ', error);
        }
    };

    const checkPrivateChat = async (recipientUserId) => {
        try {
            const result = await fetchMoreConversation({
                variables: { recipientUserId }
            });

            return result?.data?.privateChat;
        } catch (error) {
            console.log('Error getting agreement info: ', error);
        }
    };

    const handleClickRow = async (item) => {
        analytics.track({
            event: 'Clicked on the row',
            properties: { item }
        });

        if (typeof changeHandleClickRow === 'function') {
            changeHandleClickRow(item);
            return;
        }

        const isAgreement = await checkAgreement(item);

        if (isAgreement) {
            redirectToDetailsPage(item);
            return;
        }

        const { _id } = item;
        setProductDetailsModal({ display: true, _id });
    };

    const onFinishOrder = (e, item) => {
        e.stopPropagation();

        setOrderFinishModal({
            display: true,
            params: item
        });
    };

    const createPrivateChat = (recipientUserId) => {
        const data = {
            members: [recipientUserId, userId],
            type: messenger.types.private
        };

        createConversation({
            variables: { data },
            update: async (cache, result) => {
                const newConversation = result?.data?.createConversation;
                if (newConversation) {
                    await updateConversationListInCache({
                        conversation: newConversation,
                        options: { add: true }
                    });
                    changeActiveConversation(newConversation);
                    history.push(navigation.pages.messenger);
                }
            }
        });
    };

    const openOrderDocumentsModal = (e, assets) => {
        e.stopPropagation();

        analytics.track({
            event: 'Clicked on "documents" button',
            properties: { assets }
        });

        handleOrderAssetsClick(assets);
    };

    const sendMessageToUser = async (e, recipient) => {
        e.stopPropagation();

        analytics.track({
            event: 'Clicked on "send message" button',
            properties: {
                initiator: {
                    id: userId,
                    username: userName
                },
                recipient
            }
        });

        if (recipient?.id && userId !== recipient?.id) {
            const result = await checkPrivateChat(recipient.id);

            if (result) {
                changeActiveConversation(result);
                history.push(navigation.pages.messenger);
            } else {
                createPrivateChat(recipient.id);
            }
        }
    };

    const { activeTableColumns } = TableColumns({
        show,
        hide,
        change,
        onChangeSort,
        checkSortField,
        activeFilterPanel,
        onOpenFilterPanel,
        setRefTitle,
        filterParams,
        clearFilterParams,
        applyFilterParams,
        navToProfile,
        openOrderDocumentsModal,
        productUniqOptions,
        onChangeCheckBoxesList,
        onChangeTax,
        onChangeBuy,
        createOrder,
        userId,
        sendMessage,
        onRemoveProduct,
        onToggleProductActivate,
        onFinishOrder,
        onChangeOrderStatus,
        onChangeCertificate,
        onChangePaymentType,
        sendMessageToUser,
        onChangeTransport
    })

    return {
        activeTableColumns,
        closeActiveFilterPanel: () => setActiveFilterPanel(null),
        titleFilterPanels,
        setRef,
        tableType,
        tabletTypeToggle,
        handleClickRow,
        modals: {
            buySellProductModal,
            setBuySellProductModal,
            closeBuySellProductModal: () => setBuySellProductModal({ display: false }),
            productDetailsModal,
            setProductDetailsModal,
            closeProductDetailsModal: () => setProductDetailsModal({ display: false }),
            orderFinishModal,
            setOrderFinishModal,
            closeOrderFinishModal: () => setOrderFinishModal((prev) => ({ ...prev, display: false })),
            createMessageModal,
            closeCreateMessageModal: () => setCreateMessageModal((prev) => ({ ...prev, display: false }))
        }
    };
};
export default useTableColumnsHook;
