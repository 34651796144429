import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import BaseModal from '../index';
import { GeneralContext } from 'contexts';
import { useAuthToken } from '_hooks';
import { CREATE_ASSETS, CREATE_DELIVERY, GET_DELIVERIES_LIST } from '_graphql';
import ClientInfoBlock from './client-info-block';
import DeliveryInfoBlock from './delivery-info-block';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { newDeliverySchema } from '_constants';
import { useSegment } from 'react-segment-hooks';

const initialStateDeliveryInfo = {
    name: '',
    phone: '',
    email: '',
    deliveryRoutes: [],
    description: '',
    isDriver: true
};

const initialStateAddress = {
    placeId: '',
    placeName: '',
    latitude: '',
    longitude: '',
    houseNumber: '',
    streetName: ''
};

const initialStateDeliveryRoutes = [
    {
        price: 0,
        freeSpace: 0,
        space: 0,
        startAddress: initialStateAddress,
        endAddress: initialStateAddress,
        startDate: new Date(),
        endDate: new Date()
    }
];

const NewDeliveryModal = () => {
    const { register, handleSubmit, setValue, formState: { errors }, watch, reset } = useForm({
        resolver: yupResolver(newDeliverySchema())
    });
    const { setLoading, toggleSegmentButton } = useContext(GeneralContext);
    const { userId } = useAuthToken();
    const analytics = useSegment();

    const { newDeliveryModal: isOpen, closeNewDeliveryModal } = useContext(GeneralContext);

    const [createDelivery, { loading: deliveryLoading, error: deliveryError }] = useMutation(CREATE_DELIVERY);
    const [createAssets, { loading: assetsLoading, error: assetsError }] = useMutation(CREATE_ASSETS);

    const [files, setFiles] = useState([]);

    useEffect(() => {
        setLoading((deliveryLoading && !deliveryError) && (assetsLoading && !assetsError));
    }, [deliveryLoading, deliveryError, assetsLoading, assetsError]);

    useEffect(() => {
        if (isOpen) {
            reset({ ...initialStateDeliveryRoutes[0], ...initialStateDeliveryInfo });

            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'New Delivery'
                }
            });
        }
    }, [isOpen]);

    const addDeliveryToCache = (cache, delivery) => {
        try {
            const data = cache.readQuery({ query: GET_DELIVERIES_LIST, variables: { page: 1, userId } });

            if (data) {
                const deliveriesList = data.deliveries ? {
                    ...data.deliveries,
                    rows: [
                        delivery,
                        ...data.deliveries?.rows
                    ],
                    total: data.deliveries?.total + 1
                } : {
                    page: 1,
                    pageSize: 1,
                    rows: [delivery],
                    total: 1,
                    totalPages: 1
                };
                cache.writeQuery({
                    query: GET_DELIVERIES_LIST,
                    variables: { page: 1, userId },
                    data: { deliveries: deliveriesList }
                });
            }
        } catch (e) {
            console.error('Write to cache error: ', e);
        } finally {
            closeNewDeliveryModal();
        }
    };

    const toCreateDelivery = (data) => {
        createDelivery({
            variables: { data },
            update: async (cache, { data }) => {
                const createDeliveryData = data?.createDelivery;
                if (createDeliveryData) {
                    let newDeliveryData = { ...createDeliveryData };
                    if (files.length > 0) {
                        const assetsData = { model: 'delivery', itemId: createDeliveryData._id };
                        await createAssets(
                            {
                                variables: { data: assetsData, files },
                                update: (assetCache, { data: assetsData }) => {
                                    if (assetsData?.createAssets) {
                                        newDeliveryData = { ...newDeliveryData, assets: assetsData?.createAssets };
                                    }
                                }
                            });
                    }
                    addDeliveryToCache(cache, newDeliveryData);
                }
            }
        });
    };

    const submitData = (formData) => {
        toggleSegmentButton('Submit', 'New Delivery');
        const deliveryRouter = {
            price: Number(formData.price),
            startAddress: formData.startAddress,
            startDate: formData.startDate
        };

        if (formData.endAddress?.placeId && formData.endAddress?.streetName && formData.endAddress?.houseNumber) {
            deliveryRouter.endAddress = formData.endAddress;
            deliveryRouter.endDate = formData.endDate;
        }

        if (formData.isDriver) {
            deliveryRouter.freeSpace = Number(formData.freeSpace);
        } else {
            deliveryRouter.space = Number(formData.space);
        }

        const data = {
            userId,
            deliveryRoutes: [deliveryRouter],
            name: formData.name,
            phone: formData.phone,
            email: formData.email,
            description: formData.description,
            isDriver: formData.isDriver
        };

        toCreateDelivery(data);
    };

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={closeNewDeliveryModal}
        >
            <form className='flex flex-row h-full' onSubmit={handleSubmit(submitData)}>
                <ClientInfoBlock
                    hookForm={{ register, errors, setValue }}
                    setFiles={setFiles}
                />
                <DeliveryInfoBlock
                    hookForm={{ register, errors, setValue, watch }}
                />
            </form>
        </BaseModal>
    );
};

export default withTranslation()(NewDeliveryModal);
