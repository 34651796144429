import {gql} from '@apollo/client';
import {TYPE_DELIVERY} from '_graphql/types';

export const GET_DELIVERIES_LIST = gql`
    query deliveries(
        $page: Int!
        $pageSize: Int
        $sort: String
        $search: String
        $userId: ID
    ){
        deliveries(
            page: $page
            pageSize: $pageSize
            sort: $sort
            search: $search
            userId: $userId
        ){
            rows {
                ${TYPE_DELIVERY}
            }
            total
            page
            pageSize
            totalPages
        }
    }
`;
