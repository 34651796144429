import React from 'react';
import {PolarAngleAxis, RadialBar, ResponsiveContainer, RadialBarChart} from 'recharts';

export const StatisticPipe = ({ title, minMax, value = 0, onClick = false }) => {
    const data = [
        {
            name: 'rating',
            value,
            fill: '#2563EB'
        }
    ];

    return (
        <div className = 'relative flex flex-col items-center justify center gap-5 font-sans text-theme-blue'>
            <div className='w-36 h-36'>
            <ResponsiveContainer>
                <RadialBarChart
                   innerRadius={60}
                   outerRadius={60}
                   barSize={10}
                   data={data}
                   startAngle={90}
                   endAngle={450}
                >
                    <RadialBar
                       label={{position: 'center', fill: '#2563EB'}}
                       dataKey="value"
                       background
                       isAnimationActive={false}
                    />
                    <PolarAngleAxis
                       type="number"
                       domain={minMax}
                       angleAxisId={0}
                       tick={false}
                    />
                </RadialBarChart>
            </ResponsiveContainer>
            </div>
            <p className='text-base'>{title}</p>
            {onClick && (<button onClick={onClick} className='absolute z-50 w-full h-full cursor-pointer'/>)}
        </div>
    )
}

export default StatisticPipe
