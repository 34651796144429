import { gql } from '@apollo/client';

export const ACTIVE_MAIL_MUTATION = gql`
    mutation activateAccount(
        $resetToken: String!
    ){
        activateAccount(
            resetToken: $resetToken
        ) {
            status
        }
    }
`;

