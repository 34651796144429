export const storageNames = {
    saveLocale: 'localeCode',
    tabletType: 'tabletType',
    TOKEN_NAME: 'authToken',
    USER_DATA: 'userData',
    chatPanel: 'chatPanel',
    filterOptions: 'filterParams',
    activeSubMenu: 'lastActivePage',
    tableSettings: 'tableSettings'
}

