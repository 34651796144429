import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useSegment } from 'react-segment-hooks';
import { LOGIN } from '_graphql';
import { useLazyQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAuthToken, useLogin } from '_hooks';
import { navigation } from '_constants';
import { IconArrow4, IconDoubleArrowRight, IconEmail, IconGoogle, IconLock } from 'assets/images/svg';
import { LoadingSpinner } from '../../../components';
import useWindowDimensions from '../../../_hooks/useWindowDimensions';

const initialStateUser = { email: '', password: '' };

const LoginPage = ({ t }) => {
    const schema = yup.object({
        email: yup.string().email(t('login.errorValidateEmail')).required(t('login.errorEmail')),
        password: yup.string().required(t('login.errorPassword'))
    }).required();
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialStateUser
    });

    const { width, height } = useWindowDimensions();
    const { setAuthToken, setUserData } = useAuthToken();
    const loginGoogleService = useLogin({ service: 'google' });
    const history = useHistory();
    const analytics = useSegment();

    const [loaderState, setLoaderState] = useState(false);

    const [login, {
        data,
        loading,
        error,
        refetch,
        called
    }] = useLazyQuery(LOGIN, { fetchPolicy: 'network-only' });

    useEffect(() => {
        setLoaderState(loading && !error);
    }, [loading, error]);

    useEffect(() => {
        if (data?.login) {
            setAuthToken(data.login.accessToken);
            // eslint-disable-next-line no-unused-vars
            const { __typename, ...rest } = data.login.currentUser;
            setUserData(rest);

            analytics.identify({
                userId: rest._id,
                traits: {
                    username: rest.username,
                    email: rest.email,
                    avatar: rest.avatar,
                    viewport: `${width}x${height}`
                }
            });

            history.push(navigation.pages.app);
            reset(initialStateUser);
        }
    }, [data]);

    useEffect(() => {
        analytics.page({ name: 'Login Page' });
        analytics.track({ event: 'Opened Login Page' });
    }, []);

    const Login = async (formValues) => {
        if (called) {
            refetch({ ...formValues });
        } else {
            login({ variables: { ...formValues } });
        }

        analytics.track({ event: 'Clicked on “SignIn” button', properties: { email: formValues.email } });
    };

    const clickedForgotPassword = () => {
        analytics.track({ event: 'Clicked on “forgot password” button' });
    };

    const clickedSignup = () => {
        analytics.track({ event: 'Clicked on “SignUp” button' });
    };

    return (
        <>
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <p className='text-white text-subtitle text-center font-normal'>{t('login.title')}</p>
                <p className='text-white text-sm text-center font-normal mb-12'>{t('login.subTitle')}</p>
                <form className='flex flex-col gap-y-3 max-w-sm w-full' onSubmit={handleSubmit(Login)}>
                    <div className='relative'>
                        <label
                        htmlFor='email'
                            className='cursor-pointer z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'><IconEmail/></label>
                        <input
                            {...register('email')}
                            className={`pl-12 pr-5 h-11 text-xs text-theme-gray rounded-full cursor-pointer w-full duration-100 hover:bg-gray-100 ${errors.email ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                            id='email'
                            name='email'
                            type='email'
                            autoComplete='email'
                            placeholder={t('login.email')}
                        />
                        <p className='h-5 text-theme-red-main text-sm font-semibold pl-5'>{errors.email?.message}</p>
                    </div>
                    <div className='relative'>
                        <label
                        htmlFor='password'
                            className='cursor-pointer z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'><IconLock/></label>
                        <input
                            {...register('password')}
                            className={`pl-12 pr-5 h-11 text-xs text-theme-gray rounded-full cursor-pointer w-full duration-100 hover:bg-gray-100 ${errors.password ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                            id='password'
                            name='password'
                            type='password'
                            autoComplete='password'
                            placeholder={t('login.password')}
                        />
                        <p className='h-5 text-theme-red-main text-sm font-semibold pl-5'>{errors.password?.message}</p>
                    </div>
                    <div className='relative login-hover'>
                        <button
                            className='text-white flex flex-row items-center w-full h-11 rounded-full border-2 border-white text-center bg-transparent text-base font-normal duration-200 cursor-pointer justify-center'
                            type='submit'
                        >
                            <span
                                className='z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'>
                                <IconArrow4 className='fill-current text-white'/>
                            </span>
                            <span className='duration-200 pl-2'>{t('login.submit')}</span>
                            <IconDoubleArrowRight className='duration-200 login-icon ml-2 w-0' />
                        </button>
                    </div>
                </form>
                <Link
                    className='inline-link relative text-white text-sm font-normal text-center mt-2 mb-8 cursor-pointer duration-200 hover:text-white'
                    to={navigation.nonAuth.forgotPassword}
                    onClick={clickedForgotPassword}
                >
                    <p>{t('login.forgotPassword')}</p>
                    <span className='duration-200 absolute w-0 h-px bottom-0 left-0 bg-theme-blue' />
                </Link>
                <button
                    className='h-11 px-4 border-2 border-white hover:border-theme-blue rounded-full cursor-pointer duration-200 hover:bg-theme-blue flex flex-row justify-center items-center gap-x-1.5'
                    onClick={loginGoogleService}
                >
                    <IconGoogle className='flex-none w-7 h-7'/><p
                    className='text-base font-normal text-white '>{t('login.google')}</p></button>
                <p className='text-white text-center text-xs font-normal mt-6 mb-4'>{t('login.or')}</p>
                <Link
                    className='inline-link duration-200 relative text-white font-normal text-sm text-center cursor-pointer duration-100 hover:text-white'
                    to={navigation.nonAuth.signup}
                    onClick={clickedSignup}
                >
                    <p>{t('login.signup')}</p>
                    <span className='duration-200 absolute w-0 h-px bottom-0 left-0 bg-theme-blue' />
                </Link>
            </div>
            <LoadingSpinner loading={loaderState}/>
        </>
    );
};

export default withTranslation()(LoginPage);
