import React, { useContext, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { FilterContext } from 'contexts';
import { MultiSelect } from '../../common';
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCT_CUSTOMERS, GET_PRODUCT_LIST } from '_graphql';

const FilterClientSelect = ({ t, setSpinnerState, filterType, isActiveUsers, filterVariables: variables }) => {
    const { filterParams, saveToFilter } = useContext(FilterContext);
    const [userList, setUserList] = useState([]);

    const productQuery = isActiveUsers ? GET_PRODUCT_CUSTOMERS : GET_PRODUCT_LIST;
    const [getActiveUsers, { loading }] = useLazyQuery(productQuery, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first'
    });

    const handleGetActiveUsers = async () => {
        const fetchedProducts = await getActiveUsers({ variables });
        const dataType = isActiveUsers ? 'productCustomers' : 'products';
        const userType = filterType.slice(0, -1);

        const fetchedUsers = fetchedProducts.data[dataType].rows.map((product) => ({
            _id: product[userType]?._id,
            name: product[userType]?.username
        }));
        setUserList(fetchedUsers);
    };

    useEffect(() => {
        handleGetActiveUsers();
    }, []);

    const { activeUsers, actualParams } = useMemo(() => {
        const activeUsers = [...new Map(userList.map((user) => [user._id, user])).values()];
        const filteredArray = [...filterParams[filterType]];
        let actualParams = [];

        if (activeUsers?.length) {
            actualParams = filteredArray?.map((_id) => activeUsers?.find(({ _id: userId }) => userId === _id));
        } else {
            actualParams = filteredArray?.map((_id) => ({ _id }));
        }

        return {
            activeUsers,
            actualParams
        };
    }, [userList]);

    const saveToFiltersWithName = (data) => {
        saveToFilter(filterType, data);
    };

    useEffect(() => {
        const actualLoadingState = filterParams[filterType]?.length ?
            !actualParams[0]?.name :
            loading;

        setSpinnerState(actualLoadingState);
    }, [loading, actualParams]);

    return (
        <>
            <p className='mb-2'>{t('filterPanel.username')}</p>
            <MultiSelect
                isUserList={true}
                className={'w-full flex flex-col items-center relative text-xs'}
                defaultSelected={actualParams}
                data={activeUsers || []}
                setValue={saveToFiltersWithName}
            />
        </>
    );
};

export default withTranslation()(FilterClientSelect);
