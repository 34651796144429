const navigation = {
    nonAuth: {
        login: '/login',
        signup: '/signup',
        changeUserInfo: '/changeUserInfo',
        forgotPassword: '/reset-password',
        changePassword: '/changePassword',
        activateEmail: '/activateEmail'
    },
    pages: {
        app: '/',

        // Pallets
        pallets: '/pallets',
        activatePallets: '/pallets/activate',
        deactivatePallets: '/pallets/deactivate',
        myOrders: '/pallets/my-orders',
        archiveOrders: '/pallets/archive-orders',
        productDetails: '/pallets/product',

        // Delivery
        delivery: '/deliveries',
        myDelivery: '/deliveries/my',
        orderDelivery: '/deliveries/order',
        deliveryDetails: '/deliveries/detail',

        // Proposals
        proposals: '/proposals',
        myProposals: '/proposals/my',
        orderProposals: '/proposals/order',
        proposalsDetails: '/proposals/detail',

        // Profile
        account: '/profile',
        messenger: '/messenger',
        members: '/members',

        // other
        advertisement: '/app/advertisement'
    }
};

export default navigation;
