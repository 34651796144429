import {gql} from '@apollo/client';
import {TYPE_USER} from '_graphql/types';

export const GET_USER = gql`
    query user($_id: ID){
        user(_id: $_id) {
            ${TYPE_USER}
        }
    }
`;
