import {gql} from '@apollo/client';
import {TYPE_CONVERSATION} from '../../types';

export const UPDATE_CONVERSATION = gql`
    mutation updateConversation(
        $_id: ID!
        $data: ConversationUpdateInput!
    ){
        updateConversation(
            data: $data
            _id: $_id
        ){
            ${TYPE_CONVERSATION}
        }
    }
`;
