import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IconArrow1 } from 'assets/images/svg';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import TimePicker from 'react-time-picker/dist/entry.nostyle';
import { CustomRadioSlider } from 'components';
import { GeneralContext, LocaleContext } from 'contexts';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_API_KEY } from '_constants';
import { withTranslation } from 'react-i18next';

const DeliveryInfoBlock = ({ t, hookForm }) => {
    const { closeNewDeliveryModal, toggleSegmentButton } = useContext(GeneralContext);
    const { locale } = useContext(LocaleContext);

    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        const subscription = hookForm.watch((value) => {
            setFormValues(value);
        });
        return () => subscription.unsubscribe();
    }, [hookForm.watch]);

    const disabledDate = useMemo(() => ({
        startDate: !formValues.startAddress?.placeId || !formValues.startAddress?.streetName || !formValues.startAddress?.houseNumber,
        endDate: !formValues.endAddress?.placeId || !formValues.endAddress?.streetName || !formValues.endAddress?.houseNumber
    }), [formValues]);

    const onPlaceSelected = async (place, field) => {
        if (place.formatted_address && place.place_id && place.geometry) {
            const latitude = `${await place.geometry.location.lat()}`;
            const longitude = `${await place.geometry.location.lng()}`;
            hookForm.setValue(field, {
                placeName: place.formatted_address,
                placeId: place.place_id,
                latitude,
                longitude
            });
        }
    };

    const onChangeTime = (time, field) => {
        if (!time) {
            return;
        }
        const prevDate = new Date(formValues[field]);
        const newDateWithHours = new Date(prevDate.setHours(time.split(':')[0]));
        const newDateWithMinutes = new Date(newDateWithHours.setMinutes(time.split(':')[1]));
        hookForm.setValue(field, newDateWithMinutes);
    };

    const onChangeDate = (date, field) => {
        if (!date) {
            console.log(date);
            return;
        }
        const prevDate = new Date(formValues[field]);
        const newDateWithDay = new Date(prevDate.setDate(date.getDate()));
        const newDateWithMonth = new Date(newDateWithDay.setMonth(date.getMonth()));
        const newDateWithYear = new Date(newDateWithMonth.setFullYear(date.getFullYear()));
        hookForm.setValue(field, newDateWithYear);
    };

    return (
        <div
            className='min-h-full flex flex-col w-full max-w-newTaraRightBlock text-sm bg-theme-gray-main py-5 px-10 text-theme-blue overflow-y-auto scrollable'>
            <p className='text-left text-lg uppercase w-max inline-block mx-auto mb-4 font-medium'>{t('modals.newDeliveryModal.load')}</p>
            <div className='w-full flex flex-row gap-4 items-center justify-between mb-4'>
                <div className='w-full max-w-66 w-full relative flex flex-row'>
                    <p className='w-full max-w-30'>{t('modals.newDeliveryModal.city')}</p>
                    <Autocomplete
                        {...hookForm.register('startAddress')}
                        name={'startAddress'}
                        apiKey={GOOGLE_API_KEY}
                        onPlaceSelected={(place) => onPlaceSelected(place, 'startAddress')}
                        onChange={(e) => hookForm.setValue('startAddress.placeName', e.target.value)}
                        language={locale}
                        className={`w-full h-6 pl-3 pr-9 border ${hookForm.errors.startAddress ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent text-xs`}
                        options={{
                            types: ['(cities)']
                        }}
                    />
                    {!hookForm.errors.startAddress &&
                        <IconArrow1 className='absolute top-1/2 right-3 transform -translate-y-1/2'/>}
                </div>
                <div className='w-full max-w-36 flex flex-row items-center'>
                    <p className='flex-grow'>{t('modals.newDeliveryModal.date')}</p>
                    <DatePicker
                        {...hookForm.register('startDate')}
                        disableCalendar={true}
                        clearIcon={null}
                        className={`text-xs w-20 border rounded-2xl h-6 ${disabledDate.startDate ? 'bg-gray-100 text-gray-400 border-gray-400' : 'bg-transparent border-theme-blue'}`}
                        value={formValues.startDate}
                        onChange={(date) => onChangeDate(date, 'startDate')}
                        disabled={disabledDate.startDate}
                    />
                </div>
            </div>
            <div className='w-full flex flex-row gap-4 items-center justify-between mb-4'>
                <div className='w-full max-w-66 w-full flex flex-row'>
                    <p className='w-full max-w-30'>{t('modals.newDeliveryModal.street')}</p>
                    <input
                        {...hookForm.register('startAddress.streetName')}
                        type='text'
                        name='startAddress.streetName'
                        autoComplete={'off'}

                        className={`w-full h-6 px-2 border ${hookForm?.errors.startAddress?.streetName ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent text-xs`}
                    />
                </div>
                <div className='w-full max-w-36 flex flex-row items-center'>
                    <p className='flex-grow'>{t('modals.newDeliveryModal.time')}</p>
                    <TimePicker
                        className={`w-20 h-6 border rounded-3xl text-xs flex items-center ${disabledDate.startDate ? 'bg-gray-100 text-gray-400 border-gray-400' : 'bg-transparent border-theme-blue'}`}
                        onChange={(time) => onChangeTime(time, 'startDate')}
                        clearIcon={null}
                        clockIcon={null}
                        value={formValues.startDate || ''}
                        disabled={disabledDate.startDate}
                    />
                </div>
            </div>
            <div className='w-full flex flex-row gap-4 items-center justify-between mb-4'>
                <div className='w-full max-w-66 flex flex-row'>
                    <p className='w-full max-w-30'>{t('modals.newDeliveryModal.house')}</p>
                    <input
                        {...hookForm.register('startAddress.houseNumber')}
                        type='text'
                        name='startAddress.houseNumber'
                        className={`w-20 h-6 px-2 border ${hookForm?.errors?.startAddress?.houseNumber ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent text-xs`}
                    />
                </div>
            </div>
            <p className='text-left text-lg uppercase w-max inline-block mx-auto mb-4 font-medium'>{t('modals.newDeliveryModal.unload')}</p>
            <div className='w-full flex flex-row gap-4 items-center justify-between mb-4'>
                <div className='w-full max-w-66 w-full relative flex flex-row'>
                    <p className='w-full max-w-30'>{t('modals.newDeliveryModal.city')}</p>
                    <Autocomplete
                        name={'endAddress'}
                        apiKey={GOOGLE_API_KEY}
                        onPlaceSelected={(place) => onPlaceSelected(place, 'endAddress')}
                        onChange={(e) => hookForm.setValue('endAddress.placeName', e.target.value)}
                        language={locale}
                        className={`w-full h-6 pl-3 pr-9 border ${hookForm.errors.endAddress ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent text-xs`}
                        options={{
                            types: ['(cities)']
                        }}
                    />
                    {!hookForm.errors.endAddress && (
                        <IconArrow1 className='absolute top-1/2 right-3 transform -translate-y-1/2'/>)}
                </div>
                <div className='w-full max-w-36 flex flex-row'>
                    <p className='flex-grow'>{t('modals.newDeliveryModal.date')}</p>
                    <DatePicker
                        disableCalendar={true}
                        clearIcon={null}
                        className={`text-xs w-20 border rounded-2xl h-6 ${disabledDate.endDate ? 'bg-gray-100 text-gray-400 border-gray-400' : 'bg-transparent border-theme-blue'}`}
                        value={formValues.endDate}
                        onChange={(date) => onChangeDate(date, 'endDate')}
                        disabled={disabledDate.endDate}
                    />
                </div>
            </div>
            <div className='w-full flex flex-row gap-4 items-center justify-between mb-4'>
                <div className='w-full max-w-66 w-full flex flex-row'>
                    <p className='w-full max-w-30'>{t('modals.newDeliveryModal.street')}</p>
                    <input
                        {...hookForm.register('endAddress.streetName')}
                        type='text'
                        name='endAddress.streetName'
                        autoComplete={'off'}
                        className={`w-full h-6 px-2 border ${hookForm?.errors?.endAddress?.streetName ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent text-xs`}
                    />
                </div>
                <div className='w-full max-w-36 flex flex-row'>
                    <p className='flex-grow'>{t('modals.newDeliveryModal.time')}</p>
                    <TimePicker
                        {...hookForm.register('endDate')}
                        className={`w-20 h-6 border rounded-3xl text-xs flex items-center ${disabledDate.endDate ? 'bg-gray-100 text-gray-400 border-gray-400' : 'bg-transparent border-theme-blue'}`}
                        onChange={(time) => onChangeTime(time, 'endDate')}
                        clearIcon={null}
                        clockIcon={null}
                        value={formValues.endDate}
                        disabled={disabledDate.endDate}
                    />
                </div>
            </div>
            <div className='w-full flex flex-row gap-4 items-center justify-between mb-8'>
                <div className='w-full max-w-66 flex flex-row'>
                    <p className='w-full max-w-30'>{t('modals.newDeliveryModal.house')}</p>
                    <input
                        {...hookForm.register('endAddress.houseNumber')}
                        type='text'
                        name='endAddress.houseNumber'
                        className={`w-20 h-6 px-2 border ${hookForm?.errors?.endAddress?.houseNumber ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent text-xs`}
                    />
                </div>
            </div>


            <div className='flex flex-row items-center flex-nowrap mb-4'>
                <p className='flex-grow-0 w-30'>{t('modals.newDeliveryModal.type')}</p>
                <div className='flex-grow flex flex-row items-center'>
                    <div className='w-2/3'>
                        <CustomRadioSlider
                            register={hookForm.register}
                            value={formValues.isDriver}
                            name={'isDriver'}
                            text={t('modals.newDeliveryModal.driver')}
                            onChange={() => hookForm.setValue('isDriver', true)}
                        />
                    </div>
                    <div className='w-1/3'>
                        <CustomRadioSlider
                            register={hookForm.register}
                            name={'isDriver'}
                            value={!formValues.isDriver}
                            text={t('modals.newDeliveryModal.client')}
                            onChange={() => hookForm.setValue('isDriver', false)}
                        />
                    </div>
                </div>
            </div>

            <div className='w-full flex flex-row gap-4 items-center justify-between mb-4'>
                <div className='w-full max-w-66 flex flex-row'>
                    <p className='w-full max-w-30'>{t(formValues.isDriver ? 'modals.newDeliveryModal.freeSpace' : 'modals.newDeliveryModal.space')}</p>
                    <input
                        {...hookForm.register(formValues.isDriver ? 'freeSpace' : 'space')}
                        type='number'
                        min={0}
                        step={1}
                        name={formValues.isDriver ? 'freeSpace' : 'space'}
                        className='w-20 h-6 px-2 border border-theme-blue rounded-3xl bg-transparent text-xs'
                    />
                    <p className='ml-2'>{t('modals.newDeliveryModal.capacity')}</p>
                </div>
            </div>

            <div className='w-full flex flex-row gap-4 items-center justify-between mb-4'>
                <div className='w-full max-w-66 flex flex-row'>
                    <p className='w-full max-w-30'>{t('modals.newDeliveryModal.price')}</p>
                    <input
                        {...hookForm.register('price')}
                        type='number'
                        min={0}
                        step={1}
                        name={'price'}
                        className='w-20 h-6 px-2 border border-theme-blue rounded-3xl bg-transparent text-xs'
                    />
                    <p className='ml-2'>{t('modals.newDeliveryModal.currency')}/{t('modals.newDeliveryModal.capacity')}</p>
                </div>
            </div>

            <div className='mb-6'>
                <p className='mb-2'>{t('modals.newDeliveryModal.description')}</p>
                <textarea
                    {...hookForm.register('description')}
                    className='w-full h-12 border-b-2 bg-transparent border-theme-blue bg-theme-blue-light-2 rounded-t-xl px-2 py-1'
                    name='description'
                />
            </div>
            <div className='mt-auto flex justify-start gap-8'>
                <button type='submit'
                        className='flex flex-row items-center justify-center bg-theme-blue rounded-3xl text-white h-10 px-7 text-sm hover:bg-theme-blue-light duration-100'>{t('modals.newDeliveryModal.save')}</button>
                <button type='button'
                        className='flex flex-row items-center justify-center bg-theme-blue-main rounded-3xl text-theme-blue h-10 px-7 text-sm mr-6 hover:bg-theme-gray-main duration-100'
                        onClick={() => {
                            closeNewDeliveryModal();
                            toggleSegmentButton('Cancel', 'New Delivery');
                        }}>{t('modals.newDeliveryModal.cancel')}</button>
            </div>
        </div>
    );
};

export default withTranslation()(DeliveryInfoBlock);
