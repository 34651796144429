import React, {useContext, useEffect} from 'react';
import {GeneralContext, NotificationsContext} from 'contexts';
import {useAuthToken, useOutsideClick} from '_hooks';
import {IconArrow3} from 'assets/images/svg';
import {useQuery} from '@apollo/client';
import {GET_NOTIFICATION_LIST} from '_graphql';

const NotificationPanel = () => {
    const { setLoading } = useContext(GeneralContext);
    const { notificationPanel, closeNotificationPanel, toggleNotificationPanel } = useContext(NotificationsContext);
    const { userId } = useAuthToken()

    const { loading, error } = useQuery(GET_NOTIFICATION_LIST, { variables: { userId, page: 1 }, skip: !userId });

    useEffect(() => {
        setLoading(loading && !error)
    }, [loading, error])

    const refNotifications = useOutsideClick(() => closeNotificationPanel());

    return (
        <div className={`absolute ${notificationPanel ? 'left-0' : '-left-full'} z-30 h-full pt-4 mt-0.5 pb-6 pl-3 duration-200`}>
            <div ref={refNotifications} className={'h-full overflow-y-auto scrollable w-notificationsBlock flex flex-col rounded-xl shadow-main bg-white text-theme-blue text-xs mt-0.5 py-8'}>
                <div className='flex-none flex flex-row justify-between w-full pl-8 mb-8 pr-0'>
                    <p>Сповіщення</p>
                    <button className='w-16 flex justify-center text-theme-blue hover:text-blue-400 duration-100 cursor-pointer' onClick={toggleNotificationPanel}>
                        <IconArrow3
                            className={'stroke-current'}
                        />
                    </button>
                </div>
                <div className='flex-grow overflow-x-hidden scrollable'>
                    <div className='w-full flex flex-row px-8 py-3 items-center bg-theme-gray-light-3'>
                        <div className='flex-none w-4 mr-2'><div className='bg-theme-blue rounded-full w-2 h-2'/></div>
                        <div className='flex-grow truncate mr-4'>Відкрита тара відВідкрита тара відВідкрита тара відВідкрита тара від</div>
                        <div className='flex-none mr-4'>ТОВ  Епіцентр</div>
                        <div className='flex-none'>14:00</div>
                    </div>
                    <div className='w-full flex flex-row px-8 py-3 items-center'>
                        <div className='flex-none w-4 mr-2'><div className='bg-theme-blue rounded-full w-2 h-2'/></div>
                        <div className='flex-grow truncate mr-4'>Відкрита тара відВідкрита тара відВідкрита тара відВідкрита тара від</div>
                        <div className='flex-none mr-4'>ТОВ  Епіцентр</div>
                        <div className='flex-none'>14:00</div>
                    </div>
                </div>
                <div className='flex-none mt-8 w-full text-center'>
                    <button className='bg-theme-blue rounded-full px-4 py-2 text-white'>Переглянути всі</button>
                </div>
            </div>
        </div>
    )
}

export default NotificationPanel
