import { gql } from '@apollo/client';
import {TYPE_CURRENT_USER} from '_graphql/types';

export const LOGIN = gql`
    query login(
        $email: String!
        $password: String!
    ){
        login(
            email: $email
            password: $password
        ){
            accessToken
            currentUser {
                ${TYPE_CURRENT_USER}
            }
        }
    }
`;


