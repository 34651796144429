export const shortFileSizeUtils = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export const getFileNameUtils = (url) => {
    const sliceUrl = url.split('/')
    return sliceUrl[sliceUrl.length - 1]
}

export const changeDownloadFileName = (file, name) => {
    window.URL = window.URL || window.webkitURL;

    const xhr = new XMLHttpRequest();
    const a = document.createElement('a');
    let downLoadFile;

    xhr.open('GET', file, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
        downLoadFile = new Blob([xhr.response], { type: 'application/octet-stream' });
        a.href = window.URL.createObjectURL(downLoadFile);
        a.download = name;
        a.click();
    };
    xhr.send();
}

export const checkToImageFileUtils = (file) => {
    const mimeType = file?.mimetype?.split('/')
    return mimeType?.length ? !!mimeType?.includes('image') : false
};

export const urlToFileUtils = async (url, fileName = 'image.png', type = 'image/png') => {
    const res = await fetch(url);
    const blob = await res.blob();
    return new File([blob], fileName, { type });
}

export const toBase64Utils = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});
