import React, {useEffect, useMemo, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSegment} from 'react-segment-hooks';
import {useMutation} from '@apollo/client';
import {UPDATE_USER} from '_graphql';
import {useLocale, useAuthToken} from '_hooks';
import TaraLogo from 'assets/images/img-logo-1.png';
import {
    IconArrow4,
    IconCellPhone, IconClose,
    IconEmail,
    IconLocationCity, IconLocationHouse,
    IconLocationStreet, IconNoImage,
    IconProfile
} from 'assets/images/svg';
import {toBase64Utils, urlToFileUtils} from 'utils';
import Avatar from 'react-avatar-edit';
import 'react-nice-dates/build/style.css'
import Autocomplete from 'react-google-autocomplete';
import {navigation, GOOGLE_API_KEY, welcomeFormSchema} from '_constants';
import {useHistory} from 'react-router-dom';


const ChangeUserInfoPage = ({ t }) => {
    const { userData: { username, email, avatar }, setUserData, userId } = useAuthToken()
    const analytics = useSegment();
    const { locale } = useLocale()
    const history = useHistory()

    const initialStateUserInfo = {
        username,
        email,
        phone: ''
    }

    const [updateUserInfo, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_USER);

    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(welcomeFormSchema()),
        defaultValues: initialStateUserInfo
    });

    const [steps, setSteps] = useState([
        {
            id: 'profile',
            title: t('welcome.step1'),
            fields: ['email', 'username', 'phones', 'placeId', 'houseNumber', 'streetName'],
            fillFields: 0,
            active: true,
            error: false
        },
        {
            id: 'avatar',
            title: t('welcome.step2'),
            fields: ['uploadFile'],
            fillFields: 0,
            active: false,
            error: false
        },
        {
            id: 'info',
            title: t('welcome.step3'),
            fields: ['userDesc', 'companyDesc'],
            fillFields: 0,
            active: false,
            error: false
        }
    ].map((value, index) => ({...value, index})));

    const [uploadFile, setUploadFile] = useState(null);
    const [formValues, setFormValues] = useState(initialStateUserInfo);

    useEffect(() => {
        analytics.page({ name: 'Change User Info' });
    }, [])

    useEffect(() => {
        const subscription = watch((value) => {
            setFormValues(value)
        })
        return () => subscription.unsubscribe();
    }, [watch])

    const currentStep = useMemo(() => steps.find((item) => item.active), [steps]);
    const checkedSteps = useMemo(() => steps.map((step) => {
        const item = {...step}
        if (item.id === 'avatar') {
            item.fillFields = !!(uploadFile?.cropImage || avatar)
        } else {
            item.fillFields = item.fields.reduce((acc, value) => (formValues?.[value] ? acc + 1 : acc), 0)
        }
        item.error = item.fields.some((value) => Object.keys(errors).includes(value))
        return item
    }), [steps, errors, formValues, uploadFile, avatar]);

    const currentAvatarUrl = useMemo(() => {
        if (uploadFile?.cropImage) {
            return uploadFile.cropImage
        }
        if (avatar) {
            return avatar
        }
        return null
    }, [avatar, uploadFile])

    const changeStep = (id) => {
        setSteps((prev) => prev.map((item) => ({...item, active: item.id === id })))
    }

    const goBack = () => {
        if (currentStep.index > 0) {
            changeStep(steps[currentStep.index - 1].id)
        }
    }

    const goNext = () => {
        if (currentStep.index < steps.length - 1) {
            changeStep(steps[currentStep.index + 1].id)
        }
    }

    const editUserInfo = (e) => {
        const data = {}

        if (e.phone) {
            data.phones = [e.phone]
        }
        if (e.birthdayDate) {
            data.birthdayDate = e.birthdayDate
        }
        if (e.streetName) {
            data.streetName = e.streetName
        }
        if (e.houseNumber) {
            data.houseNumber = e.houseNumber
        }
        if (e.gender === 'male' || e.gender === 'female') {
            data.gender = e.gender
        }
        if (e.placeName && e.placeId) {
            data.location = {
                name: e.placeName,
                placeId: e.placeId
            }
        }
        if (e.userDesc || e.companyDesc) {
            const description = {}
            if (e.userDesc) {
                description.userDesc = e.userDesc
            }
            if (e.companyDesc) {
                description.companyDesc = e.companyDesc
            }
            data.description = description
        }

        const variables = {
            _id: userId,
            data
        }

        if (uploadFile?.cropFile) {
            variables.file = uploadFile?.cropFile
        }

        updateUserInfo({
            variables,
            update: (cache, result) => {
                setUserData(result?.data?.updateUser)
                history.push(navigation.pages.pallets)
            }
        })
    }

    const uploadImageFile = async (e) => {
        const { files } = e.target
        setUploadFile({ image: await toBase64Utils(files[0]) })
    }

    const cancelImgCrop = () => {
        setUploadFile(null)
    }

    const setCropImage = async (cropImage) => {
        const cropFile = await urlToFileUtils(cropImage)
        setUploadFile((prev) => ({...prev, cropImage, cropFile }))
    }

    const onPlaceSelected = async (place) => {
        if (place.formatted_address && place.place_id && place.geometry) {
            setValue('placeName', place.formatted_address)
            setValue('placeId', place.place_id)
        }
    }

    const onChangeCity = (e) => {
        setValue('placeName', e.target.value || '')
        setValue('placeId', '')
    }

    return (
        <div className="h-screen w-screen bg-login bg-no-repeat bg-center bg-cover flex flex-row">
            <div className='flex-grow-0 min-w-70 bg-white bg-opacity-75 flex flex-col justify-start items-start text-white py-24 gap-4 text-sm'>
                {checkedSteps.map(({id, title, active, error, fillFields, fields}) => (
                    <button
                        key={id}
                        className={`w-full px-6 flex flex-row gap-x-3 items-center justify-start text-xs ${active ? 'text-theme-blue' : 'text-theme-blue-light'} hover:text-theme-blue duration-100`}
                        onClick={() => changeStep(id)}
                    >
                        <div className='relative h-25 w-6 bg-theme-blue-main rounded-3xl'>
                            <div
                                className={`absolute h-${Math.floor((25 / fields.length) * fillFields || 0)} w-6 ${active ? 'bg-theme-blue' : 'bg-theme-blue-light'} rounded-3xl`}/>
                        </div>
                        <div
                            className={`h-1.5 w-1.5 ${active ? 'bg-theme-blue' : 'bg-theme-blue-light'} ${error ? 'xs:bg-theme-red' : ''} rounded-full`}/>
                        <p>{title}</p>
                    </button>
                ))}
            </div>
            <form className='flex-grow text-white p-20 break-words max-w-newTaraRightBlock text-sm leading-loose' onSubmit={handleSubmit(editUserInfo)}>
                <img src={TaraLogo} className='mb-5' alt='Tara online' width={150}/>
                <div className='w-full max-w-welcomeForm mt-10'>
                    {currentStep.id === 'profile' && (
                        <>
                            <div className='relative mb-10'>
                                <input {...register('email')}
                                       className={`pl-12 pr-5 h-11 text-xs rounded-full text-theme-blue-light placeholder-gray-400 text-theme-blue w-full duration-100 ${errors.email ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                                       name="email"
                                       type="text"
                                       autoComplete='email'
                                       placeholder={t('welcome.email')}
                                       disabled
                                />
                                <div
                                    className='h-9 w-9 absolute top-1 left-1 bg-theme-blue rounded-full text-white flex justify-center items-center'>
                                    <IconEmail className='stroke-current w-4 h-4'/>
                                </div>
                            </div>
                            <div className='relative mb-10'>
                                <input {...register('username')}
                                       className={`pl-12 pr-5 h-11 text-xs rounded-full placeholder-gray-400 text-theme-blue cursor-pointer w-full duration-100 ${errors.username ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                                       name="username"
                                       type="text"
                                       autoComplete='username'
                                       placeholder={t('welcome.fullName')}
                                />
                                <div
                                    className='h-9 w-9 absolute top-1 left-1 bg-theme-blue rounded-full text-white flex justify-center items-center'>
                                    <IconProfile className='stroke-current w-4 h-4'/>
                                </div>
                            </div>
                            <div className='relative mb-19'>
                                <input {...register('phone')}
                                       className={'pl-12 pr-5 h-11 text-xs rounded-full placeholder-gray-400 text-theme-blue cursor-pointer w-full duration-100 hover:bg-gray-100'}
                                       name="phone"
                                       type="text"
                                       autoComplete='phone'
                                       placeholder={t('welcome.phone')}
                                />
                                <div
                                    className='h-9 w-9 absolute top-1 left-1 bg-theme-blue rounded-full text-white flex justify-center items-center'>
                                    <IconCellPhone className='stroke-current w-4 h-4'/>
                                </div>
                            </div>
                            <p className='text-lg mb-5'>{t('welcome.address')}</p>
                            <div className='relative mb-10'>
                                <Autocomplete
                                    {...register('location')}
                                    name={'location'}
                                    apiKey={GOOGLE_API_KEY}
                                    onPlaceSelected={(place) => onPlaceSelected(place)}
                                    onChange={onChangeCity}
                                    language={locale}
                                    value={formValues?.placeName || ''}
                                    className={`pl-12 pr-5 h-11 text-xs rounded-full placeholder-gray-400 text-theme-blue cursor-pointer w-full duration-100 ${errors.placeId ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'}`}
                                    options={{
                                        types: ['(cities)']
                                    }}
                                />
                                <div
                                    className='h-9 w-9 absolute top-1 left-1 bg-theme-blue rounded-full text-white flex justify-center items-center'>
                                    <IconLocationCity className='stroke-current w-4 h-4'/>
                                </div>
                            </div>
                            <div className='flex flex-row w-full gap-x-3.5'>
                                <div className='relative flex-grow'>
                                    <input {...register('streetName')}
                                           className={`pl-12 pr-5 h-11 text-xs rounded-full placeholder-gray-400 text-theme-blue cursor-pointer w-full duration-100 ${errors.streetName ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'}`}
                                           type="text"
                                           autoComplete='street'
                                           placeholder={t('welcome.street')}
                                    />
                                    <div
                                        className='h-9 w-9 absolute top-1 left-1 bg-theme-blue rounded-full text-white flex justify-center items-center'>
                                        <IconLocationStreet className='stroke-current w-4 h-4'/>
                                    </div>
                                </div>
                                <div className='relative flex-grow'>
                                    <input {...register('houseNumber')}
                                           className={`pl-12 pr-5 h-11 text-xs rounded-full placeholder-gray-400 text-theme-blue cursor-pointer w-full duration-100 ${errors.houseNumber ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'}`}
                                           type="text"
                                           autoComplete='house'
                                           placeholder={t('welcome.house')}
                                    />
                                    <div
                                        className='h-9 w-9 absolute top-1 left-1 bg-theme-blue rounded-full text-white flex justify-center items-center'>
                                        <IconLocationHouse className='stroke-current w-4 h-4'/>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {currentStep.id === 'avatar' && (
                        <>
                            <div className='flex justify-center mb-10'>
                                {currentAvatarUrl ? (
                                    <img src={currentAvatarUrl} width={100} height={100} className='w-25 h-25 rounded-full' alt='profile avatar'/>
                                ) : (
                                    <div className='w-25 h-25 flex items-center justify-center bg-theme-blue-light rounded-full text-white'>
                                        <IconProfile className='stroke-current w-15 h-15'/>
                                    </div>
                                )}
                            </div>
                            <div className='flex justify-center'>
                                { uploadFile ? (
                                    <button
                                        onClick={cancelImgCrop}
                                        className='py-2.5 px-5 mx-auto mb-10 w-max flex flex-row items-center gap-3 text-white border rounded-3xl border-theme-blue bg-theme-blue hover:border-theme-blue-light hover:bg-theme-blue-light cursor-pointer duration-100'
                                    >
                                        {t('global.buttons.delete')}
                                        <IconClose className='text-white fill-current stroke-current'/>
                                    </button>
                                ) : (
                                    <label
                                        className='py-2.5 px-5 mx-auto mb-10 w-max flex flex-row items-center gap-3 text-white border rounded-3xl border-theme-blue bg-theme-blue hover:border-theme-blue-light hover:bg-theme-blue-light cursor-pointer duration-100'
                                    >
                                        <input
                                            accept="image/*"
                                            type="file"
                                            name="file"
                                            className='hidden'
                                            onChange={uploadImageFile}
                                        />
                                        {t('global.buttons.chooseFile')}
                                    </label>
                                )}
                            </div>
                            <div className='w-full h-55 bg-white bg-opacity-20 rounded-3xl flex justify-center items-center'>

                                {uploadFile?.image ? (
                                    <Avatar
                                        width={'100%'}
                                        height={200}
                                        imageHeight={220}
                                        onCrop={setCropImage}
                                        src={uploadFile.image}
                                        cropColor={'#2563EB'}
                                        closeIconColor={'transparent'}
                                        shadingColor={'#BFDBFE'}
                                        lineWidth={5}
                                        onClose={cancelImgCrop}
                                    />
                                ) : (
                                    <IconNoImage/>
                                )}
                            </div>
                        </>
                    )}
                    {currentStep.id === 'info' && (
                        <>
                            <textarea
                                cols="30"
                                rows="5"
                                className='px-2 py-1 w-full h-full bg-theme-blue-light-2 text-theme-blue-light-1 placeholder-theme-blue-light-1 bg-opacity-50 rounded-xl mb-10'
                                onChange={(e) => setValue('userDesc', e.target.value)}
                                value={formValues?.userDesc}
                                placeholder={t('profile.about')}
                            />
                            <textarea
                                cols="30"
                                rows="5"
                                className='px-2 py-1 w-full h-full bg-theme-blue-light-2 text-theme-blue-light-1 placeholder-theme-blue-light-1 bg-opacity-50 rounded-xl'
                                onChange={(e) => setValue('companyDesc', e.target.value)}
                                value={formValues?.companyDesc}
                                placeholder={t('profile.aboutCompany')}
                            />
                        </>
                    )}
                </div>


                <div className='mt-10 flex justify-between'>
                    <button
                        className={`py-2.5 px-5 ${currentStep.index === 0 ? 'cursor-default text-theme-gray border-theme-gray' : 'text-white border-white hover:border-theme-blue hover:bg-theme-blue'} flex flex-row items-center gap-3 border rounded-3xl duration-100`}
                        onClick={goBack}
                        type='button'
                    >
                        <IconArrow4 className='fill-current transform rotate-180'/>
                        {t('global.buttons.back')}
                    </button>
                    <button
                        className={`py-2.5 px-5 flex flex-row items-center gap-3 text-white border rounded-3xl border-theme-blue bg-theme-blue hover:border-theme-blue-light hover:bg-theme-blue-light duration-100 ${currentStep.index >= (steps.length - 1) ? 'hidden' : '' }`}
                        onClick={goNext}
                        type='button'
                    >
                        {t('global.buttons.next')}
                        <IconArrow4 className='fill-current'/>
                    </button>
                    <button
                        className={`py-2.5 px-5 flex flex-row items-center gap-3 text-white border rounded-3xl border-theme-blue bg-theme-blue hover:border-theme-blue-light hover:bg-theme-blue-light duration-100 ${currentStep.index < (steps.length - 1) ? 'hidden' : '' }`}
                        type='submit'
                        disabled={updateLoading && !updateError}
                    >
                        {t('global.buttons.save')}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default withTranslation()(ChangeUserInfoPage)
