import React, {useContext, useEffect, useMemo, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {FilterContext, GeneralContext} from 'contexts';
import {useLazyQuery} from '@apollo/client';
import {GET_PRODUCT_LIST} from '_graphql';
import {NoPage, Table} from 'components';

const OrderDeliveryPage = ({ show = false}) => {
    const { setLoading } = useContext(GeneralContext)
    const { parseFilterParams } = useContext(FilterContext);
    const [getProductList, { data, loading, error }] = useLazyQuery(GET_PRODUCT_LIST);

    const [pagination, setPagination] = useState({ page: 1 });

    const products = useMemo(() => data?.products ? data?.products : { rows: [], totalPages: 0 }, [data]);

    useEffect(() => {
        let variables = pagination
        if (parseFilterParams && Object.keys(parseFilterParams).length > 0) {
            variables = {
                ...variables,
                ...parseFilterParams
            }
        }

        getProductList({ variables });
    }, [pagination, parseFilterParams]);

    useEffect(() => {
        setLoading(loading && !error)
    }, [loading, error])

    return show ? (
        <div className="flex-grow flex flex-row gap-x-5 h-full pl-2">
            <Table
                tableName={'orderDeliveries'}
                products={products}
                setPagination={setPagination}
            />
        </div>
    ) : (
        <NoPage/>
    )
}


export default withTranslation()(OrderDeliveryPage);
