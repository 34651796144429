import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    ActivatePalletsPage, AllPalletPage, ArchiveOrdersPage, DeactivatePalletsPage, MyOrdersPage, PalletDetailsPage
} from './Pallets';
import { AllDeliveriesPage, MyDeliveriesPage, OrderDeliveryPage } from './Delivery';
import { AllProposalsPage, MyProposalsPage, OrderProposalsPage } from './Proposals';
import MessengerPage from './messenger-page';
import AccountPage from './account-page';
import MembersPage from './members';
import { navigation } from '_constants';
import AppLayouts from 'app/app-layout';
import { LoadingSpinner, LogoutModal, NewDeliveryModal, TopMenu } from 'components';
import { FilterContext, GeneralContext, MessagesContext } from 'contexts';
import { useFilter, useGeneralHook, useMessages } from '_hooks';
import { withTranslation } from 'react-i18next';

const Pages = ({ settings }) => {
    const { generalContextValue, loaderSpinner } = useGeneralHook();
    const { filterContextValue } = useFilter();
    const messagesContextValue = useMessages();

    return (
        <GeneralContext.Provider value={{ ...generalContextValue, globalSettings: settings }}>
            <AppLayouts>
                <FilterContext.Provider value={filterContextValue}>
                    <MessagesContext.Provider value={messagesContextValue}>
                        <div
                            className='h-screen w-screen flex flex-col gap-y-3 pt-3 bg-white relative'>
                            <div
                                className='flex-none overflow-hidden rounded-xl bg-theme-gray-main shadow-main mx-3 h-18'>
                                <TopMenu/>
                            </div>
                            <div className='overflow-auto max-h-min flex-grow shadow-lg px-3 pb-3 pt-1'>
                                <Switch>
                                    {/*Profile*/}
                                    <Route path={navigation.pages.account} exact component={AccountPage}/>
                                    <Route path={`${navigation.pages.account}/:id`} component={AccountPage}/>
                                    <Route path={navigation.pages.members} component={MembersPage}/>

                                    {/*Pallets*/}
                                    <Route path={navigation.pages.pallets} exact component={AllPalletPage}/>
                                    <Route path={navigation.pages.activatePallets} exact
                                           component={ActivatePalletsPage}/>
                                    <Route path={navigation.pages.deactivatePallets} exact
                                           component={DeactivatePalletsPage}/>
                                    <Route path={navigation.pages.myOrders} exact component={MyOrdersPage}/>
                                    <Route path={navigation.pages.archiveOrders} exact component={ArchiveOrdersPage}/>
                                    <Route path={`${navigation.pages.pallets}/:id/:idConversation`}
                                           component={PalletDetailsPage}/>
                                    <Route path={`${navigation.pages.pallets}/:id`} component={PalletDetailsPage}/>

                                    {/*Delivery*/}
                                    <Route path={navigation.pages.delivery} exact component={AllDeliveriesPage}/>
                                    <Route path={navigation.pages.myDelivery} exact component={MyDeliveriesPage}/>
                                    <Route path={navigation.pages.orderDelivery} exact component={OrderDeliveryPage}/>

                                    {/*Proposals*/}
                                    <Route path={navigation.pages.proposals} component={AllProposalsPage}/>
                                    <Route path={navigation.pages.myProposals} exact component={MyProposalsPage}/>
                                    <Route path={navigation.pages.orderProposals} exact component={OrderProposalsPage}/>

                                    {/*Messenger*/}
                                    <Route path={navigation.pages.messenger} component={MessengerPage}/>

                                    {/*Redirect*/}
                                    <Redirect to={navigation.pages.pallets}/>
                                </Switch>
                            </div>
                        </div>

                        <NewDeliveryModal/>
                        <LogoutModal/>
                        <LoadingSpinner loading={loaderSpinner}/>
                    </MessagesContext.Provider>
                </FilterContext.Provider>
            </AppLayouts>
        </GeneralContext.Provider>
    );
};

export default withTranslation()(Pages);
