import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconArrow5, IconFilterTitle} from 'assets/images/svg';
import {AsyncFilter, FilterClientSelect} from 'components/filter';

export default ({
                    t,
                    hide,
                    change,
                    onChangeSort,
                    checkSortField,
                    filterParams,
                    onOpenFilterPanel,
                    setRefTitle,
                    activeFilterPanel,
                    clearFilterParams,
                    applyFilterParams,
                    navToProfile
                }) => ({
    name: columnName.customer,
    title: t('table.header.username'),
    titleFilter: true,
    excelValue: (item) => item?.customerId?.username || '-',
    thead: function () {
        return (
            <th key={this.name}
                className={'tableTitle py-3 px-1 align-top'}>
                <div className='relative flex flex-row items-center'>
                    <div className='activeBtn flex flex-col items-center cursor-pointer justify-center'
                         onClick={(e) => onChangeSort(e, this.name)}>
                        <p className='whitespace-nowrap font-medium text-xs text-black'>{this.title}</p>
                        <div className='flex flex-row justify-center items-center'>
                            <IconArrow5
                                className={`h-2.5 w-2.5 fill-current text-theme-blue ${checkSortField(this.name)}`}/>
                            {this.titleFilter && (
                                <button
                                    className={`activeBtn text-theme-blue-light hover:text-theme-blue ml-1 ${filterParams?.customerIds?.length ? 'active' : ''}`}
                                    onClick={(e) => onOpenFilterPanel(e, 'customerIds')}
                                >
                                    <IconFilterTitle className='stroke-current w-4 h-4'/>
                                </button>
                            )}
                        </div>
                    </div>
                    {this.titleFilter && ['customerIds', this.name].includes(activeFilterPanel) && (
                        <AsyncFilter name={this.name}
                                     filterName={'customerIds'}
                                     setRefTitle={setRefTitle}
                                     clearFilterParams={clearFilterParams}
                                     applyFilterParams={applyFilterParams}
                        >
                            <FilterClientSelect
                                filterType={'customerIds'} isActiveUsers={change.isActive}
                                filterVariables={change.filterVariables}
                            />
                        </AsyncFilter>
                    )}
                </div>
            </th>
        );
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'whitespace-nowrap px-1'}>
                <button
                    className={`truncate max-w-24 text-theme-blue ${hide.handleClickUsername ? '' : ' hover:text-blue-400 cursor-pointer'} duration-100`}
                    onClick={(e) => navToProfile(e, item?.customerId?._id)}
                >
                    {item?.customerId?.username || '-'}
                </button>
            </td>
        );
    },
    tabletTitle: function ({ _id }) {
        return (
            <p key={`title_${this.name}${_id}`}
               className='font-medium'>{this.title}</p>
        );
    },
    tabletContent: function ({ _id, customerId }) {
        return (
            <button
                key={`content_${this.name}${_id}`}
                className={`w-3/5 truncate text-left ${hide.handleClickUsername ? '' : ' hover:text-blue-400 cursor-pointer'} duration-100`}
                onClick={(e) => navToProfile(e, customerId?._id)}
            >
                {customerId?.username || '-'}
            </button>
        );
    }
})
