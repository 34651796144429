import {gql} from '@apollo/client';
import {TYPE_PRODUCT, TYPE_USERID} from '_graphql/types';

export const CREATE_PRODUCT_CUSTOMER = gql`
    mutation createProductCustomer(
        $data: ProductCustomerInput
    ){
        createProductCustomer(
            data: $data
        ){
            _id
            ownerId {
                ${TYPE_USERID}
            }
            productId {
                ${TYPE_PRODUCT}
            }
        }
    }
`;
