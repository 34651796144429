import { gql } from '@apollo/client';

export const REMOVE_USER_WAREHOUSES = gql`
    mutation removeWarehouse(
        $_id: [ID!]!
    ){
        removeWarehouse(
            _id: $_id
        ){
            _id
        }
    }
`;

