import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconArrow5, IconClose, IconFilterTitle} from 'assets/images/svg';
import {formatDateExcelUtils, formatDateRowsUtils, formatDateUtils} from 'utils';
import {FilterDatePickerCalendar, FilterDatePickerForm} from 'components/filter';

export default ({
                    t,
                    hide,
                    onChangeSort,
                    checkSortField,
                    filterParams,
                    onOpenFilterPanel,
                    setRefTitle,
                    activeFilterPanel,
                    clearFilterParams,
                    applyFilterParams
                }) => ({
    name: columnName.lastLoginAt,
    title: t('table.header.updatedAt'),
    titleFilter: true,
    excelValue: (item) => formatDateExcelUtils(item.updatedAt, hide.createAtDate) || '',
    thead: function () {
        return (
            <th key={this.name}
                className={'relative tableTitle flex justify-center items-center py-3 px-1'}>
                <div
                    className={'activeBtn flex flex-col items-center cursor-pointer justify-center'}
                    onClick={(e) => onChangeSort(e, this.name)}
                >
                    <p className='whitespace-nowrap font-medium text-xs text-black'>{this.title}</p>
                    <div className='flex flex-row justify-center items-center'>
                        <IconArrow5
                            className={`h-2.5 w-2.5 ml-2 fill-current text-theme-blue ${checkSortField(this.name)}`}/>
                        {this.titleFilter && (
                            <button
                                className={`activeBtn text-theme-blue-light hover:text-theme-blue ml-1 ${filterParams?.lastLoginAt?.from && filterParams?.lastLoginAt?.to ? 'active' : ''}`}
                                onClick={(e) => onOpenFilterPanel(e, this.name)}
                            >
                                <IconFilterTitle className='stroke-current w-4 h-4'/>
                            </button>
                        )}
                    </div>
                </div>
                {this.titleFilter && (
                    <div
                        ref={(element) => setRefTitle(element, this.name)}
                        className={`absolute z-10 text-theme-blue top-full left-1/2 text-xs font-normal ${activeFilterPanel === this.name ? 'block' : 'hidden'} bg-theme-gray-main shadow-main rounded-2xl pt-4 gap-x-4 flex flex-col overflow-hidden`}
                    >
                        <div className='w-full flex flex-row'>
                            <div className='w-60 text-left pl-4'>
                                <p className='mb-2'>{t('filterPanel.createdAt')}</p>
                                <FilterDatePickerForm field={this.name}/>
                            </div>
                            <div className='w-60'>
                                <FilterDatePickerCalendar field={this.name}/>
                            </div>
                        </div>
                        <div className='w-full flex flex-row justify-around gap-x-4 mt-auto pb-4 px-6 pt-2'>
                            <button
                                className='flex flex-row items-center justify-center border border-theme-blue rounded-2xl text-theme-blue bg-transparent hover:bg-theme-blue-light hover:border-theme-blue-light hover:text-white h-8 w-full duration-100'
                                onClick={() => clearFilterParams(this.name)}
                            >
                                <IconClose className={'fill-current'}/>
                                <p>{t('filterPanel.clear')}</p>
                            </button>
                            <button
                                className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light h-8 w-full duration-100'
                                onClick={applyFilterParams}>{t('filterPanel.apply')}</button>
                        </div>
                    </div>
                )}
            </th>
        );
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'whitespace-nowrap px-1'}>
                {formatDateUtils(item.lastLoginAt, hide.createAtDate) || ''}
            </td>
        );
    },
    tabletTitle: function ({ _id }) {
        return (
            <p key={`title_${this.name}${_id}`}
               className='whitespace-nowrap font-medium'>{this.title}</p>
        );
    },
    tabletContent: function ({ _id, lastLoginAt }) {
        return (
            <p key={`content_${this.name}${_id}`} className='truncate'>{formatDateRowsUtils(lastLoginAt)}</p>
        );
    }
})
