import {gql} from '@apollo/client';
import {TYPE_CONVERSATION} from '_graphql/types';

export const GET_CONVERSATIONS = gql`
    query conversations(
        $senderId: ID!,
        $page: Int!,
        $pageSize: Int
    ){
        conversations(
            senderId: $senderId,
            page: $page
            pageSize: $pageSize
        ){
            rows {
                ${TYPE_CONVERSATION}
            }
            total
            page
            pageSize
            totalPages
        }
    }
`;
