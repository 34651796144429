export const errorMessageUtils = (message, t) => {
    if (message.includes('duplicate')) {
        if (message.includes('username')) {
            return t('signup.errorMessageUsername');
        } else if (message.includes('email')) {
            return t('signup.errorMessageEmail');
        }
    } else {
        return message;
    }
}
