import {gql} from '@apollo/client';

export const DELETE_ASSET = gql`
    mutation deleteAsset(
        $_id: ID!
    ){
        deleteAsset(
            _id: $_id
        ){
            _id
        }
    }
`;
