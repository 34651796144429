import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    CREATE_CONVERSATION_SUBSCRIPTION, GET_CONVERSATIONS, UPDATE_ALL_SEEN_MESSAGES, UPDATE_CONVERSATION_SUBSCRIPTION
} from '_graphql';
import { useAuthToken, useGraphqlCache } from '_hooks';
import _ from 'lodash';
import { messenger, navigation } from '_constants';
import { useLocation } from 'react-router-dom';
import { useSegment } from 'react-segment-hooks';

const useMessenger = () => {
    const analytics = useSegment();
    const { userId } = useAuthToken();
    const { pathname } = useLocation();
    const { updateConversationListInCache } = useGraphqlCache();

    const [variables] = useState({ senderId: userId, page: 1, pageSize: messenger.conversations.pageSize });

    const {
        data: conversationsData,
        loading: conversationLoading,
        error: conversationError
    } = useQuery(GET_CONVERSATIONS, { variables, errorPolicy: 'all', skip: !userId });
    const { data: updateConversationData } = useSubscription(UPDATE_CONVERSATION_SUBSCRIPTION, {
        variables: { userId },
        shouldResubscribe: !!userId
    });
    const { data: createConversationData } = useSubscription(CREATE_CONVERSATION_SUBSCRIPTION, {
        variables: { userId },
        shouldResubscribe: !!userId
    });
    const [updateAllSeenMessages, { loading: updateAllSeenMessagesLoading }] = useMutation(UPDATE_ALL_SEEN_MESSAGES);

    const [selectedConversation, setSelectedConversation] = useState(null);

    const conversations = useMemo(() => conversationsData?.conversations?.rows || [], [conversationsData]);
    const sortConversations = useMemo(() => {
        const filteredConversations = conversations?.map((item) => ({
            ...item,
            updatedAt: item?.lastMessageDate || item?.createdAt,
            isUnread: !!item?.unreadMessagesCount
        }));

        const chats = [
            ...filteredConversations.filter((item) => item.type === messenger.types.public),
            ..._.chain(filteredConversations).filter((item) => item.type !== messenger.types.public && !item?.chatClosesUsers?.length).sortBy(['isUnread', 'updatedAt']).reverse().value(),
            ..._.chain(filteredConversations).filter((item) => item.type !== messenger.types.public && !!item?.chatClosesUsers?.length).sortBy(['updatedAt']).reverse().value()
        ];

        if (chats.length > 0 && !selectedConversation) {
            setSelectedConversation(chats[0])
        }

        return chats
    }, [conversations]);

    useEffect(() => {
        if (pathname === navigation.pages.messenger && sortConversations?.length > 0 && !selectedConversation?._id) {
            setPublicConversationToSelected();
        } else {
            setSelectedConversation(null);
        }
    }, [pathname]);

    useEffect(() => {
        if (selectedConversation?.unreadMessagesCount > 0) {
            seeConversationAllMessage(selectedConversation);
        }
    }, [selectedConversation]);

    useEffect(() => {
        const conversation = updateConversationData?.conversationUpdated;
        if (conversation) {
            let options = { change: true };

            if (conversation.chatClosesUsers?.length && !!conversation.chatClosesUsers?.find((item) => item === userId)) {
                options = { remove: true };
            }

            updateConversationListInCache({ conversation, options });
        }
    }, [updateConversationData]);

    useEffect(() => {
        const conversation = createConversationData?.conversationCreated;
        if (conversation) {
            updateConversationListInCache({ conversation, options: { add: true } });
        }
    }, [createConversationData]);

    const changeActiveConversation = (conversation) => {
        if (conversation === null) {
            setSelectedConversation(null);
            return;
        }

        const { _id } = conversation;

        if (_id !== selectedConversation?._id) {
            setSelectedConversation(conversation);
        }
        analytics.track({ event: 'Select conversation', properties: { conversation } });
    };

    const seeConversationAllMessage = (conv) => {
        if (!conv?._id) {
            return;
        }

        updateAllSeenMessages({
            variables: { conversationId: conv?._id },
            update: (cache, { data }) => {
                const result = data?.updateAllSeenMessages;
                if (result && result?.conversationId) {
                    updateConversationListInCache({
                        conversation: { _id: result?.conversationId, unreadMessagesCount: 0 },
                        options: { change: true }
                    });
                }
            }
        });
    };

    const setPublicConversationToSelected = () => {
        setSelectedConversation(sortConversations[0]);
    };

    const getConversationById = (chatId) => conversations.find(({ _id }) => _id === chatId);

    return {
        conversations,
        getConversationById,
        selectedConversation,
        changeActiveConversation,
        sortConversations,
        variables,
        seeConversationAllMessage,
        setPublicConversationToSelected,

        unReadMessagesCount: sortConversations?.reduce((acc, value) => acc + (value?.unreadMessagesCount || 0), 0) || 0,
        chatsCount: sortConversations?.length || 0,
        loading: conversationLoading && !conversationError,
        updateAllSeenMessagesLoading
    };
};

export default useMessenger;
