import React from 'react';
import {Router, useHistory} from 'react-router-dom';
import {ApolloProvider} from '@apollo/client';
import {ModalProvider} from 'react-modal-hook';
import {useAppApolloClient} from '_graphql';
import Routers from './routers';

const App = () => {
    const history = useHistory();
    const client = useAppApolloClient();

    return (
        <ApolloProvider client={client}>
            <ModalProvider>
                <Router history={history}>
                    <Routers/>
                </Router>
            </ModalProvider>
        </ApolloProvider>
    );
}

export default App;
