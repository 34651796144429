import {gql} from '@apollo/client';

export const UPDATE_ALL_SEEN_MESSAGES = gql`
    mutation updateAllSeenMessages(
        $conversationId: ID!
    ){
        updateAllSeenMessages(
            conversationId: $conversationId
        ) {
            messagesIds
            conversationId
        }
    }
`;
