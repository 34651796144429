import React, {useEffect, useState} from 'react';
import i18n from 'translation';
import {localesCode, storageNames} from '_constants';
import {IconFlagRu, IconFlagUa, IconFlagUs} from 'assets/images/svg';

const useLocale = () => {
    const defaultLang = JSON.parse(localStorage.getItem(storageNames.saveLocale))

    const localeItems = [
        { value: localesCode.ua, icon: <IconFlagUa className='w-5 h-5' /> },
        { value: localesCode.ru, icon: <IconFlagRu className='w-5 h-5'/> },
        { value: localesCode.en, icon: <IconFlagUs className='w-5 h-5' /> }
    ]

    const [locale, changeLocale] = useState(defaultLang || localesCode.ua);

    useEffect(() => {
        i18n.changeLanguage(locale);
        localStorage.setItem(storageNames.saveLocale, JSON.stringify(locale))
    }, [locale])

    return {
        locale,
        changeLocale,
        localeItems
    }
}

export default useLocale;
