import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconPlus} from 'assets/images/svg';

export default ({ t, createOrder, userId }) => ({
    name: columnName.order,
    title: t('table.header.order'),
    excelValue: null,
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1 align-top'}>
                <p className='whitespace-nowrap font-medium text-xs text-black'>{this.title}</p>
            </th>
        );
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'w-1 whitespace-nowrap px-1 text-center'}>
                {userId !== item?.userId?._id && (
                    <button
                        className={'flex justify-center items-center cursor-pointer mx-auto h-10 w-10  duration-100 text-theme-blue bg-theme-blue-main rounded-full hover:bg-gray-200'}
                        onClick={(e) => createOrder(e, item)}
                    >
                        <IconPlus className='fill-current w-4 h-4'/>
                    </button>
                )}
            </td>
        );
    },
    tabletTitle: () => null,
    tabletContent: function (item) {
        return (
            <button
                key={`content_${this.name}${item._id}`}
                className={'flex justify-center cursor-pointer duration-100 text-theme-blue hover:text-theme-blue-light'}
                onClick={(e) => createOrder(e, item)}
            >
                <IconPlus className='fill-current w-4 h-4'/>
            </button>
        );
    }
})
