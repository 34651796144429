import { Trans, withTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import Tablet from './tablet';
import Rows from './rows';
import {
    BuySellProductModal, CreatePrivateChatModal, OrderFinishModal, ProductDetailsModal, ToolTip
} from 'components';
import { IconArrow, IconCells, IconExcel, IconInfoFilled, IconRows } from 'assets/images/svg';
import { useTableColumnsHook } from '_hooks';
import ExcelFileDownload from './excel-file';
import { GeneralContext } from 'contexts';
import TableSettings from './settings';
import { storageNames } from '_constants';
import { hasOwnPropertyUtils } from 'utils';
import { useSegment } from 'react-segment-hooks';
import {columnName} from './configuration';

const ToolTipTitle = ({ t }) => (
    <Trans>
        <p className='font-bold text-sm'>{t('components.infoToolTip.title.icons')}</p>
        {t('components.infoToolTip.content.icons')}
        <br/>
        <p className='font-bold text-sm'>{t('components.infoToolTip.title.warning')}</p>
        <br/>
        <p className='font-bold text-sm'>{t('components.infoToolTip.title.nuances')}</p>
        {t('components.infoToolTip.content.nuances')}
    </Trans>
);

const Table = ({
    t,
    products,
    setPagination,
    hide,
    show,
    change,
    loader,
    tableName
}) => {
    const { setLoading } = useContext(GeneralContext);
    const analytics = useSegment();

    const {
        activeTableColumns,
        titleFilterPanels,
        closeActiveFilterPanel,
        setRef,
        tableType,
        tabletTypeToggle,
        handleClickRow,
        modals
    } = useTableColumnsHook({ hide, change, show, setPagination, tableName });

    const initialStateTableSettings = {
        hideColumns: show.columns.filter((item) => hasOwnPropertyUtils(item, 'defaultShow') && !item.defaultShow).map(({ name }) => name) || null
    };

    const [tableSettings, setTableSettings] = useState(JSON.parse(localStorage.getItem(storageNames.tableSettings))?.[tableName] || initialStateTableSettings);

    const paginationContainerClassName = ' flex justify-center items-center gap-1 list-none text-theme-blue text-xs font-normal absolute left-1/2 transform -translate-x-1/2';
    const paginationButtonsClassName = ' h-5 w-5 rounded-full flex justify-center items-center hover:bg-gray-200 duration-100';

    const {
        pagination: hidePagination,
        changeType: hideChangeType,
        settings: hideSettings
    } = hide;

    const {
        downloadExcelFile: showDownloadExcelFile,
        showAdminsBtn
    } = show;

    useEffect(() => {
        const settings = JSON.parse(localStorage.getItem(storageNames.tableSettings));

        Object.keys(initialStateTableSettings).forEach((item) => {
            if (settings && !hasOwnPropertyUtils(settings, item)) {
                settings[item] = initialStateTableSettings[item];
            }
        });

        localStorage.setItem(storageNames.tableSettings, JSON.stringify({ ...settings, [tableName]: tableSettings }));
    }, [tableSettings]);

    useEffect(() => setLoading(loader), [loader]);

    const handlePageClick = (event) => {
        const page = event.selected + 1;
        analytics.track({
            event: 'Clicked on "pagination" in Table', properties: {
                pageNumber: page,
                tableName
            }
        });
        setPagination((prev) => ({ ...prev, page }));
    };

    const changeSettings = (field, data) => {
        setTableSettings((prev) => ({ ...prev, [field]: data }));
    };

    const showAdminsToggle = () => {
        analytics.track({
            event: 'Clicked on button in Table',
            properties: { buttonName: 'Show Admins', tableName }
        });
        setPagination((prev) => {
            if (prev.role) {
                delete prev.role;
            } else {
                prev.role = 'admin';
            }

            return { ...prev };
        });
    };

    return (
        <>
            <div className='relative h-full flex-auto flex flex-col scrollable overflow-hidden rounded-xl bg-theme-gray-main shadow-main'>
                <div ref={(element) => setRef(element, 'table')}
                     className={'palletsTable h-full scrollable overflow-y-hidden w-full'}>
                    {tableType && !hideChangeType ? (
                        <Tablet
                            hide={hide}
                            show={show}
                            change={change}
                            products={products}
                            handleClickRow={handleClickRow}
                            activeTableColumns={activeTableColumns.tablet.filter(({
                                tabletTitle,
                                tabletContent
                            }) => tabletTitle && tabletContent)}
                            tableSettings={tableSettings}
                        />
                    ) : (
                        <Rows
                            hide={hide}
                            show={show}
                            change={change}
                            setRef={setRef}
                            products={products}
                            handleClickRow={handleClickRow}
                            titleFilterPanels={titleFilterPanels}
                            activeTableColumns={activeTableColumns.rows}
                            closeActiveFilterPanel={closeActiveFilterPanel}
                            tableSettings={tableSettings}
                        />
                    )}
                </div>
                <div className='flex flex-row relative justify-between px-2 items-center min-h-7'>
                    <div>
                        {showAdminsBtn ? (
                            <button className='text-xs text-theme-red hover:text-opacity-60 duration-100'
                                    onClick={showAdminsToggle}>{t('table.showAdmins')}</button>
                        ) : (
                            <div data-tip data-for={'table-info'} className={'text-theme-red-main'}>
                                <IconInfoFilled className={'w-5 h-5 fill-current'}/>
                                <ToolTip id={'table-info'}
                                         place={'right'}
                                         overridePosition={({ left, top },
                                             currentEvent, currentTarget, node) => {
                                             const d = document.documentElement;
                                             left = Math.min(d.clientWidth - node.clientWidth, left) - 10;
                                             top = Math.min(d.clientHeight - node.clientHeight, top) - 40;
                                             return { top, left };
                                         }}
                                         title={<ToolTipTitle t={t}/>}
                                />
                            </div>
                        )}
                    </div>
                    {!hidePagination && products.rows?.length > 0 ? (
                        <ReactPaginate
                            previousLabel={<IconArrow className='fill-current text-theme-blue transform -rotate-90'/>}
                            nextLabel={<IconArrow className='fill-current text-theme-blue transform rotate-90'/>}
                            pageCount={products.totalPages || 0}
                            forcePage={products.page - 1}
                            onPageChange={handlePageClick}
                            containerClassName={paginationContainerClassName}
                            pageLinkClassName={paginationButtonsClassName}
                            previousLinkClassName={paginationButtonsClassName}
                            nextLinkClassName={paginationButtonsClassName}
                            activeLinkClassName={'bg-theme-blue text-white hover:bg-blue-400 text-xs font-normal'}
                        />
                    ) : (<></>)}
                    <div className='relative flex flex-row gap-x-2 items-center'>
                        {!hideSettings && (
                            <TableSettings
                                tableName={tableName}
                                show={show}
                                changeSettings={changeSettings}
                                tableSettings={tableSettings}
                                activeTableColumns={activeTableColumns.rows.map(({ name, title }) => ({ name, title }))}
                                initialState={initialStateTableSettings}
                            />
                        )}
                        {showDownloadExcelFile && (
                            <div className='excelFileBtn p-0 m-0'>
                                <ExcelFileDownload
                                    button={(
                                        <button onClick={() =>
                                            analytics.track({
                                                event: 'Clicked on button in Table',
                                                properties: { buttonName: 'Download Excel', tableName }
                                            })}>
                                            <IconExcel
                                                className={'w-4 h-4 fill-current text-theme-blue hover:text-theme-blue-light duration-100'}/>
                                        </button>
                                    )}
                                    data={products}
                                    activeTableColumns={activeTableColumns.rows.filter(({ excelValue }) => !!excelValue)}
                                    tableSettings={tableSettings}
                                />
                            </div>
                        )}
                        {!hideChangeType && (
                            <button
                                className='inline-block'
                                onClick={tabletTypeToggle}
                            >
                                {tableType ? (
                                    <IconRows
                                        className={'fill-current text-theme-blue hover:text-theme-blue-light cursor-pointer duration-100 transform rotate-90'}/>
                                ) : (
                                    <IconCells
                                        className='fill-current text-theme-blue hover:text-theme-blue-light cursor-pointer duration-100'/>
                                )}
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <BuySellProductModal modals={modals}/>
            <ProductDetailsModal modals={modals}/>
            <OrderFinishModal modals={modals} change={change}/>
            <CreatePrivateChatModal modals={modals}/>
        </>
    );
};

Table.propTypes = {
    setPagination: PropTypes.func.isRequired,
    tableName: PropTypes.string.isRequired
};

Table.defaultProps = {
    products: [],
    loader: false,
    hide: {
        pagination: false, // true
        handleClickRow: false, // true
        handleClickUsername: false, // true
        titleFilters: false, // true
        createAtDate: false, // true
        changeType: false, // true
        settings: false // true
    },
    show: {
        columns: [], // [{} || ''] => { name: columnName.username || undefined, title: t('change.username') || undefined, defaultShow: false || true || undefined, settingDisabled: false || true || undefined }
        titleFilters: false, // ['title', ....]
        downloadExcelFile: false, // true
        activeRows: false, // [{ value: (item) => true, className: 'bg-white', hideClick: true || undefined }]
        showAdminsBtn: false // true
    },
    change: {
        handleOrderAssetsClick: false,
        handleClickRow: false, // (item) => { }
        noContentMessage: false, // 'no data'
        onRemoveProduct: false, // (_id) => { }
        onToggleProductActivate: false // (item) => { }
    }
};

export { columnName }
export default withTranslation()(Table);
