import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import BaseModal from '../index';
import { useAuthToken, useGraphqlCache } from '_hooks';
import NewTaraModalLeftBlock from './left-block';
import NewTaraModalRightBlock from './right-block';
import { GeneralContext } from 'contexts';
import { CREATE_ASSETS, CREATE_PRODUCT } from '_graphql';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { newProductSchema } from '_constants';

import 'react-phone-number-input/style.css';
import { useSegment } from 'react-segment-hooks';

const initialStateTaraInfo = {
    city: {},
    minCount: 1,
    maxCount: 1,
    price: 1,
    isTax: true,
    isBuy: true,
    isCertificate: true,
    paymentType: '',
    productType: '',
    productSize: '',
    productSort: '',
    productLoad: '',
    productPalletType: '',
    productBrands: [],
    description: '',
    customSize: null,
    customPalletType: null
};
// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const NewTaraModal = ({ pagination, editData, setDeletedImages, productUpdate }) => {
    const { register, handleSubmit, getValues, setValue, reset, formState: { errors }, watch } = useForm({
        resolver: yupResolver(newProductSchema()),
        defaultValues: initialStateTaraInfo
    });

    const { newTaraModal: isOpen, closeNewTaraModal, userInfo } = useContext(GeneralContext);

    const analytics = useSegment();
    const { updateProductListInCache } = useGraphqlCache();
    const { userId } = useAuthToken();
    const { setLoading } = useContext(GeneralContext);
    const [createProduct, { loading: productLoading, error: productError }] = useMutation(CREATE_PRODUCT);
    const [createAssets, { loading: assetsLoading, error: assetsError }] = useMutation(CREATE_ASSETS);

    const [files, setFiles] = useState([]);
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        setLoading((productLoading && !productError) && (assetsLoading && !assetsError));
    }, [productLoading, assetsLoading, productError, assetsError]);

    useEffect(() => {
        if (isOpen) {
            analytics.track({
                        event: 'Open modal',
                        properties: {
                            modalName: 'New Tara'
                        }
                    })

            if (editData) {
                const resetData = {
                    ...editData,
                    productPalletType: editData?.productPalletType || editData?.productPalletType?._id || 'other',
                    paymentType: editData?.paymentType?._id
                };
                reset(resetData);
                setFormValues(resetData);
            } else {
                reset(initialStateTaraInfo);
            }
        }
    }, [isOpen]);

    const toCreateProduct = (data) => {
        createProduct(
            {
                variables: { data },
                update: async (cache, { data }) => {
                    const createProductData = data?.createProduct;
                    if (createProductData) {
                        let newProductData = { ...createProductData };
                        if (files.length > 0) {
                            const assetsData = { model: 'product', itemId: createProductData._id };
                            await createAssets(
                                {
                                    variables: { data: assetsData, files },
                                    update: (assetCache, { data: assetsData }) => {
                                        if (assetsData?.createAssets) {
                                            newProductData = { ...newProductData, assets: assetsData?.createAssets };
                                        }
                                    }
                                });
                        }
                        updateProductListInCache({
                            product: newProductData,
                            options: { add: true },
                            variables: pagination
                        });
                    }
                }
            }).then(() => {
            closeNewTaraModal();
        });
    };

    const cleanProductDataToUpdate = (data) => {
        const newData = { ...data };

        delete newData?.assets;
        delete newData?.canVote;
        delete newData?.conversationId;
        delete newData?.createdAt;
        delete newData?.createdBy;
        delete newData?.isActivated;
        delete newData?.isDeleted;
        delete newData?.productPalletTypes;
        delete newData?.updatedAt;
        delete newData?.updatedBy;
        delete newData?.userId;
        delete newData?.__typename;
        delete newData?._id;

        if (newData?.city?.__typename) {
            delete newData?.city?.__typename;
        }

        return newData;
    };

    const submitData = (e) => {
        const data = {
            userId,
            ...e,
            minCount: Number(e.minCount),
            maxCount: Number(e.maxCount),
            count: editData ? Number(e.count) : Number(e.maxCount),
            price: Number(e.price) || 0
        };

        if (e.productSize === 'other') {
            data.productSize = null;
        } else {
            data.customSize = null;
        }
        if (e.productPalletType === 'other') {
            data.productPalletType = null;
        } else {
            data.customPalletType = null;
        }

        // if (new Date()) {
        //     console.log(data);
        // } else {
        if (!editData) {
            toCreateProduct(data);
        } else {
            productUpdate(cleanProductDataToUpdate(data), files);
        }
        // }
    };

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={closeNewTaraModal}
        >
            <form className='flex flex-row h-full w-full' onSubmit={handleSubmit(submitData)}>
                <NewTaraModalLeftBlock
                    hookForm={{ register, getValues, errors, setValue, watch }}
                    formValues={formValues}
                    editData={editData}
                    setFormValues={setFormValues}
                />
                <NewTaraModalRightBlock
                    onClose={closeNewTaraModal}
                    hookForm={{ register, getValues, errors, setValue }}
                    setFiles={setFiles}
                    isEdit={!!editData}
                    userWarehouses={userInfo?.warehouses || []}
                    formValues={formValues}
                    setDeletedImages={setDeletedImages}
                />
            </form>
        </BaseModal>
    );
};

NewTaraModal.defaultProps = {
    isOpen: false
};

export default withTranslation()(NewTaraModal);
