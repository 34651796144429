import {gql} from '@apollo/client';
import {TYPE_PAYMENT_TYPE} from '_graphql/types';

export const GET_PAYMENT_TYPE = gql`
    query paymentType(
        $_id: ID
        $value: String
    ){
        paymentType(
            _id: $_id
            value: $value
        ) {
            ${TYPE_PAYMENT_TYPE}
            createdAt
            createdBy
            modifiedAt
            modifiedBy
        }
    }
`;

