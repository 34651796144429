import {gql} from '@apollo/client';
import {TYPE_PRODUCT_CUSTOMER} from '_graphql/types';

export const CREATE_PRODUCT_CUSTOMER_SUBSCRIPTION = gql`
    subscription productCustomerCreated(
        $userId: ID
    ){
        productCustomerCreated(
            userId: $userId
        ){
            ${TYPE_PRODUCT_CUSTOMER}
        }
    }
`;
