import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconArrow5, IconClose, IconFilterTitle} from 'assets/images/svg';
import {CustomCheckbox} from 'components/common';

export default ({
                    t,
                    onChangeSort,
                    checkSortField,
                    filterParams,
                    onOpenFilterPanel,
                    setRefTitle,
                    activeFilterPanel,
                    clearFilterParams,
                    applyFilterParams,
                    onChangeCertificate
                }) => ({
    name: columnName.isCertificate,
    title: t('table.header.certificate'),
    titleFilter: true,
    excelValue: (item) => item?.isCertificate ? '+' : '-',
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1 align-top'}>
                <div className='relative flex flex-row items-center justify-center'>
                    <div className='activeBtn flex flex-col items-center cursor-pointer justify-center'
                         onClick={(e) => onChangeSort(e, this.name)}>
                        <p className='whitespace-nowrap font-medium text-xs text-black'>{this.title}</p>
                        <div className='flex flex-row justify-center items-center'>
                            <IconArrow5
                                className={`h-2.5 w-2.5 fill-current text-theme-blue ${checkSortField(this.name)}`}/>
                            {this.titleFilter && (
                                <button
                                    className={`activeBtn text-theme-blue-light hover:text-theme-blue ml-1 ${filterParams?.isCertificate?.with || filterParams?.isCertificate?.withOut ? 'active' : ''}`}
                                    onClick={(e) => onOpenFilterPanel(e, this.name)}
                                >
                                    <IconFilterTitle className='stroke-current w-4 h-4'/>
                                </button>
                            )}
                        </div>
                    </div>
                    {this.titleFilter && (
                        <div
                            ref={(element) => setRefTitle(element, this.name)}
                            className={`absolute z-10 ${activeFilterPanel === this.name ? 'block' : 'hidden'} mt-3 text-theme-blue top-full -left-full text-xs font-normal bg-theme-gray-main shadow-main rounded-2xl py-4 gap-x-4 flex flex-col overflow-hidden w-60 px-3 text-left`}
                        >

                            <p className='w-15 mb-2'>{t('table.header.certificate')}</p>
                            <div className='flex flex-col px-4 gap-y-4'>
                                <CustomCheckbox
                                    checked={filterParams.isCertificate?.with}
                                    onChange={onChangeCertificate}
                                    name='with'
                                    text={'+'}
                                />
                                <CustomCheckbox
                                    checked={filterParams.isCertificate?.withOut}
                                    onChange={onChangeCertificate}
                                    name='withOut'
                                    text={'-'}
                                />
                            </div>
                            <div
                                className='w-full flex flex-row justify-around gap-x-4 mt-auto pt-4 text-theme-blue'>
                                <button
                                    className='flex flex-row items-center justify-center border border-theme-blue rounded-2xl text-theme-blue bg-transparent hover:bg-theme-blue-light hover:border-theme-blue-light hover:text-white h-8 w-full duration-100'
                                    onClick={() => clearFilterParams(this.name)}
                                >
                                    <IconClose className={'fill-current'}/>
                                    <p>{t('filterPanel.clear')}</p>
                                </button>
                                <button
                                    className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light h-8 w-full duration-100'
                                    onClick={applyFilterParams}>{t('filterPanel.apply')}</button>
                            </div>
                        </div>
                    )}
                </div>
            </th>
        );
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id}
                className={'whitespace-nowrap px-1 text-black text-center'}>
                {item?.isCertificate ? '+' : '-'}
            </td>
        );
    },
    tabletTitle: function ({ _id }) {
        return (
            <p key={`title_${this.name}${_id}`}
               className='whitespace-nowrap font-medium'>{this.title}</p>
        );
    },
    tabletContent: function ({ isCertificate, _id }) {
        return (
            <p key={`content_${this.name}${_id}`}
               className={`truncate ${!isCertificate ? ' text-black text-opacity-50 ' : ''}`}>
                {isCertificate ? '+' : '-'}
            </p>
        );
    }
})
