import { gql } from '@apollo/client';

export const FORGOT_PASSWORD_MUTATION = gql`
    mutation forgotPassword(
        $email: String!
    ){
     forgotPassword(
            email: $email
        ){
            status       
        }
    }
`;
