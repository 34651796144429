import {gql} from '@apollo/client';
import {TYPE_PRODUCT_TYPE} from '_graphql/types';

export const GET_PRODUCT_TYPE = gql`
    query ProductType(
        $_id: ID
        $value: String
    ){
        ProductType(
            _id: $_id
            value: $value
        ) {
            ${TYPE_PRODUCT_TYPE}
            createdAt
            createdBy
            modifiedAt
            modifiedBy
        }
    }
`;

