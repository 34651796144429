import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconArrow5, IconClose, IconFilterTitle} from 'assets/images/svg';
import {CustomCheckbox} from 'components/common';

export default ({
                    t,
                    onChangeSort,
                    checkSortField,
                    filterParams,
                    onOpenFilterPanel,
                    setRefTitle,
                    activeFilterPanel,
                    clearFilterParams,
                    applyFilterParams,
                    productUniqOptions,
                    onChangePaymentType
                }) => ({
    name: columnName.paymentType,
    title: t('table.header.paymentType'),
    titleFilter: false,
    excelValue: ({ productId, paymentType }) => {
        const name = (productId ? productId?.paymentType?.name : paymentType?.name)?.toLowerCase() || '-';
        return t(`modals.newTaraModal.${name}`);
    },
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1 align-top'}>
                <div className='relative flex flex-row items-center justify-center'>
                    <div className='activeBtn flex flex-col items-center cursor-pointer justify-center'
                         onClick={(e) => onChangeSort(e, this.name)}>
                        <p className='whitespace-nowrap font-medium text-xs text-theme-blue'>{this.title}</p>
                        <div className='flex flex-row justify-center items-center'>
                            <IconArrow5
                                className={`h-2.5 w-2.5 fill-current text-theme-blue ${checkSortField(this.name)}`}/>
                            {this.titleFilter && (
                                <button
                                    className={`activeBtn text-theme-blue-light hover:text-theme-blue ml-1 ${filterParams?.paymentType ? 'active' : ''}`}
                                    onClick={(e) => onOpenFilterPanel(e, this.name)}
                                >
                                    <IconFilterTitle className='stroke-current w-4 h-4'/>
                                </button>
                            )}
                        </div>
                    </div>
                    {this.titleFilter && (
                        <div
                            ref={(element) => setRefTitle(element, this.name)}
                            className={`absolute z-10 ${activeFilterPanel === this.name ? 'block' : 'hidden'} mt-3 text-theme-blue top-full -left-full text-xs font-normal bg-theme-gray-main shadow-main rounded-2xl py-4 gap-x-4 flex flex-col overflow-hidden w-60 px-3 text-left`}
                        >

                            <p className='w-15 mb-2'>{t('filterPanel.paymentType')}</p>
                            <div className='flex flex-col px-4 gap-y-4'>
                                {productUniqOptions?.paymentTypes.map(({ _id, checked, name }) => (
                                    <CustomCheckbox
                                        key={_id}
                                        checked={checked}
                                        onChange={() => onChangePaymentType(_id)}
                                        name={'paymentType'}
                                        text={t(`filterPanel.${name.toLowerCase()}`)}
                                    />
                                ))}
                            </div>
                            <div
                                className='w-full flex flex-row justify-around gap-x-4 mt-auto pt-4 text-theme-blue'>
                                <button
                                    className='flex flex-row items-center justify-center border border-theme-blue rounded-2xl text-theme-blue bg-transparent hover:bg-theme-blue-light hover:border-theme-blue-light hover:text-white h-8 w-full duration-100'
                                    onClick={() => clearFilterParams(this.name)}
                                >
                                    <IconClose className={'fill-current'}/>
                                    <p>{t('filterPanel.clear')}</p>
                                </button>
                                <button
                                    className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light h-8 w-full duration-100'
                                    onClick={applyFilterParams}>{t('filterPanel.apply')}</button>
                            </div>
                        </div>
                    )}
                </div>
            </th>
        );
    },
    tbody: function ({ productId, paymentType, _id }) {
        const name = (productId ? productId?.paymentType?.name : paymentType?.name)?.toLowerCase() || '-';
        return (
            <td key={this.name + _id}
                className={'whitespace-nowrap px-1 text-theme-blue text-center'}>
                {t(`modals.newTaraModal.${name}`)}
            </td>
        );
    },
    tabletTitle: function ({ _id }) {
        return (
            <p key={`title_${this.name}${_id}`}
               className='whitespace-nowrap font-medium'>{this.title}</p>
        );
    },
    tabletContent: function ({ productId, paymentType, _id }) {
        const name = (productId ? productId?.paymentType?.name : paymentType?.name)?.toLowerCase() || '-';
        return (
            <p key={`content_${this.name}${_id}`}
               className={'truncate'}>
                {t(`modals.newTaraModal.${name}`)}
            </p>
        );
    }
})
