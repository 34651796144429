import {columnName} from 'components/table/configuration';
import React from 'react';

export default () => ({
    name: columnName.sequenceNumber,
    title: '#',
    excelValue: null,
    thead: function () {
        return (<th key={this.name} className={'tableTitle py-3 px-1 align-top text-xs'}>{this.title}</th>);
    },
    tbody: function (item, index) {
        return (
            <td key={this.name + item._id} className={'whitespace-nowrap px-1 text-center'}>
                <p>{index}</p>
            </td>
        );
    },
    tabletTitle: null,
    tabletContent: null
})
