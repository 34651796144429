import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { IconClose, IconTick1 } from 'assets/images/svg';
import { navigation } from '_constants';
import { useMutation } from '@apollo/client';
import { ACTIVE_MAIL_MUTATION } from '_graphql';
import { useSegment } from 'react-segment-hooks';

const ActivateEmailPage = () => {
    const { token } = useParams();
    const analytics = useSegment();

    const [activeMail, { data, loading, error }] = useMutation(ACTIVE_MAIL_MUTATION);

    useEffect(() => {
        activeMail({ variables: { resetToken: token } }).catch(() => { });
    }, [token]);

    useEffect(() => {
        analytics.page({ name: 'Activate mail Page' });
    }, []);

    return (
        <>
            {!loading && (
                <div
                    className='w-full max-w-newTaraRightBlock mx-4 bg-white rounded-3xl shadow-main py-18 flex flex-col items-center'>
                    {data?.activateAccount?.status === 'ACCOUNT_ACTIVATED' ? (
                        <>
                            <div
                                className='w-24 h-24 bg-theme-blue rounded-full flex items-center justify-center mb-15'>
                                <IconTick1/></div>
                            <p className='text-theme-blue text-xl mb-15'>Реєстрація підтверджена</p>
                            <Link className='bg-theme-blue rounded-3xl py-3 px-14 text-white'
                                  to={navigation.nonAuth.login}>login</Link>
                        </>
                    ) : (
                        <>
                            <div className='w-24 h-24 bg-red-400 rounded-full flex items-center justify-center mb-15'>
                                <IconClose className='fill-current text-white w-15 h-15'/></div>
                            <p className='text-red-400 text-xl'>{error?.message || ''}</p>
                        </>
                    )}

                </div>
            )}
        </>
    );
};

export default withTranslation()(ActivateEmailPage);
