import React, {useContext, useEffect, useMemo, useState} from 'react';
import {IconArrow1, IconFilter, IconNote, IconTara} from 'assets/images/svg';
import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {navigation} from '_constants';
import {FilterContext, GeneralContext, LocaleContext, SubMenuContext} from 'contexts';
import {useOutsideClick} from '_hooks';
import {getBasicLocationUtils} from 'utils';
import {useSegment} from 'react-segment-hooks';

const SubMenu = ({ t }) => {
    const analytics = useSegment();
    const { filterToggle } = useContext(FilterContext);
    const { locale, changeLocale, localeItems } = useContext(LocaleContext);
    const { activeSubMenu, closeSubMenuPanel, subMenuPanel, activeButtonsRefs, toggleSubMenuPanel } = useContext(SubMenuContext);
    const { toggleNewTaraModal } = useContext(GeneralContext);

    const [localeMenu, openLocaleMenu] = useState(false);

    const refSubMenu = useOutsideClick(() => closeSubMenuPanel(), Object.values(activeButtonsRefs));

    const navClassName = ' py-1 cursor-pointer w-full flex flex-col justify-center items-center text-center gap-y-3 border-l-2 border-transparent hover:bg-theme-blue-main hover:text-theme-blue hover:border-theme-blue duration-100';
    const navActiveClassName = ' text-default bg-theme-gray-light border-l-2 border-theme-gray-light';
    const navDisabledClassName = ' py-1 cursor-default w-full flex flex-col justify-center items-center text-center gap-y-3'

    const disabledLink = (e) => {
        e.preventDefault()
    }

    const clickedToSubMenu = (event) => {
        analytics.track({ event })
    }

    const newTaraModal = () => {
        clickedToSubMenu('Select “Add Suggestions” from sub menu')
        toggleNewTaraModal()
    }

    const openFilter = () => {
        clickedToSubMenu('Select “Filter” from sub menu')
        filterToggle()
    }

    const menu = [
        {
            menu: navigation.pages.account,
            list: [
                (
                    <NavLink key={'e51353rcq34r34r'} activeClassName={navActiveClassName} className={navClassName} to={navigation.pages.account} exact>
                        <IconTara className="stroke-current"/>
                        {t('subMenu.account')}
                    </NavLink>
                )
            ]
        },
        {
            menu: navigation.pages.pallets,
            list: [
                (
                    <NavLink key={'1235123461346fc35x'} exact to={navigation.pages.pallets} activeClassName={navActiveClassName} className={navClassName} onClick={() => clickedToSubMenu('Select "All Tara" from sub menu')}>
                        <IconTara className="stroke-current"/>
                        <p>{t('subMenu.pallets')}</p>
                    </NavLink>
                ),
                (
                    <NavLink key={'345vq3c5q34c5q45'} to={navigation.pages.activatePallets} activeClassName={navActiveClassName} className={navClassName} onClick={() => clickedToSubMenu('Select “My Suggestions” from sub menu')}>
                        <IconTara className="stroke-current"/>
                        <p>{t('subMenu.myProposals')}</p>
                    </NavLink>
                ),
                (
                    <NavLink key={'3453q4523452345234'} to={navigation.pages.myOrders} activeClassName={navActiveClassName} className={navClassName} onClick={() => clickedToSubMenu('Select “My Orders” from sub menu')}>
                        <IconTara className="stroke-current"/>
                        <p>{t('subMenu.myOrder')}</p>
                    </NavLink>
                ),
                (
                    <NavLink key={'vbw4e5ve4c5q45v'} to={navigation.pages.activatePallets} className={navClassName} onClick={newTaraModal}>
                        <IconNote className="stroke-current fill-current"/>
                        <p>{t('subMenu.addStatement')}</p>
                    </NavLink>
                ),
                (
                    <button key={'345vqcqwv45cqwx45'} className={navClassName + ' hidden'} onClick={openFilter} >
                        <IconFilter className="stroke-current "/>
                        <p>{t('subMenu.filter')}</p>
                    </button>
                )
            ]
        },
        {
            menu: navigation.pages.delivery,
            list: [
                (
                    <NavLink key={'qwe5bqv5cqw5'} exact to={navigation.pages.delivery} activeClassName={navActiveClassName} className={navClassName + navDisabledClassName} onClick={disabledLink}>
                        <IconTara className="stroke-current"/>
                        <p>{t('subMenu.delivery')}</p>
                    </NavLink>
                ),
                (
                    <button key={'aweb5avwc45a45vaw'} to={navigation.pages.myDelivery} className={navDisabledClassName} disabled={true}>
                        <IconTara className="stroke-current"/>
                        <p>{t('subMenu.myProposals2')}</p>
                    </button>
                ),
                (
                    <button key={'awe5baw4v5cw45rc'} to={navigation.pages.orderDelivery} className={navDisabledClassName} disabled={true}>
                        <IconTara className="stroke-current"/>
                        <p>{t('subMenu.myOrder')}</p>
                    </button>
                ),
                (
                    <button key={'we4bvqwc34xwqw'} to={navigation.pages.myDelivery} className={navDisabledClassName} disabled={true}>
                        <IconNote className="stroke-current fill-current"/>
                        <p>{t('subMenu.addNewDelivery')}</p>
                    </button>
                ),
                (
                    <button key={'aervw4cawe4vEACXR'} className={navDisabledClassName + ' hidden'} disabled={true}>
                        <IconFilter className="stroke-current "/>
                        <p>{t('subMenu.filter')}</p>
                    </button>
                )
            ]
        },
        {
            menu: navigation.pages.proposals,
            list: [
                (
                    <NavLink key={'AWEB5AVWCEAWEwvb5'} exact to={navigation.pages.proposals} activeClassName={navActiveClassName} className={navClassName + navDisabledClassName} onClick={disabledLink}>
                        <IconTara className="stroke-current"/>
                        <p>{t('subMenu.allProposals')}</p>
                    </NavLink>
                ),
                (
                    <button key={'aweb5aev5aec54vc5ca'} to={navigation.pages.myProposals} className={navDisabledClassName} disabled={true}>
                        <IconTara className="stroke-current"/>
                        <p>{t('subMenu.myProposals2')}</p>
                    </button>
                ),
                (
                    <button key={'awb45ve4c5aw5vaec'} to={navigation.pages.orderProposals} className={navDisabledClassName} disabled={true}>
                        <IconTara className="stroke-current"/>
                        <p>{t('subMenu.myOrder')}</p>
                    </button>
                ),
                (
                    <button key={'awb45avec5awev5ce4v5'} className={navDisabledClassName} disabled={true}>
                        <IconNote className="stroke-current fill-current"/>
                        <p>{t('subMenu.addNewProposal')}</p>
                    </button>
                ),
                (
                    <button key={'abw5vec5wbevsce5va5sdf'} className={navDisabledClassName + ' hidden'} disabled={true}>
                        <IconFilter className="stroke-current "/>
                        <p>{t('subMenu.filter')}</p>
                    </button>
                )
            ]
        }
    ];

    useEffect(() => {
        if (!subMenuPanel && localeMenu) {
            openLocaleMenu(false)
        }
    }, [subMenuPanel])

    const onOpenLocaleMenu = (e) => {
        e.stopPropagation()
        openLocaleMenu((prev) => !prev)
    }

    const onChangeLocale = async (e, name) => {
        e.stopPropagation()
        await openLocaleMenu(false)
        await changeLocale(name)
        await closeSubMenuPanel()
    }

    const taraSubMenu = useMemo(() => {
        const findActiveMenu = menu.find((item) => item.menu === getBasicLocationUtils(activeSubMenu)) || false

        if (findActiveMenu && findActiveMenu.menu === navigation.pages.account) {
            const selectedLocale = localeItems.find((item) => item.value === locale) || localeItems[0]
            const localeMenuList = localeMenu ? [selectedLocale, ...localeItems.filter((item) => item.value !== selectedLocale.value)] : [selectedLocale]

            const localeMenuListParse = localeMenuList.map((item) => (
                <button key={item.value} className={navClassName + (item.value === locale ? navActiveClassName : '')} onClick={item.value === locale ? onOpenLocaleMenu : (e) => onChangeLocale(e, item.value)}>
                    {item.icon}
                    {item.value.slice(3)}
                </button>
            ))

            return [
                ...findActiveMenu.list,
                ...localeMenuListParse
            ]
        }

        return findActiveMenu?.list
    }, [locale, localeMenu, activeSubMenu])

    return taraSubMenu?.length > 0 ? (
        <div ref={refSubMenu} className={`absolute z-5 ${subMenuPanel ? 'left-full ml-1' : 'left-4'} w-24 duration-100 h-full rounded-xl shadow-main bg-theme-gray-main flex flex-col justify-between text-menu font-normal pt-22`}>
            <div className='flex flex-col justify-start h-full gap-y-6' onClick={() => closeSubMenuPanel()}>
                {taraSubMenu}
            </div>

            <button
                onClick={() => toggleSubMenuPanel()}
                className={'absolute w-max flex items-center h-full right-0 top-0 duration-100'}
            >
                <IconArrow1 className={`transform translate-y-1/2 ${subMenuPanel ? 'rotate-90' : '-rotate-90'}`}/>
            </button>
        </div>
    ) : (<div />)
}

export default withTranslation()(SubMenu)
