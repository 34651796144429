import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GOOGLE_API_KEY, newWarehouseSchema } from '_constants';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useLocale } from '_hooks';

const initialState = {
    placeId: '',
    name: '',
    streetName: '',
    houseNumber: ''
};

const WarehouseFrom = ({ t, open, close, save }) => {
    const { locale } = useLocale();

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(newWarehouseSchema()),
        defaultValues: initialState
    });

    useEffect(() => {
        reset(initialState);
    }, [open]);

    const onPlaceSelected = (place) => {
        if (place.formatted_address && place.place_id) {
            setValue('name', place.formatted_address);
            setValue('placeId', place.place_id);
        }
    };

    const { ref: autoCompleteRef } = usePlacesWidget({
        apiKey: GOOGLE_API_KEY,
        onPlaceSelected: onPlaceSelected,
        language: locale,
        options: {
            types: ['(cities)']
        }
    });

    return (
        <form onSubmit={handleSubmit(save)} className='text-xs flex flex-col gap-y-2'>
            <input
                {...register('name')}
                type='text'
                name='name'
                ref={autoCompleteRef}
                placeholder={t('modals.newTaraModal.cityDelivery')}
                className={`flex-grow-0 w-full py-1 px-4 border ${errors?.name || errors?.placeId ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}
            />
            <input
                {...register('streetName')}
                type='text'
                name='streetName'
                placeholder={t('modals.newTaraModal.street')}
                className={`flex-grow-0 w-full py-1 px-4 border ${errors?.streetName ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}
            />
            <input
                {...register('houseNumber')}
                type='text'
                name='houseNumber'
                placeholder={t('modals.newTaraModal.house')}
                className={`flex-grow-0 w-full py-1 px-4 border ${errors?.houseNumber ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}
            />
            <div className='flex flex-row justify-end gap-x-2'>
                <button type='button' className='w-24 h-7 rounded-3xl bg-theme-blue text-white text-xs'
                        onClick={() => close()}>{t('profile.cancel')}</button>
                <button type='submit'
                        className='w-24 h-7 rounded-3xl bg-theme-blue text-white text-xs'>{t('profile.save')}</button>
            </div>
        </form>
    );
};

export default withTranslation()(WarehouseFrom);
