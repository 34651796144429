import {gql} from '@apollo/client';
import {TYPE_MESSAGE} from '_graphql/types';

export const CREATE_MESSAGE_SUBSCRIPTION = gql`
    subscription messageCreated(
        $userId: ID!
        $conversationId: ID!
    ) {
        messageCreated(
            userId: $userId
            conversationId: $conversationId
        ) {
            ${TYPE_MESSAGE}
        }
    }
`;
