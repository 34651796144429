import {TYPE_USERID} from '../user';
import {TYPE_ASSET} from '../asset'

export const TYPE_DELIVERY_ADDRESS = `
    _id
    placeId
    placeName
    latitude
    longitude
    houseNumber
    streetName
`

export const TYPE_DELIVERY_ROUTE = `
    _id
    price
    freeSpace
    space
    startAddress {
        ${TYPE_DELIVERY_ADDRESS}
    }
    endAddress {
        ${TYPE_DELIVERY_ADDRESS}
    }
    startDate
    endDate
`

export const TYPE_DELIVERY = `
    _id
    userId {
        ${TYPE_USERID}
    }
    name
    email
    phone
    description
    assets {
        ${TYPE_ASSET}
    }
    canVote
    isDriver
    deliveryRoutes {
        ${TYPE_DELIVERY_ROUTE}
    }
        
`
