import React, { useState } from 'react';
import moment from 'moment';
import { IconSeen1, IconSeen2, IconSettings } from 'assets/images/svg';
import { ToolTip } from 'components';
import { withTranslation } from 'react-i18next';
import ChatMessageFiles from '../message-files';
import { useOutsideClick } from '_hooks';
import {messenger, navigation} from '_constants';
import { useSegment } from 'react-segment-hooks';
import {useHistory} from 'react-router-dom';

const ChatMessage = ({
    t,
    item,
    isAuthor,
    show,
    setFirstBlockRef,
    setEditMessage,
    editMessage,
    setRemoveMessageModal,
    conversationId
}) => {
    const analytics = useSegment();
    const history = useHistory();

    const [messageSettings, setMessageSettings] = useState(false);
    const { userId, createdAt, message, seen, _id, isDeleted, updatedAt } = item;

    const settingsPanelRef = useOutsideClick(() => setMessageSettings(false));

    const {
        adaptiveWidthMessage: showAdaptiveWidthMessage
    } = show;

    const settingsPanelToggle = () => {
        analytics.track({ event: 'Clicked on "message menu" button' });
        setMessageSettings((prev) => !prev);
    };

    const clickEditMessage = () => {
        if (moment().diff(createdAt, 'minutes') < messenger.history.timeForEditMessage) {
            analytics.track({
                event: 'Clicked on "edit message" button',
                properties: { messageId: item?._id, conversationId }
            });
            setEditMessage(item);
            setMessageSettings(false);
        }
    };

    const clickRemoveMessage = () => {
        analytics.track({ event: 'Clicked on "delete message" button', properties: { messageId: item?._id } });
        setRemoveMessageModal((prev) => ({ ...prev, display: true, message: item, conversationId }));
    };


    const navToProfile = (id) => {
        if (!id) {
            return
        }

        history.push(`${navigation.pages.account}/${id}`);
    };

    return (
        <div className={`w-full ${editMessage?._id === _id ? 'bg-theme-blue-light-2' : ''} py-0.5 px-3`}>
            <div
                ref={setFirstBlockRef}
                className={`flex flex-col gap-y-1.5 min-w-30 w-max max-w-4/5 ${showAdaptiveWidthMessage ? ' xl:max-w-1/2' : ''} ${isAuthor ? 'ml-auto xl:pl-7' : ' ml-0.5 xl:pr-7'}`}
            >
                <div className='relative text-xs text-theme-gray flex flex-row justify-between items-center'>
                    {isAuthor ? (
                        <p className='flex-grow truncate'>{t('messenger.you')}</p>
                    ) : (
                        <button className='truncate hover:text-theme-blue' onClick={() => navToProfile(userId?._id)} >{userId?.username}</button>
                    )}
                    <p data-tip data-for={_id}
                       className='flex-grow-0 ml-3 whitespace-nowrap'>{moment(createdAt).format('LT')}</p>
                    {isAuthor && (
                        <div ref={settingsPanelRef} className='flex items-center'>
                            <button className='text-theme-blue ml-2' onClick={settingsPanelToggle}>
                                <IconSettings className={'w-3 h-3 fill-current'}/>
                            </button>
                            <ul className={`${messageSettings ? '' : 'hidden'} z-100 absolute top-0 right-4 w-30 bg-theme-blue-light text-white rounded-xl overflow-hidden shadow-main`}>
                                <li
                                    data-tip data-for={`edit_${_id}`}
                                    className={`w-full px-4 py-1.5 ${moment().diff(createdAt, 'minutes') < messenger.history.timeForEditMessage ? ' cursor-pointer hover:bg-theme-blue-light-1 duration-100 ' : ' cursor-not-allowed bg-theme-blue-light-1'}`}
                                    onClick={clickEditMessage}
                                >
                                    {t('messenger.edit')}
                                </li>
                                <li
                                    className={`w-full px-4 py-1.5 ${!isDeleted ? ' cursor-pointer hover:bg-theme-blue-light-1 duration-100 ' : ' cursor-not-allowed bg-theme-blue-light-1'}`}
                                    onClick={clickRemoveMessage}
                                >
                                    {t('messenger.remove')}
                                </li>
                            </ul>
                            <ToolTip id={`edit_${_id}`} position={'top'} title={t('messenger.editMsgTooltip')}/>
                        </div>
                    )}
                </div>
                <ToolTip id={_id} title={
                    <div>{moment(createdAt).calendar()}<br/>{updatedAt ? (`Edit: ${moment(updatedAt).calendar()}`) : ''}
                    </div>}/>
                {(message || isDeleted) && (
                    <div
                        className={`rounded-3xl ${isAuthor ? 'bg-theme-blue text-white' : 'bg-theme-blue-main text-black'} text-base font-normal pl-5 pr-8 py-2 relative`}>
                        {isDeleted ? (
                            <p className={`whitespace-normal break-words ${isAuthor ? 'text-gray-300' : 'text-gray-500'}`}>{t('messenger.deleted')}</p>
                        ) : (
                            <p className='whitespace-normal break-words'>{message}</p>
                        )}
                        <div className='absolute right-3 bottom-4'>
                            {seen ? (
                                <IconSeen2 className={`stroke-current ${isAuthor ? '' : 'text-theme-blue'}`}/>
                            ) : (
                                <IconSeen1 className={`stroke-current ${isAuthor ? '' : 'text-theme-blue'}`}/>
                            )}
                        </div>
                    </div>
                )}
                <ChatMessageFiles assets={item?.assets} isAuthor={isAuthor}/>
            </div>
        </div>
    );
};

export default withTranslation()(ChatMessage);
