import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { localesCode } from '../_constants';

import TRANSLATIONS_UA from './translations-ua.json';
import TRANSLATIONS_RU from './translations-ru.json';
import TRANSLATIONS_EN from './translations-en.json';

const resources = {
    [localesCode.ua]: {
        translation: TRANSLATIONS_UA
    },
    ['ru-UA']: {
        translation: TRANSLATIONS_UA
    },
    [localesCode.ru]: {
        translation: TRANSLATIONS_RU
    },
    [localesCode.en]: {
        translation: TRANSLATIONS_EN
    }
};

const detection = {
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' }
};

const options = {
    detection,
    resources,
    fallbackLng: localesCode.ua,
    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 'h3', 'p', 'ol', 'li']
    },
    load: [localesCode.ua, localesCode.ru, localesCode.en]
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init(options);

export default i18n;
