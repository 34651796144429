import {gql} from '@apollo/client';
import {TYPE_MESSAGE} from '../../types';

export const UPDATE_MESSAGE = gql`
    mutation updateMessage(
        $_id: ID!
        $data: MessageUpdateInput!
    ){
        updateMessage(
            _id: $_id
            data: $data
        ){
            ${TYPE_MESSAGE}
        }
    }
`;
