import React, { useContext, useEffect, useState } from 'react';
import ConversationsBlock from './conversations-block';
import { ChatMessages } from 'components';
import { IconSearchComponent } from 'assets/images/svg';
import { GeneralContext, MessagesContext } from 'contexts';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { UPDATE_CONVERSATION } from '../../_graphql';
import { useAuthToken } from '../../_hooks';
import { useSegment } from 'react-segment-hooks';

const MessengerPage = ({ t }) => {
    const analytics = useSegment();
    const { setLoading } = useContext(GeneralContext);
    const {
        chatsCount,
        loading,
        selectedConversation,
        setPublicConversationToSelected,
        getConversationById
    } = useContext(MessagesContext);
    const { userId } = useAuthToken();

    const [search, setSearch] = useState('');

    const [updateConversation, {
        loading: closeLoading,
        error: closeError
    }] = useMutation(UPDATE_CONVERSATION, { fetchPolicy: 'network-only' });

    const closeChat = async (chatId) => {
        const conversation = getConversationById(chatId)

        if (!chatId || !conversation || conversation.type === 'public') {
            return;
        }

        analytics.track({
            event: 'Clicked on "close chat"',
            properties: { conversation: conversation, initiator: userId }
        });

        await updateConversation({
            variables: { _id: chatId, data: { chatClosesUser: userId } }
        });

        setPublicConversationToSelected();
    };

    useEffect(() => {
        analytics.page({ name: 'Messenger Page' });
    }, []);

    useEffect(() => {
        setLoading(loading && closeLoading && !closeError);
    }, [loading, closeLoading, closeError]);

    return (
        <div
            className='h-full w-full flex-grow flex flex-row overflow-hidden rounded-xl bg-theme-gray-main shadow-main'>
            {chatsCount > 0 ? (
                <>
                    <div className='flex-none w-95 pt-8 text-theme-blue overflow-hidden flex flex-col'>
                        <div className='flex-grow-0 relative mb-5 mx-9'>
                            <input
                                type='text'
                                value={search}
                                className='w-full h-7 pr-3 pl-6 border border-theme-blue rounded-3xl bg-transparent text-xs'
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <IconSearchComponent className='absolute top-1/2 left-2 transform -translate-y-1/2'/>
                        </div>
                        <ConversationsBlock closeChat={closeChat} search={search}/>
                    </div>
                    {/*<CloseConversationModal*/}
                    {/*    isOpen={removeChatModal.display}*/}
                    {/*    conversationId={removeChatModal.conversationId}*/}
                    {/*    onClose={() => setRemoveChatModal((prev) => ({ ...prev, display: false }))}*/}
                    {/*/>*/}
                    <div className='flex-grow w-100 rounded-xl bg-theme-gray-main shadow-main'>
                        <ChatMessages closeChat={closeChat} selectedConversation={selectedConversation}
                                      show={{ adaptiveWidthMessage: true }}/>
                    </div>
                </>
            ) : (
                <div className='h-full w-full flex justify-center items-center'>
                    <p className='text-lg uppercase text-gray-300'>{t('messenger.empty')}</p>
                </div>
            )}
        </div>
    );
};

export default withTranslation()(MessengerPage);
