import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {navigation, storageNames} from '_constants';
import {getBasicLocationUtils} from 'utils';

const { pages: { pallets } } = navigation

const useSubMenu = () => {
    const { pathname } = useLocation()
    const history = useHistory()

    const initialStateLastActive = localStorage.getItem(storageNames.activeSubMenu) ? JSON.parse(localStorage.getItem(storageNames.activeSubMenu)) : {}

    const [active, setActive] = useState(pallets)
    const [lastActive, setLastActive] = useState(initialStateLastActive)
    const [panel, setPanel] = useState(false)
    const [refs, setRefs] = useState({})
    const [timeoutId, setTimeoutId] = useState(null)

    const close = () => setPanel(false)
    const open = () => setPanel(true)

   useEffect(() => {
       localStorage.setItem(storageNames.activeSubMenu, JSON.stringify(lastActive))
   }, [lastActive])

    useEffect(() => {
        open()

        if (timeoutId) {
            setTimeoutId(null)
        }
    }, [active])

    useEffect(() => {
        if (pathname.includes(active)) {
            setLastActive((prev) => ({...prev, [active]: pathname }))
        }
        if (pathname && pathname === '/') {
            setActive(pallets)
        } else if (active !== getBasicLocationUtils(pathname)) {
            setActive(getBasicLocationUtils(pathname))
        }
    }, [active, pathname])

    useEffect(() => {
        if (panel) {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
            const id = setTimeout(() => {
                close()
                setTimeoutId(null)
            }, 2000)
            setTimeoutId(id)
        }
    }, [panel])

    const setRef = (element, id) => {
        if (element && !refs[id]) {
            setRefs((prev) => ({...prev, [id]: { current: element }}))
        }
    }

    const toggle = () => {
        setPanel((prev) => !prev)
    }

    const getLastActivePage = (name) => {
        if (lastActive[name]) {
            return lastActive[name]
        }
        return name
    }

    const setActiveSubMenu = (name) => {
        history.push(getLastActivePage(name))
        if (name !== active) {
            setActive(name)
        }
        toggle()
    }

    return {
        subMenuPanel: panel,
        activeSubMenu: active,
        setActiveSubMenu,
        activeButtonsRefs: refs,
        closeSubMenuPanel: close,
        toggleSubMenuPanel: toggle,
        setButtonsRefs: setRef
    }
}

export default useSubMenu
