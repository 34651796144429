import {gql} from '@apollo/client';

export const CREATE_DELIVERY_CUSTOMER = gql`
    mutation createDeliveryCustomer(
        $data: DeliveryCustomerInput
    ){
        createDeliveryCustomer(
            data: $data
        ){
            _id
        }
    }
`;
