import {gql} from '@apollo/client';
import {
    TYPE_PAYMENT_TYPE, TYPE_PRODUCT_BRAND, TYPE_PRODUCT_PALLET_TYPE, TYPE_PRODUCT_TYPES
} from '_graphql/types';

export const GET_PRODUCT_OPTIONS_LIST = gql`
    query productOptions(
        $page: Int!
        $pageSize: Int
        $sort: String
        $search: String
        $searchFields: String
    ){
        productOptions(
            page: $page
            pageSize: $pageSize
            sort: $sort
            search: $search
            searchFields: $searchFields
        ) {
            productTypes {
                ${TYPE_PRODUCT_TYPES}
            }
            paymentTypes {
                rows {
                    ${TYPE_PAYMENT_TYPE}
                }
            }
            productPalletTypes {
                rows {
                    ${TYPE_PRODUCT_PALLET_TYPE}
                }
            }
            productBrands {
                rows {
                    ${TYPE_PRODUCT_BRAND}
                }
            }
        }
    }
`;
