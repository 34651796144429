import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER } from '_graphql';
import { useAuthToken } from '_hooks';
import { useSegment } from 'react-segment-hooks';

const useGeneralHook = () => {
    const analytics = useSegment();
    const { userId } = useAuthToken();

    const { data } = useQuery(GET_USER, { variables: { _id: userId }, errorPolicy: 'all' });

    const [newTaraModal, setNewTaraModal] = useState(false);
    const [newDeliveryModal, setNewDeliveryModal] = useState(false);
    const [newOrderDocumentsModal, setNewOrderDocumentsModal] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [loaderSpinner, setLoaderSpinner] = useState(false);
    const [tablePageSize, setTablePageSize] = useState(20);

    const userInfo = useMemo(() => data?.user || null, [data]);

    const toggleNewTaraModal = () => {
        setNewTaraModal((prev) => !prev);
    };

    const toggleOrderDocumentsModal = () => {
        setNewOrderDocumentsModal((prev) => !prev);
    };

    const toggleNewDeliveryModal = () => {
        setNewDeliveryModal((prev) => !prev);
    };

    const toggleLogoutModal = () => {
        setLogoutModal((prev) => !prev);
    };

    const toggleSegmentButton = (buttonName, modalName) => {
        analytics.track({
            event: `Clicked on "${buttonName}"`,
            properties: { modalName }
        });
    };

    const generalContextValue = {
        newTaraModal,
        toggleNewTaraModal,
        closeNewTaraModal: () => setNewTaraModal(false),

        newDeliveryModal,
        toggleNewDeliveryModal,
        closeNewDeliveryModal: () => setNewDeliveryModal(false),

        newOrderDocumentsModal,
        toggleOrderDocumentsModal,
        closeOrderDocumentsModal: () => setNewOrderDocumentsModal(false),

        logoutModal,
        toggleLogoutModal,
        closeLogoutModal: () => setLogoutModal(false),

        loaderSpinner,
        setLoading: setLoaderSpinner,

        tablePageSize,
        setTablePageSize,

        userInfo,
        toggleSegmentButton
    };

    return {
        generalContextValue,
        loaderSpinner
    };
};

export default useGeneralHook;
