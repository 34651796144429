import React from 'react';
import { messenger, navigation } from '_constants';
import { NavLink } from 'react-router-dom';
import { IconClose, IconPersonName, IconPlus } from 'assets/images/svg';
import { ToolTip } from 'components/index';
import { useAuthToken } from '_hooks';
import { withTranslation } from 'react-i18next';

const ChatHeader = ({ t, companions, type, closeChat, hideCloseChat = false }) => {
    const { userId } = useAuthToken();
    let membersList = null;

    if (!type || !companions) {
        return (<></>);
    }

    const filteredCompanions = companions.filter((value) => value._id !== userId);

    if (type === messenger.types.private && filteredCompanions[0]) {
        const user = filteredCompanions[0];
        membersList = (
            <NavLink
                key={'companions' + user._id}
                to={`${navigation.pages.account}/${user._id}`}
                className='flex flex-row items-center'
            >
                <p className='text-xl font-normal mr-3'>{user.username || ''}</p>
                <div
                    className='flex-grow-0 gap-x-8 bg-theme-blue-main rounded-full flex justify-center items-center overflow-hidden rounded-full h-12 w-12 border-3 border-theme-blue text-theme-blue'>
                    {user?.avatar ? (
                        <img
                            width='44'
                            height='44'
                            src={user?.avatar.assetUrl}
                            alt={user?.username}
                        />
                    ) : (
                        <IconPersonName className='w-5 h-5 stroke-current'/>
                    )}
                </div>
            </NavLink>
        );
    }

    if (type === messenger.types.public) {
        membersList = companions.map((user) => (
            <NavLink
                key={'membersList' + user._id}
                to={`${navigation.pages.account}/${user._id}`}
                data-tip
                data-for={user._id}
                className='flex-grow-0 bg-theme-blue-main rounded-full flex justify-center items-center overflow-hidden rounded-full h-12 w-12 border-3 border-theme-blue text-theme-blue -ml-4'
            >
                {user?.avatar ? (
                    <img
                        width='44'
                        height='44'
                        src={user?.avatar.assetUrl}
                        alt={user?.username}
                    />
                ) : (
                    <IconPersonName className='w-5 h-5 stroke-current'/>
                )}
                <ToolTip id={user._id} title={user?.username}/>
            </NavLink>
        ));
        if (membersList.length > 5) {
            membersList = membersList.slice(0, 5);
        }
        membersList.push((
            <div
                data-tip
                key={'lasfdasfasfvasdvracdrvdzstcaxefvds'}
                data-for={'allUsers'}
                className='flex-grow-0 bg-theme-blue rounded-full flex justify-center items-center overflow-hidden rounded-full h-12 w-12 border-3 border-theme-blue text-theme-blue -ml-4'
            >
                <IconPlus/>
                <ToolTip id={'allUsers'} title={companions.map(({ username }) => username).join(', ')}/>
            </div>
        ));
    }

    return (
        <div
            className={'flex-grow-0 min-h-22 w-full flex flex-row justify-between items-center px-3 overflow-y-hidden scrollable'}>
            {type === messenger.types.private && !hideCloseChat ? (
                <>
                    <button
                        data-tip
                        data-for='closeChat'
                        onClick={() => closeChat()}
                        className='flex flex-row items-center text-theme-blue hover:text-theme-red-main duration-100'
                    >
                        <IconClose className='fill-current h-8 w-8'/>
                    </button>
                    <ToolTip id='closeChat' title={t('global.buttons.close')}/>
                </>
            ) : (<div/>)}
            <div className='flex flex-row items-center'>
                {membersList}
            </div>
        </div>
    );
};

export default withTranslation()(ChatHeader);
