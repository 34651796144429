import {IconArrow1} from 'assets/images/svg';
import React, {useContext} from 'react';
import {FilterContext} from 'contexts';
import {useTranslation} from 'react-i18next';

export const FilterMaxCount = () => {
    const { t } = useTranslation()
    const {
        filterParams,
        saveToFilter
    } = useContext(FilterContext);


    const incrementMinMax = (field) => {
        saveToFilter(field, Number(filterParams[field]) + 1)
    }

    const decrementMinMax = (field) => {
        saveToFilter(field, Math.max(Number(filterParams[field]) - 1, 0))
    }

    const saveToFiltersWithName = (e) => {
        const { value, name } = e.target
        saveToFilter(name, value ? value : null)
    }

    return (
        <>
            <p className='mb-2'>{t('filterPanel.max')}</p>
            <div className='w-24 relative'>
                <input type='number' name='maxCount' className='w-full py-1.5 pl-3 pr-8 border border-theme-blue-main rounded-3xl bg-transparent' value={filterParams.maxCount || 0} onChange={saveToFiltersWithName} />
                <div className='absolute top-0 right-0 flex flex-col h-full'>
                    <button type='button' className='block h-1/2 pr-3 hover:text-theme-blue-light duration-150' onClick={() => incrementMinMax('maxCount')}>
                        <IconArrow1 className='transform rotate-180 stroke-current'/>
                    </button>
                    <button type='button' className='block h-1/2 pr-3 hover:text-theme-blue-light duration-150' onClick={() => decrementMinMax('maxCount')}>
                        <IconArrow1 className='stroke-current'/>
                    </button>
                </div>
            </div>
        </>
    )
}
export const FilterMinCount = () => {
    const { t } = useTranslation()
    const {
        filterParams,
        saveToFilter
    } = useContext(FilterContext);


    const incrementMinMax = (field) => {
        saveToFilter(field, Number(filterParams[field]) + 1)
    }

    const decrementMinMax = (field) => {
        saveToFilter(field, Math.max(Number(filterParams[field]) - 1, 0))
    }

    const saveToFiltersWithName = (e) => {
        const { value, name } = e.target
        saveToFilter(name, value ? value : null)
    }

    return (
        <>
            <p className='mb-2'>{t('filterPanel.min')}</p>
            <div className='w-24 relative'>
                <input type='number' name='minCount' className='w-full py-1.5 pl-3 pr-8 border border-theme-blue-main rounded-3xl bg-transparent' value={filterParams.minCount || 0} onChange={saveToFiltersWithName} />
                <div className='absolute top-0 right-0 flex flex-col h-full'>
                    <button type='button' className='block h-1/2 pr-3 hover:text-theme-blue-light duration-150'>
                        <IconArrow1 className='transform rotate-180 stroke-current' onClick={() => incrementMinMax('minCount')}/>
                    </button>
                    <button type='button' className='block h-1/2 pr-3 hover:text-theme-blue-light duration-150' onClick={() => decrementMinMax('minCount')}>
                        <IconArrow1 className='stroke-current'/>
                    </button>
                </div>
            </div>
        </>
    )
}
