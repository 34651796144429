import {gql} from '@apollo/client';
import {TYPE_USERID} from '_graphql/types';

export const CREATE_NOTIFICATION_SUBSCRIPTION = gql`
    subscription notificationCreated(
        $userId: ID!
    ) {
        notificationCreated(
            userId: $userId
        ) {
            _id
            userId {
                ${TYPE_USERID}
            }
            title
            message
            seen
            createdAt
            updatedAt
        }
    }
`;
