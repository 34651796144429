import { gql } from '@apollo/client';

export const CREATE_USER_WAREHOUSES = gql`
    mutation createWarehouse(
        $data: [WarehouseInput!]!
    ){
        createWarehouse(
            data: $data
        ){
            _id
            name
            placeId
            houseNumber
            streetName
        }
    }
`;

