import {gql} from '@apollo/client';

export const REMOVE_PRODUCT = gql`
    mutation removeProduct(
        $_id: ID!
    ){
       removeProduct(
            _id: $_id
        ){
            _id
        }
    }
`;
