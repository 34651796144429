import { gql } from '@apollo/client';

export const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword(
        $resetToken: String!,
        $password: String
    ){
        resetPassword(
            resetToken: $resetToken,
            password: $password
            
        ){
            status
        }
    }
`;
