import React, {useContext, useEffect, useMemo, useState} from 'react';
import {withTranslation} from 'react-i18next';
import Map from 'map';
import {useQuery} from '@apollo/client';
import {GET_DELIVERIES_LIST} from '_graphql';
import {DirectionsTable, NoPage} from 'components';
import {useAuthToken} from '_hooks';
import {GeneralContext} from 'contexts';

const MyDeliveriesPage = ({ show = false}) => {
    const { setLoading } = useContext(GeneralContext)
    const { userId } = useAuthToken()

    const [pagination, setPagination] = useState({ page: 1, userId });

    const {data, loading, error, refetch} = useQuery(GET_DELIVERIES_LIST, { variables: pagination, errorPolicy: 'all' })

    const [activePlace, setActivePlace] = useState(null)

    useEffect(() => {
        refetch(pagination);
    }, [pagination]);

    useEffect(() => {
        setLoading(loading && !error)
    }, [loading, error])

    const deliveries = useMemo(() => {
        if (data?.deliveries) {
            setActivePlace(data?.deliveries.rows[0])
            return data?.deliveries
        } else {
            return {rows: [], totalPages: 0}
        }
    }, [data])

    const places = useMemo(() => {
        const places = { }
        if (activePlace) {
            const markers = []
            activePlace.deliveryRoutes.forEach((value) => {
                if (value.startAddress) {
                    markers.push({ latitude: Number(value.startAddress.latitude), longitude: Number(value.startAddress.longitude) })
                }
                if (value.endAddress) {
                    markers.push({ latitude: Number(value.endAddress.latitude), longitude: Number(value.endAddress.longitude) })
                }
            })
            places.markers = markers

            const [firstMarker] = markers?.map(({latitude, longitude}) => ({ lat: latitude, lng: longitude }))

            places.center = firstMarker
        }

        return places?.markers?.length ? places : null
    }, [activePlace])

    return show ? (
        <div className='h-full relative flex-auto flex flex-col overflow-hidden rounded-xl bg-theme-gray-main shadow-main text-theme-blue pl-72'>
            <DirectionsTable
                deliveries={deliveries}
                activePlace={activePlace}
                setActivePlace={setActivePlace}
                setPagination={setPagination}
                hide={{
                    owner: true,
                    btnSendMessage: true
                }}
                show={{
                    btnRemove: true,
                    bgDeliveryIcon: true
                }}
            />
            <Map markers={places?.markers} defaultCenter={places?.center}/>
        </div>
    ) : (
        <NoPage/>
    )
}


export default withTranslation()(MyDeliveriesPage)
