import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconSend} from 'assets/images/svg';

export default ({
                    t,
                    userId,
                    sendMessageToUser
                }) => ({
    name: columnName.sendMessageToUser,
    title: t('table.header.btnSendMessage'),
    excelValue: null,
    thead: function () {
        return (
            <th key={this.name}/>
        );
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'w-1 whitespace-nowrap px-1 text-center'}>
                {userId !== item?._id && (
                    <button
                        className='cursor-pointer h-10 w-10 flex items-center justify-center rounded-full bg-theme-blue-main hover:bg-gray-200'
                        onClick={(e) => sendMessageToUser(e, { id: item?._id, username: item?.username })}
                    >
                        <IconSend/>
                    </button>
                )}
            </td>
        );
    },
    tabletTitle: null,
    tabletContent: null
})
