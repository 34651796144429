import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Table, columnName } from 'components';
import { useAuthToken, useGraphqlCache } from '_hooks';
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCT_CUSTOMERS } from '_graphql';
import { useParams } from 'react-router-dom';
import { GeneralContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const ProductCustomersTable = ({ ownerId, setChatConversation, chatConversation }) => {
    const { setLoading } = useContext(GeneralContext);
    const { updateCustomersListInCache } = useGraphqlCache();
    const { userId } = useAuthToken();
    const { id, idConversation } = useParams();
    const { t } = useTranslation();

    const [getCustomersList, { data, loading, error }] = useLazyQuery(GET_PRODUCT_CUSTOMERS);

    const [pagination, setPagination] = useState({ page: 1, ownerId: userId, productId: id });

    const customersTableData = useMemo(() => data?.productCustomers ? data?.productCustomers : {
        rows: [],
        totalPages: 0
    }, [data]);

    useEffect(() => {
        setLoading(loading && !error);
    }, [loading, error]);

    useEffect(() => {
        if (idConversation && customersTableData?.rows?.length > 0) {
            const conv = customersTableData.rows.find((item) => item?.conversationId?._id === idConversation);
            if (conv) {
                setChatConversation(conv.conversationId);
            }
        }
    }, [customersTableData]);

    useEffect(() => {
        if (ownerId && ownerId === userId) {
            getCustomersList({ variables: pagination });
        }
    }, [pagination, ownerId]);

    const handleClickRow = (item) => {
        if (item.conversationId) {
            setChatConversation(item.conversationId);
        }
    };

    return ownerId && ownerId === userId ? (
        <div
            className='h-full min-w-1/2 w-1/2 bg-theme-gray-main flex-grow flex-shrink min-w-25 overflow-x-auto scrollable rounded-xl overflow-y-auto shadow-main'>
            <Table
                tableName={'productOrders'}
                products={customersTableData}
                setPagination={setPagination}
                show={{
                    columns: [
                        columnName.customerImage,
                        { name: columnName.customer, title: t('table.header.client') },
                        columnName.count,
                        columnName.price,
                        columnName.finishOrder,
                        columnName.unreadMessagesCount
                    ],
                    activeRows: [{
                        value: (item) => item?.conversationId?._id === chatConversation?._id || (!chatConversation?._id && item?.conversationId?._id === idConversation),
                        className: 'bg-theme-blue-light-2'
                    }]
                }}
                hide={{
                    header: true,
                    changeType: true
                }}
                change={{
                    handleClickRow,
                    noContentMessage: t('table.noCustomers'),
                    updateFinishOrder: (customer) => updateCustomersListInCache({ customer, variables: pagination }),
                    defaultSort: { field: columnName.createdAt, value: '-1' }
                }}
            />
        </div>
    ) : (<></>);
};
