import {gql} from '@apollo/client';
import {TYPE_PRODUCT} from '_graphql/types';

export const UPDATE_PRODUCT = gql`
    mutation updateProduct(
        $data: ProductUpdateInput!
        $_id: ID!
        $removeAssetList: [ID]
    ){
        updateProduct(
            _id: $_id
            data: $data
            removeAssetList: $removeAssetList
        ){
            ${TYPE_PRODUCT}
        }
    }
`;
