import {TYPE_MEMBERS, TYPE_USERID} from '../user';
import {TYPE_PRODUCT} from '../product';
import {TYPE_DELIVERY} from '../delivery';
import {TYPE_ASSET} from '../asset';

export const TYPE_MESSAGE = `
    _id
    message
    seen
    createdAt
    updatedAt
    userId {
        ${TYPE_USERID}
    }
    assets {
        ${TYPE_ASSET}
    }
    isDeleted
`

export const TYPE_CONVERSATION_ID = `
    _id
    members {
        ${TYPE_MEMBERS}
    }
    chatClosesUsers
    lastMessageId {
       ${TYPE_MESSAGE}
    }
    lastMessageDate
    type
`

export const TYPE_CONVERSATION = `
    _id
    members {
        ${TYPE_MEMBERS}
    }
    unreadMessagesCount
    lastMessageId {
        conversationId {
            ${TYPE_CONVERSATION_ID}
        }
        ${TYPE_MESSAGE}
    }
    productId {
        ${TYPE_PRODUCT}
    }
    deliveryId {
        ${TYPE_DELIVERY}
    }
    lastMessageDate
    createdAt
    name
    type
    chatClosesUsers
`
