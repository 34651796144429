import React from 'react';
import {withTranslation} from 'react-i18next';
import {IconArrow4} from 'assets/images/svg';
import {changeDownloadFileName, checkToImageFileUtils, shortFileSizeUtils} from 'utils';
import {ToolTip} from 'components/index';

const ChatMessageFiles = ({assets, isAuthor}) => {
    if (assets?.length > 0) {
        return assets.map((item) => checkToImageFileUtils(item) ? (
            <div
                className={`max-w-70 min-w-70 w-full cursor-pointer flex justify-center ${isAuthor ? 'ml-auto' : ''}`}
                key={'messageFile' + item._id}
                onClick={() => window.open(item.assetUrl, '_blank')}
                data-tip
                data-for={'openInNewWindow'}
            >
                <img src={item.assetUrl} alt={item.filename} className='max-w-full w-auto h-max max-h-100 border ' />
                <ToolTip id={'openInNewWindow'} title={'Open this image in new window'}/>
            </div>
        ) : (
            <div
                className='bg-theme-gray-light rounded-3xl p-1 flex flex-row justify-between max-w-70 min-w-70 w-full text-xs'
                key={'messageFile' + item._id}
            >
                <div className='flex-grow flex flex-col justify-center pl-4 gap-y-0.5 w-full max-w-56'>
                    <p data-tip data-for={'messageFile' + item._id} className='truncate'>{item.filename || '-'}</p>
                    <p className='text-theme-blue'>{shortFileSizeUtils(item.filesize, true, 2) || '-' }</p>
                    {item.filename && (<ToolTip id={'messageFile' + item._id} title={item.filename}/>)}
                </div>
                <button
                    className='flex-none w-10 h-10 rounded-3xl bg-theme-blue-light flex justify-center items-center hover:bg-theme-blue duration-100'
                    onClick={() => changeDownloadFileName(item.assetUrl, item.filename)}
                >
                    <IconArrow4 className='fill-current text-white transform rotate-90' />
                </button>
            </div>
        ))
    }
    return (<></>)
}

export default withTranslation()(ChatMessageFiles)
