import {gql} from '@apollo/client';

export const UPLOAD_ASSET = gql`
    mutation uploadAsset(
        $file: Upload!,
        $productId: String,
    ){
        uploadAsset(
            file: $file,
            productId: $productId,
        ){
            _id
            fileName
            productId
            assetUrl
#            fileFormatId
#            userId
            createdAt
            updatedAt
        }
    }
`;
