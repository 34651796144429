import {gql} from '@apollo/client';

export const UPDATE_RATING = gql`
    mutation createRanking(
        $data: RankingInput
    ){
        createRanking(
            data: $data
        ){
           _id
            userId
            votedUserId
            itemId
            rating
            createdAt
        }
    }
`;
