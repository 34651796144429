import React from 'react';
import ReactTooltip from 'react-tooltip';

const ToolTip = ({ id, title, place = 'bottom', ...restProps }) => (
    <ReactTooltip id={id} place={place} effect='solid' multiline type='light' backgroundColor='gray'
                  className={'max-w-1/2'}
                  textColor='white' {...restProps}>
        {title}
    </ReactTooltip>
);
export default ToolTip;
