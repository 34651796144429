import {gql} from '@apollo/client';
import {TYPE_MESSAGE} from '../../types';

export const CREATE_MESSAGE = gql`
    mutation createMessage(
        $data: MessageInput
    ){
        createMessage(
            data: $data
        ){
            ${TYPE_MESSAGE}
        }
    }
`;
