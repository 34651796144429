import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { navigation, storageNames } from '../_constants';

export const useAuthToken = () => {
    const setAuthToken = (authToken) => localStorage.setItem(storageNames.TOKEN_NAME, authToken);
    const setUserData = (userData) => localStorage.setItem(storageNames.USER_DATA, JSON.stringify(userData));
    const removeAuthToken = () => localStorage.removeItem(storageNames.TOKEN_NAME);
    const removeUserData = () => localStorage.removeItem(storageNames.USER_DATA);
    const userData = JSON.parse(localStorage.getItem(storageNames.USER_DATA));
    const authToken = localStorage.getItem(storageNames.TOKEN_NAME);

    return {
        userId: userData?._id,
        userName: userData?.username,
        authToken,
        userData,
        setAuthToken,
        setUserData,
        removeAuthToken,
        removeUserData
    };
};

export const useLogout = () => {
    const history = useHistory();
    const { removeAuthToken, removeUserData } = useAuthToken();
    const apolloClient = useApolloClient();

    return () => {
        localStorage.clear();
        history.push(navigation.nonAuth.login);
        apolloClient.clearStore();
        apolloClient.resetStore();// we remove all information in the store
        removeAuthToken();
        removeUserData();
        location.reload();
    };
};
