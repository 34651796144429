import {gql} from '@apollo/client';
import {TYPE_CONVERSATION} from '_graphql/types';

export const GET_PRIVATE_CHAT = gql`
    query privateChat(
        $recipientUserId: ID
    ){
        privateChat(
            recipientUserId: $recipientUserId
        ){
            ${TYPE_CONVERSATION}
        }
    }
`;
