import { ApolloClient, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { useAuthToken } from '_hooks';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { cache } from './cache';
import { onError } from '@apollo/client/link/error';
import { navigation } from '_constants';
import { useSegment } from 'react-segment-hooks';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { errorMessageUtils } from 'utils';

const graphUri = `${process.env.REACT_APP_API}/graphql`;
const wsGraphqlUri = `${process.env.REACT_APP_SUBSCRIPTIONS}/subscriptions`;
const httpLink = new createUploadLink({ uri: graphUri, headers: { 'keep-alive': 'true' } });

const authLink = (authToken) => setContext((_, { headers }) =>
    ({
        headers: {
            ...headers,
            authorization: authToken ? authToken : ''
        }
    })
);

const wsLink = (authToken) => new WebSocketLink({
    uri: wsGraphqlUri,
    options: {
        lazy: true,
        minTimeout: (15 * 1000),
        reconnect: true,
        connectionParams: {
            authorization: authToken ? authToken : ''
        }
    }
});

const splitLink = (authToken) => split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink(authToken),
    authLink(authToken).concat(httpLink)
);

export const useAppApolloClient = () => {
    const { t } = useTranslation();
    const { authToken, removeAuthToken, removeUserData } = useAuthToken();
    const analytics = useSegment();
    const history = useHistory();

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            analytics.track({ event: 'Received graphQL error' });
            graphQLErrors.forEach(({ message, locations, path }) => {
                analytics.track({ event: 'Received graphQL error', properties: { message: message } });
                console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

                if (path?.includes('login')) {
                    Swal.fire({
                        title: t('login.errorMessageTitle'),
                        text: message,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
                if (path?.includes('createUser')) {
                    Swal.fire({
                        title: t('signup.errorMessageTitle'),
                        text: errorMessageUtils(message, t),
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            });
        }

        if (networkError) {
            const typeError = networkError?.result?.type || networkError?.message?.toUpperCase();
            const errorTypes = [
                'INVALID_TOKEN',
                'USER_NOT_FOUND',
                'SERVICE_NOT_AVAILABLE',
                'NO_ACCESS_TOKEN'
                // 'FAILED TO FETCH'
            ];
            if (errorTypes.includes(typeError)) {
                removeAuthToken();
                removeUserData();
                history.push(navigation.nonAuth.login);
                location.reload();
                return;
            }
            let message = networkError;
            if (networkError.result && networkError.result.errors) {
                message = networkError.result?.errors[0]?.message;
            } else if (networkError.result && networkError.result.message) {
                message = networkError.result?.message;
            }

            analytics.track({ event: 'Received network error', properties: { message: message } });
            console.log(`[Network error]: ${message}`);
        }
    });

    return new ApolloClient({
        link: errorLink.concat(splitLink(authToken)),
        cache
    });
};
