import React, { useContext } from 'react';
import { FilterContext, LocaleContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import { IconArrow1 } from 'assets/images/svg';
import Calendar from 'react-calendar';

export const FilterDatePickerForm = ({ field }) => {
    const { filterParams, onChangeDate } = useContext(FilterContext);
    const { t } = useTranslation();

    const onChangeDateRange = (e) => {
        const { name } = e.target;
        let newDate = [];
        const dateNow = new Date();

        if (name === 'week') {
            newDate = [new Date(new Date().setDate(dateNow.getDate() - 6)), new Date(new Date().setDate(dateNow.getDate()))];
        }
        if (name === 'month') {
            newDate = [new Date(new Date().setDate(dateNow.getDate() - 30)), new Date(new Date().setDate(dateNow.getDate()))];
        }
        if (name === 'day') {
            newDate = [new Date(), new Date()];
        }

        onChangeDate(newDate, field);
    };

    return (
        <>
            <div className='w-full flex flex-row justify-between items-center gap-x-6 px-2 mb-5'>
                <div className='w-full text-left'>
                    <p>{t('filterPanel.from')}</p>
                    <input type='text' name='from'
                           className='w-full py-1.5 px-3 border border-theme-blue-main rounded-3xl bg-transparent text-center'
                           value={filterParams?.[field]?.from ? `${new Date(filterParams?.[field]?.from).getDate()}.${new Date(filterParams?.[field]?.from).getMonth()}.${new Date(filterParams?.[field]?.from).getFullYear()}` : ''}
                           disabled/>
                </div>
                <div className='w-full text-left'>
                    <p>{t('filterPanel.to')}</p>
                    <input type='text' name='to'
                           className='w-full py-1.5 px-3 border border-theme-blue-main rounded-3xl bg-transparent text-center'
                           value={filterParams?.[field]?.to ? `${new Date(filterParams?.[field]?.to).getDate()}.${new Date(filterParams?.[field]?.to).getMonth()}.${new Date(filterParams?.[field]?.to).getFullYear()}` : ''}
                           disabled/>
                </div>
            </div>
            <div className='w-full flex flex-col items-center gap-y-2'>
                <button className='bg-theme-blue rounded-2xl text-white h-8 w-1/2' name='day'
                        onClick={onChangeDateRange}>{t('filterPanel.today')}</button>
                <button className='bg-theme-blue rounded-2xl text-white h-8 w-1/2' name='week'
                        onClick={onChangeDateRange}>{t('filterPanel.last7days')}</button>
                <button className='bg-theme-blue rounded-2xl text-white h-8 w-1/2' name='month'
                        onClick={onChangeDateRange}>{t('filterPanel.last30days')}</button>
            </div>
        </>
    );
};

export const FilterDatePickerCalendar = ({ field }) => {
    const { locale } = useContext(LocaleContext);
    const { filterParams, onChangeDate } = useContext(FilterContext);
    const onCalendarChange = (data) => {
        onChangeDate(data, field);
    };

    return (
        <Calendar
            onChange={onCalendarChange}
            value={[filterParams?.[field]?.from || new Date(), filterParams?.[field]?.to || new Date()]}
            selectRange
            prev2Label={null}
            next2Label={null}
            prevLabel={<IconArrow1 className='transform rotate-90'/>}
            nextLabel={<IconArrow1 className='transform -rotate-90'/>}
            locale={locale}
            tileClassName='bg-red text-sideBar text-center hover:rounded-xl'
            className='bg-red'
        />
    );
};
