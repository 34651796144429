import React, { useContext, useEffect, useState } from 'react';
import { IconArrow1, IconSearch1 } from 'assets/images/svg';
import { usePlacesWidget } from 'react-google-autocomplete';
import { GOOGLE_API_KEY } from '_constants';
import { FilterContext, LocaleContext } from 'contexts';
import { withTranslation } from 'react-i18next';

const FilterCityInput = ({ t }) => {
    const { locale } = useContext(LocaleContext);
    const {
        saveToFilter,
        filterParams
    } = useContext(FilterContext);

    const [searchCity, setSearchCity] = useState('');

    useEffect(() => {
        setSearchCity(filterParams?.city?.name || '');
    }, [filterParams?.city?.name]);

    const onPlaceSelected = (place) => {
        if (place.formatted_address && place.place_id) {
            saveToFilter('city', { name: place.formatted_address, placeId: place.place_id });
            setSearchCity(place.formatted_address);
        }
    };

    const onChangeCity = (e) => {
        setSearchCity(e.target.value);
        if (filterParams?.city?.name) {
            saveToFilter('city', null);
        }
    };

    const { ref: refPlacesWidget } = usePlacesWidget({
        apiKey: GOOGLE_API_KEY,
        onPlaceSelected: onPlaceSelected,
        language: locale,
        options: {
            types: ['(cities)']
        }
    });

    return (
        <>
            <p className='mb-2'>{t('filterPanel.city')}</p>
            <div className='w-full relative'>
                <input
                    ref={refPlacesWidget}
                    className='w-full py-1.5 px-9 border border-theme-blue-main rounded-3xl bg-transparent'
                    type='city'
                    placeholder={t('filterPanel.placeholder')}
                    value={searchCity}
                    onChange={onChangeCity}
                />
                <IconSearch1 className='w-4 h-4 absolute top-1/2 left-3 transform -translate-y-1/2'/>
                <IconArrow1 className='absolute top-1/2 right-3 transform -translate-y-1/2'/>
            </div>
        </>
    );
};

export default withTranslation()(FilterCityInput);
