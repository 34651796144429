import { gql } from '@apollo/client';

export const CREATE_BETA_USER_MUTATION = gql`
    mutation createBetaUser(
            $email: String
    ){
        createBetaUser(
            email: $email
        ){
            email       
        }
    }
`;
