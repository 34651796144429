import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Table, columnName } from 'components';
import { useLazyQuery } from '@apollo/client';
import { GET_USERS } from '_graphql';
import { FilterContext, GeneralContext } from 'contexts';
import { useSegment } from 'react-segment-hooks';
import { useHistory } from 'react-router-dom';
import { navigation } from '_constants';
import { useAuthToken } from '_hooks';

const MembersPage = ({ t }) => {
    const analytics = useSegment();
    const history = useHistory();
    const { userId } = useAuthToken();
    const { tablePageSize } = useContext(GeneralContext);
    const { parseFilterParams } = useContext(FilterContext);

    const [getUsersList, { data, loading, error }] = useLazyQuery(GET_USERS, { fetchPolicy: 'network-only' });

    const [pagination, setPagination] = useState({ page: 1, pageSize: tablePageSize });

    const users = useMemo(() => data?.users ? data?.users : { rows: [], totalPages: 0 }, [data]);

    useEffect(() => {
        analytics.page({ name: 'Members' });
    }, []);

    const handleClickRow = (item) => {
        history.push(userId === item._id ? navigation.pages.account : `${navigation.pages.account}/${item._id}`);
    };

    useLayoutEffect(() => {
        let variables = pagination;
        if (parseFilterParams && Object.keys(parseFilterParams).length > 0) {
            variables = {
                ...variables,
                ...parseFilterParams
            };
            analytics.track({ event: 'Set Filter params in all users page', properties: { data: variables } });
        }

        getUsersList({ variables });
    }, [pagination, parseFilterParams]);

    return (
        <Table
            tableName={'members'}
            products={users}
            setPagination={setPagination}
            loader={loading && !error}
            show={{
                columns: [
                    columnName.sequenceNumber,
                    { name: columnName.lastLoginAt, title: t('table.header.createdAtProfile') },
                    columnName.userImage,
                    { name: columnName.username, title: t('table.header.userProfileName') },
                    { name: columnName.location, title: t('table.header.address') },
                    columnName.phones,
                    columnName.activatedProducts,
                    columnName.sendMessageToUser
                ],
                // activeRows: { field: 'productId.isDeleted', value: true, color: 'bg-theme-red-light' },
                downloadExcelFile: true,
                showAdminsBtn: true
            }}
            hide={{
                changeType: true
            }}
            change={{
                handleClickRow
            }}
        />
    );
};

export default withTranslation()(MembersPage);
