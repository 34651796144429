import {gql} from '@apollo/client';
import {TYPE_FRONT_SETTINGS} from '_graphql/types';

export const UPDATE_FRONT_SETTINGS_SUBSCRIPTION = gql`
    subscription frontSettingsUpdated(
        $all: Boolean
    ) {
        frontSettingsUpdated(
            all: $all
        ) {
            ${TYPE_FRONT_SETTINGS}
        }
    }
`;
