import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CREATE_USER_MUTATION } from '_graphql';
import { useSegment } from 'react-segment-hooks';
import { useMutation } from '@apollo/client';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';

import { useLogin } from '_hooks';
import { navigation } from '_constants';
import { IconArrow4, IconDoubleArrowRight, IconEmail, IconGoogle, IconLock, IconPersonName } from 'assets/images/svg';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoadingSpinner } from '../../../components';

const initialStateUser = { email: '', username: '', password: '' };

const SignupPage = ({ t }) => {
    const schema = yup.object({
        username: yup.string().required(t('signup.errorFullName')),
        email: yup.string().email().required(t('signup.errorEmail')),
        password: yup.string().required(t('signup.errorPassword')),
        passwordConfirmation: yup.string().required(t('signup.errorConfirm')).oneOf([yup.ref('password'), null], (t('signup.errorComparePasswords')))
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });
    const loginGoogleService = useLogin({ service: 'google' });
    const history = useHistory();
    const analytics = useSegment();

    const [loaderState, setLoaderState] = useState(false);

    const [createUser, { data, error, loading }] = useMutation(CREATE_USER_MUTATION);

    useEffect(() => {
        analytics.page({ name: 'SignUp Page' });
    }, []);

    useEffect(() => {
        setLoaderState(loading && !error);
    }, [loading, error]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                title: t('signup.errorMessageTitle'),
                text: errorMessage(error.message),
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            reset(initialStateUser);
        }
    }, [error]);

    useEffect(() => {
        if (data?.createUser && !error) {
            successMessage();
            reset(initialStateUser);
        }
    }, [error, data]);

    const errorMessage = (message) => {
        if (message.includes('duplicate')) {
            if (message.includes('username')) {
                return t('signup.errorMessageUsername');
            } else if (message.includes('email')) {
                return t('signup.errorMessageEmail');
            }
        } else {
            return message;
        }
    };

    const successMessage = () => {
        if (!error) {
            Swal.fire({
                title: t('signup.successMessageTitle'),
                text: t('signup.successMessage'),
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                reset(initialStateUser);
                history.push(navigation.nonAuth.login);
            });
        }
    };

    const signup = (formValues) => {
        const value = formValues;
        delete value.passwordConfirmation;
        createUser({
            variables: {
                ...value
            }
        }).then(() => {
            successMessage();
        }).catch((error) => {
            console.log(error);
        });
    };

    const onTypeEmail = (e) => {
        const { value } = e.target;
        analytics.track({ event: 'Typed text in the "email" input', properties: { email: value } });
    };

    const onTypeName = (e) => {
        const { value } = e.target;
        analytics.track({ event: 'Typed text in the "name" input', properties: { name: value } });
    };

    const clickedSingUp = () => {
        analytics.track({ event: 'Clicked on “SignUp” button' });
    };
    const clickedLogin = () => {
        analytics.track({ event: 'Clicked on “SignIn” button' });
    };

    return (
        <>
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <p className='text-white text-xl text-center font-normal mb-12'>{t('signup.subTitle')}</p>
                <form className='flex flex-col gap-y-5 max-w-sm w-full' onSubmit={handleSubmit(signup)}>
                    <div className='relative'>
                        <label
                        htmlFor='username'
                            className='cursor-pointer z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'>
                            <IconPersonName/>
                        </label>
                        <input {...register('username')}
                               className={`pl-12 pr-5 h-11 text-xs text-theme-gray rounded-full cursor-pointer w-full duration-100 hover:bg-gray-100 ${errors.username ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                               id='username'
                               name='username'
                               type='text'
                               autoComplete='username'
                               placeholder={t('signup.fullName')}
                               onChange={onTypeName}
                        />
                        <p className='h-5 text-theme-red-main text-sm font-semibold pl-5'>{errors.username?.message}</p>
                    </div>
                    <div className='relative'>
                        <label
                        htmlFor='email'
                            className='cursor-pointer z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'>
                            <IconEmail/>
                        </label>
                        <input
                            {...register('email')}
                            className={`pl-12 pr-5 h-11 text-xs text-theme-gray rounded-full cursor-pointer w-full duration-100 hover:bg-gray-100 ${errors.email ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                            id='email'
                            name='email'
                            type='email'
                            autoComplete='email'
                            placeholder={t('signup.email')}
                            onChange={onTypeEmail}
                        />
                        <p className='h-5 text-theme-red-main text-sm font-semibold pl-5'>{errors.email?.message}</p>
                    </div>
                    <div className='relative'>
                        <label
                        htmlFor='password'
                            className='cursor-pointer z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'><IconLock/></label>
                        <input {...register('password')}
                               className={`pl-12 pr-5 h-11 text-xs text-theme-gray rounded-full cursor-pointer w-full duration-100 hover:bg-gray-100 ${errors.password ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                               id='password'
                               name='password'
                               type='password'
                               autoComplete='password'
                               placeholder={t('signup.password')}
                        />
                        <p className='h-5 text-theme-red-main text-sm font-semibold pl-5'>{errors.password?.message}</p>
                    </div>
                    <div className='relative'>
                        <label
                        htmlFor='passwordConfirm'
                            className='cursor-pointer z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'><IconLock/></label>
                        <input
                            {...register('passwordConfirmation')}
                            className={`pl-12 pr-5 h-11 text-xs text-theme-gray rounded-full cursor-pointer w-full duration-100 hover:bg-gray-100 ${errors.passwordConfirmation ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                            id='passwordConfirm'
                            name='passwordConfirmation'
                            type='password'
                            placeholder={t('signup.confirm')}
                        />
                        <p className='h-5 text-theme-red-main text-sm font-semibold pl-5'>{errors.passwordConfirmation?.message}</p>
                    </div>
                    <div className='relative login-hover'>
                        <button
                            className='text-white flex flex-row items-center w-full h-11 rounded-full border-2 border-white text-center bg-transparent text-base font-normal duration-200 cursor-pointer justify-center'
                            type='submit' onClick={clickedSingUp}>
                            <span
                                className='z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'>
                                <IconArrow4 className='fill-current text-white'/>
                            </span>
                            <span className='duration-200 pl-2'>{t('signup.submit')}</span>
                            <IconDoubleArrowRight className='duration-200 login-icon ml-2 w-0' />
                        </button>
                    </div>
                </form>
                <p className='text-white text-center text-xs font-normal mt-6 mb-4'>{t('signup.or')}</p>
                <button
                    className='h-11 px-4 border-2 border-white hover:border-theme-blue rounded-full cursor-pointer duration-200 hover:bg-theme-blue flex flex-row justify-center items-center gap-x-1.5 mb-4'
                    onClick={loginGoogleService}
                >
                    <IconGoogle className='flex-none w-7 h-7'/><p
                    className='text-base font-normal text-white'>{t('login.google')}</p></button>
                <Link
                    className='inline-link relative font-normal text-white text-sm text-center cursor-pointer duration-100 hover:text-white'
                    to={navigation.nonAuth.login}
                    onClick={clickedLogin}
                >
                    <p>{t('signup.login')}</p>
                    <span className='duration-200 absolute w-0 h-px bottom-0 left-0 bg-theme-blue' />
                </Link>
            </div>
            <LoadingSpinner loading={loaderState}/>
        </>
    );
};

export default withTranslation()(SignupPage);
