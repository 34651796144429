import {gql} from '@apollo/client';
import {TYPE_CONVERSATION} from '_graphql/types';

export const UPDATE_CONVERSATION_SUBSCRIPTION = gql`
    subscription conversationUpdated(
        $userId: ID!
    ) {
        conversationUpdated(
            userId: $userId
        ) {
            ${TYPE_CONVERSATION}
        }
    }
`;
