import React, { useContext, useEffect, useMemo, useState } from 'react';
import StatisticPipe from './statisticPipe';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_USER_WAREHOUSES, GET_USER, REMOVE_USER_WAREHOUSES, UPDATE_USER } from '_graphql';
import UserInfo from './usserInfo';
import { useAuthToken, useGraphqlCache } from '_hooks';
import { useHistory, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ProfileImage from './userImage';
import UserAbout from './userAbout';
import { FilterContext } from 'contexts';
import { navigation } from '_constants';
import { LoadingSpinner } from 'components';
import { useSegment } from 'react-segment-hooks';

const AccountPage = ({ t }) => {
    const analytics = useSegment();
    const { updateUserInfoInCache } = useGraphqlCache();

    const { userId } = useAuthToken();
    const history = useHistory();
    const { id } = useParams();
    const { setAsyncFilterParams } = useContext(FilterContext);

    const [loaderState, setLoaderState] = useState(true);

    const _id = id ? id : userId;

    const { data: userData, loading: getLoading, error: getError } = useQuery(GET_USER, {
        variables: { _id },
        errorPolicy: 'all'
    });

    useEffect(() => {
        analytics.page({ name: 'SignUp Page' });
    }, []);

    const [updateUserInfo, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_USER);
    const [createWarehouses] = useMutation(CREATE_USER_WAREHOUSES);
    const [removeWarehouses] = useMutation(REMOVE_USER_WAREHOUSES);

    const user = useMemo(() => userData?.user || null, [userData]);

    const { all, activated } = useMemo(() => ({
        all: (user?.counts?.activatedProducts + user?.counts?.deactivatedProducts) || 0,
        activated: user?.counts?.activatedProducts || 0,
        deactivated: user?.counts?.deactivatedProducts || 0
    }), [user]);

    useEffect(() => {
        setLoaderState(updateLoading && !updateError && getLoading && !getError);
    }, [updateLoading, updateError, getLoading, getError]);

    const saveUserData = (data, { file, newWarehouses, deletedWarehouses }, dataType) => {
        const variables = {
            _id: userId,
            data
        };

        if (file) {
            variables.file = file;
        }

        if (data || file) {
            const updateResponse = updateUserInfo({
                variables,
                update: (cache, { data }) => updateUserInfoInCache({ userInfo: data?.updateUser, variables: { _id } })
            });
            analytics.track({ event: `Clicked on "save user ${dataType}"`, properties: { data: updateResponse } });
        }

        if (newWarehouses?.length > 0) {
            createWarehouses({
                variables: { data: newWarehouses },
                update: (cache, { data }) => {
                    if (data?.createWarehouse) {
                        const warehouses = user?.warehouses?.length > 0 ? [...new Set([...data?.createWarehouse, ...user?.warehouses])] : data?.createWarehouse;
                        updateUserInfoInCache({ userInfo: { warehouses } });
                    }
                }
            });
        }

        if (deletedWarehouses?.length > 0) {
            removeWarehouses({
                variables: { _id: deletedWarehouses },
                update: (cache, { data }) => {
                    const deleteIds = data?.removeWarehouse?.map(({ _id }) => _id) || [];
                    if (data?.removeWarehouse && user?.warehouses?.length > 0) {
                        const warehouses = user?.warehouses?.filter((item) => !deleteIds.includes(item._id));
                        updateUserInfoInCache({ userInfo: { warehouses } });
                    }
                }
            });
        }
    };

    const navigateToActivateProducts = async () => {
        analytics.track({
            event: 'Clicked on "activated requests user"',
            properties: { userId: id || userId, count: activated }
        });
        if (id) {
            await setAsyncFilterParams({ [navigation.pages.pallets]: { username: user?.username } });
            history.push(navigation.pages.pallets);
        } else {
            history.push(navigation.pages.activatePallets);
        }
    };

    // const navigateToDeactivateProducts = () => {
    //     history.push(navigation.pages.deactivatePallets);
    // };

    return (
        <div
            className={`h-full w-full flex-grow flex flex-row overflow-hidden rounded-xl bg-theme-gray-main shadow-main gap-4 ${user ? '' : 'items-center justify-center'}`}>
            {user ? (
                <>
                    <UserInfo user={user} saveUserData={saveUserData} saveStatus={updateLoading}/>
                    <div className='flex-grow w-full flex flex-col gap-4 font-sans '>
                        <div className='flex-none flex h-60 gap-x-5'>
                            <div
                                className='flex-grow rounded-xl shadow-main w-full p-7 flex flex-row justify-around items-center'>
                                <StatisticPipe title={t('profile.activate')} minMax={[0, all]} value={activated}
                                               onClick={navigateToActivateProducts}/>
                                {/*{!id && (*/}
                                {/*    <StatisticPipe title={t('profile.deactivate')} minMax={[0, all]} value={deactivated} onClick={navigateToDeactivateProducts}/>*/}
                                {/*)}*/}
                                {/*<StatisticPipe title={'Не активні заявки'} minMax={[0, 4]} value={1}/>*/}
                            </div>
                            <ProfileImage userData={user} onSave={saveUserData} status={updateLoading}/>
                        </div>
                        <div className='flex-grow'>
                            <UserAbout user={user} saveUserData={saveUserData} saveStatus={updateLoading}/>
                        </div>
                    </div>
                </>
            ) : (
                <p className='text-lg uppercase text-gray-300'>{t('profile.notFound')}</p>
            )}
            <LoadingSpinner loading={loaderState}/>
        </div>
    );
};

export default withTranslation()(AccountPage);

