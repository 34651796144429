import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLazyQuery, useSubscription } from '@apollo/client';
import _ from 'lodash';
import { useAuthToken, useGraphqlCache } from '_hooks';
import {
    CREATE_MESSAGE_SUBSCRIPTION, GET_MESSAGES, READ_MESSAGE_SUBSCRIPTION, UPDATE_MESSAGE_SUBSCRIPTION
} from '_graphql';
import { IconArrow1, IconArrowMessage } from 'assets/images/svg';
import ChatMessage from './chat-message';
import ChatHeader from './chat-header';
import { GeneralContext, MessagesContext } from 'contexts';
import MessageForm from './message-form';
import { messenger } from '_constants';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { RemoveMessageModal } from 'components/modals';
import { useSegment } from 'react-segment-hooks';

const ChatMessages = ({ t, closeChat, selectedConversation, show }) => {
    const analytics = useSegment();
    const location = useLocation();
    const { userId } = useAuthToken();
    const { setLoading } = useContext(GeneralContext);
    const { seeConversationAllMessage, updateAllSeenMessagesLoading } = useContext(MessagesContext);
    const { updateMessageInCache, updateMessagesSeenInCache } = useGraphqlCache();

    const [conversation, setConversation] = useState(selectedConversation || null);

    const { data: subsMessageData } = useSubscription(CREATE_MESSAGE_SUBSCRIPTION, {
        variables: {
            userId,
            conversationId: conversation?._id || ' '
        }, shouldResubscribe: !!conversation?._id
    });
    const { data: subsUpdatedMessageData } = useSubscription(UPDATE_MESSAGE_SUBSCRIPTION, {
        variables: {
            userId,
            conversationId: conversation?._id || ' '
        }, shouldResubscribe: !!conversation?._id
    });
    const { data: subsReadMessageData } = useSubscription(READ_MESSAGE_SUBSCRIPTION, {
        variables: {
            userId,
            conversationId: conversation?._id || ' '
        }, shouldResubscribe: !!conversation?._id
    });

    const [getMessages, { data, loading, error }] = useLazyQuery(GET_MESSAGES, { fetchPolicy: 'network-only' });

    const [pagination, setPagination] = useState({
        conversationId: conversation?._id,
        page: 1,
        pageSize: messenger.history.pageSize
    });
    const [chatHistory, setChatHistory] = useState({});
    const [chatCompanions, setChatCompanions] = useState(null);
    const [editMessage, setEditMessage] = useState(null);

    const [scrollBottomButton, setScrollBottomButton] = useState(false);
    const [autoScroll, setAutoScroll] = useState(false);
    const [removeMessageModal, setRemoveMessageModal] = useState({
        display: false,
        message: null,
        conversationId: null
    });

    const refChatBlock = useRef(null);
    const refChatLastBlock = useRef(null);
    let refChatFirstBlock = useRef(null);

    useEffect(() => {
        setLoading((loading && !error));
    }, [loading, error]);

    useEffect(() => {
        if (selectedConversation) {
            setConversation(selectedConversation);

            if (selectedConversation?.members) {
                setChatCompanions(selectedConversation?.members);
            }
        }
    }, [selectedConversation]);

    useEffect(() => {
        const message = subsMessageData?.messageCreated;
        if (message) {
            updateMessageInCache({ message, variables: pagination, options: { add: true } });
        }
    }, [subsMessageData]);

    useEffect(() => {
        const message = subsUpdatedMessageData?.messageUpdated;
        if (message) {
            updateMessageInCache({ message, variables: pagination, options: { change: true } });
        }
    }, [subsUpdatedMessageData]);

    useEffect(() => {
        const conversationId = subsReadMessageData?.readMessages?.conversationId;
        const messagesIds = subsReadMessageData?.readMessages?.messagesIds;
        if (conversationId && messagesIds) {
            updateMessagesSeenInCache(messagesIds, pagination);
        }
    }, [subsReadMessageData]);

    useLayoutEffect(() => {
        setAutoScroll(true);
    }, [location]);

    useLayoutEffect(() => {
        scrollHistoryToBottom();
    }, [chatHistory]);

    useEffect(() => {
        if (pagination.conversationId) {
            getMessages({ variables: pagination, errorPolicy: 'all' });
        }
    }, [pagination]);

    useEffect(() => {
        if (conversation?._id && conversation?._id !== pagination.conversationId) {
            setAutoScroll(true);
            setScrollBottomButton(false);
            setPagination((prev) => ({
                ...prev,
                page: 1,
                conversationId: conversation?._id,
                pageSize: messenger.history.pageSize
            }));

            if (conversation?.unreadMessagesCount > 0 && conversation?.lastMessageId?.conversationId?._id) {
                seeConversationAllMessage(conversation);
            }
        }
    }, [conversation]);

    const listenToScroll = () => {
        const winScroll = refChatBlock.current.scrollTop;
        const height = refChatBlock.current.scrollHeight - refChatBlock.current.clientHeight;

        setScrollBottomButton(height - winScroll > 100);
        setAutoScroll(!(height - winScroll > 100));
    };

    useEffect(() => {
        if (data?.messages?.rows) {
            setChatHistory({ ...data?.messages, rows: _.chain(data?.messages?.rows).sortBy(['createdAt']).value() });
        }

        refChatBlock?.current?.addEventListener('scroll', listenToScroll);

        return () => refChatBlock?.current?.removeEventListener('scroll', listenToScroll);
    }, [data]);

    const scrollHistoryToBottom = (behavior = null, forceScroll = false) => {
        if ((refChatLastBlock?.current && typeof autoScroll === 'boolean' && autoScroll) || forceScroll) {
            refChatLastBlock.current.scrollIntoView({ behavior: behavior || 'auto' });
            return;
        }

        if (typeof autoScroll === 'object') {
            autoScroll.scrollIntoView({ behavior: behavior || 'auto' });
            setAutoScroll(false);
        }
    };

    const chatMouseMove = () => {
        if (chatHistory?.rows?.length > 0) {
            const filteredHistory = chatHistory?.rows.filter((value) => value.userId._id !== userId);
            if (filteredHistory.length > 0 && filteredHistory.some((value) => !value.seen) && !updateAllSeenMessagesLoading) {
                seeConversationAllMessage(conversation);
            }
        }
    };

    const loadOldMessages = () => {
        analytics.track({ event: 'Clicked on "load more messages" button' });
        setAutoScroll(refChatFirstBlock);
        setPagination((prev) => ({ ...prev, pageSize: Number(prev.pageSize) + messenger.history.loadPageSize }));
    };

    const setFirstBlockRef = (element) => {
        if (chatHistory?.total > chatHistory?.pageSize) {
            refChatFirstBlock = element;
        }
    };

    return (
        <>
            <div className='w-full h-full flex flex-col justify-between' onMouseMove={chatMouseMove}>
                <ChatHeader companions={chatCompanions} type={conversation?.type} closeChat={closeChat}
                            hideCloseChat={conversation?.chatClosesUsers?.find((item) => item === userId)}/>
                <div className='relative w-full h-50 flex-grow flex flex-col overflow-hidden justify-end'>
                    <div ref={refChatBlock}
                         className={`w-full flex flex-col ${chatHistory?.rows?.length > 0 ? 'overflow-y-auto scrollable' : 'h-full justify-center items-center'}`}>
                        {chatHistory?.rows?.length > 0 && chatHistory?.total > chatHistory?.pageSize && (
                            <button
                                className='mx-auto rounded-full bg-theme-blue py-2 px-4 text-white flex flex-nowrap items-center gap-x-4 uppercase text-xs'
                                onClick={loadOldMessages}>
                                <IconArrowMessage className={'transform rotate-180'}/>
                                {t('messenger.loadMessages')}
                            </button>
                        )}
                        {chatHistory?.rows?.length > 0 ? chatHistory?.rows?.map((item, index) => (
                            <ChatMessage
                                show={show}
                                item={item}
                                key={'listMessages' + item._id}
                                isAuthor={item?.userId?._id === userId}
                                setFirstBlockRef={(element) => index === 0 ? setFirstBlockRef(element) : {}}
                                setEditMessage={setEditMessage}
                                editMessage={editMessage}
                                setRemoveMessageModal={setRemoveMessageModal}
                                conversationId={chatHistory?.conversationId}
                            />
                        )) : (
                            <p className='text-lg uppercase text-gray-300'>{t('messenger.empty')}</p>
                        )}
                        <div ref={refChatLastBlock}/>
                    </div>
                    {chatHistory?.rows?.length > 0 && (
                        <button
                            onClick={() => scrollHistoryToBottom('smooth', true)}
                            className={`absolute bottom-2 ${scrollBottomButton ? 'right-2 ' : '-right-12'} w-10 h-10 flex justify-center items-center border-theme-blue bg-white rounded-full shadow-main duration-200`}
                        >
                            <IconArrow1/>
                        </button>
                    )}
                </div>
                {/*<div className='w-24 rounded-3xl px-3 py-2 mx-3 bg-theme-blue-main flex flex-row justify-center items-baseline gap-x-2 my-2'>*/}
                {/*    <p className='text-sm text-theme-gray font-normal'>Пише</p>*/}
                {/*    <div className='flex flex-row gap-x-1'>*/}
                {/*        <div className='flex-none animate-fade-first rounded-full w-1 h-1 bg-theme-gray-dark'/>*/}
                {/*        <div className='flex-none animate-fade-second rounded-full w-1 h-1 bg-theme-gray-dark'/>*/}
                {/*        <div className='flex-none animate-fade-third rounded-full w-1 h-1 bg-theme-gray-dark'/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className='flex flex-row duration-100 w-full justify-center items-center gap-x-2 mb-2'>*/}
                {/*    <div*/}
                {/*        className='w-7 h-7 rounded-full bg-theme-blue hover:bg-blue-500 flex items-center justify-center'>*/}
                {/*        <IconArrowMessage/>*/}
                {/*    </div>*/}
                {/*    <p className='text-sm font-normal text-theme-blue'>Нове повідомлення</p>*/}
                {/*</div>*/}
                {conversation && (
                    <MessageForm
                        selectedConversation={conversation}
                        saveMessageToCache={(message, options) => updateMessageInCache({
                            message,
                            variables: pagination,
                            options
                        })}
                        setAutoScroll={setAutoScroll}
                        editMessage={editMessage}
                        setEditMessage={setEditMessage}
                    />
                )}
            </div>
            <RemoveMessageModal
                pagination={pagination}
                isOpen={removeMessageModal.display}
                message={removeMessageModal.message}
                conversationId={removeMessageModal.conversationId}
                onClose={() => setRemoveMessageModal((prev) => ({ ...prev, display: false }))}
            />
        </>
    );
};

ChatMessages.defaultProps = {
    show: {
        chatPanel: false, // true
        showAdaptiveWidthMessage: false // true
    }
};

export default withTranslation()(ChatMessages);
