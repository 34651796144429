const columnName = {
    sequenceNumber: 'sequenceNumber',
    description: 'description',
    updatedAt: 'updatedAt',
    createdAt: 'createdAt',
    username: 'username',
    userImage: 'userImage',
    customerImage: 'customerImage',
    city: 'city',
    location: 'location',
    phones: 'phones',
    productSort: 'productSort',
    productLoad: 'productLoad',
    productSize: 'productSize',
    minCount: 'minCount',
    maxCount: 'maxCount',
    price: 'price',
    isBuy: 'isBuy',
    order: 'order',
    sendMessage: 'sendMessage',
    remove: 'remove',
    quantity: 'quantity',
    owner: 'owner',
    customer: 'customer',
    isActivated: 'isActivated',
    activatedProducts: 'activatedProducts',
    finishOrder: 'finishOrder',
    orderStatus: 'orderStatus',
    unreadMessagesCount: 'unreadMessagesCount',
    count: 'count',
    productPalletType: 'productPalletType',
    productBrands: 'productBrands',
    isCertificate: 'isCertificate',
    paymentType: 'paymentType',
    sendMessageToUser: 'sendMessageToUser',
    lastLoginAt: 'lastLoginAt',
    lastMessage: 'lastMessage',
    isOwnerTransport: 'isOwnerTransport',
    dateLoad: 'dateLoad',
    productType: 'productType',
    documents: 'documents',
    isTax: 'isTax'
}

export {
    columnName
}
