import React, { useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { navigation } from '_constants';
import NonAuth from 'pages/no-auth-pages';
import Pages from 'pages';
import { useAuthToken } from '_hooks';
import { useQuery, useSubscription } from '@apollo/client';
import { cache, GET_FRONT_SETTINGS, UPDATE_FRONT_SETTINGS_SUBSCRIPTION } from '_graphql';

const Routers = () => {
    const { authToken } = useAuthToken();

    const { data } = useQuery(GET_FRONT_SETTINGS);
    const { data: frontSettingsSubs } = useSubscription(UPDATE_FRONT_SETTINGS_SUBSCRIPTION);

    const settings = useMemo(() => data?.frontSettings || {}, [data]);

    useEffect(() => {
        const newSettings = frontSettingsSubs?.frontSettingsUpdated;
        if (newSettings) {
            try {
                cache.writeQuery({ query: GET_FRONT_SETTINGS, data: { frontSettings: newSettings } });
            } catch (e) {
                console.error('Update front settings in cache error: ', e);
            }
        }
    }, [frontSettingsSubs]);

    return (
        <div className='relative'>
            <Switch>
                {/*//for not auth user*/}
                <PrivateRoute access={!authToken} path={Object.values(navigation.nonAuth)} component={NonAuth}
                              settings={settings}/>
                {/*//for auth user*/}
                <PrivateRoute access={!!authToken} path={navigation.pages.app} component={Pages} settings={settings}/>

                <Redirect to={authToken ? navigation.pages.app : navigation.nonAuth.login}/>
            </Switch>
        </div>
    );
};

export const PrivateRoute = ({ component: Component, access = false, settings, ...rest }) => {
    const { authToken } = useAuthToken();
    return (
        <Route {...rest} render={(props) => (
            access ? (
                <Component {...props} settings={settings}/>
            ) : (
                <Redirect to={authToken ? navigation.pages.app : navigation.nonAuth.login}/>
            )
        )}/>
    );
};

export default withTranslation()(Routers);
