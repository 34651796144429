import React from 'react';
import { IconTick } from 'assets/images/svg';

export const CustomCheckbox = ({ text, checked, name, onChange = () => {}, disabled = false }) => (
    <label
        className={`flex flex-row items-center ${disabled ? 'text-theme-blue-light cursor-not-allowed' : 'cursor-pointer'} w-max`}>
        <input className='hidden' type='checkbox' name={name} onChange={onChange} checked={checked}
               disabled={disabled}/>
        {checked ? (
            <IconTick className={'fill-current'}/>
        ) : (
            <div className='w-3.5 h-3.5 border border-theme-blue rounded'/>
        )}
        <p className='ml-3'>{text}</p>
    </label>
);

export const CustomCheckboxSlider = ({
    text,
    checked,
    name,
    register = null,
    isDisabled = false,
    onChange = () => {}
}) => {
    const currentColor = checked ? 'theme-blue' : 'theme-blue-light';
    return (
        <label className={'flex flex-row items-center cursor-pointer w-max'}>
            {register ? (
                <input className='hidden' disabled={isDisabled} type='checkbox' name={name} {...register(name)}/>
            ) : (
                <input className='hidden' disabled={isDisabled} type='checkbox' name={name} onChange={onChange}
                       checked={checked}/>
            )}
            <div
                className={`border rounded-full p-0.5 w-9 flex border-${isDisabled ? 'theme-gray' : currentColor} bg-${isDisabled ? 'theme-gray cursor-not-allowed' : currentColor} duration-100 `}>
                <div
                    className={`w-4 h-4 block bg-white rounded-full transition-all duration-100 ${checked ? 'ml-auto' : 'ml-0'}`}/>
            </div>
            {text && <p className={`ml-2 text-${currentColor} duration-100 `}>{text}</p>}
        </label>
    );
};

export const CustomRadioSlider = (props) => {
    const {
        text = null,
        value = false,
        name = 'radioButton',
        isCustomColor = false,
        register = () => ({}),
        onChange = () => {},
        disabled = false,
        onClick = () => {}
    } = props;

    const customColor = !isCustomColor ? 'blue' : isCustomColor;
    const currentColor = value ? `theme-${customColor}` : `theme-${customColor}-light`;
    const inputProps = { ...register(name), className: 'hidden', type: 'radio', onChange, disabled, value };

    return (
        <label className={`flex flex-row items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} w-max`}
               onClick={onClick}>
            <input {...inputProps} />
            <div
                className={`border rounded-full p-0.5 w-9 flex border-${currentColor} bg-${currentColor} duration-100 `}>
                <div
                    className={`w-4 h-4 block bg-white rounded-full transition-all duration-100 ${!value ? 'ml-auto' : 'ml-0'}`}/>
            </div>
            {text && (<p className={`ml-2 text-${currentColor} duration-100 `}>{text}</p>)}
        </label>
    );
};

