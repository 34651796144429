import {gql} from '@apollo/client';
import {
    TYPE_PRODUCT
} from '_graphql/types';

export const DEACTIVATE_PRODUCT = gql`
    mutation deactivateProduct(
        $_id: ID!
    ){
        deactivateProduct(
            _id: $_id
        ){
            ${TYPE_PRODUCT}
        }
    }
`;
