
import React from 'react';
import { render } from 'react-dom';
import Main from './app/main';
import Modal from 'react-modal';

import './assets/css/main.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/css/test.css';

// import './userback'

const rootEl = document.getElementById('app');
Modal.setAppElement(rootEl)

render(
    <Main />,
    rootEl
);
