import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import BaseModal from '../index';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_PRODUCT_CUSTOMER, GET_PRODUCT } from '_graphql';
import { ImageSlider, LoadingSpinner, ToolTip } from 'components/index';
import { IconCellPhone, IconEmail, IconPallets, IconProfile } from 'assets/images/svg';
import { useSegment } from 'react-segment-hooks';
import { navigation } from '_constants';
import { useAuthToken } from '_hooks';
import { useHistory } from 'react-router-dom';

const ProductDetailsModal = ({ t, modals }) => {
    const analytics = useSegment();
    const { userId } = useAuthToken();
    const history = useHistory();

    const [loaderState, setLoaderState] = useState(true);

    const {
        productDetailsModal: { display: isOpen, _id },
        closeProductDetailsModal
    } = modals;

    const { data, loading, error } = useQuery(GET_PRODUCT, { variables: { _id }, skip: !_id });

    const [createProductCustomer, {
        loading: createCustomerLoading,
        error: createCustomerError
    }] = useMutation(CREATE_PRODUCT_CUSTOMER);

    const productData = useMemo(() => isOpen ? data?.product || {} : {}, [data, isOpen]);

    useEffect(() => {
        if (isOpen) {
            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'Product Details'
                }
            });
        }
    }, [isOpen]);

    useEffect(() => {
        setLoaderState((loading && !error) || (createCustomerLoading && !createCustomerError));
    }, [loading, error, createCustomerLoading, createCustomerError]);

    const orderProduct = async () => {
        analytics.track({
            event: 'Clicked on "Make deal"',
            properties: { modalName: 'Product Details' }
        });

        const dataCustomer = {
            ownerId: productData?.userId?._id,
            productId: productData?._id,
            customerId: userId
        };

        await createProductCustomer({
            variables: { data: dataCustomer },
            update: (cache, { data }) => {
                const _id = data?.createProductCustomer?.productId?._id;
                if (_id) {
                    history.push(`${navigation.pages.pallets}/${_id}`);
                }
            }
        });
        closeProductDetailsModal();
    };

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={closeProductDetailsModal}
            width={'w-detailsModal'}
        >
            <div
                className='w-full text-sm bg-theme-gray-main p-5 font-sans text-theme-blue-light flex-grow min-w-104 flex flex-row gap-4'>
                <div className='flex flex-col flex-grow'>
                    <p className=' font-normal tracking-wide text-lg leading-5 mb-3.5 text-theme-blue'>{t('modals.productDetails.clientInfo')}</p>
                    <div className='flex flex-row justify-between'>
                        <div className='w-full flex flex-row items-center justify-start mb-5 gap-x-4'>
                            <div className='text-xs tracking-wide flex flex-row gap-3 min-h-full items-center'>
                                <div
                                    className='w-10 h-10 rounded-full bg-theme-blue-main flex items-center justify-center'>
                                    {productData?.userId?.avatar?.assetUrl ? (
                                        <div
                                            className='w-10 h-10 rounded-full bg-theme-blue-main flex items-center justify-center'>
                                            <img className='rounded-full w-10 h-10'
                                                 src={productData?.userId?.avatar?.assetUrl}
                                                 alt='userImage'/>
                                        </div>
                                    ) : (
                                        <div
                                            className='w-10 h-10 rounded-full bg-theme-blue-main flex items-center justify-center'>
                                            <IconProfile/>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='text-xs tracking-wide flex flex-col justify-center items-start max-w-50'>
                                <p className='truncate text-center  tracking-wide max-w-50'>{productData?.userId?.username}</p>
                                <a data-tip data-for='location'
                                   className='truncate text-left  tracking-wide max-w-50'>{productData?.userId?.location?.name || '-'}</a>
                                <ToolTip id={'location'} title={productData?.userId?.location?.name || '-'}/>
                            </div>
                        </div>
                        <div className='min-w-60 max-w-60 text-xs tracking-wide flex flex-col justify-start'>
                            <div className='flex flex-row items-center gap-x-2 '>
                                <IconEmail className='stroke-current'/>
                                <p data-tip data-for='email'
                                   className='truncate  tracking-wide max-w-45'>{productData?.userId?.email || '-'}</p>
                                <ToolTip id={'email'} title={productData?.userId?.email || '-'}/>
                            </div>
                            <div className='flex flex-row items-center gap-x-2'>
                                <IconCellPhone className='stroke-current mx-0.5'/>
                                <p className=' tracking-wide max-w-45'>{productData?.userId?.phone || '-'}</p>
                            </div>
                        </div>
                    </div>
                    <p className=' font-normal tracking-wide text-lg leading-5 mb-3.5 text-theme-blue'>{t('modals.productDetails.productInfo')}</p>
                    <div className='flex flex-row w-full items-center mb-1'>
                        <p className='min-w-35 truncate  text-theme-blue'>{t('modals.productDetails.address')}</p>
                        <p data-tip data-for='address'
                           className='flex-grow truncate text-theme-blue-light '>{productData?.city?.name} / {productData?.streetName} / {productData?.houseNumber}</p>
                        <ToolTip id={'address'}
                                 title={`${productData?.city?.name} / ${productData?.streetName} / ${productData?.houseNumber}`}/>
                    </div>
                    <div className='flex flex-row justify-between mb-3 gap-2 border-b border-theme-blue-light pb-3'>
                        <div className='w-max flex flex-col gap-1'>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.requestType')}</p>
                                <p className='text-theme-blue-light '>{productData?.isBuy ? t('modals.productDetails.buy') : t('modals.productDetails.sell')}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.taraType')}</p>
                                <p className='text-theme-blue-light '>{t(`modals.productDetails.taraTypeOptions.${productData?.productType?.value}`)}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.palletType')}</p>
                                <p className='text-theme-blue-light '>{!productData?.productType?.isPalletType ? '-' : productData?.customPalletType?.name || productData?.productPalletType?.name}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.taraSort')}</p>
                                <p className='text-theme-blue-light '>
                                    {productData?.productSort?.name?.toLowerCase() === 'higher' ?
                                        t('global.productOptions.sortHigher') :
                                        productData?.productSort?.name}
                                </p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.price')}</p>
                                <p className='text-theme-blue-light '><span
                                    className='mr-2'>{productData?.price || 0}</span> {t('modals.productDetails.priceCurrency')}
                                </p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.paymentType')}</p>
                                <p className='text-theme-blue-light '>{productData?.paymentType?.name ? t(`modals.newTaraModal.${productData?.paymentType?.name.toLowerCase()}`) : '-'}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.tax.label')}</p>
                                <p className='text-theme-blue-light '>{t(`modals.productDetails.tax.${productData?.isTax ? 'withTax' : 'noTax'}`)}</p>
                            </div>
                            {/*<div className='flex flex-row w-full items-center'>
                            <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.number')}</p>
                            <p className='text-theme-blue-light '>{productData?.count || 0}</p>
                        </div>*/}
                        </div>
                        <div className='min-w-60 max-w-60 flex flex-col gap-1'>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.taraSize')}</p>
                                <p className='text-theme-blue-light  flex-grow flex-wrap'>{productData?.customSize ? productData.customSize : productData?.productSize?.name || '-'}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.taraLoad')}</p>
                                <p className='text-theme-blue-light '>{productData?.productType?.value !== '1' ? '-' : productData?.productLoad?.name}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.min')}</p>
                                <p className='text-theme-blue-light '>{productData?.minCount}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.max')}</p>
                                <p className='text-theme-blue-light '>{productData?.maxCount}</p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='w-35 flex-none truncate  text-theme-blue'>{t('modals.productDetails.brand')}</p>
                                <p className='text-theme-blue-light  flex-grow flex-wrap'>{!productData?.productType?.isBrand ? '-' : productData?.productBrands?.length ? productData?.productBrands?.map((item) => item.name).join(', ') : t('modals.productDetails.noCertificate')}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.certificate')}</p>
                                <p className='text-theme-blue-light '>{!productData?.productType?.isCertificate ? '-' : t(`modals.productDetails.${productData?.isCertificate ? 'withCertificate' : 'noCertificate'}`)}</p>
                            </div>
                        </div>
                    </div>
                    {productData?.description && (
                        <p className='w-full border-b-2 border-theme-blue-light-1 py-2 mb-2 break-words'>{productData?.description}</p>)}
                    <div className='w-full flex justify-end'>
                        <button
                            className={`py-2.5 px-7 rounded-full duration-100 ${productData?.isBuy ? 'text-theme-blue bg-theme-blue-main hover:bg-gray-200' : 'text-white bg-theme-blue hover:bg-blue-400'}`}
                            onClick={orderProduct}
                        >
                            {t('modals.productDetails.order')}
                        </button>
                    </div>
                </div>

                <div className='bg-theme-gray-light rounded-2xl w-1/2 content-center overflow-hidden p-2'>
                    <ImageSlider
                        images={productData?.assets?.map(({ _id, assetUrl }) => ({ _id, image: assetUrl })) || []}
                        noImageIcon={<IconPallets/>}
                        onlyView={true}
                    />
                </div>
            </div>
            <LoadingSpinner loading={loaderState}/>
        </BaseModal>
    );
};

ProductDetailsModal.defaultProps = {
    isOpen: false,
    onClose: () => {},
    _id: null
};

export default withTranslation()(ProductDetailsModal);
