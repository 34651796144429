import {gql} from '@apollo/client';
import {
    TYPE_PRODUCT
} from '_graphql/types';

export const CREATE_PRODUCT = gql`
    mutation createProduct(
        $data: ProductInput
    ){
        createProduct(
            data: $data
        ){
            ${TYPE_PRODUCT}
        }
    }
`;
