import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSegment } from 'react-segment-hooks';
import { Table, columnName } from 'components';
import { useLazyQuery, useSubscription } from '@apollo/client';
import {
    CREATE_PRODUCT_SUBSCRIPTION, DELETE_PRODUCT_SUBSCRIPTION, GET_PRODUCT_LIST, UPDATE_PRODUCT_SUBSCRIPTION
} from '_graphql';
import { FilterContext, GeneralContext } from 'contexts';
import { useAuthToken, useGraphqlCache } from '_hooks';

const AllPalletPage = () => {
    const analytics = useSegment();
    const { userId } = useAuthToken();
    const { updateProductListInCache } = useGraphqlCache();
    const { tablePageSize } = useContext(GeneralContext);
    const { parseFilterParams } = useContext(FilterContext);

    const [getProductList, { data, loading, error }] = useLazyQuery(GET_PRODUCT_LIST, { fetchPolicy: 'network-only' });

    const { data: subsProductDeleted } = useSubscription(DELETE_PRODUCT_SUBSCRIPTION);
    const { data: subsProductUpdate } = useSubscription(UPDATE_PRODUCT_SUBSCRIPTION);
    const { data: subsProductCreate } = useSubscription(CREATE_PRODUCT_SUBSCRIPTION);

    const [pagination, setPagination] = useState({ page: 1, pageSize: tablePageSize });

    const products = useMemo(() => data?.products ? data?.products : { rows: [], totalPages: 0 }, [data]);

    useEffect(() => {
        analytics.page({ name: 'All requests Page' });
    }, []);

    useEffect(() => {
        const product = subsProductDeleted?.productDeleted;
        if (product && products?.rows?.some((item) => item._id === product._id)) {
            updateProductListInCache({
                product,
                options: { remove: true },
                variables: { ...pagination, ...parseFilterParams }
            });
        }
    }, [subsProductDeleted]);

    useEffect(() => {
        const product = subsProductUpdate?.productUpdated;
        if (product) {
            if (products?.rows?.some((item) => item._id === product._id)) {
                const options = product.isActivated ? { change: true } : { remove: true };
                updateProductListInCache({ product, options, variables: { ...pagination, ...parseFilterParams } });
            } else if (product.isActivated) {
                updateProductListInCache({
                    product,
                    options: { add: true },
                    variables: { ...pagination, ...parseFilterParams }
                });
            }
        }
    }, [subsProductUpdate]);

    useEffect(() => {
        const product = subsProductCreate?.productCreated;
        if (product) {
            updateProductListInCache({
                product,
                options: { add: true },
                variables: { ...pagination, ...parseFilterParams }
            });
        }
    }, [subsProductCreate]);

    useLayoutEffect(() => {
        let variables = pagination;
        if (parseFilterParams && Object.keys(parseFilterParams).length > 0) {
            variables = {
                isActivate: true,
                ...variables,
                ...parseFilterParams
            };
            analytics.track({ event: 'Set Filter params in all pallets page', properties: { data: variables } });
        }

        getProductList({ variables });
    }, [pagination, parseFilterParams]);

    return (
        <div className='flex-grow flex flex-row gap-x-5 h-full'>
            <Table
                tableName={'allRequest'}
                products={products}
                setPagination={setPagination}
                loader={loading && !error}
                show={{
                    columns: [
                        { name: columnName.sequenceNumber, defaultShow: true, settingDisabled: true },
                        columnName.updatedAt,
                        columnName.username,
                        columnName.city,
                        columnName.isBuy,
                        columnName.productType,
                        columnName.productPalletType,
                        columnName.productSort,
                        columnName.productSize,
                        columnName.productLoad,
                        columnName.minCount,
                        columnName.maxCount,
                        columnName.price,
                        columnName.isTax,
                        columnName.paymentType,
                        columnName.productBrands,
                        columnName.isCertificate,
                        columnName.description
                        // columnName.sendMessage
                    ],
                    tabletSort: [
                        columnName.updatedAt,
                        columnName.username,
                        columnName.city,
                        columnName.productType,
                        columnName.productPalletType,
                        columnName.productSort,
                        columnName.productSize,
                        columnName.productLoad,
                        columnName.minCount,
                        columnName.maxCount,
                        columnName.price,
                        columnName.isTax,
                        columnName.paymentType,
                        columnName.productBrands,
                        columnName.isCertificate,
                        columnName.description,
                        columnName.isBuy
                    ],
                    activeRows: [{ value: (item) => item?.userId?._id === userId }],
                    downloadExcelFile: true
                }}
                hide={{
                    createAtDate: true
                }}
                change={{
                    isActive: false,
                    filterVariables: { ...pagination, pageSize: 10000 }
                }}
            />
        </div>
    );
};

export default AllPalletPage;
