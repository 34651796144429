import React, {useMemo} from 'react';
import {withTranslation} from 'react-i18next';
import {useOutsideClick} from '_hooks';
import {tableConstants} from '_constants';

const PalletRow = (
    {
        t,
        hide: {
            handleClickRow: hideHandleClickRow
        },
        show: {
            activeRows
        },
        change: {
            noContentMessage: changeNoContentMessage
        },
        tableSettings: {
            hideColumns
        },
        setRef,
        products,
        handleClickRow,
        titleFilterPanels,
        activeTableColumns,
        closeActiveFilterPanel
    }) => {
    const cityFilters = [...document.querySelectorAll('.pac-container')].map((el) => ({ current: el }));
    const tableThead = useOutsideClick(() => closeActiveFilterPanel(null), [...Object.values(titleFilterPanels), ...cityFilters]);

    const numeration = useMemo(() => (products?.page > 1 ? (products?.page - 1) * products?.pageSize : 0) + 1, [products])

    const getRowProps = (item) => {
        let className = 'px-2 text-xs font-normal text-black items-center duration-100 hover:shadow w-full'
        let hideClickRow = hideHandleClickRow
        let addedClasses = false
        let onClick = () => { }
        const style = { height: tableConstants.default.rowHeight }

        if (activeRows && Array.isArray(activeRows)) {
            activeRows.forEach(({ value, className: classes, hideClick }) => {
                if (!addedClasses && typeof value === 'function' && value(item)) {
                    className += ' ' + (classes ? classes : tableConstants.default.ActiveRowClassName)
                    hideClickRow = !!hideClick
                    addedClasses = true
                }
            })
        }

        if (!hideClickRow) {
            className += ' cursor-pointer hover:bg-theme-blue-main hover:text-theme-blue hover:border-theme-blue'
            onClick = () => handleClickRow(item)
        }

        return { onClick, className, style }
    }

    return (
        <>
            <table className='min-w-full'>
                <thead ref={tableThead}>
                    <tr ref={(element) => setRef(element, 'title')} className='align-middle'>
                        {activeTableColumns.map((item) => hideColumns?.includes(item.name) ? null : item.thead.call(item))}
                    </tr>
                </thead>
                <tbody className='h-full overflow-y-auto scrollable'>
                    {products?.rows?.length > 0 && products.rows.map((item, index) => (
                        <tr key={item._id} {...getRowProps(item)} >
                            {activeTableColumns.map((row) => hideColumns?.includes(row.name) ? null : row.tbody.call(row, item, numeration + index))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {products?.rows?.length <= 0 && (
                <div className='flex justify-center items-center h-full w-full'>
                    <p className='text-lg uppercase text-gray-300 text-center'>{changeNoContentMessage && typeof changeNoContentMessage === 'string' ? changeNoContentMessage : t('table.noData')}</p>
                </div>
            )}
        </>
    )
}

export default withTranslation()(PalletRow);
