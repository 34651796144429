import React, { useContext, useEffect } from 'react';
import BaseModal from '../index';
import { useMutation } from '@apollo/client';
import { useGraphqlCache } from '_hooks';
import { REMOVE_PRODUCT } from '_graphql';
import { GeneralContext } from 'contexts';
import { useSegment } from 'react-segment-hooks';

const RemoveProductModal = ({ isOpen, onClose, productId, onFinally = null, pagination = {}, redirect = () => {} }) => {
    const analytics = useSegment();
    const { setLoading, toggleSegmentButton } = useContext(GeneralContext);
    const { updateProductListInCache } = useGraphqlCache();

    const [removeProduct, { loading, error }] = useMutation(REMOVE_PRODUCT);

    useEffect(() => {
        if (isOpen) {
            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'Remove Product'
                }
            });
        }
    }, [isOpen]);

    useEffect(() => {
        setLoading(loading && !error);
    }, [loading, error]);

    const applyRemoveProduct = () => {
        toggleSegmentButton('Remove', 'Remove Product');
        removeProduct({
            variables: { _id: productId },
            update: (cache, { data }) => {
                const product = data?.removeProduct;

                if (product) {
                    updateProductListInCache({
                        product,
                        variables: pagination,
                        options: { remove: true },
                        success: redirect()
                    });
                }
            }
        }).finally(() => {
            onFinally ? onFinally : onClose();
        });
    };

    return (
        <BaseModal onClose={onClose} isOpen={isOpen}>
            <div className='sm:max-w-xs py-9 text-gray-900 mx-auto text-center'>
                <p className='font-bold w-full text-xl uppercase'>Remove product</p>
                <p className='px-2'>Are you sure you want to remove this product?</p>
                <div className='flex justify-center mt-4'>
                    <button
                        className='w-32 py-2 self-stretch rounded mr-5 bg-transparent border  hover:text-gray-600 hover:bg-gray-200'
                        onClick={() => {
                            onClose();
                            toggleSegmentButton('Cancel', 'Remove Product');
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className='w-32 py-2 self-stretch text-gray-900 rounded cursor-pointer hover: text-gray-600'
                        onClick={applyRemoveProduct}
                    >
                        Remove
                    </button>
                </div>
            </div>
        </BaseModal>
    );
};
export default RemoveProductModal;
