import { gql } from '@apollo/client';
import { TYPE_USER } from '_graphql/types';

export const GET_USERS = gql`
    query users(
        $userId: ID
        $city: String
        $username: String
        $createdAt: DateInput
        $updatedAt: DateInput
        $page: Int!
        $pageSize: Int
        $sort: SortInput
        $onlyActive: Boolean
        $role: Role
    ){
        users(
            userId: $userId
            location: $city
            username: $username
            createdAt: $createdAt
            modifiedAt: $updatedAt
            page: $page
            pageSize: $pageSize
            sort: $sort
            onlyActive: $onlyActive
            role: $role
        ) {
            rows {
                ${TYPE_USER}
            }
            total
            page
            pageSize
            totalPages
        }
    }
`;
