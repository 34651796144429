import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconArrow5} from 'assets/images/svg';

export default ({
                    t,
                    onChangeSort,
                    checkSortField
                }) => ({
    name: columnName.count,
    title: t('table.header.quantity'),
    excelValue: (item) => item?.count || '-',
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1 align-top'}>
                <button className='activeBtn flex flex-col items-center cursor-pointer justify-center'
                        onClick={(e) => onChangeSort(e, this.name)}>
                    <p className='whitespace-nowrap font-medium text-xs text-black'>{this.title}</p>
                    <IconArrow5
                        className={`h-2.5 w-2.5 fill-current text-theme-blue ${checkSortField(this.name)} ml-1`}/>
                </button>
            </th>
        );
    },
    tbody: function ({ count, _id }) {
        return (
            <td key={this.name + _id} className={'whitespace-nowrap px-1'}>
                <p className='truncate max-w-24 text-black'>
                    {count || '-'}
                </p>
            </td>
        );
    },
    tabletTitle: function ({ _id }) {
        return (
            <p key={`title_${this.name}${_id}`} className='font-medium'>{this.title}</p>
        );
    },
    tabletContent: function ({ _id, count }) {
        return (
            <p key={`content_${this.name}${_id}`}
               className='w-3/5 truncate'>{count || '-'}</p>
        );
    }
})
