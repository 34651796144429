export const messenger = {
    types: {
        public: 'public',
        private: 'private'
    },
    history: {
        pageSize: 40,
        loadPageSize: 40,
        timeForEditMessage: 31
    },
    conversations: {
        pageSize: 1000
    }
}
