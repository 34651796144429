import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RemoveProductModal, Table, columnName } from 'components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ACTIVATE_PRODUCT, GET_PRODUCT_LIST } from '_graphql';
import { FilterContext, GeneralContext } from 'contexts';
import { useAuthToken, useGraphqlCache } from '_hooks';
import { navigation } from '_constants';
import { useHistory } from 'react-router-dom';
import { useSegment } from 'react-segment-hooks';

const DeactivatePalletsPage = () => {
    const { tablePageSize } = useContext(GeneralContext);
    const { parseFilterParams } = useContext(FilterContext);
    const analytics = useSegment();
    const { updateProductListInCache } = useGraphqlCache();

    const { userId } = useAuthToken();
    const history = useHistory();

    const [activateProduct, { loading: activateLoading, error: activateError }] = useMutation(ACTIVATE_PRODUCT);

    const [pagination, setPagination] = useState({ page: 1, userId, isActivate: false, pageSize: tablePageSize });
    const [removeProductModal, setRemoveProductModal] = useState({ display: false, _id: null });

    const [getProductList, { data, loading, error }] = useLazyQuery(GET_PRODUCT_LIST, { fetchPolicy: 'network-only' });

    const products = useMemo(() => data?.products ? data?.products : { rows: [], totalPages: 0 }, [data]);

    useEffect(() => {
        analytics.page({ name: 'Archive Requests Page' });
    }, []);

    useEffect(() => {
        let variables = pagination;
        if (parseFilterParams && Object.keys(parseFilterParams).length > 0) {
            variables = { ...variables, ...parseFilterParams };
            analytics.track({
                event: 'Set Filter params in archive suggestions pallets page',
                properties: { data: variables }
            });
        }

        getProductList({ variables });
    }, [pagination, parseFilterParams]);

    const onRemoveProduct = (_id) => {
        setRemoveProductModal({ display: true, _id });
    };

    const handleClickRow = (item) => {
        history.push(`${navigation.pages.pallets}/${item._id}`);
    };

    const onToggleProductActivate = (item) => activateProduct({
        variables: { _id: item._id }, update: (cache, result) => {
            updateProductListInCache({
                product: result?.data?.activateProduct,
                variables: { ...pagination, ...parseFilterParams },
                options: { change: true }
            });
        }
    });

    return (
        <div className='flex-grow flex flex-row gap-x-5 h-full'>
            <Table
                tableName={'archiveProposals'}
                products={products}
                setPagination={setPagination}
                loader={(loading && !error) || (activateLoading && !activateError)}
                show={{
                    columns: [
                        { name: columnName.sequenceNumber, defaultShow: true, settingDisabled: true },
                        columnName.updatedAt,
                        columnName.city,
                        columnName.isBuy,
                        columnName.productType,
                        columnName.productPalletType,
                        columnName.productSort,
                        columnName.productSize,
                        columnName.productLoad,
                        columnName.minCount,
                        columnName.maxCount,
                        columnName.price,
                        columnName.isTax,
                        columnName.paymentType,
                        columnName.productBrands,
                        columnName.isCertificate,
                        columnName.description,
                        columnName.isActivated,
                        columnName.remove
                    ],
                    tabletSort: [
                        columnName.sequenceNumber,
                        columnName.updatedAt,
                        columnName.city,
                        columnName.productPalletType,
                        columnName.productSort,
                        columnName.productSize,
                        columnName.productLoad,
                        columnName.minCount,
                        columnName.maxCount,
                        columnName.price,
                        columnName.isTax,
                        columnName.paymentType,
                        columnName.productBrands,
                        columnName.isCertificate,
                        columnName.description,
                        columnName.isBuy,
                        columnName.isActivated,
                        columnName.remove
                    ],
                    // activeRows: { value: (item) => item?.isActivated, color: 'bg-theme-blue-light-2' }
                    downloadExcelFile: true
                }}
                change={{
                    onRemoveProduct,
                    handleClickRow,
                    onToggleProductActivate
                }}
            />
            <RemoveProductModal
                isOpen={removeProductModal.display}
                onClose={() => setRemoveProductModal({ display: false, _id: null })}
                productId={removeProductModal._id}
                pagination={pagination}
            />
        </div>
    );
};

export default DeactivatePalletsPage;
