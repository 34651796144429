import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useSegment } from 'react-segment-hooks';

const UserAbout = ({ t, user, saveUserData, saveStatus }) => {
    const { id } = useParams();
    const analytics = useSegment();

    const [userData, setUserData] = useState(null);
    const [isEditing, setIsEditing] = useState(true);

    useEffect(() => {
        setData();
    }, [user]);

    useEffect(() => {
        if (!saveStatus) {
            setIsEditing(false);
        }
    }, [saveStatus]);

    const changeDescription = (type, value) => {
        setUserData((prev) => ({ ...prev, [type]: value }));
    };

    const setData = () => {
        setUserData({
            userDesc: user?.description?.userDesc,
            companyDesc: user?.description?.companyDesc
        });
    };

    const saveData = () => {
        const newData = {
            description: {
                ...userData
            }
        };
        const dataType = 'about info';
        saveUserData(newData, {}, dataType);
    };

    const handleEdit = () => {
        setIsEditing(true);
        analytics.track({ event: 'Clicked on "edit user about info"' });
    };

    const cancelEditing = () => {
        setIsEditing(false);
        setData();
    };

    return (
        <div
            className='h-full rounded-xl shadow-main w-full px-7 pt-10 pb-4 tracking-wide justify-between items-start flex flex-col overflow-hidden'>
            <ul className='list-disc text-theme-blue flex flex-col w-full h-full gap-4 mb-4 scrollable overflow-y-auto'>
                <li className='w-full flex-grow h-full flex flex-col'>
                    <p className=''>{t('profile.about')}</p>
                    {isEditing ? (
                        <textarea
                            cols='30'
                            rows='2'
                            className='border-b-2 bg-transparent border-theme-blue bg-theme-blue-light-2 rounded-t-xl px-2 py-1 w-full h-full'
                            onChange={(e) => changeDescription('userDesc', e.target.value)}
                            value={userData?.userDesc}
                        />
                    ) : (
                        <div className='text-black mt-3'>
                            {userData?.userDesc?.split('\n').map((str, i) => (
                                <p key={i + 'p'} className='min-h-6'>{str}</p>))}
                        </div>
                    )}
                </li>
                <li className='w-full flex-grow h-full flex flex-col'>
                    <p className=''>{t('profile.aboutCompany')}</p>
                    {isEditing ? (
                        <textarea
                            cols='30'
                            rows='2'
                            className='border-b-2 bg-transparent border-theme-blue bg-theme-blue-light-2 rounded-t-xl px-2 py-1 w-full h-full'
                            onChange={(e) => changeDescription('companyDesc', e.target.value)}
                            value={userData?.companyDesc}
                        />
                    ) : (
                        <div className='text-black mt-3'>
                            {userData?.companyDesc?.split('\n').map((str, i) => (
                                <p key={i + 'p'} className='min-h-6'>{str}</p>))}
                        </div>
                    )}
                </li>
            </ul>
            {!id && (
                <div className='flex flex-row w-full justify-end gap-5'>
                    {isEditing ? (
                        <>
                            <button
                                className='w-24 h-7 rounded-3xl bg-theme-blue text-white text-xs'
                                onClick={cancelEditing}
                            >
                                {t('profile.cancel')}
                            </button>
                            <button
                                className='w-24 h-7 rounded-3xl bg-theme-blue text-white text-xs'
                                onClick={saveData}
                            >
                                {t('profile.save')}
                            </button>
                        </>
                    ) : (
                        <button
                            className='w-24 h-7 rounded-3xl bg-theme-blue text-white text-xs'
                            onClick={handleEdit}
                        >
                            {t('profile.edit')}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};
export default withTranslation()(UserAbout);
