import {gql} from '@apollo/client';
import {TYPE_PAYMENT_TYPE} from '_graphql/types';

export const GET_PAYMENT_TYPE_LIST = gql`
    query paymentTypes(
        $page: Int!
        $pageSize: Int
        $sort: String
        $search: String
        $searchFields: String
    ){
        paymentTypes(
            page: $page
            pageSize: $pageSize
            sort: $sort
            search: $search
            searchFields: $searchFields
        ) {
            rows {
                ${TYPE_PAYMENT_TYPE}
            }
        }
    }
`;
