export const checkEmailUtils = (email) => {
    if (typeof email !== 'string') {
        return;
    }

    const test = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return Boolean(test.test(email.toLowerCase()))
}

export const checkPhoneUtils = (phone) => {
    if (typeof phone !== 'string') {
        return;
    }

    const test = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\\./0-9]*$/g;

    return Boolean(test.test(phone.toLowerCase()))
}

export const checkStringUtils = (string) => {
    if (typeof string !== 'string' || string.trim() === '') {
        return false;
    }

    return !!string
}

export const checkHouseNumberUtil = (number) => {
    if (!number) {
        return false
    }
    const test = /^\d{1,5}[A-Za-zА-Яа-я]{0,3}$/g;

    return Boolean(test.test(number.toLowerCase()))
}

export const checkUsernameUtil = (username) => {
    if (!username) {
        return false
    }
    const test = /[A-Za-z]{0,20}$/g;

    return Boolean(test.test(username.toLowerCase()))
}

export const checkNumberUtil = (number) => {
    if (!number) {
        return false
    }
    const test = /^\d+$/;

    return Boolean(test.test(number))
}

export const checkCityUtils = (props) => props && ((props.name && checkStringUtils(props.name)) || (props.placeId && checkStringUtils(props.placeId)))
