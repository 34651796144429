import React from 'react';
import { withTranslation } from 'react-i18next';
import { ToolTip } from 'components';

const UploadFileButton = ({
    t,
    disabled = false,
    onChange,
    placeToolTip = 'top',
    typeFilter = 'image/*',
    ...restData
}) => (
    <>
        <label
            data-tip data-for={'uploadFileBtn'}
            className={`flex-grow-0 bg-theme-blue-main ${disabled ? 'bg-opacity-50 text-theme-blue-main' : 'hover:bg-theme-gray-main cursor-pointer'} rounded-3xl mx-auto max-w-max block duration-100 ${restData?.className?.length ? restData?.className : 'px-7 py-3 text-sm'}`}
        >
            {t('global.buttons.chooseFile')}
            <input
                type='file'
                name='file'
                className='hidden'
                disabled={disabled}
                onChange={onChange}
                accept={typeFilter}
                multiple
            />
        </label>
        <ToolTip id={'uploadFileBtn'}
                 title={disabled ? t('global.buttons.tooltip.maxFiles') : t('global.buttons.tooltip.maxSize')}
                 place={placeToolTip}/>
    </>
);

export default withTranslation()(UploadFileButton);
