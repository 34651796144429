import React from 'react';
import Loader from 'react-loader-spinner';
import { CSSTransition } from 'react-transition-group';

export const LoadingSpinner = ({ loading }) => (
        <CSSTransition
            classNames='spinner'
            in={loading}
            unmountOnExit
            timeout={300}
        >
            {/*<div className={'absolute top-0 left-0 w-full z-100'}>*/}
            <>
                <div className='fixed inset-0'>
                    <div className='absolute inset-0 bg-gray-200 opacity-75'></div>
                </div>
                <div className='fixed inset-x-1/2 inset-y-1/2 -m-16'>
                    <Loader
                        type='Rings'
                        color='#434190'
                        height={100}
                        width={100}
                    />
                </div>
            </>
            {/*</div>*/}
        </CSSTransition>
    );
