import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconSend} from 'assets/images/svg';

export default ({ t, userId, sendMessage }) => ({
    name: columnName.sendMessage,
    title: t('table.header.btnSendMessage'),
    excelValue: null,
    thead: function () {
        return (
            <th key={this.name}/>
        );
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'w-1 whitespace-nowrap px-1 text-center'}>
                {userId !== item?.userId?._id && (
                    <button
                        className='cursor-pointer h-10 w-10 flex items-center justify-center rounded-full bg-theme-blue-main hover:bg-gray-200'
                        onClick={(e) => sendMessage(e, item)}
                    >
                        <IconSend/>
                    </button>
                )}
            </td>
        );
    },
    tabletTitle: () => null,
    tabletContent: function (item) {
        return (
            <button
                key={`content_${this.name}${item._id}`}
                className='cursor-pointer h-10 w-10 flex-none flex items-center justify-center hover:text-theme-blue-light text-theme-blue'
                onClick={(e) => sendMessage(e, item)}
            >
                <IconSend className='stroke-current'/>
            </button>
        );
    }
})
