import {useEffect, useRef} from 'react';

const useOutsideClick = (callback, additionalRefList = []) => {
    const ref = useRef();

    const handleClick = (event) => {
        if (![ref, ...additionalRefList].some(({ current }) => !current || current.contains(event.target))) {
            callback(event);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('touchstart', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('touchstart', handleClick);
        };
    });

    return ref;
};

export default useOutsideClick;
