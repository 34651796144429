import React, { useEffect, useState } from 'react';
import { IconArrow4, IconDoubleArrowRight, IconEmail } from 'assets/images/svg';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { navigation } from '_constants';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD_MUTATION } from '_graphql/mutations/user/forgotPasssword';
import Swal from 'sweetalert2';
import { useSegment } from 'react-segment-hooks';
import { LoadingSpinner } from '../../../components';

const ResetPasswordPage = ({ t }) => {
    const history = useHistory();
    const analytics = useSegment();

    const [loaderState, setLoaderState] = useState(false);

    const schema = yup.object({
        email: yup.string().email(t('reset.errorValidateEmail')).required(t('reset.email'))

    });

    const [forgotPassword, { error, data, loading }] = useMutation(FORGOT_PASSWORD_MUTATION);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        setLoaderState(loading && !error);
    }, [loading, error]);

    useEffect(() => {
        analytics.page({ name: 'Forgot password Page' });
    }, []);

    useEffect(() => {
        if (error) {
            return showMessage(true);
        }
        if (data) {
            showMessage(false);
        }
    }, [error, data]);

    const onSubmit = (formValue) => {
        forgotPassword({
            variables: { ...formValue }
        }).catch(() => {});
    };
    const showMessage = (isError) => {
        Swal.fire({
            title: isError ? t('reset.errorMessage') : t('reset.successTitle'),
            text: isError ? error?.message : t('reset.successMessage'),
            icon: isError ? 'error' : 'success',
            confirmButtonText: 'Ok'
        }).then(() => {
            if (!error) {
                history.push(navigation.nonAuth.login);
            }
        });
    };

    const onTypeEmail = (e) => {
        const { value } = e.target;
        analytics.track({ event: 'Typed text in the "email" input', properties: { email: value } });
    };

    const clickedForgotPassword = () => {
        analytics.track({ event: 'Clicked on “ForgotPassword” button' });
    };

    const clickedGoBack = () => {
        analytics.track({ event: 'Clicked on “Back ” button' });
    };

    return (
        <>
            <div className='h-full w-full flex flex-col justify-center items-center gap-y-5'>
                <p className='text-white text-xl text-center font-normal mb-10'>{t('reset.title')}</p>
                <form className='flex flex-col gap-y-5 max-w-sm w-full' onSubmit={handleSubmit(onSubmit)}>
                    <div className='relative'>
                        <label
                        htmlFor='email'
                            className='cursor-pointer z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'><IconEmail/></label>
                        <input
                            {...register('email')}
                            className={`pl-12 pr-5 h-11 text-xs text-theme-gray rounded-full cursor-pointer w-full duration-100 hover:bg-gray-100 ${errors.email ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                            id='email'
                            name='email'
                            type='email'
                            autoComplete='email'
                            placeholder={t('reset.email')}
                            onChange={onTypeEmail}
                        />
                        <p className='h-5 text-theme-red-main text-sm font-semibold pl-5'>{errors.email?.message}</p>
                    </div>
                    <div className='relative login-hover'>
                        <button
                            className='text-white flex flex-row items-center w-full h-11 rounded-full border-2 border-white text-center bg-transparent text-base font-normal duration-200 cursor-pointer justify-center'
                            type='submit' onClick={clickedForgotPassword}>
                            <span
                                className='z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'>
                                <IconArrow4 className='fill-current text-white'/>
                            </span>
                            <span className='duration-200 pl-2'>{t('reset.reset')}</span>
                            <IconDoubleArrowRight className='duration-200 login-icon ml-2 w-0' />
                        </button>
                    </div>
                </form>
                    <Link
                        className='inline-link relative font-normal text-white text-sm text-center cursor-pointer duration-100 hover:text-white'
                        to={navigation.nonAuth.login}
                        onClick={clickedGoBack}
                    >
                        <p>{t('reset.goBack')}</p>
                        <span className='duration-200 absolute w-0 h-px bottom-0 left-0 bg-theme-blue' />
                    </Link>
            </div>
            <LoadingSpinner loading={loaderState}/>
        </>
    );
};
export default withTranslation()(ResetPasswordPage);

