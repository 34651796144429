import React, {useMemo} from 'react';
import {withTranslation} from 'react-i18next';
import ReactExport from 'react-data-export';
import {formatDateExcelUtils} from 'utils';

const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelFileDownload = ({ button, data = [], activeTableColumns, tableSettings }) => {
    const { hideColumns } = tableSettings

    const columns = useMemo(() => activeTableColumns.filter((row) => !hideColumns?.includes(row.name)), [hideColumns, activeTableColumns])

    return (
        <ExcelFile element={button} filename={'TaraOnlineTable' + formatDateExcelUtils(new Date()).replace(' ', '_')}>
            <ExcelSheet data={data?.rows} name={formatDateExcelUtils(new Date())}>
                {columns.map((row) => (<ExcelColumn key={'excel' + row.name} label={row.title} value={(item) => row.excelValue(item)}/>))}
            </ExcelSheet>
        </ExcelFile>
    )
}

export default withTranslation()(ExcelFileDownload)
