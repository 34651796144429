import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconArrow5, IconClose, IconFilterTitle} from 'assets/images/svg';
import {CustomCheckbox} from 'components/common';

export default ({
                    t,
                    onChangeSort,
                    checkSortField,
                    filterParams,
                    onOpenFilterPanel,
                    setRefTitle,
                    activeFilterPanel,
                    clearFilterParams,
                    applyFilterParams,
                    onChangeCheckBoxesList,
                    productUniqOptions
                }) => ({
    name: columnName.productPalletType,
    title: t('table.header.palletType'),
    titleFilter: true,
    excelValue: (item) => item.productId ? (item.productId?.productPalletType?.name || item.productId?.customPalletType) : (item?.productPalletType?.name || item?.customPalletType) || '-',
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1 align-top'}>
                <div className='relative flex flex-row items-center'>
                    <div
                        className='activeBtn flex flex-col items-center justify-center cursor-pointer'
                        onClick={(e) => onChangeSort(e, this.name)}
                    >
                        <p className='whitespace-nowrap font-medium text-xs text-theme-blue'>{this.title}</p>
                        <div className='flex flex-row justify-center items-center'>
                            <IconArrow5
                                className={`h-2.5 w-2.5 fill-current text-theme-blue ${checkSortField(this.name)}`}/>
                            {this.titleFilter && productUniqOptions?.productPalletTypes?.length > 0 && (
                                <button
                                    className={`activeBtn text-theme-blue-light hover:text-theme-blue ml-1 ${filterParams?.productPalletTypes?.length > 0 || filterParams.customPalletType ? 'active' : ''}`}
                                    onClick={(e) => onOpenFilterPanel(e, 'productPalletTypes')}
                                >
                                    <IconFilterTitle className='stroke-current w-4 h-4'/>
                                </button>
                            )}
                        </div>
                    </div>
                    {this.titleFilter && productUniqOptions?.productPalletTypes?.length > 0 && (
                        <div
                            ref={(element) => setRefTitle(element, this.name)}
                            className={`absolute z-10 ${activeFilterPanel === 'productPalletTypes' ? 'block' : 'hidden'} mt-3 text-theme-blue top-full left-5 text-xs font-normal bg-theme-gray-main shadow-main rounded-2xl py-4 gap-x-4 flex flex-col overflow-hidden w-60 px-3 text-left`}
                        >
                            <p className='mb-2'>{t('filterPanel.productSort')}</p>
                            <div className='w-full flex flex-col gap-y-1'>
                                {productUniqOptions?.productPalletTypes?.map(({
                                                                                  _id,
                                                                                  name,
                                                                                  checked,
                                                                                  inputName
                                                                              }) => (
                                    <CustomCheckbox
                                        key={_id}
                                        checked={checked}
                                        name={inputName || 'productPalletTypes'}
                                        text={_id === 'other' ? t('filterPanel.otherSort') : name}
                                        onChange={(e) => onChangeCheckBoxesList(e.target.name, _id)}
                                    />
                                ))}
                            </div>
                            <div
                                className='w-full flex flex-row justify-around gap-x-4 mt-auto pt-4 text-theme-blue'>
                                <button
                                    className='flex flex-row items-center justify-center border border-theme-blue rounded-2xl text-theme-blue bg-transparent hover:bg-theme-blue-light hover:border-theme-blue-light hover:text-white h-8 w-full duration-100'
                                    onClick={() => clearFilterParams([this.name + 's', 'customPalletType'])}
                                >
                                    <IconClose className={'fill-current'}/>
                                    <p>{t('filterPanel.clear')}</p>
                                </button>
                                <button
                                    className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light h-8 w-full duration-100'
                                    onClick={applyFilterParams}>{t('filterPanel.apply')}</button>
                            </div>
                        </div>
                    )}
                </div>
            </th>
        );
    },
    tbody: function (item) {
        const name = item.productId ?
            (item.productId?.productPalletType?.name || item.productId?.customPalletType) || '-' :
            (item?.productPalletType?.name || item?.customPalletType) || '-';
        return (
            <td key={this.name + item._id} className={'whitespace-nowrap px-1'}>
                <p className='truncate max-w-24 text-theme-blue'>
                    {name}
                </p>
            </td>
        );
    },
    tabletTitle: function ({ _id }) {
        return (
            <p key={`title_${this.name}${_id}`} className='font-medium'>{this.title}</p>
        );
    },
    tabletContent: function (item) {
        const name = item.productId ?
            (item.productId?.productPalletType?.name || item.productId?.customPalletType) || '-' :
            (item?.productPalletType?.name || item?.customPalletType) || '-';
        return (
            <p key={`content_${this.name}${item._id}`}
               className='w-3/5 truncate'>{name}</p>
        );
    }
})
