import {gql} from '@apollo/client';
import {TYPE_CONVERSATION} from '../../types';

export const CREATE_CONVERSATION = gql`
    mutation createConversation(
        $data: ConversationInput
    ){
        createConversation(
            data: $data
        ){
            ${TYPE_CONVERSATION}
        }
    }
`;
