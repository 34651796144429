import {gql} from '@apollo/client';
import {TYPE_FRONT_SETTINGS} from '_graphql/types';

export const GET_FRONT_SETTINGS = gql`
    query frontSettings($all: Boolean){
        frontSettings(all: $all) {
            ${TYPE_FRONT_SETTINGS}
        }
    }
`;
