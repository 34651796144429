import {gql} from '@apollo/client';
import {TYPE_PRODUCT} from '_graphql/types';

export const UPDATE_PRODUCT_SUBSCRIPTION = gql`
    subscription productUpdated(
        $_id: ID
    ){
        productUpdated(
            _id: $_id
        ){
            ${TYPE_PRODUCT}
        }
    }
`;
