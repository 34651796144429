import React from 'react';
import {columnName} from 'components/table/configuration';
import {IconProfile} from 'assets/images/svg';

export default ({t}) => ({
    name: columnName.customerImage,
    title: t('table.header.avatar'),
    excelValue: null,
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1'}/>
        );
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'w-1 whitespace-nowrap px-1'}>
                <div className='h-10 w-10 flex items-center justify-center'>
                    {item?.customerId?.avatar?.assetUrl ? (
                        <img className='h-6 w-6 rounded-full block' src={item?.customerId?.avatar?.assetUrl}
                             alt={'user avatar'}/>
                    ) : (
                        <IconProfile/>
                    )}
                </div>
            </td>
        );
    },
    tabletTitle: null,
    tabletContent: null
})
