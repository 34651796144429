import {gql} from '@apollo/client';
import {TYPE_PRODUCT} from '_graphql/types';

export const DELETE_PRODUCT_SUBSCRIPTION = gql`
    subscription productDeleted(
        $_id: ID
    ){
        productDeleted(
            _id: $_id
        ){
            ${TYPE_PRODUCT}
        }
    }
`;
