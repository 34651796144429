import React, { useContext, useEffect, useState } from 'react';
import { IconClip, IconClose, IconSend } from 'assets/images/svg';
import { useAuthToken } from '_hooks';
import { useMutation } from '@apollo/client';
import { CREATE_ASSETS, CREATE_MESSAGE, DELETE_ASSET, UPDATE_MESSAGE } from '_graphql';
import { ToolTip } from 'components/index';
import { GeneralContext } from 'contexts';
import { shortFileSizeUtils } from 'utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { messenger, newMessageFormSchema } from '_constants';
import { withTranslation } from 'react-i18next';
import { useSegment } from 'react-segment-hooks';

const initialState = {
    message: '',
    files: null
};

const MessageForm = ({ t, selectedConversation, saveMessageToCache, setAutoScroll, editMessage, setEditMessage }) => {
    const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
        resolver: yupResolver(newMessageFormSchema()),
        defaultValues: initialState
    });

    const analytics = useSegment();
    const { pathname } = useLocation();
    const { userId } = useAuthToken();
    const { setLoading } = useContext(GeneralContext);

    const [createNewMessage, { loading: newMessageLoading, error: newMessageError }] = useMutation(CREATE_MESSAGE);
    const [updateMessage, { loading: updateMessageLoading, error: updateMessageError }] = useMutation(UPDATE_MESSAGE);
    const [createAssets, { loading: newAssetLoading, error: newAssetError }] = useMutation(CREATE_ASSETS);
    const [deleteAssets, { loading: deleteAssetLoading, error: deleteAssetError }] = useMutation(DELETE_ASSET);

    const [formValues, setFormValues] = useState(initialState);

    useEffect(() => {
        setLoading(
            (newMessageLoading && !newMessageError) &&
            (newAssetLoading && !newAssetError) &&
            (updateMessageLoading && !updateMessageError) &&
            (deleteAssetLoading && !deleteAssetError)
        );
    }, [newMessageLoading, newMessageError, newAssetLoading, newAssetError, updateMessageLoading, updateMessageError, deleteAssetLoading, deleteAssetError]);

    useEffect(() => {
        const subscription = watch((value) => {
            setFormValues(value);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => resetForm(), [pathname, selectedConversation]);

    useEffect(() => {
        if (editMessage) {
            const files = editMessage.assets?.length > 0 ? editMessage.assets : null;
            reset({
                message: editMessage.message,
                files
            });
        }
    }, [editMessage]);

    const resetForm = () => {
        reset(initialState);
        setEditMessage(null);
    };

    const sendMessage = (e) => {
        if (selectedConversation?.chatClosesUsers?.length > 0 && selectedConversation.type === messenger.types.private) {
            return;
        }

        if (editMessage) {
            const data = {
                message: e.message.length > 0 ? e.message : ''
            };

            const fileDeleted = editMessage.assets.length > 0 && !(e?.files?.length > 0);
            const fileChanged = editMessage.assets.length > 0 && e?.files?.length > 0 && !(e?.files?.[0]?.assetUrl);
            const fileUploaded = editMessage.assets.length <= 0 && e?.files?.length > 0;

            updateMessage({
                variables: { _id: editMessage._id, data },
                update: async (cache, result) => {
                    setAutoScroll(false);
                    const message = result?.data?.updateMessage;
                    if (message) {
                        let updateMessage = { ...message };
                        if (fileDeleted) {
                            await deleteAssets({ variables: { _id: editMessage.assets[0]._id } });
                            updateMessage.assets = null;
                        }
                        if (fileChanged || fileUploaded) {
                            if (fileChanged) {
                                await deleteAssets({ variables: { _id: editMessage.assets[0]._id } });
                            }
                            const assetsData = { model: 'message', itemId: updateMessage._id };
                            await createAssets({
                                variables: { data: assetsData, files: e?.files },
                                update: (assetCache, { data: assetsData }) => {
                                    if (assetsData?.createAssets) {
                                        updateMessage = { ...updateMessage, assets: assetsData?.createAssets };
                                    }
                                }
                            });
                        }
                        saveMessageToCache(updateMessage, { change: true });
                    }
                }
            }).then(() => resetForm());
        } else {
            const data = {
                conversationId: selectedConversation?._id,
                userId,
                message: e.message.length > 0 ? e.message : ''
            };

            analytics.track({
                event: 'Clicked on "send" button',
                properties: { message: data.message, conversationId: data.conversationId }
            });

            createNewMessage({
                variables: { data },
                update: async (cache, result) => {
                    setAutoScroll(true);
                    if (result?.data?.createMessage) {
                        let newMessage = { ...result?.data?.createMessage };

                        if (e?.files?.length > 0) {
                            const assetsData = { model: 'message', itemId: newMessage._id };
                            await createAssets({
                                variables: { data: assetsData, files: e?.files },
                                update: (assetCache, { data: assetsData }) => {
                                    if (assetsData?.createAssets) {
                                        newMessage = { ...newMessage, assets: assetsData?.createAssets };
                                    }
                                }
                            });
                        }

                        saveMessageToCache(newMessage, { add: true });
                    }
                }
            }).then(() => resetForm());
        }
    };

    return (
        <form className='relative flex flex-row justify-between items-start duration-100 gap-x-2 py-4 px-3'
              onSubmit={handleSubmit(sendMessage)}>
            {selectedConversation?.chatClosesUsers?.length > 0 && (
                <div
                    className='absolute inset-0 bg-black bg-opacity-50 my-4 mx-3 rounded-3xl flex justify-center items-center uppercase text-white'>
                    {t('messenger.chatDeleted')}
                </div>
            )}
            {editMessage && (
                <button
                    type={'reset'}
                    data-tip
                    data-for='cancelEdit'
                    className='cursor-pointer h-10 w-10 flex-none flex items-center justify-center rounded-full text-white bg-theme-blue hover:bg-theme-blue-light duration-100'
                    onClick={resetForm}
                >
                    <IconClose className='fill-current'/>
                    <ToolTip id='cancelEdit' title={t('messenger.cancelEdit')}/>
                </button>
            )}
            <input
                {...register('message')}
                type='text'
                className={`p-3 flex-grow text-xs font-normal resize-none border border-theme-gray rounded-3xl bg-transparent ${errors?.message ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                placeholder={t('messenger.typeMsg')}
                autoComplete={'off'}
            />
            <div className='flex-shrink flex flex-row gap-x-2'>
                {formValues?.files ? (
                    <button
                        data-tip
                        data-for='uploadedFile'
                        className='cursor-pointer h-10 w-10 flex-none flex items-center justify-center rounded-full text-white bg-theme-blue hover:bg-theme-blue-light duration-100'
                        onClick={() => setValue('files', null)}
                    >
                        <IconClose className='fill-current'/>
                        <ToolTip id='uploadedFile'
                                 title={`${formValues?.files?.[0]?.filename ? formValues?.files?.[0]?.filename : formValues?.files?.[0]?.name}, ${shortFileSizeUtils(formValues?.files?.[0]?.filesize ? formValues?.files?.[0]?.filesize : formValues?.files?.[0]?.size, true)}`}/>
                    </button>
                ) : (
                    <label
                        data-tip
                        data-for='uploadFile'
                        className='cursor-pointer h-10 w-10 flex-none flex items-center justify-center rounded-full text-theme-blue bg-theme-blue-main hover:bg-gray-200 duration-100'
                    >
                        <ToolTip id='uploadFile' title={t('global.buttons.tooltip.maxSize')}/>
                        <input
                            {...register('files')}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .pdf, .csv, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*'
                            type='file'
                            onClick={() => analytics.track({ event: 'Clicked on "files" button' })}
                            className='hidden'
                            multiple={false}
                        />
                        <IconClip className={'stroke-current'}/>
                    </label>
                )}
                {/*<div className='cursor-pointer hidden h-10 w-10 flex-none flex items-center justify-center rounded-full bg-theme-blue-main hover:bg-gray-200'>*/}
                {/*    <IconMicrophone/>*/}
                {/*</div>*/}
                <button
                    type='submit'
                    className='cursor-pointer h-10 w-10 flex-none flex items-center justify-center rounded-full bg-theme-blue-main hover:bg-gray-200 duration-100'
                >
                    <IconSend/>
                </button>
            </div>
        </form>
    );
};

export default withTranslation()(MessageForm);
