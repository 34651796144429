import {gql} from '@apollo/client';
import {TYPE_PRODUCT_CUSTOMER} from '_graphql/types';

export const UPDATE_PRODUCT_CUSTOMER = gql`
    mutation updateProductCustomer(
        $data: UpdateProductCustomerInput!
        $_id: ID!
    ){
        updateProductCustomer(
            _id: $_id
            data: $data
        ){
            ${TYPE_PRODUCT_CUSTOMER}
        }
    }
`;
