import React from 'react';
import {columnName} from 'components/table/configuration';

export default ({ t, onFinishOrder }) => ({
    name: columnName.finishOrder,
    title: t('table.rows.finishOrder'),
    excelValue: null,
    thead: function () {
        return (<th key={this.name} className={'px-1'}/>);
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'w-1 whitespace-nowrap px-1'}>
                {item?.isFinished ? (
                    <div
                        className={'text-center text-menu font-normal rounded-3xl py-2.5 text-theme-blue'}
                    >
                        {t(item.isApproved ? 'table.rows.isFinished' : 'table.rows.notApproved')}
                    </div>
                ) : (
                    <button
                        className={'text-center text-menu font-normal cursor-pointer rounded-3xl py-2.5 px-3 hover:shadow-md hover:duration-100 text-white bg-theme-blue hover:bg-blue-400'}
                        onClick={(e) => onFinishOrder(e, item)}
                    >
                        {t('table.rows.finishOrder')}
                    </button>
                )}
            </td>
        );
    },
    tabletTitle: () => null,
    tabletContent: () => null
})
