import React, { useContext, useEffect, useState } from 'react';
import BaseModal from '../index';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_API_KEY } from '_constants';
import { withTranslation } from 'react-i18next';
import { IconArrow1 } from 'assets/images/svg';
import { useAuthToken } from '_hooks';
import { useMutation } from '@apollo/client';
import { GeneralContext, LocaleContext } from 'contexts';
import { checkCityUtils, checkStringUtils } from 'utils/validations.utils';
import { CREATE_DELIVERY_CUSTOMER } from '_graphql';
import { useSegment } from 'react-segment-hooks';

const initialState = {
    loadCity: null,
    unloadCity: null,
    space: 0,
    message: ''
};

const OrderDeliveryModal = ({ t, isOpen, onClose, params }) => {
    const { setLoading, toggleSegmentButton } = useContext(GeneralContext);
    const { userId } = useAuthToken();
    const analytics = useSegment();

    const [createDeliveryCustomer, { loading, error }] = useMutation(CREATE_DELIVERY_CUSTOMER);

    const [customerInfo, setCustomerInfo] = useState(initialState);
    const [errorFields, setErrorFields] = useState(null);

    useEffect(() => {
        setLoading(loading && !error);
    }, [loading, error]);

    useEffect(() => {
        setCustomerInfo(initialState);
        setErrorFields(null);
        if (isOpen) {
            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'Order Delivery'
                }
            });
        }
    }, [isOpen]);

    useEffect(() => {
        if (errorFields?.length) {
            setErrorFields((prev) => prev.filter((field) => !customerInfo[field]));
        }
    }, [customerInfo]);

    const editCustomerInfo = (field, value) => {
        setCustomerInfo((prev) => ({ ...prev, [field]: value }));
    };

    const editCustomerInfoByTarget = ({ target }) => {
        editCustomerInfo(target.name, target.value);
    };

    const onPlaceSelected = (place, name) => {
        if (place.formatted_address && place.place_id) {
            editCustomerInfo(name, { name: place.formatted_address, placeId: place.place_id });
        }
    };

    const validationFields = () => {
        const errors = [];

        if (params?.isDriver && !checkStringUtils(customerInfo.space)) {
            errors.push('space');
        }
        if (!checkStringUtils(customerInfo.message)) {
            errors.push('message');
        }
        if (!checkCityUtils(customerInfo.loadCity)) {
            errors.push('loadCity');
        }
        if (!checkCityUtils(customerInfo.unloadCity)) {
            errors.push('unloadCity');
        }

        setErrorFields(errors.length > 0 ? errors : null);

        return errors.length > 0;
    };

    const onSubmitForm = (e) => {
        e.preventDefault();

        if (validationFields()) {
            return;
        }

        const data = {
            ownerId: params?.ownerId,
            deliveryId: params?._id,
            customerId: userId,
            message: customerInfo.message
            // quantity: Number(customerInfo.quantity)
        };

        createDeliveryCustomer({ variables: { data } })
            .then(() => {
                onClose();
            });
    };

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width='w-97'
            showClose={false}
        >
            <div className='p-7 text-sm text-theme-blue'>
                <form className='flex flex-col gap-y-4' onSubmit={onSubmitForm}>
                    <div className='w-full flex flex-row gap-4 items-center'>
                        <p className='w-1/4'>Погрузка</p>
                        <div className='w-3/4 relative'>
                            <PlaceInputComponent
                                t={t}
                                onPlaceSelected={onPlaceSelected}
                                editCustomerInfo={editCustomerInfo}
                                error={errorFields?.find((item) => item === 'loadCity')}
                                name={'loadCity'}
                            />
                            {!errorFields?.find((item) => item === 'loadCity') && (
                                <IconArrow1 className='absolute top-1/2 right-3 transform -translate-y-1/2'/>)}
                        </div>
                    </div>
                    <div className='w-full flex flex-row gap-4 items-center'>
                        <p className='w-1/4'>Розгрузка</p>
                        <div className='w-3/4 relative'>
                            <PlaceInputComponent
                                t={t}
                                onPlaceSelected={onPlaceSelected}
                                editCustomerInfo={editCustomerInfo}
                                error={errorFields?.find((item) => item === 'unloadCity')}
                                name={'unloadCity'}
                            />
                            {!errorFields?.find((item) => item === 'unloadCity') && (
                                <IconArrow1 className='absolute top-1/2 right-3 transform -translate-y-1/2'/>)}
                        </div>
                    </div>
                    {params?.isDriver && (
                        <div className='w-full flex flex-row gap-4 items-center'>
                            <p className='w-1/4'>Місце</p>
                            <div className='w-3/4 flex flex-row items-center'>
                                <input
                                    type='text'
                                    name='space'
                                    value={customerInfo.space}
                                    onChange={editCustomerInfoByTarget}
                                    className={`w-1/3 py-1.5 px-4 border ${errorFields?.find((item) => item === 'space') ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}
                                />
                                <p className='ml-2'>{t('modals.newDeliveryModal.capacity')}</p>
                                <p className='ml-auto text-theme-blue-light'>(max: <b>{params?.freeSpace}</b> {t('modals.newDeliveryModal.capacity')})
                                </p>
                            </div>
                        </div>
                    )}
                    <textarea
                        className={`w-full h-12 border-b-2 bg-transparent ${errorFields?.find((item) => item === 'message') ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'}`}
                        placeholder={t('modals.buySellProduct.message')}
                        name='message'
                        value={customerInfo.message}
                        onChange={editCustomerInfoByTarget}
                    />
                    <div className='flex justify-end'>
                        <button type='button'
                                className='flex flex-row items-center justify-center bg-theme-blue-main rounded-3xl text-theme-blue h-7 px-7 text-10 mr-6 hover:bg-theme-gray-main duration-100'
                                onClick={() => {
                                    onClose();
                                    toggleSegmentButton('Cancel', 'Order Delivery');
                                }}>{t('modals.buySellProduct.cancel')}</button>
                        <button type='submit'
                                className='flex flex-row items-center justify-center bg-theme-blue rounded-3xl text-white h-7 px-7 text-10 hover:bg-theme-blue-light duration-100'
                                onClick={() => toggleSegmentButton('Submit', 'Order Deliverys')}>{t('modals.buySellProduct.save')}</button>
                    </div>
                </form>
            </div>
        </BaseModal>
    );
};

const PlaceInputComponent = ({ t, onPlaceSelected, editCustomerInfo, error, name }) => {
    const { locale } = useContext(LocaleContext);

    return (
        <Autocomplete
            apiKey={GOOGLE_API_KEY}
            onPlaceSelected={(place) => onPlaceSelected(place, name)}
            onChange={() => editCustomerInfo(name, null)}
            placeholder={t('modals.newTaraModal.city')}
            language={locale}
            className={`w-full py-1.5 pl-3 pr-9 border ${error ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}
            options={{
                types: ['(cities)']
            }}
        />
    );
};

OrderDeliveryModal.defaultProps = {
    isOpen: false
};

export default withTranslation()(OrderDeliveryModal);
