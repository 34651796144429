import {gql} from '@apollo/client';

export const READ_MESSAGE_SUBSCRIPTION = gql`
    subscription readMessages(
        $conversationId: ID!
    ) {
        readMessages(
            conversationId: $conversationId
        ) {
            messagesIds
            conversationId
        }
    }
`;
