import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSegment } from 'react-segment-hooks';
import { useAuthToken } from '_hooks';
import { IconArrow1 } from 'assets/images/svg';
import TaraLogo from 'assets/images/img-logo-1.png';
import { LocaleContext } from 'contexts';
import { localesCode, navigation } from '_constants';
import { Redirect, Route, Switch } from 'react-router-dom';
import ChangeUserInfoPage from '../change-user-info-page';
import LoginPage from './login-page';
import SignupPage from './singup-page';
import ResetPasswordPage from './reset-password';
import ChangeForgottenPassword from './changePassword';
import ActivateEmailPage from './activate-email-page';

const NonAuth = ({ t, settings }) => {
    const analytics = useSegment();
    const { authToken, userData } = useAuthToken();
    const { locale, changeLocale: setLocale } = useContext(LocaleContext);

    const changeLocale = (localeName) => {
        analytics.track({
            event: 'Clicked on change local',
            properties: { localeName }
        });
        setLocale(localeName);
    };

    const [activeInfoPanel, setActiveInfoPanel] = useState(null);

    const changeActiveInfoPanel = (panelName) => {
        analytics.track({
            event: 'Clicked on panel info',
            properties: { panelName }
        });
        setActiveInfoPanel((prev) => prev === panelName ? null : panelName);
    };

    return (
        <div className='h-screen w-screen bg-login bg-no-repeat bg-center bg-cover flex flex-row'>
            <div
                className='relative flex-grow-0 min-w-90 bg-theme-blue-light-2 bg-opacity-75 flex flex-col justify-center items-center text-white p-12 gap-4 text-sm'>
                <Switch>
                    <PrivateRoute access={!authToken} path={navigation.nonAuth.login} component={LoginPage}
                                  settings={settings}/>
                    <PrivateRoute access={!authToken && !!settings?.enabledRegistration}
                                  path={navigation.nonAuth.signup}
                                  component={SignupPage}/>
                    <PrivateRoute access={!authToken} path={navigation.nonAuth.forgotPassword}
                                  component={ResetPasswordPage}/>
                    <PrivateRoute access={!authToken} path={`${navigation.nonAuth.changePassword}/:id`}
                                  component={ChangeForgottenPassword}/>
                    <PrivateRoute access={!authToken} path={`${navigation.nonAuth.activateEmail}/:token`}
                                  component={ActivateEmailPage}/>
                    <PrivateRoute access={!!authToken && !userData?.modifiedAt} path={navigation.nonAuth.changeUserInfo}
                                  component={ChangeUserInfoPage}/>
                </Switch>
                <div className='absolute bottom-0 flex flex-row justify-between w-full'>
                    <button
                        className={`${locale === localesCode.ua ? 'text-theme-blue' : 'text-white'} w-full p-4 hover:text-theme-blue-light duration-100`}
                        onClick={() => changeLocale(localesCode.ua)}
                    >
                        UA
                    </button>
                    <button
                        className={`${locale === localesCode.ru ? 'text-theme-blue' : 'text-white'} w-full p-4 hover:text-theme-blue-light duration-100`}
                        onClick={() => changeLocale(localesCode.ru)}
                    >
                        RU
                    </button>
                    <button
                        className={`${locale === localesCode.en ? 'text-theme-blue' : 'text-white'} w-full p-4 hover:text-theme-blue-light duration-100`}
                        onClick={() => changeLocale(localesCode.en)}
                    >
                        EN
                    </button>
                </div>
            </div>
            <div
                className='flex-grow text-white p-20 break-words w-full text-base leading-loose overflow-y-auto scrollable h-full'>
                <img src={TaraLogo} className='mb-10' alt='Tara online' width={150}/>
                <p className='text-theme-blue-light text-xl uppercase mb-2'>{t('login.about')}</p>
                <p className='max-w-newTaraRightBlock mb-10'>
                    {t('login.aboutContent', { name: 'Azon5' })}
                </p>
                <button
                    onClick={() => changeActiveInfoPanel('about')}
                    className='text-theme-blue-light text-xl uppercase mb-2 flex flex-row items-center gap-x-2'
                >
                    {t('login.platform')}
                    <IconArrow1
                        className={`stroke-current transform ${activeInfoPanel === 'about' ? '' : 'rotate-180'} duration-100`}/>
                </button>
                {activeInfoPanel === 'about' && (
                    <div className='max-w-newTaraRightBlock mb-10'>
                        <p>{t('login.platformContent.title')}</p>
                        <p className='text-theme-blue-light'>{t('login.platformContent.listName')}</p>
                        <ul className='list-disc pl-8'>
                            <li>{t('login.platformContent.content1')}</li>
                            <li>{t('login.platformContent.content2')}</li>
                            <li>{t('login.platformContent.content3')}</li>
                            <li>{t('login.platformContent.content4')}</li>
                            <li>{t('login.platformContent.content5')}</li>
                            <li>{t('login.platformContent.content6')}</li>
                        </ul>
                    </div>
                )}

                <button
                    onClick={() => changeActiveInfoPanel('feedback')}
                    className='text-theme-blue-light text-xl uppercase mb-2 flex flex-row items-center gap-x-2'
                >
                    {t('login.feedback')}
                    <IconArrow1
                        className={`stroke-current transform ${activeInfoPanel === 'feedback' ? '' : 'rotate-180'} duration-100`}/>
                </button>
                {activeInfoPanel === 'feedback' && (
                    <div className='max-w-newTaraRightBlock mb-10'>
                        <p>{t('login.feedbackContent.title')}</p>
                        <ul className='list-disc pl-8'>
                            <li>{t('login.feedbackContent.list1')}</li>
                            <li>{t('login.feedbackContent.list2')}</li>
                        </ul>
                    </div>
                )}

                <button
                    onClick={() => changeActiveInfoPanel('userInstruction')}
                    className='text-theme-blue-light text-xl uppercase mb-2 flex flex-row items-center gap-x-2'
                >
                    {t('login.instruction')}
                    <IconArrow1
                        className={`stroke-current transform ${activeInfoPanel === 'userInstruction' ? '' : 'rotate-180'} duration-100`}/>
                </button>
                {activeInfoPanel === 'userInstruction' && (
                    <div className='max-w-newTaraRightBlock mb-10'>
                        <p>{t('login.instructionContent.title')}</p>
                        <ul className='list-disc pl-8'>
                            <li><span
                                className='text-theme-blue-light'>{t('login.instructionContent.list1.title')}</span>{t('login.instructionContent.list1.content')}
                            </li>
                            <li><span
                                className='text-theme-blue-light'>{t('login.instructionContent.list2.title')}</span>{t('login.instructionContent.list2.content')}
                            </li>
                            <li><span
                                className='text-theme-blue-light'>{t('login.instructionContent.list3.title')}</span>{t('login.instructionContent.list3.content')}
                            </li>
                            <li><span
                                className='text-theme-blue-light'>{t('login.instructionContent.list4.title')}</span>{t('login.instructionContent.list4.content')}
                            </li>
                            <li><span
                                className='text-theme-blue-light'>{t('login.instructionContent.list5.title')}</span>{t('login.instructionContent.list5.content')}
                            </li>
                            <li><span
                                className='text-theme-blue-light'>{t('login.instructionContent.list6.title')}</span>{t('login.instructionContent.list6.content')}
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

const PrivateRoute = ({ component: Component, access = false, settings, ...rest }) => {
    const { authToken } = useAuthToken();
    return (
        <Route {...rest} render={(props) => (
            access ? (
                <Component {...props} settings={settings}/>
            ) : (
                <Redirect to={authToken ? navigation.pages.app : navigation.nonAuth.login}/>
            )
        )}/>
    );
};

export default withTranslation()(NonAuth);
