import {gql} from '@apollo/client';
import {TYPE_CONVERSATION} from '../../types';

export const START_PRIVATE_CHAT = gql`
    mutation startPrivateChat(
        $data: ConversationStartPrivateChatInput
    ){
        startPrivateChat(
            data: $data
        ){
            ${TYPE_CONVERSATION}
        }
    }
`;
