import React, { useEffect, useState } from 'react';
import { IconArrow4, IconLock } from 'assets/images/svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Swal from 'sweetalert2';
import { changePasswordSchema, navigation } from '_constants';
import { RESET_PASSWORD_MUTATION } from '_graphql/mutations/user/resetPassword';
import { useSegment } from 'react-segment-hooks';
import { LoadingSpinner } from '../../../components';

const ChangeForgottenPassword = ({ t }) => {
    const resetToken = useParams()?.id;
    const history = useHistory();
    const analytics = useSegment();

    const [loaderState, setLoaderState] = useState(false);

    const [forgotPassword, { error: resetErrors, data: resetData, loading }] = useMutation(RESET_PASSWORD_MUTATION);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(changePasswordSchema(t))
    });

    useEffect(() => {
        setLoaderState(loading && !resetErrors);
    }, [loading, resetErrors]);

    useEffect(() => {
        analytics.page({ name: 'Change password Page' });
    }, []);

    useEffect(() => {
        if (resetErrors) {
            return showMessage(true);
        }
        if (resetData) {
            showMessage(false);
        }
    }, [resetData, resetErrors]);

    const onSubmit = (formValue) => {
        forgotPassword({
            variables: { ...formValue, resetToken }
        }).catch(() => {});
    };

    const showMessage = (isError) => {
        Swal.fire({
            title: isError ? t('changePassword.errorMessageTitle') : t('changePassword.successTitle'),
            text: isError ? resetErrors?.message : t('changePassword.successMessage'),
            icon: isError ? 'error' : 'success',
            confirmButtonText: 'Ok'
        }).then(() => {
            history.push(navigation.nonAuth.login);
        });
    };

    const clickedChangePassword = () => {
        analytics.track({ event: 'Clicked on “SignUp” button' });
    };

    return (
        <>
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <p className='text-white text-xl text-center font-normal mb-10'>{t('reset.title')}</p>
                <form className='flex flex-col gap-y-5 max-w-sm w-full' onSubmit={handleSubmit(onSubmit)}>
                    <div className='relative'>
                        <span
                            className='z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'><IconLock/></span>
                        <input {...register('password')}
                               className={`pl-12 pr-5 h-11 text-xs rounded-full cursor-pointer w-full duration-100 hover:bg-gray-100 ${errors.password ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                               name='password'
                               type='password'
                               autoComplete='disabled'
                               placeholder={t('changePassword.password')}
                        />
                        <p className='h-5 text-theme-red-main text-sm font-semibold pl-5'>{errors.password?.message}</p>
                    </div>
                    <div className='relative'>
                        <span
                            className='z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'><IconLock/></span>
                        <input {...register('passwordConfirmation')}
                               className={`pl-12 pr-5 h-11 text-xs rounded-full cursor-pointer w-full duration-100 hover:bg-gray-100 ${errors.passwordConfirmation ? 'border-2 bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : ''}`}
                               name='passwordConfirmation'
                               type='password'
                               autoComplete='disabled'
                               placeholder={t('changePassword.confirm')}
                        />
                        <p className='h-5 text-theme-red-main text-sm font-semibold pl-5'>{errors.passwordConfirmation?.message}</p>
                    </div>
                    <div className='relative text-center'>
                        <span
                            className='z-10 absolute left-1 top-1 bottom-1 rounded-full bg-theme-blue w-9 h-9 flex justify-center items-center'>
                            <IconArrow4 className='fill-current text-white'/>
                        </span>
                        <button
                            className='text-white w-full h-11 rounded-full border-2 border-white text-center bg-transparent text-base font-normal duration-100 cursor-pointer hover:bg-white hover:bg-opacity-70 mb-4'
                            type='submit' onClick={clickedChangePassword}>{t('changePassword.reset')}</button>
                    </div>
                </form>
            </div>
            <LoadingSpinner loading={loaderState}/>
        </>
    );
};
export default withTranslation()(ChangeForgottenPassword);
