import React from 'react';

export const formatDateUtils = (date, hideDate = false) => (
    <>
        <div className='flex-col flex text-center'>
            <p className='text-theme-blue'>{getTimeString(date)}</p>
            {!hideDate && (<p>{getDateString(date)}</p>)}
        </div>
    </>
)
export const formatLoadDateUtils = (date) => getDateString(date)

export const formatDateRowsUtils = (date) => (<>{getDateString(date)}{' '}<span className='text-theme-blue ml-5'>{getTimeString(date)}</span></>)
export const formatDateExcelUtils = (date, hideDate = false) => hideDate ? getTimeString(date) : [getDateString(date), getTimeString(date)].join(' ')

const getDateString = (date) => [numberToRound(new Date(date).getDate()), numberToRound(new Date(date).getMonth() + 1), new Date(date).getFullYear()].join('.')
const getTimeString = (date) => [numberToRound(new Date(date).getHours()), numberToRound(new Date(date).getMinutes())].join(':')

const numberToRound = (number) => number < 10 ? '0' + number : number

