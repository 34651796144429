import { createContext } from 'react';

export const SubMenuContext = createContext({
    subMenuPanel: { },
    toggleSubMenuPanel: () => {},
    activeSubMenu: { },
    setSubMenuPane: () => {},
    setActiveSubMenu: () => {},
    activeButtonsRefs: { },
    setButtonsRefs: () => {}
});
