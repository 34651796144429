import { withTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { FilterContext } from 'contexts';

const FilterPriceInput = ({ t }) => {
    const {
        filterParams,
        saveToFilter
    } = useContext(FilterContext);

    const onChangePrice = (e) => {
        const { name, value } = e.target;
        const { price } = filterParams;
        saveToFilter('price', { ...price, [name]: value });
    };

    return (
        <>

            <p className='mb-2'>{t('filterPanel.price')}</p>
            <div className='w-full flex flex-row justify-between items-center'>
                <input type='number' name='from'
                       className='w-24 py-1.5 px-3 border border-theme-blue-main rounded-3xl bg-transparent'
                       value={filterParams.price?.from || 0} onChange={onChangePrice}/>
                <div className='h-px bg-theme-blue w-4'/>
                <input type='number' name='to'
                       className='w-24 py-1.5 px-3 border border-theme-blue-main rounded-3xl bg-transparent'
                       value={filterParams.price?.to || 0} onChange={onChangePrice}/>
            </div>
        </>
    );
};

export default withTranslation()(FilterPriceInput);
