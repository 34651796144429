import React, { useContext, useEffect, useState } from 'react';
import { ImageSlider, UploadFileButton } from 'components';
import { IconTruck1 } from 'assets/images/svg';
import PhoneInput from 'react-phone-number-input';
import { toBase64Utils } from 'utils';
import { withTranslation } from 'react-i18next';
import { GeneralContext, LocaleContext } from 'contexts';

const ClientInfoBlock = ({ t, hookForm, setFiles }) => {
    const { newDeliveryModal: isOpen, toggleSegmentButton } = useContext(GeneralContext);

    const { locale } = useContext(LocaleContext);
    const [uploadImages, setUploadImages] = useState([]);

    useEffect(() => {
        setFiles(uploadImages.map(({ file }) => file));
    }, [uploadImages]);

    useEffect(() => {
        if (isOpen) {
            setUploadImages([]);
        }
    }, [isOpen]);

    const uploadImagesFile = async (e) => {
        const imageCount = uploadImages?.length || 0;
        const { files } = e.target;
        if (imageCount <= 10 && files.length > 0) {
            const filesBase64 = [];
            for (const file of files) {
                if (imageCount + 1 <= 10) {
                    filesBase64.push({ file, image: await toBase64Utils(file) });
                } else {
                    break;
                }
            }
            setUploadImages((prev) => prev.length > 0 ? [...prev, ...filesBase64] : filesBase64);
        }
    };

    const changePhone = (newPhone) => {
        hookForm.setValue('phone', newPhone);
    };

    return (
        <div className='w-full max-w-newTaraLeftBlock p-8 pb-8 text-theme-blue flex flex-col gap-y-4'>
            <ImageSlider images={uploadImages.map(({ _id, image }) => ({ _id, image }))} setImages={setUploadImages}
                         noImageIcon={<IconTruck1 className={'w-30 h-21 stroke-current text-theme-gray-light-1'}/>}/>
            <UploadFileButton
                onClick={() => toggleSegmentButton('Upload File', 'New Delivery')}
                disabled={uploadImages?.length >= 10}
                onChange={uploadImagesFile}
            />
            <input
                {...hookForm.register('name')}
                type='text'
                name='name'
                placeholder={t('modals.newDeliveryModal.name')}
                className={`flex-grow-0 w-full py-2 px-4 border ${hookForm?.errors?.name ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}

            />
            <input
                {...hookForm.register('email')}
                type='text'
                name='email'
                placeholder={t('modals.newTaraModal.email')}
                className={`flex-grow-0 w-full py-2 px-4 border ${hookForm?.errors?.email ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent`}
            />
            <PhoneInput
                {...hookForm.register('phone')}
                name={'phone'}
                international
                defaultCountry={locale.split('-')[1]}
                placeholder={t('modals.newTaraModal.phone')}
                className={`flex-grow-0 w-full min-h-10 py-2 px-3 border ${hookForm?.errors?.phone ? 'bg-theme-red-light border-theme-red-main bg-warning bg-no-repeat' : 'border-theme-blue'} rounded-3xl bg-transparent overflow-hidden`}
                onChange={changePhone}
            />
        </div>
    );
};

export default withTranslation()(ClientInfoBlock);
