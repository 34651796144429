import React, { useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import BaseModal from '../index';
import { ImageSlider } from 'components/index';
import { GeneralContext } from '../../../contexts';
import { changeDownloadFileName } from '../../../utils';
import { useSegment } from 'react-segment-hooks';

const OrderDocumentsModal = ({ t, assets = [] }) => {
    const analytics = useSegment();
    const {
        newOrderDocumentsModal: isOpen,
        closeOrderDocumentsModal,
        toggleSegmentButton
    } = useContext(GeneralContext);
    const filteredImages = assets?.filter(({ mimetype }) => mimetype?.includes('image/'));

    useEffect(() => {
        if (isOpen) {
            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'Order Documents'
                }
            });
        }
    }, [isOpen]);

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={closeOrderDocumentsModal}
            width={filteredImages?.length ? 'w-detailsModal' : 'w-97'}
        >
            <div
                className='w-full text-sm bg-theme-gray-main p-5 font-sans text-theme-blue-light flex-grow min-w-104 flex flex-row gap-4'>
                <div className='flex flex-col flex-grow'>
                    <p className={'font-normal tracking-wide text-lg leading-5 mb-3.5 text-theme-blue'}>
                        {t('modals.orderDocuments.title')}
                    </p>
                    <ul className='flex flex-col flex-grow'>
                        {assets?.map(({ _id, filename, assetUrl }) => (
                            <li key={_id} className={'flex flex-row justify-between items-center pr-5'}>
                                <span>{filename}</span>
                                <button
                                    className={'w-20 text-center text-menu font-normal rounded-3xl py-1.5 my-1.5 text-theme-blue bg-theme-blue-main'}
                                    onClick={() => {
                                        changeDownloadFileName(assetUrl, filename);
                                        toggleSegmentButton('Download', 'Order Documents');
                                    }}>
                                    {t('modals.orderDocuments.download')}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                {!!filteredImages?.length && (
                    <div className='bg-theme-gray-light rounded-2xl w-1/2 content-center overflow-hidden p-2'>
                        <ImageSlider
                            images={filteredImages?.map(({ _id, assetUrl }) => ({ _id, image: assetUrl })) || []}
                            onlyView={true}
                        />
                    </div>
                )}
            </div>
        </BaseModal>
    );
};

OrderDocumentsModal.defaultProps = {
    isOpen: false
};

export default withTranslation()(OrderDocumentsModal);
