export const getBasicLocationUtils = (pathName) => {
    if (pathName) {
        const locName = pathName.split('/').filter((item) => item)[0]
        return `/${locName}`
    }
}

export const getLocationWithOutIdUtils = (pathName) => {
    if (pathName && pathName.split('/').length > 2) {
        const parseToArray = pathName.split('/').filter((item) => item)
        const locName = parseToArray.slice(0, parseToArray.length - 1).join('/')
        return `/${locName}`
    }
}
