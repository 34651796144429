import React from 'react';
import image from 'assets/images/404.png'

const NoPage = () => (
        <div className='h-screen w-full flex flex-col items-center justify-around'>
            <p className='uppercase'>page under construction</p>
            <img src={image}/>
        </div>
    )

export default NoPage
