import React, { useContext, useEffect } from 'react';
import BaseModal from '../index';
import { useMutation } from '@apollo/client';
import { useAuthToken } from '_hooks';
import { GET_DELIVERIES_LIST, REMOVE_DELIVERY } from '_graphql';
import { GeneralContext } from 'contexts';
import { useSegment } from 'react-segment-hooks';

const RemoveDeliveryModal = ({ isOpen, onClose, _id, onFinally = null }) => {
    const analytics = useSegment();
    const { setLoading, toggleSegmentButton } = useContext(GeneralContext);
    const { userId } = useAuthToken();

    const [removeDelivery, { loading, error }] = useMutation(REMOVE_DELIVERY);

    useEffect(() => {
        if (isOpen) {
            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'Remove Delivery'
                }
            });
        }
    }, [isOpen]);

    useEffect(() => {
        setLoading(loading && !error);
    }, [loading, error]);

    const remove = () => {
        toggleSegmentButton('Remove', 'Remove Delivery');
        removeDelivery({
            variables: { _id },
            update: (cache, { data }) => {
                try {
                    const removeId = data?.removeDelivery?._id;
                    const { deliveries } = cache.readQuery({
                        query: GET_DELIVERIES_LIST,
                        variables: { page: 1, userId }
                    });
                    const deliveriesList = {
                        ...deliveries,
                        rows: deliveries?.rows.filter((item) => item._id !== removeId),
                        total: deliveries.total - 1
                    };
                    cache.writeQuery({
                        query: GET_DELIVERIES_LIST,
                        variables: { page: 1, userId },
                        data: { deliveries: deliveriesList }
                    });
                } catch (e) {
                    console.error('Write to cache error: ', e);
                }
            }
        }).finally(() => {
            if (onFinally) {
                onFinally();
            } else {
                onClose();
            }
        });
    };

    return (
        <BaseModal onClose={onClose} isOpen={isOpen}>
            <div className='sm:max-w-xs py-9 text-gray-900 mx-auto text-center'>
                <p className='font-bold w-full text-xl uppercase'>Remove delivery</p>
                <p className='px-2'>Are you sure you want to remove this delivery?</p>
                <div className='flex justify-center mt-4'>
                    <button
                        className='w-32 py-2 self-stretch rounded mr-5 bg-transparent border  hover:text-gray-600 hover:bg-gray-200'
                        onClick={() => {
                            onClose();
                            toggleSegmentButton('Cancel', 'Remove Delivery');
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className='w-32 py-2 self-stretch text-gray-900 rounded cursor-pointer hover: text-gray-600'
                        onClick={remove}
                    >
                        Remove
                    </button>
                </div>
            </div>
        </BaseModal>
    );
};
export default RemoveDeliveryModal;
