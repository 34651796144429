import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { FilterContext } from 'contexts';
import { IconArrow1, IconClose } from 'assets/images/svg';
import { CustomCheckbox } from 'components';
import { FilterDatePickerCalendar, FilterDatePickerForm } from './date-picker';
import { FilterMaxCount, FilterMinCount } from './min-max-inputs';
import FilterClientInput from './client-input';
import FilterClientSelect from './client-select';
import FilterCityInput from './city-input';
import FilterPriceInput from './price-input';
import AsyncFilter from './async-filter';
import { useSegment } from 'react-segment-hooks';
import { hasOwnPropertyUtils } from 'utils';

const Filter = ({ t }) => {
    const analytics = useSegment();

    const {
        filterParams,
        changeFilter,
        filterClose,
        clearFilter,
        applyFilter,
        productUniqOptions
    } = useContext(FilterContext);

    const saveToFilters = (field, data) => {
        if (hasOwnPropertyUtils(filterParams, field)) {
            changeFilter({ ...filterParams, [field]: data });
        }
    };

    const clearFilterForm = () => {
        analytics.track({ event: 'Clicked on “Clear” button' });
        clearFilter();
    };

    const saveToFiltersWithName = (e) => {
        const { value, name } = e.target;
        saveToFilters(name, value ? value : null);
    };

    const onChangeTax = (e) => {
        const { name } = e.target;
        const { isTax } = filterParams;
        saveToFilters('isTax', { ...isTax, [name]: !isTax[name] });
    };

    const onChangeCheckBoxesList = (field, id) => {
        if (id === 'other') {
            saveToFilters(field, !filterParams[field]);
        } else {
            const findItem = filterParams[field].find((prevId) => prevId === id);
            saveToFilters(field, findItem ? filterParams[field].filter((prevId) => prevId !== id) : [...filterParams[field], id]);
        }
    };

    const onChangeProductTypes = (id) => {
        const findItem = filterParams.productTypes.find((prevId) => prevId === id);
        saveToFilters('productTypes', findItem ? filterParams.productTypes.filter((prevId) => prevId !== id) : [...filterParams.productTypes, id]);
    };

    const incrementMinMax = (field) => {
        saveToFilters(field, Number(filterParams[field]) + 1);
    };

    const decrementMinMax = (field) => {
        saveToFilters(field, Math.max(Number(filterParams[field]) - 1, 0));
    };

    const submitFilter = () => {
        analytics.track({ event: 'Clicked on “Apply” button' });
        applyFilter();
    };

    return (
        <div
            className={'h-full overflow-y-auto scrollable w-72 flex flex-col rounded-xl shadow-main bg-theme-gray-main text-theme-blue text-xs mt-0.5'}>
            <button className='absolute right-0 top-7 p-4' onClick={() => filterClose()}>
                <IconArrow1 className='transform rotate-90'/>
            </button>
            <div className='h-full overflow-y-auto scrollable flex flex-col gap-y-7 mt-4 px-6'>
                <div className='w-full'>
                    <FilterClientInput/>
                </div>
                <div className='w-full'>
                    <p className='mb-2'>{t('filterPanel.createdAt')}</p>
                    <FilterDatePickerForm/>
                    <div className='w-full mt-5'>
                        <FilterDatePickerCalendar/>
                    </div>
                </div>
                <div className='w-full'>
                    <FilterCityInput/>
                </div>

                <div className='flex flex-row w-full'>
                    <div className='flex-grow w-full'>
                        <p className='mb-2'>{t('filterPanel.min')}</p>
                        <div className='w-24 relative'>
                            <input type='number' name='minCount'
                                   className='w-full py-1.5 pl-3 pr-8 border border-theme-blue-main rounded-3xl bg-transparent'
                                   value={filterParams.minCount || 0} onChange={saveToFiltersWithName}/>
                            <div className='absolute top-0 right-0 flex flex-col h-full'>
                                <button type='button'
                                        className='block h-1/2 pr-3 hover:text-theme-blue-light duration-150'>
                                    <IconArrow1 className='transform rotate-180 stroke-current'
                                                onClick={() => incrementMinMax('minCount')}/>
                                </button>
                                <button type='button'
                                        className='block h-1/2 pr-3 hover:text-theme-blue-light duration-150'
                                        onClick={() => decrementMinMax('minCount')}>
                                    <IconArrow1 className='stroke-current'/>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='flex-grow-0 min-w-24'>
                        <p className='mb-2'>{t('filterPanel.max')}</p>
                        <div className='w-full relative'>
                            <input type='number' name='maxCount'
                                   className='w-full py-1.5 pl-3 pr-8 border border-theme-blue-main rounded-3xl bg-transparent'
                                   value={filterParams.maxCount || 0} onChange={saveToFiltersWithName}/>
                            <div className='absolute top-0 right-0 flex flex-col h-full'>
                                <button type='button'
                                        className='block h-1/2 pr-3 hover:text-theme-blue-light duration-150'
                                        onClick={() => incrementMinMax('maxCount')}>
                                    <IconArrow1 className='transform rotate-180 stroke-current'/>
                                </button>
                                <button type='button'
                                        className='block h-1/2 pr-3 hover:text-theme-blue-light duration-150'
                                        onClick={() => decrementMinMax('maxCount')}>
                                    <IconArrow1 className='stroke-current'/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full'>
                    <FilterPriceInput/>
                </div>
                <div className='flex flex-row w-full gap-x-2'>
                    <div className='flex-grow w-full flex flex-row flex-nowrap'>
                        <p className='w-15'>{t('filterPanel.isTax')}</p>
                        <CustomCheckbox
                            checked={!!filterParams.isTax?.with}
                            onChange={onChangeTax}
                            name='with'
                            text={t('table.rows.withTax')}
                        />
                    </div>
                    <div className='flex-grow-0 min-w-24'>
                        <CustomCheckbox
                            checked={!!filterParams.isTax?.withOut}
                            onChange={onChangeTax}
                            name='withOut'
                            text={t('table.rows.withOutTax')}
                        />
                    </div>
                </div>
                <div className='flex flex-row w-full gap-x-2'>
                    {productUniqOptions?.productTypes?.length > 0 && (
                        <div className='flex-grow w-full'>
                            <p className='mb-2'>{t('filterPanel.productType')}</p>
                            <div className='w-full flex flex-col gap-y-1'>
                                {productUniqOptions?.productTypes?.map(({ _id, name, checked }) => (
                                    <CustomCheckbox
                                        key={_id}
                                        checked={checked}
                                        name={'productTypes'}
                                        text={name}
                                        onChange={() => onChangeProductTypes(_id)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {productUniqOptions?.productSorts?.length > 0 && (
                        <div className='flex-grow-0 min-w-24'>
                            <p className='mb-2'>{t('filterPanel.productSort')}</p>
                            <div className='w-full flex flex-col gap-y-1'>
                                {productUniqOptions?.productSorts?.map(({ _id, name, checked, inputName }) => (
                                    <CustomCheckbox
                                        key={_id}
                                        checked={checked}
                                        name={inputName || 'productSorts'}
                                        text={_id === 'other' ? t('filterPanel.otherSort') : name}
                                        onChange={(e) => onChangeCheckBoxesList(e.target.name, _id)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className='flex flex-row w-full gap-x-2'>
                    {productUniqOptions?.productSizes?.length > 0 && (
                        <div className='flex-grow w-full'>
                            <p className='mb-2'>{t('filterPanel.productSize')}</p>
                            <div className='w-full flex flex-col gap-y-1'>
                                {productUniqOptions?.productSizes?.map(({ _id, name, checked, inputName }) => (
                                    <CustomCheckbox
                                        key={_id}
                                        checked={checked}
                                        name={inputName || 'productSizes'}
                                        text={_id === 'other' ? t('filterPanel.otherSize') : name}
                                        onChange={(e) => onChangeCheckBoxesList(e.target.name, _id)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {productUniqOptions?.productLoads?.length > 0 && (
                        <div className='flex-grow-0 min-w-24'>
                            <p className='mb-2'>{t('filterPanel.productLoad')}</p>
                            <div className='w-full flex flex-col gap-y-1'>
                                {productUniqOptions?.productLoads?.map(({ _id, name, checked, inputName }) => (
                                    <CustomCheckbox
                                        key={_id}
                                        checked={checked}
                                        name={inputName || 'productLoads'}
                                        text={_id === 'other' ? t('filterPanel.otherLoad') : name}
                                        onChange={(e) => onChangeCheckBoxesList(e.target.name, _id)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='w-full flex flex-row justify-around gap-x-4 mt-auto pb-7 px-6 pt-4'>
                <button
                    className='flex flex-row items-center justify-center border border-theme-blue rounded-2xl text-theme-blue bg-transparent hover:bg-theme-blue-light hover:border-theme-blue-light hover:text-white h-8 w-full duration-100'
                    onClick={clearFilterForm}
                >
                    <IconClose className={'fill-current'}/>
                    <p>{t('filterPanel.clear')}</p>
                </button>
                <button
                    className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light h-8 w-full duration-100'
                    onClick={submitFilter}>{t('filterPanel.apply')}</button>
            </div>
        </div>
    );
};

export {
    FilterDatePickerCalendar,
    FilterDatePickerForm,
    FilterClientInput,
    FilterClientSelect,
    FilterCityInput,
    FilterPriceInput,
    FilterMaxCount,
    FilterMinCount,
    AsyncFilter
};

export default withTranslation()(Filter);
