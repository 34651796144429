import {gql} from '@apollo/client';
import {TYPE_MESSAGE} from '../../types';

export const DELETE_MESSAGE = gql`
    mutation removeMessage(
        $_id: ID!
    ){
        removeMessage(
            _id: $_id
        ){
            ${TYPE_MESSAGE}
        }
    }
`;
