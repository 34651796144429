import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NewTaraModal, Table, columnName } from 'components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DEACTIVATE_PRODUCT, GET_PRODUCT_LIST } from '_graphql';
import { FilterContext, GeneralContext } from 'contexts';
import { useAuthToken, useGraphqlCache } from '_hooks';
import { navigation } from '_constants';
import { useHistory } from 'react-router-dom';
import { useSegment } from 'react-segment-hooks';
import { withTranslation } from 'react-i18next';

const ActivatePalletsPage = ({ t }) => {
    const { tablePageSize } = useContext(GeneralContext);
    const { parseFilterParams } = useContext(FilterContext);
    const analytics = useSegment();
    const { userId } = useAuthToken();
    const history = useHistory();
    const { updateProductListInCache } = useGraphqlCache();

    const [deactivateProduct, { loading: deactivateLoading, error: deactivateError }] = useMutation(DEACTIVATE_PRODUCT);

    const [pagination, setPagination] = useState({ page: 1, userId, isActivate: true, pageSize: tablePageSize });

    const [getProductList, { data, loading, error }] = useLazyQuery(GET_PRODUCT_LIST, { fetchPolicy: 'network-only' });

    const products = useMemo(() => data?.products ? data?.products : { rows: [], totalPages: 0 }, [data]);

    useEffect(() => {
        analytics.page({ name: 'My Requests Page' });
    }, []);

    useEffect(() => {
        let variables = pagination;
        if (parseFilterParams && Object.keys(parseFilterParams).length > 0) {
            variables = {
                ...variables,
                ...parseFilterParams
            };
            analytics.track({
                event: 'Set Filter params in my suggestions pallets page',
                properties: { data: variables }
            });
        }

        getProductList({ variables });
    }, [pagination, parseFilterParams]);

    const handleClickRow = (item) => {
        history.push(`${navigation.pages.pallets}/${item._id}`);
    };

    const onToggleProductActivate = (item) => deactivateProduct({
        variables: { _id: item._id }, update: (cache, result) => {
            updateProductListInCache({
                product: result?.data?.activateProduct,
                variables: { ...pagination, ...parseFilterParams },
                options: { change: true }
            });
        }
    });

    return (
        <div className='flex-grow flex flex-row gap-x-5 h-full'>
            <Table
                tableName={'myProposals'}
                products={products}
                setPagination={setPagination}
                loader={(loading && !error) || (deactivateLoading && !deactivateError)}
                show={{
                    columns: [
                        { name: columnName.sequenceNumber, defaultShow: true, settingDisabled: true },
                        columnName.updatedAt,
                        columnName.city,
                        columnName.isBuy,
                        columnName.productType,
                        columnName.productPalletType,
                        columnName.productSort,
                        columnName.productSize,
                        columnName.productLoad,
                        columnName.minCount,
                        columnName.maxCount,
                        columnName.price,
                        columnName.isTax,
                        columnName.paymentType,
                        columnName.productBrands,
                        columnName.isCertificate,
                        columnName.description,
                        { name: columnName.isActivated, title: t('table.rows.remove') }
                    ],
                    tabletSort: [
                        columnName.sequenceNumber,
                        columnName.updatedAt,
                        columnName.city,
                        columnName.productPalletType,
                        columnName.productSort,
                        columnName.productSize,
                        columnName.productLoad,
                        columnName.minCount,
                        columnName.maxCount,
                        columnName.price,
                        columnName.isTax,
                        columnName.paymentType,
                        columnName.productBrands,
                        columnName.isCertificate,
                        columnName.description,
                        columnName.isBuy,
                        { name: columnName.isActivated, title: t('table.rows.remove') }
                    ],
                    activeRows: [{ value: (item) => item?.isActivated, className: 'bg-theme-blue-light-2' }],
                    downloadExcelFile: true
                }}
                change={{
                    handleClickRow,
                    onToggleProductActivate
                }}
            />
            <NewTaraModal pagination={pagination}/>
        </div>
    );
};

export default withTranslation()(ActivatePalletsPage);
