import {gql} from '@apollo/client';
import {TYPE_CONVERSATION_ID, TYPE_MESSAGE} from '_graphql/types';

export const GET_MESSAGES = gql`
    query messages(
        $conversationId: ID!
        $page: Int!
        $pageSize: Int
    ){
        messages(
            conversationId: $conversationId
            page: $page
            pageSize: $pageSize
        ){
            conversationId {
                ${TYPE_CONVERSATION_ID}
            }
            rows {
                ${TYPE_MESSAGE}
            }
            total
            page
            pageSize
            totalPages
        }
    }
`;
