import {gql} from '@apollo/client';
import {TYPE_ASSET} from '_graphql/types';

export const CREATE_ASSETS = gql`
    mutation createAssets(
        $data: AssetInput!
        $files: [Upload!]!
    ){
        createAssets(
            data: $data
            files: $files
        ) {
            ${TYPE_ASSET}
        }
    }
`;
