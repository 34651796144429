import {gql} from '@apollo/client';
import {TYPE_USERID} from '_graphql/types';

export const CREATE_NOTIFICATION = gql`
    mutation createNotification(
        $data: NotificationInput!
    ){
        createNotification(
            data: $data
        ){
            _id
            userId {
                ${TYPE_USERID}
            }
            title
            message
            seen
            createdAt
            updatedAt
        }
    }
`;
