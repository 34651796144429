import {columnName} from 'components/table/configuration';
import {IconArrow5, IconFilterTitle} from 'assets/images/svg';
import {
    AsyncFilter,
    FilterClientInput,
    FilterClientSelect
} from 'components/filter';
import React from 'react';

export default ({
                    t,
                    hide,
                    change,
                    onChangeSort,
                    checkSortField,
                    filterParams,
                    onOpenFilterPanel,
                    setRefTitle,
                    activeFilterPanel,
                    clearFilterParams,
                    applyFilterParams,
                    navToProfile
}) => ({
    name: columnName.username,
    title: t('table.header.username'),
    titleFilter: true,
    excelValue: (item) => item.username ? item.username : item.userId?.username || '',
    thead: function () {
        return (
            <th key={this.name}
                className={'tableTitle py-3 px-1 align-top'}>
                <div className='relative flex flex-row items-center'>
                    <div className='activeBtn flex flex-col items-center cursor-pointer justify-center'
                         onClick={(e) => onChangeSort(e, this.name)}>
                        <p className='whitespace-nowrap font-medium text-xs text-black'>{this.title}</p>
                        <div className='flex flex-row justify-center items-center'>
                            <IconArrow5
                                className={`h-2.5 w-2.5 fill-current text-theme-blue ${checkSortField(this.name)}`}/>
                            {this.titleFilter && (
                                <button
                                    className={`activeBtn text-theme-blue-light hover:text-theme-blue ml-1 ${filterParams?.userIds?.length ? 'active' : ''}`}
                                    onClick={(e) => onOpenFilterPanel(e,
                                        this.title === t('table.header.userProfileName') ? 'username' : 'userIds')
                                    }
                                >
                                    <IconFilterTitle className='stroke-current w-4 h-4'/>
                                </button>
                            )}
                        </div>
                    </div>
                    {this.titleFilter && ['userIds', this.name].includes(activeFilterPanel) && (
                        <AsyncFilter
                            name={this.name}
                            filterName={this.title === t('table.header.userProfileName') ? columnName.username : 'userIds'}
                            setRefTitle={setRefTitle}
                            clearFilterParams={clearFilterParams}
                            applyFilterParams={applyFilterParams}
                        >
                            {this.title === t('table.header.userProfileName') ? (
                                <FilterClientInput/>
                            ) : (
                                <FilterClientSelect
                                    filterType={'userIds'} isActiveUsers={change.isActive}
                                    filterVariables={change.filterVariables}
                                />
                            )}
                        </AsyncFilter>
                    )}
                </div>
            </th>
        );
    },
    tbody: function ({ userId, _id, username }) {
        return (
            <td key={this.name + _id} className={'px-1'}>
                <button
                    className={`truncate max-w-30 lg+:max-w-50 xl:max-w-100 w-max text-theme-blue ${hide.handleClickUsername ? 'cursor-default' : ' hover:text-blue-400 cursor-pointer'} duration-100`}
                    onClick={(e) => navToProfile(e, username ? _id : userId?._id)}
                >
                    {username ? username : userId?.username || ''}
                </button>
            </td>
        );
    },
    tabletTitle: function ({ _id }) {
        return (
            <p key={`title_${this.name}${_id}`}
               className='truncate whitespace-nowrap font-medium'>{this.title}</p>
        );
    },
    tabletContent: function ({ userId, _id, username }) {
        return (
            <button
                key={`content_${this.name}${_id}`}
                className={`truncate text-left ${hide.handleClickUsername ? 'cursor-default' : ' hover:text-blue-400 cursor-pointer'} duration-100`}
                onClick={(e) => navToProfile(e, username ? _id : userId?._id)}
            >
                {username ? username : userId?.username || ''}
            </button>
        );
    }
})
