import {gql} from '@apollo/client';
import {TYPE_CONVERSATION} from '_graphql/types';

export const GET_CONVERSATION = gql`
    query conversation(
        $_id: ID
    ){
        conversation(
            _id: $_id
        ){
            ${TYPE_CONVERSATION}
        }
    }
`;
