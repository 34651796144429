import {gql} from '@apollo/client';
import {
    TYPE_PRODUCT
} from '_graphql/types';

export const GET_PRODUCT = gql`
    query product($_id: ID!){
        product(_id: $_id) {
            ${TYPE_PRODUCT}
            isDeleted
        }
    }
`;
