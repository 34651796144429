import {ToolTip} from 'components';
import {formatDateRowsUtils} from 'utils';
import {IconDelete, IconSendMessage} from 'assets/images/svg';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {useAuthToken} from '_hooks';

const DeliveryRoute = ({ t, route, delivery, sendOrder, hide, show, remove }) => {
    const { userId } = useAuthToken()

    const {
        btnSendMessage: hideBtnSendMessage
    } = hide

    const {
        btnRemove: showBtnRemove
    } = show

    const onSendOrder = (e) => {
        e.stopPropagation()

        if (userId !== delivery.userId?._id) {
            sendOrder({
                _id: delivery._id,
                isDriver: delivery.isDriver,
                freeSpace: route.freeSpace,
                space: route.space,
                ownerId: delivery.userId._id
            })
        }
    }

    return (
        <>
            <div className='flex flex-row items-center justify-between gap-x-10 text-xss mb-4'>
                {route.startAddress && (
                    <>
                        <p
                            data-tip data-for={route._id + 'startAddress'}
                            className='py-2 border border-theme-blue rounded-full w-24 text-center truncate px-3'
                        >
                            {route?.startAddress?.placeName.split(',')[0] || '-'}
                        </p>
                        <ToolTip id={route._id + 'startAddress'} title={route?.startAddress?.placeName} place={'right'}/>
                    </>
                )}
                {route.endAddress && (
                    <>
                        <p
                            data-tip data-for={route._id + 'endAddress'}
                            className='py-2 border border-theme-blue bg-theme-blue text-white rounded-full w-24 truncate text-center px-3'
                        >
                            {route?.endAddress?.placeName.split(',')[0] || '-'}
                        </p>
                        <ToolTip id={route._id + 'endAddress'} title={route?.endAddress?.placeName} place={'right'}/>
                    </>
                )}
            </div>
            {route.startDate && (
                <div className='flex flex-row items-baseline justify-start mb-2.5'>
                    <p className='w-24'>{t('deliveryTable.dateLoad')}</p>
                    <p>{formatDateRowsUtils(route.startDate)}</p>
                </div>
            )}
            {route.endDate && (
                <div className='flex flex-row items-baseline justify-start mb-2.5'>
                    <p className='w-24'>{t('deliveryTable.dateUnload')}</p>
                    <p>{formatDateRowsUtils(route.endDate)}</p>
                </div>
            )}
            <div className='flex flex-row items-baseline justify-start mb-2.5'>
                <p className='w-24'>{t(delivery.isDriver ? 'modals.newDeliveryModal.freeSpace' : 'modals.newDeliveryModal.space')}</p>
                <p>{Number(delivery.isDriver ? route.freeSpace : route.space)} {t('deliveryTable.capacity')}</p>
            </div>
            <div className='relative flex flex-row items-center justify-start'>
                <p className='w-24'>{route.price} {t('deliveryTable.currency')}</p>
                <p>{t('modals.newDeliveryModal.capacity')}</p>
                {!hideBtnSendMessage && userId !== delivery.userId?._id && (
                    <button
                        className='ml-auto hover:text-theme-blue-light duration-100'
                        onClick={onSendOrder}
                    >
                        <IconSendMessage className='stroke-current'/>
                    </button>
                )}
                {showBtnRemove && userId === delivery.userId?._id && (
                    <button
                        className='absolute right-0 bottom-0 ml-auto hover:text-white duration-100 hover:bg-theme-blue text-theme-blue border border-theme-blue rounded-full p-2'
                        onClick={(e) => remove(e, delivery._id)}
                    >
                        <IconDelete className='stroke-current'/>
                    </button>
                )}
            </div>
        </>
    )
}

export default withTranslation()(DeliveryRoute)
