import React from 'react';
import { withTranslation } from 'react-i18next';
import { IconPallets } from 'assets/images/svg';
import { tableConstants } from '_constants';

const PalletTablet = (
    {
        t,
        hide: {
            handleClickRow: hideHandleClickRow
        },
        show: {
            activeRows
        },
        change: {
            noContentMessage: changeNoContentMessage
        },
        tableSettings: {
            hideColumns
        },
        products,
        handleClickRow,
        activeTableColumns
    }) => {
    const getClassNameRow = (item) => {
        let classes = 'w-72 bg-theme-gray-main shadow-main rounded-xl flex flex-col duration-100 p-2.5';
        let addedClasses = false;
        let hover = hideHandleClickRow;

        if (activeRows && typeof activeRows === 'object') {
            (Array.isArray(activeRows) ? activeRows : [activeRows]).forEach(({ value, className, hideClick }) => {
                if (!addedClasses && typeof value === 'function' && value(item)) {
                    classes += ' ' + (className ? className : tableConstants.default.ActiveRowClassName);
                    addedClasses = true;
                    hover = !!hideClick;
                }
            });
        }

        if (!hover) {
            classes += ' cursor-pointer hover:bg-theme-blue-main hover:text-theme-blue hover:border-theme-blue';
        }

        return classes;
    };

    return (
        <div className='flex flex-col w-full h-full rounded-xl gap-y-2.5'>
            <div
                className='overflow-y-auto scrollable flex-grow inline-flex flex-wrap gap-5 pb-14 py-2.5 content-start justify-start'>
                {products?.rows?.length > 0 ? products.rows.map(
                    (item) => (
                        <div
                            key={`productsTablet${item._id}`}
                            className={getClassNameRow(item)}
                            onClick={() => handleClickRow(item)}
                        >
                            {item.assets && item.assets.length > 0 && item.assets?.some(({ mimetype }) => mimetype?.includes('image/')) ? (
                                <img
                                    src={item.assets?.find(({ mimetype }) => mimetype?.includes('image/')).assetUrl}
                                    width='100%'
                                    height='auto'
                                    className='object-cover w-full h-36 mb-1'
                                    alt={item.title}
                                />
                            ) : (
                                <div className='object-cover w-full h-36 mb-1 flex items-center justify-center'>
                                    <IconPallets/>
                                </div>
                            )}
                            <div className='flex flex-row gap-x-4 text-xs font-normal'>
                                <div className='w-min text-theme-blue flex flex-col gap-y-1.5'>
                                    {activeTableColumns.map((row) => hideColumns?.includes(row.name) ? null : row.tabletTitle?.call(row, item))}
                                </div>
                                <div className='w-full text-black flex flex-col gap-y-1.5'>
                                    {activeTableColumns.map((row) => hideColumns?.includes(row.name) ? null : row.tabletContent?.call(row, item))}
                                </div>
                            </div>
                        </div>
                    )) : (
                    <div className='flex justify-center items-center h-full w-full'>
                        <p className='text-lg uppercase text-gray-300'>{changeNoContentMessage && typeof changeNoContentMessage === 'string' ? changeNoContentMessage : t('table.noData')}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(PalletTablet);
