import {IconArrow, IconArrowsHorizontal, IconFullPallet, IconTruck1} from 'assets/images/svg';
import React, {useState} from 'react';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {OrderDeliveryModal, RemoveDeliveryModal} from 'components';
import DeliveryRoute from './delivery-route';
import ReactPaginate from 'react-paginate';

const DirectionsTable = ({ t, hide, show, change, deliveries, activePlace, setActivePlace, setPagination }) => {
    const [removeDeliveryModal, setRemoveDeliveryModal] = useState({ display: false, _id: null })
    const [orderDeliveryModal, setOrderDeliveryModal] = useState({ display: false, params: null })

    const paginationContainerClassName = 'mt-auto flex justify-center items-center gap-1 list-none my-1 text-theme-blue text-xs font-normal'
    const paginationButtonsClassName = ' h-5 w-5 rounded-full flex justify-center items-center hover:bg-gray-200 duration-100'

    const {
        owner: hideOwner,
        pagination: hidePagination
    } = hide

    const {
        noContentMessage: changeNoContentMessage
    } = change

    const {
        bgDeliveryIcon: showBgDeliveryIcon
    } = show

    const sendOrder = (params) => {
        setOrderDeliveryModal({ display: true, params })
    }

    const removeDelivery = (e, id) => {
        e.stopPropagation()
        setRemoveDeliveryModal({ display: true, _id: id })
    }

    const handlePageClick = (event) => {
        const page = event.selected + 1;
        setPagination((prev) => ({...prev, page }))
    }

    const PaginationComponent = () => !hidePagination && deliveries.rows?.length > 0 ? (
        <ReactPaginate
            previousLabel={<IconArrow className='fill-current text-theme-blue transform -rotate-90'/>}
            nextLabel={<IconArrow className='fill-current text-theme-blue transform rotate-90'/>}
            pageCount={deliveries.totalPages || 0}
            forcePage={deliveries.page - 1}
            onPageChange={handlePageClick}
            containerClassName={paginationContainerClassName}
            pageLinkClassName={paginationButtonsClassName}
            previousLinkClassName={paginationButtonsClassName}
            nextLinkClassName={paginationButtonsClassName}
            activeLinkClassName={'bg-theme-blue text-white hover:bg-blue-400 text-xs font-normal'}
        />
    ) : (<></>)

    return (
        <div className='absolute z-5 left-0 top-0 h-full w-73 rounded-xl bg-theme-gray-main shadow-main py-2.5 overflow-hidden text-xs'>
            {deliveries?.rows?.length > 0 ? (
                <div className='scrollable overflow-x-hidden h-full flex flex-col'>
                    <div className='flex flex-row items-center pt-4 pb-2 pl-7'>
                        <p className='mr-2 text-lg'>{t('deliveryTable.directions')}</p>
                        <IconArrowsHorizontal/>
                    </div>
                    {deliveries?.rows?.map((delivery) => (
                        <div
                            key={delivery._id}
                            className={`min-h-34 py-2.5 px-7 bg-no-repeat ${delivery?._id === activePlace?._id ? 'bg-theme-gray-light-2' : ''} ${showBgDeliveryIcon && delivery.isDriver ? 'bg-delivery-truck bg-center' : ''} ${showBgDeliveryIcon && !delivery.isDriver ? 'bg-delivery-pallet bg-center' : ''} hover:bg-theme-gray-light-2 cursor-pointer duration-100`}
                            onClick={() => setActivePlace(delivery)}
                        >
                            {!hideOwner && (
                                <div className='flex flex-row items-center mb-5'>
                                    {delivery.isDriver ? (
                                        <IconTruck1 className='stroke-current text-theme-blue'/>
                                    ) : (
                                        <IconFullPallet className='stroke-current text-theme-blue'/>
                                    )}
                                    <p className='ml-4'>{delivery?.userId?.username}</p>
                                </div>
                            )}
                            {delivery.deliveryRoutes?.map((route) => (
                                <DeliveryRoute
                                    key={route._id}
                                    hide={hide}
                                    show={show}
                                    delivery={delivery}
                                    route={route}
                                    sendOrder={sendOrder}
                                    remove={removeDelivery}
                                />
                            ))}
                        </div>
                    ))}
                    <PaginationComponent />
                </div>
            ) : (
                <div className='flex justify-center items-center h-full w-full'>
                    <p className='text-lg uppercase text-gray-300'>{changeNoContentMessage && typeof changeNoContentMessage === 'string' ? changeNoContentMessage : 'no data'}</p>
                </div>
            )}

            <RemoveDeliveryModal
                isOpen={removeDeliveryModal.display}
                onClose={() => setRemoveDeliveryModal({ display: false })}
                _id={removeDeliveryModal._id}
            />
            <OrderDeliveryModal
                isOpen={orderDeliveryModal.display}
                onClose={() => setOrderDeliveryModal({ display: false })}
                params={orderDeliveryModal.params}
            />
        </div>
    )
};

DirectionsTable.propTypes = {
    activePlace: PropTypes.object,
    setPagination: PropTypes.func.isRequired
}

DirectionsTable.defaultProps = {
    deliveries: [],
    activePlace: null,
    setActivePlace: () => {},
    hide: {
        owner: false, // true
        btnSendMessage: false, // true
        pagination: false
    },
    show: {
        btnRemove: false, // true
        bgDeliveryIcon: false // true
    },
    change: {
        noContentMessage: false // 'no data'
    }
}

export default withTranslation()(DirectionsTable)
