import { gql } from '@apollo/client';
import {TYPE_PRODUCT_CUSTOMER} from '_graphql/types';

export const GET_PRODUCT_CUSTOMER = gql`
    query productCustomer(
        $_id: ID
        $productId: ID
        $ownerId: ID
        $customerId: ID
    ){
        productCustomer(
            _id: $_id
            productId: $productId
            ownerId: $ownerId
            customerId: $customerId
        ){
            ${TYPE_PRODUCT_CUSTOMER}
        }
    }
`;
