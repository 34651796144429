import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];

const store = createStore(
    rootReducer(history),
    applyMiddleware(...middlewares)
);

export default store;
export {history};
