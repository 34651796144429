import React, { useEffect, useMemo, useState } from 'react';
import { IconArrow1, IconArrow1Disabled, IconClose } from 'assets/images/svg';
import { useOutsideClick } from '_hooks';
import { withTranslation } from 'react-i18next';

const MultiSelect = ({
    t,
    data,
    defaultSelected = [],
    setValue = () => {},
    isUserList = false,
    isDisabled = false,
    className
}) => {
    const [panel, setPanel] = useState(false);
    const [selected, setSelected] = useState(defaultSelected);

    useEffect(() => {
        if (defaultSelected?.length && typeof defaultSelected[0] !== 'string') {
            setSelected(defaultSelected);
        }
    }, [defaultSelected]);

    const selectPanelRef = useOutsideClick(() => setPanel(false));

    const list = useMemo(() => data?.filter((item) => !selected?.find((select) => select._id === item._id)) || [], [data, selected]);

    useEffect(() => {
        setValue(selected.map(({ _id }) => _id) || []);
    }, [selected]);

    const panelToggle = () => !isDisabled && setPanel((prev) => !prev);

    const selectToggle = (e, item) => {
        e.stopPropagation();

        const find = selected.find((select) => item._id === select._id);
        setSelected((prev) => find ? prev.filter((prevItem) => prevItem._id !== item._id) : [...prev, item]);
    };

    return (
        <div ref={selectPanelRef} className={className}>
            <div
                className={`w-full flex border ${isDisabled ? 'border-theme-gray cursor-not-allowed' : 'border-theme-blue cursor-pointer'} ${panel ? 'rounded-t-xl' : 'rounded-xl'}`}
                onClick={panelToggle}>
                <div className='flex flex-auto flex-wrap'>
                    {selected?.length && !isDisabled ? selected?.map((item) => (
                        <button
                            key={item._id}
                            onClick={(e) => selectToggle(e, item)}
                            type='button'
                            className='flex justify-center items-center mx-1 font-medium px-2 hover:text-theme-blue-light'
                        >
                            <p className='text-xs font-normal leading-none max-w-full flex-initial'>{item.name}</p>
                            <IconClose className='fill-current'/>
                        </button>
                    )) : (
                        <p className={`flex justify-center items-center ml-1 pl-2 ${isDisabled ? 'text-theme-gray' : 'hover:text-theme-blue-light'}`}>
                            {isDisabled ? '-' : t('components.multiSelect.notSelect')}
                        </p>
                    )}
                </div>
                <div className='text-gray-300 w-8 pl-2 pr-1 flex items-center'>
                    <button type='button'
                            className={`${isDisabled && 'cursor-not-allowed'} w-6 h-6 text-gray-600 outline-none focus:outline-none`}>
                        {!isDisabled ? (
                            <IconArrow1 className={`fill-current ${panel ? 'transform rotate-180' : ''} duration-100`}/>
                        ) : (
                            <IconArrow1Disabled
                                className={`fill-current ${panel ? 'transform rotate-180' : ''} duration-100`}/>
                        )}
                    </button>
                </div>
            </div>
            <div
                className={`${panel ? '' : 'hidden'} border overflow-hidden border-theme-blue border-t-0 max-h-30 ${isUserList ? 'h-full' : 'absolute'} shadow top-full bg-theme-gray-light-3 z-40 w-full rounded-b-2xl max-h-select`}>
                <div
                    className={'overflow-y-auto scrollable max-h-30'}>
                    <div className='flex flex-col overflow-y-auto w-full'>
                        {list.length > 0 ? list.map((item) => (
                            <div key={item._id} onClick={(e) => selectToggle(e, item)}
                                 className='py-2 px-4 flex items-center cursor-pointer w-full hover:bg-theme-blue-light-2 leading-6 duration-100'>
                                {item.name}
                            </div>
                        )) : (
                            <p className='p-2 w-full flex items-center justify-center leading-6 rounded-t uppercase text-theme-blue'> {t('components.multiSelect.empty')} </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(MultiSelect);
