import React from 'react';
import ReactModal from 'react-modal';
import { IconClose } from 'assets/images/svg';

const BaseModal = ({ isOpen, onClose, width = false, showClose = true, children }) => (
    <ReactModal
        className='fixed z-40 inset-0 flex min-h-screen items-center justify-center transition-all duration-300'
        overlayClassName='z-100'
        isOpen={isOpen}
        onRequestClose={onClose}
    >
        <div className='fixed inset-0 transition-opacity'>
            <div className='absolute inset-0 bg-gray-700 opacity-75' onClick={onClose}></div>
        </div>

        <div
            className={`${width ? width : 'w-full'} max-w-modal ${width === 'w-detailsModal' ? '' : width} max-h-19/20 rounded-md bg-white shadow-xl transform transition-all scrollable overflow-x-hidden`}>
            {showClose && (
                <div className='hidden sm:block absolute top-0 right-0 pt-2 pr-2'>
                    <button type='button'
                            onClick={onClose}
                    >
                        <IconClose className='fill-current text-theme-blue hover:text-theme-blue-light duration-100'/>
                    </button>
                </div>
            )}
            {children}
        </div>
    </ReactModal>
);

export { default as BuySellProductModal } from './buy-sell-product-modal';
export { default as OrderDeliveryModal } from './order-delivery-modal';
export { default as LogoutModal } from './logout-modal';
export { default as NewDeliveryModal } from './new-delivery-modal';
export { default as NewTaraModal } from './new-tara-modal';
export { default as RemoveProductModal } from './remove-product-modal';
export { default as RemoveDeliveryModal } from './remove-delivery-modal';
export { default as ProductDetailsModal } from './product-details-modal';
export { default as OrderFinishModal } from './order-finish-modal';
export { default as CreatePrivateChatModal } from './create-private-chat-modal';
export { default as RemoveMessageModal } from './remove-message-modal';
export { default as OrderDocumentsModal } from './order-documents-modal';
export { default as CloseConversationModal } from './close-conversation-modal';

export default BaseModal;
