import {gql} from '@apollo/client';
import {TYPE_CONVERSATION} from '_graphql/types';

export const CREATE_CONVERSATION_SUBSCRIPTION = gql`
    subscription conversationCreated(
        $userId: ID!
    ) {
        conversationCreated(
            userId: $userId
        ) {
            ${TYPE_CONVERSATION}
        }
    }
`;
