import {gql} from '@apollo/client';
import {TYPE_PRODUCT} from '_graphql/types';

export const CREATE_PRODUCT_SUBSCRIPTION = gql`
    subscription productCreated(
        $userId: ID
    ){
        productCreated(
            userId: $userId
        ){
            ${TYPE_PRODUCT}
        }
    }
`;
