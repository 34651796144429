import React from 'react';
import {columnName} from 'components/table/configuration';

export default ({
                    t,
                    onToggleProductActivate
                }) => ({
    name: columnName.isActivated,
    title: t('table.rows.activate'),
    excelValue: null,
    thead: function () {
        return (<th key={this.name} className={'px-1'}/>);
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'w-1 whitespace-nowrap px-1'}>
                <button
                    className={'w-20 text-center text-menu font-normal cursor-pointer rounded-3xl py-2.5 hover:shadow-md hover:duration-100 text-white bg-theme-blue hover:bg-blue-400'}
                    onClick={(e) => onToggleProductActivate(e, item, this.title === t('table.rows.activate') ? 'activate' : 'deactivate')}
                >
                    {this.title}
                </button>
            </td>
        );
    },
    tabletTitle: () => null,
    tabletContent: function (item) {
        return (
            <button
                key={`content_${this.name}${item._id}`}
                className={'w-20 text-center inline-block text-menu font-normal cursor-pointer rounded-3xl py-2.5 hover:shadow-md hover:duration-100 text-white bg-theme-blue hover:bg-blue-400'}
                onClick={(e) => onToggleProductActivate(e, item, this.title === t('table.rows.activate') ? 'activate' : 'deactivate')}
            >
                {this.title}
            </button>
        );
    }
})
