import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IconClose, IconEmptyDelivery, IconEmptyPallets, IconPersonName } from 'assets/images/svg';
import moment from 'moment';
import { messenger } from '_constants';
import { GeneralContext, MessagesContext } from 'contexts';
import { useAuthToken } from '_hooks';
import { withTranslation } from 'react-i18next';
import { ToolTip } from 'components';
import { useSegment } from 'react-segment-hooks';

const ConversationsBlock = ({ t, closeChat, search }) => {
    const analytics = useSegment();
    const { setLoading } = useContext(GeneralContext);
    const { changeActiveConversation, sortConversations, selectedConversation, loading } = useContext(MessagesContext);
    const { userId } = useAuthToken();

    const [conversation, setConversation] = useState(selectedConversation);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    useEffect(() => {
        if (selectedConversation) {
            setConversation(selectedConversation);
        }
    }, [selectedConversation]);

    const searchSortConversations = useMemo(() => {
        let list = sortConversations;

        if (search) {
            const searchLowerCase = search.toLowerCase();
            list = sortConversations.filter((item) => item._id?.toLowerCase().includes(searchLowerCase) || item.members.some((member) => member?.username?.toLowerCase().includes(searchLowerCase)));
            analytics.track({ event: 'Search in conversations', properties: { params: { search } } });
        }

        return list.map((item) => {
            const {
                _id,
                lastMessageId,
                lastMessageDate,
                members,
                unreadMessagesCount,
                createdAt,
                name,
                type,
                productId,
                deliveryId
            } = item;

            let avatar = lastMessageId?.userId?.avatar?.assetUrl;
            let userName = lastMessageId?.userId?._id !== userId ? lastMessageId?.userId?.username : t('messenger.you');
            let chatName = name || '';
            let message = lastMessageId?.message;
            const date = lastMessageDate ? lastMessageDate : createdAt;
            const selected = conversation?._id === _id;
            let chatMembers = null;

            if (type === messenger.types.public) {
                if (lastMessageId?.userId) {
                    userName = lastMessageId.userId._id !== userId ? lastMessageId.userId.username : t('messenger.you');
                }
                if (!message && lastMessageId?.assets?.length > 0) {
                    message = lastMessageId?.assets?.length > 1 ? t('messenger.sendFiles', { count: lastMessageId?.assets?.length }) : lastMessageId?.assets[0].filename || '';
                }
                chatMembers = members?.filter((member) => member?._id !== lastMessageId?.userId?._id).slice(0, 4);
            }

            if (type === messenger.types.private) {
                userName = null;
                const findCompanion = members?.find((item) => item._id !== userId);
                if (productId) {
                    avatar = productId.assets?.[0]?.assetUrl || IconEmptyPallets;
                    const productType = t(`modals.productDetails.taraTypeOptions.${productId?.productType?.value}`);
                    const productPallet = (productId?.customPalletType || productId?.productPalletType?.name);
                    const sortValue = productId?.productSort?.name.toLowerCase() === 'higher' ?
                        t('global.productOptions.sortHigher') :
                        productId?.productSort?.name;
                    const productSort = `${t('modals.productDetails.taraSort')} ${sortValue}`;
                    const productPrice = `${productId.price} ${t('modals.productDetails.priceCurrency')}`;

                    message = [productType + (productPallet ? ` ${productPallet}` : ''), productSort, productPrice].filter((item) => item).join('; ');
                }
                if (deliveryId) {
                    avatar = deliveryId.assets?.[0]?.assetUrl || IconEmptyDelivery;
                }
                if (findCompanion) {
                    if (!avatar) {
                        avatar = findCompanion.avatar?.assetUrl;
                    }
                    chatName = findCompanion.username;
                    chatMembers = [findCompanion];
                }
            }

            if (lastMessageId?.isDeleted) {
                message = t('messenger.deleted');
            }

            return {
                _id,
                unreadMessagesCount,
                createdAt,
                chatName,
                avatar,
                userName,
                message,
                date,
                selected,
                type,
                members: chatMembers,
                conversation: item
            };
        });
    }, [search, sortConversations, conversation]);

    return (
        <div className='flex-grow flex flex-col gap-y-4 overflow-x-hidden scrollable px-9 pt-2 pb-8'>
            {searchSortConversations.map(({
                _id,
                message,
                unreadMessagesCount,
                date,
                chatName,
                avatar,
                userName,
                selected,
                members,
                type,
                conversation
            }) => (
                <div
                    key={_id}
                    className={`relative rounded-xl shadow-main h-20 py-3 pl-3 flex flex-row flex-nowrap items-center gap-x-2 transform ${!selected && !!conversation?.chatClosesUsers?.length && 'bg-red-200'} ${selected ? (!!conversation?.chatClosesUsers?.length && 'scale-105 bg-red-300') || 'scale-105 bg-theme-blue-main' : (!!conversation?.chatClosesUsers?.length && 'scale-100 hover:bg-red-300 hover:scale-105 cursor-pointer') || 'scale-100 hover:bg-theme-blue-main hover:scale-105 cursor-pointer'} ${type === messenger.types.public ? 'xs:bg-theme-blue-light-2' : ''} bg-opacity-50 duration-100`}
                    onClick={() => changeActiveConversation(conversation)}
                >
                    <div
                        className='flex-none min-w-12 max-w-12 h-12 bg-theme-blue-main rounded-full flex justify-center items-center overflow-hidden'>
                        {avatar ? (
                            <img width='48' height='48' src={avatar} alt={userName || '-'}
                                 className='rounded-full'/>
                        ) : (
                            <IconPersonName className='w-5 h-5 stroke-current'/>
                        )}
                    </div>
                    <div className={'flex-grow flex flex-col text-xs max-w-53 justify-between h-full'}>
                        <p className='text-sm truncate'>{chatName}</p>
                        <p className='truncate flex flex-row flex-nowrap gap-x-2'>
                            {userName && (
                                <span className='flex-grow-0 min-w-max max-w-1/2 truncate'>{userName}</span>
                            )}
                            {message && (
                                <span className='flex-grow truncate inline-block text-black'>{message}</span>
                            )}
                        </p>
                        <p className='truncate'>{moment(date).calendar()}</p>
                    </div>
                    {unreadMessagesCount > 0 && (
                        <div className='flex-none flex items-center'>
                            <p className='h-10 w-5 flex items-center justify-center bg-theme-red rounded-l-full inline-block text-white text-xss'>
                                {unreadMessagesCount > 99 ? '99+' : (unreadMessagesCount || 0)}
                            </p>
                        </div>
                    )}
                    {members && (
                        <div className='absolute right-5 bottom-1.5 flex flex-row'>
                            {members?.map((member) => (
                                <div
                                    data-tip
                                    data-for={member._id + _id}
                                    key={member._id}
                                    className='w-5 h-5 bg-theme-blue-main border border-theme-blue rounded-full flex items-center justify-center -ml-1.5 overflow-hidden'
                                >
                                    {member.avatar?.assetUrl ? (
                                        <img width='20' height='20' className='block' src={member.avatar?.assetUrl}
                                             alt={userName || '-'}/>
                                    ) : (
                                        <IconPersonName className='w-2.5 h-2.5 stroke-current'/>
                                    )}
                                    <ToolTip id={member._id + _id} title={member?.username}/>
                                </div>
                            ))}
                        </div>
                    )}
                    {!conversation?.chatClosesUsers?.find((item) => item === userId) && type !== 'public' && (
                        <>
                            <button
                                data-tip
                                data-for={`closeChat_${_id}`}
                                onClick={() => type === 'public' ? {} : closeChat(_id)}
                                className='absolute right-5 top-3 h-5 w-5 text-red-700 hover:text-red-700 duration-100'
                            >
                                <IconClose className='fill-current h-3 w-3 mx-auto my-auto'/>
                            </button>
                            <ToolTip id={`closeChat_${_id}`} title={t('global.buttons.close')}/>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

export default withTranslation()(ConversationsBlock);
