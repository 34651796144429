import {gql} from '@apollo/client';

export const REMOVE_DELIVERY = gql`
    mutation removeDelivery(
        $_id: ID!
    ){
        removeDelivery(
            _id: $_id
        ){
            _id
        }
    }
`;
