import {columnName} from 'components/table/configuration';
import React from 'react';
import {IconClip} from 'assets/images/svg';
import {ToolTip} from 'components/index';

export default ({ t, openOrderDocumentsModal }) => ({
    name: columnName.documents,
    title: t('table.header.documents'),
    titleFilter: false,
    excelValue: null,
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1 align-top text-left'}>
                <p className='whitespace-nowrap font-medium text-xs text-black'>
                    {this.title}
                </p>
            </th>);
    },
    tbody: function ({ _id, assets }) {
        return (
            <td key={this.name + _id} className={'px-1 max-w-20 lg+:max-w-30 w-max-60'}>
                <button
                    data-tip
                    data-for={`doc-${_id}`}
                    onClick={(e) => {
                        openOrderDocumentsModal(e, assets);
                    }}
                    type='button'
                    className={`cursor-pointer h-10 w-10 flex-none flex items-center justify-center rounded-full bg-theme-blue-main ${assets?.length ? 'text-theme-blue hover:bg-theme-blue-light hover:text-white' : 'cursor-auto text-white'} duration-100`}
                >
                    <IconClip className={'stroke-current'}/>
                    {!!assets?.length && (
                        <ToolTip id={`doc-${_id}`} title={t('modals.orderDocuments.view')}/>
                    )}
                </button>
            </td>
        );
    },
    tabletTitle: () => null,
    tabletContent: function ({ _id, assets }) {
        return (
            <div
                key={this.name + _id}
                onClick={(e) => {
                    openOrderDocumentsModal(e, assets);
                }}
                className={`w-20 text-center text-menu font-normal rounded-3xl py-1.5 my-1.5 ${assets?.length ? 'text-theme-blue bg-theme-blue-main hover:bg-theme-blue hover:text-white' : 'text-theme-gray bg-theme-blue-light-2'}`}
            >
                {t('table.header.documents')}
            </div>
        );
    }
})
