import { IconSettings } from 'assets/images/svg';
import React, { useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useOutsideClick } from '_hooks';
import { CustomCheckbox } from 'components/common';
import { hasOwnPropertyUtils } from 'utils';
import { useSegment } from 'react-segment-hooks';

const TableSettings = ({ t, show, tableName, activeTableColumns, tableSettings, changeSettings, initialState }) => {
    const analytics = useSegment();
    const [settingPanel, setSettingPanel] = useState(false);

    const { hideColumns } = tableSettings;
    const { columns } = show;

    const activeColumns = useMemo(() => columns.map((item) => {
        const object = (typeof item === 'object' ? item : { name: item });
        const findColumn = activeTableColumns.find((col) => object.name === col.name);
        const disabled = hasOwnPropertyUtils(object, 'settingDisabled') ? object.settingDisabled : false;
        return ({
            ...object,
            active: !(disabled && !object.defaultShow) && !hideColumns?.includes(object.name),
            title: findColumn?.title || object.name,
            disabled
        });
    }), [hideColumns, t]);

    const settingPanelRef = useOutsideClick(() => setSettingPanel(false));

    const changeActiveColumns = (name) => {
        const findItem = hideColumns?.find((item) => item === name);
        const newData = (findItem ? hideColumns?.filter((item) => item !== name) : [...hideColumns || [], name]);
        changeSettings('hideColumns', newData.length > 0 ? newData : null);
    };

    const handleSettingsClick = () => {
        analytics.track({
            event: 'Clicked on button in Table',
            properties: { buttonName: 'Settings', tableName }
        });
        setSettingPanel((prev) => !prev);
    };

    const columnsCheckAll = () => {
        changeSettings('hideColumns', null);
    };

    const columnsDefault = () => {
        changeSettings('hideColumns', initialState.hideColumns);
    };

    return (
        <div ref={settingPanelRef} className='p-0 m-0 flex items-center'>
            <div
                className={`${settingPanel ? 'block' : 'hidden'} absolute bottom-8 right-0 min-w-70 text-theme-blue text-xs font-normal bg-theme-gray-main shadow-main rounded-2xl px-3 py-2 `}>
                <div className='w-50 flex flex-col gap-1'>
                    <p>{t('table.settings.columns')}</p>
                    <div>
                        {activeColumns.map(({ name, active, disabled, title }, index) => (
                            <CustomCheckbox
                                key={'settingsColumns' + name + index}
                                checked={active}
                                name={name}
                                text={title}
                                disabled={disabled}
                                onChange={() => changeActiveColumns(name)}
                            />
                        ))}
                    </div>
                    <div className='flex flex-row justify-start gap-2'>
                        <button
                            className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light py-1 px-2 w-max text-xss duration-100'
                            onClick={columnsCheckAll}
                        >
                            {t('global.buttons.selectAll')}
                        </button>
                        <button
                            className='flex flex-row items-center justify-center bg-theme-blue rounded-2xl text-white hover:bg-theme-blue-light py-1 px-2 w-max text-xss duration-100'
                            onClick={columnsDefault}
                        >
                            {t('global.buttons.reset')}
                        </button>
                    </div>
                </div>
            </div>
            <button className='text-theme-blue hover:text-theme-blue-light duration-100' onClick={handleSettingsClick}>
                <IconSettings className={'w-4 h-4 fill-current'}/>
            </button>
        </div>
    );
};

export default withTranslation()(TableSettings);
