import { TYPE_MEMBERS, TYPE_USER, TYPE_USERID } from '../user';
import { TYPE_ASSET } from '../asset';

export const TYPE_CITY = `
    name
    placeId
`;

export const TYPE_PRODUCT_STATUS = `
    _id
    name
    value
`;

export const TYPE_PAYMENT_TYPE = `
    _id
    name
    value
`;

export const TYPE_PRODUCT_SIZE = `
    _id
    name
    value
`;

export const TYPE_PRODUCT_SORT = `
    _id
    name
    value
`;

export const TYPE_PRODUCT_PALLET_TYPE = `
    _id
    name
    value
`;

export const TYPE_PRODUCT_BRAND = `
    _id
    name
    value
`;

export const TYPE_PRODUCT_LOAD = `
    _id
    name
    value
    index
    highlight
`;

export const TYPE_PRODUCT_TYPE = `
    _id
    name
    value
    isCustomSize
    isCustomSort
    isCustomLoad
    isPalletType
    isBrand
    isCertificate
    isHeight
    isWidth
    isLength
        productSize {
            ${TYPE_PRODUCT_SIZE}
        }
        productSort {
            ${TYPE_PRODUCT_SORT}
        }
        productLoad {
            ${TYPE_PRODUCT_LOAD}
        }
`;
export const TYPE_PRODUCT_TYPES = `
    rows {
        ${TYPE_PRODUCT_TYPE}
    }
`;

export const TYPE_PRODUCT = `
    _id
    title
    assets {
        ${TYPE_ASSET}
    }
    userId {
        ${TYPE_USERID}
    }
    description
    minCount
    maxCount
    count
    productStatus {
        ${TYPE_PRODUCT_STATUS}
    }
    productType {
        ${TYPE_PRODUCT_TYPE}
    }
    paymentType {
        ${TYPE_PAYMENT_TYPE}
    }
    productSort {
        ${TYPE_PRODUCT_SORT}
    }
    productLoad {
        ${TYPE_PRODUCT_LOAD}
    }
    productSize {
        ${TYPE_PRODUCT_SIZE}
    }
    productPalletType {
        ${TYPE_PRODUCT_PALLET_TYPE}
    }
    productBrands {
        ${TYPE_PRODUCT_BRAND}
    }
    customLoad
    customSort
    customSize
    customPalletType
    isBuy
    isNew
    isTax
    isActivated
    isCertificate
    isDeleted
    canVote
    city {
        ${TYPE_CITY}
    }
    houseNumber
    streetName
    price,
    createdAt
    createdBy
    updatedAt
    updatedBy
    isDeleted
    isActivated
    conversationId {
        _id
        members {
            ${TYPE_MEMBERS}
        }
        lastMessageId {
           _id
            message
            seen
            createdAt
            userId {
                ${TYPE_USERID}
            }
            assets {
                ${TYPE_ASSET}
            }
        }
        lastMessageDate
        type
    }
`;

export const TYPE_PRODUCT_CUSTOMER = `
    _id
    assets {
        ${TYPE_ASSET}
    }
    customerId {
        ${TYPE_USER}
    }
    ownerId {
        ${TYPE_USER}
    }
    productId {
        ${TYPE_PRODUCT}
    }
    conversationId {
        _id
        members {
            ${TYPE_MEMBERS}
        }
        lastMessageId {
            _id
            message
            seen
            createdAt
            userId {
                ${TYPE_USERID}
            }
        }
        lastMessageDate
        unreadMessagesCount
        type
        chatClosesUsers
    }
    count
    price
    isFinished
    createdAt
    updatedAt
    isApproved
    isOwnerTransport
    dateLoad
`;
