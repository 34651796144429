import React, { useContext, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import BaseModal from '../index';
import { IconArrow2, IconCellPhone, IconDelete, IconEmail, IconProfile } from 'assets/images/svg';
import { ImageSlider, LoadingSpinner, ToolTip, UploadFileButton } from 'components/index';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CSSTransition } from 'react-transition-group';
import { finishOrderSchema } from '_constants';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { useMutation } from '@apollo/client';
import { CREATE_ASSETS, DEACTIVATE_PRODUCT, UPDATE_PRODUCT_CUSTOMER } from '_graphql';
import { GeneralContext } from '../../../contexts';
import { toBase64Utils } from '../../../utils';
import { useSegment } from 'react-segment-hooks';

const OrderFinishModal = ({ t, modals, change }) => {
    const { orderFinishModal: { display: isOpen, params }, closeOrderFinishModal } = modals;

    const analytics = useSegment();

    const { toggleNewTaraModal, toggleSegmentButton } = useContext(GeneralContext);

    const [loaderState, setLoaderState] = useState(true);

    const { customerId, productData, _id } = useMemo(() => ({
        customerId: params?.customerId,
        productData: params?.productId,
        _id: params?._id
    }), [params]);

    const { register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
        resolver: yupResolver(finishOrderSchema())
    });

    const [isOrderApprove, setIsOrderApprove] = useState(false);
    const [files, setFiles] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [isFinishOptions, setIsFinishOptions] = useState(false);

    const { updateFinishOrder } = change;

    const [updateProductCustomer,
        {
            loading: updateproductCustomerLoading,
            error: updateproductCustomerError
        }
    ] = useMutation(UPDATE_PRODUCT_CUSTOMER);
    const [createAssets, { loading: createAssetsLoading, error: createAssetsError }] = useMutation(CREATE_ASSETS);
    const [deactivateProduct, {
        loading: deactivateProductLoading,
        error: deactivateProductError
    }] = useMutation(DEACTIVATE_PRODUCT);
    const [formValues, setFormValues] = useState();

    useEffect(() => {
        setLoaderState((updateproductCustomerLoading && !updateproductCustomerError) || (createAssetsLoading && !createAssetsError) || (deactivateProductLoading && !deactivateProductError));
    }, [updateproductCustomerLoading, updateproductCustomerError, createAssetsLoading, createAssetsError, deactivateProductLoading, deactivateProductError]);

    useEffect(() => {
        if (isOpen) {
            reset({ count: productData.count || 1, price: productData.price || 0, dateLoad: new Date() });
            analytics.track({
                event: 'Open modal',
                properties: {
                    modalName: 'Order Finish'
                }
            });
        }
    }, [isOpen]);

    useEffect(() => {
        const subscription = watch((value) => {
            setFormValues(value);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const submitData = async (e) => {
        const { count, finishOption, ...restData } = e;
        toggleSegmentButton('Submit', 'Order Finish');

        const updateProductCustomerData = {
            ...restData,
            count,
            isFinished: true,
            isApproved: isOrderApprove,
            isOwnerTransport: JSON.parse(e?.isOwnerTransport)
        };

        await updateProductCustomer({
            variables: { _id, data: updateProductCustomerData },
            update: async (cache, { data }) => {
                const customer = data?.updateProductCustomer;
                if (customer) {
                    let newCustomer = { ...customer };
                    if (files.length > 0) {
                        const assetsData = { model: 'customer', itemId: customer._id };
                        await createAssets(
                            {
                                variables: { data: assetsData, files },
                                update: (assetCache, { data: assetsData }) => {
                                    if (assetsData?.createAssets) {
                                        newCustomer = { ...newCustomer, assets: assetsData?.createAssets };
                                    }
                                }
                            }
                        );
                    }
                    updateFinishOrder(newCustomer);
                    setIsFinishOptions(false);
                    closeOrderFinishModal();
                }
            }
        });

        if (!updateproductCustomerLoading && !updateproductCustomerError) {
            if (finishOption === 'delete') {
                await deactivateProduct({ variables: { _id: productData?._id } });
            }
            if (finishOption === 'edit') {
                toggleNewTaraModal();
            }
        }
    };

    const incrementMinMax = (field) => {
        setValue(field, Number(formValues[field]) + 1);
    };

    const decrementMinMax = (field) => {
        setValue(field, Math.max(Number(formValues[field]) - 1, 1));
    };

    const onChangeDate = (date) => {
        if (!date) {
            console.log(date);
            return;
        }
        const prevDate = new Date(formValues?.dateLoad);
        const newDateWithDay = new Date(prevDate.setDate(date.getDate()));
        const newDateWithMonth = new Date(newDateWithDay.setMonth(date.getMonth()));
        const newDateWithYear = new Date(newDateWithMonth.setFullYear(date.getFullYear()));
        setValue('dateLoad', newDateWithYear);
    };

    const handleFileChange = async (e) => {
        const { files: targetFiles } = e.target;
        const filesWithBase64 = [];

        for (const file of targetFiles) {
            if (file.type?.includes('image/')) {
                filesWithBase64.push({ file, image: await toBase64Utils(file) });
            }
        }
        setImageFiles((prev) => prev.length > 0 ? [...prev, ...filesWithBase64] : filesWithBase64);
        setFiles((prev) => prev.length > 0 ? [...prev, ...targetFiles] : targetFiles);
    };

    const handleCancelButton = () => {
        setFiles([]);
        setIsFinishOptions(false);
        closeOrderFinishModal();

        toggleSegmentButton('Cancel', 'Order Finish');
    };

    const handleRemoveFile = ({ lastModified }) => {
        toggleSegmentButton('Remove File', 'Order Finish');
        setFiles([...files]?.filter((file) => file.lastModified !== lastModified));
        setImageFiles([...imageFiles]?.filter(({ file }) => file.lastModified !== lastModified));
    };

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={handleCancelButton}
            width={imageFiles?.length ? 'w-detailsModal' : 'w-newTaraLeftBlock'}
        >
            <div
                className='w-full text-sm bg-theme-gray-main p-5 font-sans text-theme-blue-light flex-grow min-w-104 flex flex-row gap-4'>
                <div
                    className='text-sm bg-theme-gray-main p-5 font-sans text-theme-blue-light flex-grow min-w-104 flex flex-col'>
                    <p className=' font-normal tracking-wide text-lg leading-5 mb-3.5 text-theme-blue'>{t('modals.productDetails.clientInfo')}</p>
                    <div className='flex flex-row justify-between'>
                        <div className='w-full flex flex-row items-center justify-start mb-5 gap-x-4'>
                            <div className='text-xs tracking-wide flex flex-row gap-3 min-h-full items-center'>
                                <div
                                    className='w-10 h-10 rounded-full bg-theme-blue-main flex items-center justify-center'>
                                    {customerId?.avatar?.assetUrl ? (
                                        <div
                                            className='w-10 h-10 rounded-full bg-theme-blue-main flex items-center justify-center'>
                                            <img className='rounded-full w-10 h-10' src={customerId?.avatar?.assetUrl}
                                                 alt='userImage'/>
                                        </div>
                                    ) : (
                                        <div
                                            className='w-10 h-10 rounded-full bg-theme-blue-main flex items-center justify-center'>
                                            <IconProfile/>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='text-xs tracking-wide flex flex-col justify-center items-start max-w-50'>
                                <p className='truncate text-center  tracking-wide max-w-50'>{customerId?.username}</p>
                                <a data-tip data-for='location'
                                   className='truncate text-left  tracking-wide max-w-50'>{customerId?.location?.name || '-'}</a>
                                <ToolTip id={'location'} title={customerId?.location?.name || '-'}/>
                            </div>
                        </div>
                        <div className='min-w-60 max-w-60 text-xs tracking-wide flex flex-col justify-start'>
                            <div className='flex flex-row items-center gap-x-2 '>
                                <IconEmail className='stroke-current'/>
                                <p data-tip data-for='email'
                                   className='truncate  tracking-wide max-w-45'>{customerId?.email || '-'}</p>
                                <ToolTip id={'email'} title={customerId?.email || '-'}/>
                            </div>
                            <div className='flex flex-row items-center gap-x-2'>
                                <IconCellPhone className='stroke-current mx-0.5'/>
                                <p className=' tracking-wide max-w-45'>{customerId?.phone || '-'}</p>
                            </div>
                        </div>
                    </div>
                    <p className=' font-normal tracking-wide text-lg leading-5 mb-3.5 text-theme-blue'>{t('modals.productDetails.productInfo')}</p>
                    <div className='flex flex-row w-full items-center mb-1'>
                        <p className='min-w-35 truncate  text-theme-blue'>{t('modals.productDetails.address')}</p>
                        <p data-tip data-for='address'
                           className='flex-grow truncate text-theme-blue-light '>{productData?.city?.name} / {productData?.streetName} / {productData?.houseNumber}</p>
                        <ToolTip id={'address'}
                                 title={`${productData?.city?.name} / ${productData?.streetName} / ${productData?.houseNumber}`}/>
                    </div>
                    <div className='flex flex-row justify-between mb-3 gap-2 border-b border-theme-blue-light pb-3'>
                        <div className='w-max flex flex-col gap-1/2'>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.requestType')}</p>
                                <p className='text-theme-blue-light '>{productData?.isBuy ? t('modals.productDetails.buy') : t('modals.productDetails.sell')}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.taraType')}</p>
                                <p className='text-theme-blue-light '>{t(`modals.productDetails.taraTypeOptions.${productData?.productType?.value}`)}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.palletType')}</p>
                                <p className='text-theme-blue-light '>{!productData?.productType?.isPalletType ? '-' : productData?.customPalletType?.name || productData?.productPalletType?.name}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.taraSort')}</p>
                                <p className='text-theme-blue-light '>
                                    {productData?.productSort?.name?.toLowerCase() === 'higher' ?
                                        t('global.productOptions.sortHigher') :
                                        productData?.productSort?.name}
                                </p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.price')}</p>
                                <p className='text-theme-blue-light '><span
                                    className='mr-2'>{productData?.price || 0}</span> {t('modals.productDetails.priceCurrency')}
                                </p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.paymentType')}</p>
                                <p className='text-theme-blue-light '>{productData?.paymentType?.name ? t(`modals.newTaraModal.${productData?.paymentType?.name.toLowerCase()}`) : '-'}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.tax.label')}</p>
                                <p className='text-theme-blue-light '>{t(`modals.productDetails.tax.${productData?.isTax ? 'withTax' : 'noTax'}`)}</p>
                            </div>
                            {/*<div className='flex flex-row w-full items-center'>
                            <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.number')}</p>
                            <p className='text-theme-blue-light '>{productData?.count || 0}</p>
                        </div>*/}
                        </div>
                        <div className='min-w-60 max-w-60 flex flex-col gap-1/2'>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.taraSize')}</p>
                                <p className='text-theme-blue-light  flex-grow flex-wrap'>{productData?.customSize ? productData.customSize : productData?.productSize?.name || '-'}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.taraLoad')}</p>
                                <p className='text-theme-blue-light '>{productData?.productType?.value !== '1' ? '-' : productData?.productLoad?.name}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.min')}</p>
                                <p className='text-theme-blue-light '>{productData?.minCount}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.max')}</p>
                                <p className='text-theme-blue-light '>{productData?.maxCount}</p>
                            </div>
                            <div className='flex flex-row w-full items-start'>
                                <p className='w-35 flex-none truncate  text-theme-blue'>{t('modals.productDetails.brand')}</p>
                                <p className='text-theme-blue-light  flex-grow flex-wrap'>{!productData?.productType?.isBrand ? '-' : productData?.productBrands?.length ? productData?.productBrands?.map((item) => item.name).join(', ') : t('modals.productDetails.noCertificate')}</p>
                            </div>
                            <div className='flex flex-row w-full items-center'>
                                <p className='w-35 truncate  text-theme-blue'>{t('modals.productDetails.certificate')}</p>
                                <p className='text-theme-blue-light '>{!productData?.productType?.isCertificate ? '-' : t(`modals.productDetails.${productData?.isCertificate ? 'withCertificate' : 'noCertificate'}`)}</p>
                            </div>
                        </div>
                    </div>
                    <form id={'finish-order-form'} className='flex flex-col gap-6' onSubmit={handleSubmit(submitData)}>
                        <div className='flex flex-row justify-between'>
                            <div className='w-max flex flex-col gap-2'>
                                <div className='flex flex-row w-full items-center'>
                                    <p className='flex-grow-0 w-35'>{t('modals.newTaraModal.number')}</p>
                                    <div className='relative w-full max-w-29 h-6'>
                                        <input type='number'
                                               {...register('count')}
                                               min={1}
                                               step={1}
                                               className={`w-full h-full pl-2 pr-5 border border-theme-blue rounded-3xl bg-transparent text-xs ${errors?.count ? 'bg-theme-red-light border-theme-red-main ' : 'border-theme-blue'}`}
                                        />
                                        <div className='absolute top-0 right-3 flex flex-col h-6'>
                                            <button type='button'
                                                    className='block h-3 hover:text-theme-blue-light duration-150'
                                                    onClick={() => incrementMinMax('count')}>
                                                <IconArrow2 width={12} className='stroke-current'/>
                                            </button>
                                            <button type='button'
                                                    className='block h-3 hover:text-theme-blue-light duration-150'
                                                    onClick={() => decrementMinMax('count')}>
                                                <IconArrow2 width={12}
                                                            className='transform rotate-180  stroke-current'/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row w-full items-center'>
                                    <p className='flex-grow-0 w-35'>{t('modals.productDetails.transport.title')}</p>
                                    <select
                                        className='block w-full h-6 text-xs px-2 pr-7 max-w-29 border border-theme-blue hover:border-theme-blue-light hover:text-theme-blue-light rounded-3xl bg-transparent duration-100'
                                        name='productSize' {...register('isOwnerTransport')}>
                                        <option key={'transport-owner'}
                                                value={true}>{t('modals.productDetails.transport.owner')}</option>
                                        <option key={'transport-client'}
                                                value={false}>{t('modals.productDetails.transport.client')}</option>
                                    </select>
                                </div>
                                <div className='flex flex-row w-full items-start mt-1'>
                                    <p className='flex-grow-0 w-35'>{t('modals.orderFinish.attach')}</p>
                                    <UploadFileButton onChange={handleFileChange} typeFilter={'*'}
                                                      className={'w-29 text-xs py-1 px-4'}/>
                                </div>
                            </div>
                            <div className='min-w-60 max-w-60 flex flex-col gap-2'>
                                <div className='flex flex-row w-full items-center'>
                                    <p className='flex-grow-0 w-35'>{t('modals.productDetails.price')}</p>

                                    <div className='relative w-full max-w-29 h-6'>
                                        <input type='number'
                                               {...register('price')}
                                               min={1}
                                               step={1}
                                               className={`w-full h-full pl-2 pr-5 border border-theme-blue rounded-3xl bg-transparent text-xs ${errors?.price ? 'bg-theme-red-light border-theme-red-main ' : 'border-theme-blue'}`}
                                        />
                                        <div className='absolute top-0 right-3 flex flex-col h-6'>
                                            <button type='button'
                                                    className='block h-3 hover:text-theme-blue-light duration-150'
                                                    onClick={() => incrementMinMax('price')}>
                                                <IconArrow2 width={12} className='stroke-current'/>
                                            </button>
                                            <button type='button'
                                                    className='block h-3 hover:text-theme-blue-light duration-150'
                                                    onClick={() => decrementMinMax('price')}>
                                                <IconArrow2 width={12}
                                                            className='transform rotate-180  stroke-current'/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row w-full items-start'>
                                    <p className='flex-grow-0 w-35'>{t('modals.productDetails.dateLoad')}</p>

                                    <DatePicker
                                        format={'y/MM/dd'}
                                        calendarIcon={null}
                                        clearIcon={null}
                                        value={formValues?.dateLoad}
                                        onChange={(date) => onChangeDate(date)}
                                        className={'finish-calendar block w-full h-6 flex items-center justify-center px-2 max-w-29 border border-theme-blue hover:border-theme-blue-light hover:text-theme-blue-light rounded-3xl z-1000 duration-100'}
                                    />
                                </div>
                                <div className='flex flex-col w-full gap-1 items-start mt-1'>
                                    {!!Array.from(files)?.length && Array.from(files)?.map((file) => (
                                        <div key={file?.lastModified}
                                             className={'flex flex-row w-full items-center justify-between'}>
                                            <a href={file?.name}
                                               className='max-w-53 w-full flex-grow-0 truncate hover:underline'
                                               download>
                                                {file.name}
                                            </a>
                                            <button onClick={() => handleRemoveFile(file)}
                                                    className={'hover:text-theme-red'}>
                                                <IconDelete className={'stroke-current h-4 w-4'}/>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <button type='submit'
                                    className='flex flex-row items-center justify-center bg-theme-red rounded-3xl text-white h-7 px-7 text-11 hover:bg-theme-red-main duration-100'
                                    onClick={() => {
                                        setIsOrderApprove(false);
                                        toggleSegmentButton('Reject', 'Order Finish');
                                    }}>
                                {t('modals.productDetails.reject')}
                            </button>
                            <div className={'flex flex-row min-w-60 max-w-60 justify-between'}>
                                <button type='button'
                                        className='flex flex-row items-center justify-self-end bg-theme-blue-main rounded-3xl text-theme-blue h-7 px-7 text-11 mr-4 hover:bg-theme-gray-main duration-100'
                                        onClick={handleCancelButton}>
                                    {t('modals.productDetails.cancel')}
                                </button>
                                <button type={'button'}
                                        className='flex flex-row items-center justify-center bg-theme-blue rounded-3xl text-white h-7 px-5 text-11 hover:bg-theme-blue-light duration-100'
                                        onClick={() => {
                                            setIsOrderApprove(true);
                                            setIsFinishOptions(true);

                                            toggleSegmentButton('Save', 'Order Finish');
                                        }}>
                                    {t('modals.productDetails.save')}
                                </button>
                            </div>
                        </div>
                        <CSSTransition
                            classNames='finish-options'
                            in={isFinishOptions}
                            unmountOnExit
                            timeout={300}
                        >
                            <div className='flex flex-col gap-2'>
                                <p className={'text-theme-blue text-center'}>{t('detailsPage.finishModalSelectText')}</p>
                                <div className='flex flex-row justify-between items-start'>
                                    <div className='flex flex-col gap-1 w-1/2'>
                                        <p className={'text-theme-blue text-xs font-bold'}>{t('detailsPage.finishModalSelectTextBold')}</p>
                                    </div>
                                    <div className='flex flex-row gap-2 items-center min-w-60 max-w-60'>
                                        <select
                                            {...register('finishOption')}
                                            className='block w-full h-7 text-xs text-theme-blue px-2 pr-7 border border-theme-blue hover:border-theme-blue-light hover:text-theme-blue-light rounded-3xl bg-transparent duration-100'
                                            defaultValue={''}
                                            name='finishOption'>
                                            <option key={'edit-key'} value={'edit'}>
                                                {t('detailsPage.finishModalEdit')}
                                            </option>
                                            <option key={'delete-key'} value={'delete'}>
                                                {t('detailsPage.finishModalDelete')}
                                            </option>
                                            <option key={'default-key'} value={'default'}>
                                                {t('detailsPage.finishModalNoChanges')}
                                            </option>
                                            <option hidden disabled key={'empty'} value={''}>
                                                {t('detailsPage.finishModalOption')}
                                            </option>
                                        </select>
                                        <button type={'submit'}
                                                className='flex flex-row items-center justify-center bg-theme-blue rounded-3xl text-white h-7 px-4 text-11 hover:bg-theme-blue-light duration-100'
                                                onClick={() => toggleSegmentButton('Confirm', 'Order Finish')}
                                        >
                                            {t('modals.orderFinish.confirm')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    </form>
                </div>
                {!!imageFiles?.length && (
                    <div className='bg-theme-gray-light rounded-2xl w-1/2 content-center overflow-hidden p-2'>
                        <ImageSlider
                            images={imageFiles || []}
                            onlyView={true}
                        />
                    </div>
                )}
            </div>
            <LoadingSpinner loading={loaderState}/>
        </BaseModal>
    )
        ;
};

export default withTranslation()(OrderFinishModal);
