import React from 'react';
import {columnName} from 'components/table/configuration';

export default ({ t }) => ({
    name: columnName.description,
    title: t('table.header.description'),
    excelValue: (item) => item?.description || '',
    thead: function () {
        return (
            <th key={this.name} className={'tableTitle py-3 px-1 align-top text-left'}>
                <p className='whitespace-nowrap font-medium text-xs text-black'>
                    {this.title}
                </p>
            </th>);
    },
    tbody: function (item) {
        return (
            <td key={this.name + item._id} className={'px-1 max-w-20 lg+:max-w-30 w-max-60'}>
                <p className='truncate'>{item.description || '-'}</p>
            </td>
        );
    },
    tabletTitle: function ({ _id }) {
        return (
            <p key={`title_${this.name}${_id}`}
               className='whitespace-nowrap font-medium'>{this.title}</p>
        );
    },
    tabletContent: function ({ _id, description }) {
        return (
            <p key={`content_${this.name}${_id}`} className='truncate max-w-41'>{description || '-'}</p>
        );
    }
})
