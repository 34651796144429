import {withRouter} from 'react-router-dom';

// const AppLayouts = ({children}) => (
//         <div className="h-screen flex flex-col">
//             <main className="relative h-full overflow-y-auto focus:outline-none w-full z-0" >
//                 {children}
//             </main>
//             <Intercom appID="frw8447c" { ...{
//                 user_id: user._id,
//                 email: user.email,
//                 name: user.username,
//                 avatar: {
//                     type: 'avatar',
//                     image_url: user.avatar
//                 }
//             } } />
//         </div>
//     );


const AppLayouts = ({children}) => children;

export default withRouter(AppLayouts);
