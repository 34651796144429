import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import store, {history} from '_redux/store/store';
import {SegmentProvider} from 'react-segment-hooks';
import {useLocale} from '_hooks';
import {LocaleContext} from 'contexts';
import {SEGMENT_KEY} from '_constants';
import App from './index';

import 'translation';

const MainApp = () => {
    const localeContextValue = useLocale();

    return (
        <Provider store={store}>
            <SegmentProvider apiKey={SEGMENT_KEY}>
                <ConnectedRouter history={history}>
                    <LocaleContext.Provider value={localeContextValue}>
                        <Switch>
                            <Route path="/" component={App}/>
                        </Switch>
                    </LocaleContext.Provider>
                </ConnectedRouter>
            </SegmentProvider>
        </Provider>
   );
}

export default MainApp;
